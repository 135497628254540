/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Jan 2019
 * ========================================================================
 */

// @flow

import React, { PureComponent, Fragment } from 'react'
import { Modal, Button, Form, List, Divider } from 'semantic-ui-react'
import { createCancellationPolicy } from 'queries/accommodation'
import { graphql, withApollo } from 'react-apollo'
import { showSuccessNotification, showErrorNotification } from 'utils/notifications'
import { getCancellationDurationString } from 'utils/strings'
import type { Accommodation } from 'utils/flowtypes/models'
import type { SemanticEvent, SemanticEventProps } from 'utils/flowtypes/semantic'
import type { RefetchQueries } from 'utils/flowtypes/apollo'

type Props = {
    open: boolean,
    accommodation: Accommodation,
    onClose(): void,
    onSubmit(): void,
    refetchQueries?: RefetchQueries,
    createCancellationPolicy: Function
}

type State = {
    policyDuration: ?number,
    processing: boolean
}

export const CANCELLATION_POLICY_OPTIONS = [
    {
        key: '24h',
        text: '24 hours',
        value: 1
    },
    {
        key: '48h',
        text: '48 hours',
        value: 2
    },
    {
        key: '72h',
        text: '72 hours',
        value: 3
    },
    {
        key: '7d',
        text: '7 days (1 week)',
        value: 7
    },
    {
        key: '14d',
        text: '14 days (2 weeks)',
        value: 14
    },
    {
        key: '28d',
        text: '28 days (4 weeks)',
        value: 28
    },
    {
        key: '90d',
        text: '90 days',
        value: 90
    },
    {
        key: 'non-refundable',
        text: 'Non-refundable',
        value: 0
    },
]

class CancellationPolicyModal extends PureComponent<Props, State> {
    constructor(props) {
        super(props)

        const { cancellation_policies } = props.accommodation
        let policyDuration = null

        if (cancellation_policies.length > 0) {
            policyDuration = cancellation_policies[0].duration
        }

        this.state = {
            policyDuration,
            processing: false
        }
    }

    handleChange = (e: SemanticEvent, props: SemanticEventProps) => {
        this.setState({ [props.name]: props.value })
    }

    handleSubmit = async () => {
        this.setState({ processing: true })

        const { policyDuration } = this.state

        if (policyDuration != null) {
            const response = await this.props.createCancellationPolicy({
                variables: {
                    accommodationId: this.props.accommodation.id,
                    policy: {
                        duration: policyDuration,
                        allow_instant: true,
                        refundable: policyDuration > 0 ? true : false
                    }
                },
                refetchQueries: this.props.refetchQueries || []
            }).catch(e => e)
    
            this.setState({ processing: false })
    
            if (response instanceof Error) {
                console.error(response)
                showErrorNotification('Unable to update cancellation policy.')
            } else {
                showSuccessNotification('Successfully updated cancellation policy.')
                if (this.props.onSubmit) {
                    this.props.onSubmit()
                }
                this.props.onClose()
            }
        }        
    }

    render() {
        const { policyDuration, processing } = this.state
        const { open, onClose } = this.props

        return (
            <Modal
                open={open}
                size='tiny'
            >
                <Modal.Header>Change Cancellation Policy</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Dropdown
                            name='policyDuration'
                            selection
                            label='Policy Duration'
                            placeholder='Choose policy'
                            options={CANCELLATION_POLICY_OPTIONS}
                            onChange={this.handleChange}
                            value={policyDuration}
                        />
                    </Form>
                    {
                        policyDuration != null && (
                            <Fragment>
                                <Divider section horizontal>Breakdown</Divider>
                                <List bulleted relaxed>
                                    {
                                        policyDuration > 0 ? (
                                            <Fragment>                                                           
                                                <List.Item>{getCancellationDurationString(policyDuration)}’ notice is required to cancel the booking prior to arrival.</List.Item>
                                                <List.Item>This is for all lengths of stay in order to avoid charges.</List.Item>
                                                <List.Item>For guest in house {getCancellationDurationString(policyDuration)}’ notice is required to shorten or extend their stay/booking.</List.Item>
                                                <List.Item>Group Bookings cancellation policy may vary.</List.Item>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <List.Item>Once booking has been made and paid there is no refund.</List.Item>
                                                <List.Item>Group Bookings cancellation policy may vary.</List.Item>
                                            </Fragment>
                                        )   
                                    }
                                </List>
                            </Fragment>
                        )
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={onClose}
                        content='Cancel'
                    />
                    <Button
                        primary
                        onClick={this.handleSubmit}
                        disabled={processing || policyDuration === null}
                        loading={processing}
                        content='Save'
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

export default graphql(
    createCancellationPolicy,
    {
        name: 'createCancellationPolicy'
    }
)(withApollo(CancellationPolicyModal))