/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Yunjia Li <yunjia.li@launchbase.solutions> Nov 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import style from './guestform.less'
import { Segment, Form, Button, Icon, Header } from 'semantic-ui-react'
import { ImageInput, GuestPhotoManager } from 'components'
import ErrorBoundary from 'ErrorBoundary'

type Props = {
    isUpdate: boolean,
    onChange: Function,
    onCancel: Function,
    onSubmit: Function,
	onAddImage: Function,
	guestId?: string,
    guestName: string,
    guestEmail: string,
    contactNumber: string,
	images: Array<Object>,
	loading: boolean
}

const GuestForm = (props: Props) => {

    const { isUpdate, onChange, onCancel, onSubmit, onAddImage, images, guestId, guestName, guestEmail, contactNumber, loading } = props

    return (
        <ErrorBoundary>
            <Segment raised className={style.guest__form}> 
                <Form>
                    <Form.Input
                        fluid
                        label='Guest name'
                        placeholder='Guest name'
                        icon='user'
                        iconPosition='left'
                        name='guestName'
                        required
                        onChange={onChange}
                        value={guestName}
                    />
                    <Form.Input
                        fluid
                        label='Guest email'
                        placeholder='Guest email'
                        icon='mail'
                        iconPosition='left'
                        name='guestEmail'
                        onChange={onChange}
                        value={guestEmail}
                    />
                    <Form.Input
                        fluid
                        label='Contact number'
                        placeholder='Contact number'
                        icon='phone'
                        iconPosition='left'
                        name='contactNumber'
                        required
                        onChange={onChange}
                        value={contactNumber}
                    />
                    <Header dividing as='h4'>ID Documents</Header>
					{
						isUpdate ? (
							<GuestPhotoManager
								guestId={guestId}
								photos={images}
							/>
						)
						:
						(
							<ImageInput
								onChange={onAddImage}
								images={images}
							/>
						)
						
					}
                    
                    <div className={style.guest__form__footer}>
                        <div className={style.guest__form__footer__actions}>
                            <div>
                                <Button
                                    onClick={onCancel}
                                    basic
                                    color='grey'
                                    animated
                                    size='large'
                                >
                                    <Button.Content visible>Cancel</Button.Content>
                                    <Button.Content hidden>
                                        <Icon name='arrow left' />
                                    </Button.Content>
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={onSubmit}
                                    disabled={!guestName || !guestEmail || !contactNumber || loading}
                                    primary
									animated
                                    size='large'
                                    style={{
                                        margin: 0
                                    }}
                                >
                                    <Button.Content visible>Submit</Button.Content>
                                    <Button.Content hidden>
                                        <Icon name='checkmark' />
                                    </Button.Content>
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Segment>
      </ ErrorBoundary>
    )
}

export default GuestForm