/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Oct 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import type { SemanticEvent, SemanticEventProps } from 'utils/flowtypes-sui'
import { List, Dropdown } from 'semantic-ui-react'
import type { Feature, FeatureDefinition } from 'utils/flowtypes/models'

type Props = {
    onClick: Function,
    featureDefinition: FeatureDefinition,
    accommodationFeature?: Feature
}

const ListItem = (props: Props) => {
    
    const { featureDefinition, onClick, accommodationFeature } = props
    
    const handleInteraction = (e: SemanticEvent, data: SemanticEventProps) => {
        onClick({
            id: featureDefinition.id,
            definition: featureDefinition,
            type: featureDefinition.type
        }, data.value)
    }

    switch (featureDefinition.type) {
        case 'boolean': {
            return (
                <List.Item onClick={handleInteraction}>
                    <List.Content>
                        <strong>{featureDefinition.name}</strong>
                        { accommodationFeature && <List.Icon name='checkmark' /> }
                    </List.Content>
                </List.Item>
            )
        }

        case 'number': {
            return (
                <List.Item onClick={handleInteraction}>
                    <List.Content>
                        <span>
                            <strong>{featureDefinition.name}</strong>
                            { accommodationFeature && <span>: {accommodationFeature.value}</span> }
                        </span>
                        { accommodationFeature && <List.Icon name='checkmark' /> }
                    </List.Content>
                </List.Item>
            )
        }

        case 'enum': {
            const options = featureDefinition.meta.options.map(option => {
                return {
                    key: option,
                    text: `${featureDefinition.name}: ${option}`,
                    value: option,
                    content: option
                }
            })

            return (
                <List.Item>
                    <List.Content>
                        <Dropdown
                            inline
                            options={options}
                            value={accommodationFeature?.value || options[0].value}
                            onChange={handleInteraction}
                        />
                        { accommodationFeature && <List.Icon name='checkmark' /> }
                    </List.Content>
                </List.Item>
            )
        }

        default: {
            return (
                <List.Item onClick={handleInteraction}>
                    <List.Content>
                        <strong>{featureDefinition.name}</strong>
                        { accommodationFeature && <List.Icon name='checkmark' /> }
                    </List.Content>
                </List.Item>
            )
        }
    }
}

export default ListItem