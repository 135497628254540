/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Oct 2018
 * ========================================================================
 */

// @flow

import React, { Fragment } from 'react'
import { Item, List, Segment } from 'semantic-ui-react'
import { FeatureDisplay } from 'components'
import style from './featurelists.less'
import { AddFeature } from 'components/FeatureDisplay'
import type { Features } from 'utils/flowtypes/models'

type Props = {
    features: Features,
    onClickChangeFeature: Function,
    onClickRemoveFeature: Function,
    onAddFeatureClick: Function
}

const FeatureLists = (props: Props) => {
    const {
        features,
        onClickChangeFeature,
        onClickRemoveFeature,
        onAddFeatureClick
    } = props

    const FeatureListSection = ({ name, features }) => {

        if (name === 'description') {
            name = 'details'
            features = features.filter(feature => feature.definition.shortcode !== 'description')
        }

        return (
            <Segment vertical>
                <Item.Group>
                    <Item>
                        <Item.Content>
                            <Item.Meta>
                                <span className={style.featurelists__section__heading}>{name}</span>
                                <span><AddFeature onClick={onAddFeatureClick} activeFeatureList={name}/></span>
                            </Item.Meta>
                            <Item.Description className={style.featurelists__section}>
                                <List selection verticalAlign='middle' className={style.featurelists__list}>    
                                    {
                                        features.length > 0 ? (
                                            features.map(feature => {
                                                return (
                                                    <FeatureDisplay
                                                        key={feature.id}
                                                        type={feature.definition.type}
                                                        value={feature.value}
                                                        name={feature.definition.name}
                                                        feature={feature}
                                                        onClickChangeFeature={onClickChangeFeature}
                                                        onClickRemoveFeature={onClickRemoveFeature}
                                                    />
                                                )
                                            })
                                        ) : (
                                            <div>No feature has been specified yet.</div>
                                        )
                                    }
                                </List>
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
        )
    }

    const featureGroups = ['rooms', 'description', 'appliances', 'housekeeping', 'amenities', 'additional services', 'complimentaries']

    return (
        <Fragment>
            {
                featureGroups.map(featureGroup => (
                    <FeatureListSection
                        key={featureGroup}
                        name={featureGroup}
                        features={features.filter(feature => feature.definition.group === featureGroup)}
                    />
                ))
            }
        </Fragment>
    )
}

export default FeatureLists