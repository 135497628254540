/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Dropdown } from 'semantic-ui-react'
import { getLanguageDropdownOptions } from 'utils'
import { setLocale } from 'store/locale/actions'
import _ from 'lodash'
import intl from 'react-intl-universal'
import SUPPORTED_LOCALES from 'resources/language/supported'
import localise from 'languages'

const mapStateToProps = state => {
    return {
        locale: state.locale
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLocale: locale => dispatch(setLocale(locale))
    }
}

class LanguageSwitcher extends PureComponent {
    handleChange = async (e, {value}) => {        
        const currentLocale = _.find(SUPPORTED_LOCALES, { value }) || _.find(SUPPORTED_LOCALES, { value: 'en-US' })

        await intl.init({
            currentLocale: currentLocale.value,
            locales: {
                [currentLocale.value]: await currentLocale.loader()
            }
        })

        this.props.setLocale(value)
    }

    render() {
        const languageDropdown = getLanguageDropdownOptions()

        return (
            <div>
                <span>
                    {localise('LANGUAGE')}:{' '}
                    <Dropdown
                        inline
                        upward
                        options={languageDropdown}
                        onChange={this.handleChange}
                        defaultValue={this.props.locale}
                    />
                </span>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher)