/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

import React, {PureComponent} from 'react'
import Geocode from 'react-geocode';
import { Wizard, CustomMap } from 'components'
import { maps } from 'config'
import { addressBitsToHTML, addressBitsToText } from 'utils'
import commonStyle from 'styles/app.less'

import {
    Button,
    Icon,
    Segment,
    Divider,
    Header,
    Message
} from 'semantic-ui-react'

class ConfirmPropertyAddressStep extends PureComponent{

    componentDidMount = ()=>{
        Geocode.setApiKey(maps.api_key);
    }

    componentDidUpdate = async()=>{
        const {
            activeStep,
            street,
            town,
            county,
            postcode,
            onChange,
            coords
        } = this.props

        if(activeStep == 2 && !coords.lat){    
            let addressText = addressBitsToText([street, town, county, postcode])
            let response = await Geocode.fromAddress(addressText)
            const { lat, lng } = response.results[0].geometry.location;
            onChange(this, {name:'coords', value:{lat:lat, lng:lng}})
        }
    }

    render(){
        const {
            activeStep,
            step,
            onClickNext,
            onClickBack,
            onChange,
            street,
            town,
            county,
            postcode,
            coords,
            stepCount
        } = this.props

        return (
            <Wizard.Step
                step={step}
                activeStep={activeStep}
                panel={
                    <Wizard.Panel
                        step={step}
                        totalSteps={stepCount}
                        header='Is this address correct?'
                        nextAction={
                            <Button onClick={onClickNext}
                                primary 
                                animated
                                disabled={!street || !postcode}
                            >
                                <Button.Content visible>Yes</Button.Content>
                                <Button.Content hidden>
                                    <Icon name='arrow right' />
                                </Button.Content>
                            </Button>
                        }
                        previousAction={
                            <Button
                                onClick={onClickBack}
                                basic
                                color='grey'
                                animated
                            >
                                <Button.Content visible>No</Button.Content>
                                <Button.Content hidden>
                                    <Icon name='arrow left' />
                                </Button.Content>
                            </Button>
                        }
                    >

                        <Message className={commonStyle.veryBasicMessage}>
                            {addressBitsToHTML([street, town, county, postcode])}
                        </Message>
                        <Divider hidden />
                        <div>
                            <CustomMap 
                                zoom={18} 
                                markerEnabled
                                coords={coords}
                                markerDraggable
                                onMarkerDragEnd={onChange}
                                googleMapURL = {`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${maps.api_key}&libraries=geometry,drawing,places`}
                                loadingElement={<div style={{ height: '100%' }} />}
                                containerElement={<div style={{ height: '400px' }} />}
                                mapElement={<div style={{ height: '100%' }} />}
                            />
                            {/* <iframe
                                width='800'
                                height='250'
                                frameBorder='0'
                                style={{
                                    border: 0,
                                    maxWidth: '100%',
                                    borderRadius: '0.3rem'
                                }}
                                src={`https://www.google.com/maps/embed/v1/place?key=${maps.api_key}&q=${encodeURI(postcode)}`}
                                allowFullStep
                            /> */}
                        </div>
                    </Wizard.Panel>
                }
                extraPanel={
                    <Wizard.ExtraPanel desktopOnly>
                        <Segment padded='very' basic textAlign='center'>
                            <Icon.Group size='massive'>
                                <Icon name='map' />
                                <Icon corner name='marker' />
                            </Icon.Group>
                            <Divider hidden />
                            <Header as='h4'>Is this address correct?</Header>
                            <p>
                                If the marker on the map is incorrect, please drag the marker to the correct location!
                            </p>
                        </Segment>
                    </Wizard.ExtraPanel>
                }
            />
        )
    }
}

export default ConfirmPropertyAddressStep