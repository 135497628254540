/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Jan 2019
 * ==========================================================================
 */

// @flow

import React from 'react'
import style from './listing.less'
import { Header, List, Divider } from 'semantic-ui-react'
import type { Features } from 'utils/flowtypes/models'
import { displayEnumFeatureValue, displayNumberFeatureValue } from 'utils/features'

type Props = {
    features: Features
}

export const FACILITY_GROUPS = [
    {
        key: 'description',
        text: 'General',
    },
    {
        key: 'appliances',
        text: 'Appliances',
    },
    {
        key: 'housekeeping',
        text: 'Housekeeping'
    },
    {
        key: 'amenities',
        text: 'Amenities'   
    },
    {
        key: 'additional services',
        text: 'Services'
    },
    {
        key: 'complimentaries',
        text: 'Complimentaries'
    }
]

export const NON_FACILITIES = [
    'description',
    'size_m2'
]

const Facilities = (props: Props) => {

    const facilities = props.features.filter(feature => {
        return !NON_FACILITIES.includes(feature.definition.shortcode)
    })

    return (
        <div>
            <Header id='facilities' as='h3'>Facilities</Header>
            <div className={style.listing__facilities}>
                {
                    FACILITY_GROUPS.map(group => {
                        const groupFacilities = facilities.filter(feature => feature.definition.group === group.key)

                        if (groupFacilities.length > 0) {
                            return (
                                <div className={style.listing__facilities__section} key={group.key}>
                                    <Header as='h4' content={group.text} />
                                    <List>
                                        {
                                            groupFacilities.map(facility => {
                                                switch (facility.definition.type) {
                                                    case 'enum':
                                                        return (
                                                            <List.Item
                                                                key={facility.id}
                                                            >
                                                                <List.Icon name='check' size='small' verticalAlign='middle' /> 
                                                                {` ${displayEnumFeatureValue(facility)}`}
                                                            </List.Item>
                                                        )
                                                        
                                                    case 'number':
                                                        return (
                                                            <List.Item
                                                                key={facility.id}
                                                            >
                                                                <List.Icon name='check' size='small' verticalAlign='middle' /> 
                                                                {` ${displayNumberFeatureValue(facility)}`}
                                                            </List.Item>
                                                        )

                                                    case 'boolean':
                                                        return (
                                                            <List.Item
                                                                key={facility.id}
                                                            >
                                                                <List.Icon name='check' size='small' verticalAlign='middle' /> 
                                                                {' '}{facility.name}
                                                            </List.Item>    
                                                        )
                                                
                                                    default:
                                                        return (
                                                            <List.Item
                                                                key={facility.id}
                                                            >
                                                                <List.Icon name='check' size='small' verticalAlign='middle' /> 
                                                                {' '}{facility.name}
                                                            </List.Item>    
                                                        )
                                                }
                                            })
                                        }
                                    </List>
                                </div>
                            )
                        } else {
                            return null
                        }
                    })
                }
            </div>
            <Divider section />
        </div>
    )
}

export default Facilities