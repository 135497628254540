/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Yunjia Li <yunjia.li@launchbase.solutions>, Nov 2018
 * ========================================================================
 */

// @flow

import React, {PureComponent} from 'react'
import style from './itinerarycard.less'
import { Link } from 'react-router-dom'
import { GuestLabel } from 'components'
import ErrorBoundary from 'ErrorBoundary'
import { withApollo } from 'react-apollo'
import type { Accommodation, Guest, Tax } from 'utils/flowtypes/models'
import { Item, List, Button } from 'semantic-ui-react'
import { displayPrice } from 'utils/money'
import { randomString } from 'utils'
import moment from 'moment'

type Props = {
    pricing: {
        price: number,
		currency: string,
		tax: number,
		taxes: Array<Tax>
    },
    preBookingId: string,
    accommodation: Accommodation,
    checkIn: string,
    checkOut: string,
    guests: Array<Guest>,
    onRemove: Function
}

class ItineraryCard extends PureComponent<Props> {

    render(){
        const { accommodation, checkIn, checkOut, pricing, guests, onRemove } = this.props
        
        const {
            id,
            name,
            property,
            attachments,
            features
        } = accommodation

        const image = attachments.length >= 1 ? attachments[0].url : 'https://picsum.photos/800/600'
        const size = features.find(feature => feature.definition.shortcode === 'size_m2')
        const nonsmoking = features.find(feature => feature.definition.shortcode === 'non_smoking')
        const bedrooms = features.find(feature => feature.definition.shortcode === 'bedrooms')

        return (
            <ErrorBoundary>
                <Item>
                    <Item.Image
                        src={image}
                        floated='left'
                        size='small'
                    />
                    <Item.Content>
                        <div className={style.itinerarycard__title}>
                            <Link to={`listings/${id}`}><strong>{ name }</strong></Link>
                            <div>
                                <span>{ property.name ? property.name : '' }</span>
                            </div>
                        </div>
                        <Item.Meta>
                            <span>Check in: {moment(checkIn).format('Do MMM YYYY')}</span><br/>
                            <span>Check out: {moment(checkOut).format('Do MMM YYYY')}</span>
                        </Item.Meta>
                        <Item.Meta>
                            Occupant(s): 
                            {' '}{
                                guests.map(g=>{
                                    return (
                                        <GuestLabel guest={g} key={`${g.id}_${randomString()}`}/>
                                    )
                                })
                            }
                        </Item.Meta>
                        <List horizontal bulleted>
                            {
                                size &&
                                <List.Item>{`${size.value}m2`}</List.Item>
                            }
                            {
                                nonsmoking &&
                                <List.Item>Non-smoking</List.Item>
                            }
                            {
                                bedrooms &&
                                <List.Item>{`${bedrooms.value} bedrooms`}</List.Item>
                            }
                        </List>
                        <Item.Extra>
                            <Button size='mini' floated='right' color='red' onClick={onRemove}>Remove</Button>
                        </Item.Extra>
                    </Item.Content>
                    <Item.Content floated='right'>
                        {`${displayPrice(pricing.currency, pricing.price)} + ${displayPrice(pricing.currency, pricing.tax)} VAT`}
                    </Item.Content>
                </Item>
            </ErrorBoundary>
        )
    }
}

export default withApollo(ItineraryCard)