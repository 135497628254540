/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Jan 2019
 * ========================================================================
 */

// @flow

import React, { PureComponent, Fragment } from 'react'
import style from './photomanager.less'
import Dropzone from 'react-dropzone'
import Sortable from 'react-sortablejs'
import _ from 'lodash'
import cx from 'classnames'
import { Gallery } from 'components'
import { uploadImageToCloudinary } from 'utils/files'
import { sortAttachments } from 'utils'
import { showErrorNotification, showSuccessNotification } from 'utils/notifications'
import { Segment, Icon, Header, Form, Button } from 'semantic-ui-react'
import { withApollo, compose, graphql } from 'react-apollo'
import type { ID, Accommodation, Attachment } from 'utils/flowtypes/models'
import type { SemanticEvent, SemanticEventProps } from 'utils/flowtypes/semantic'
import {
    setAccommodationAttachmentOrder,
    addAccommodationAttachment,
    updateAttachment,
    removeAttachment
} from 'queries/accommodation'

type CustomAttachment = {
    loading?: boolean,
    selected?: boolean
} & Attachment

type CustomFile = {
    id: string,
    file: {
        preview: string,
        name: string
    } & File
}

type SortablePhotosProps = {
    photos: CustomAttachment[],
    onChange: Function,
    onClick: Function
}

const SortablePhotos = (props: SortablePhotosProps) => {
    const photos = props.photos.map(photo => {
        return (
            <div
                key={photo.id}
                data-id={photo.id}
                className={
                    photo.selected
                    ? style.photomanager__gallery__photo__selected
                    : style.photomanager__gallery__photo
                }
                onClick={() => props.onClick(photo)}
            >
                <img src={photo.url} />
            </div>
        )
    })

    return (
        <Sortable
            onChange={props.onChange}    
        >
            {photos}
        </Sortable>
    )
}

type Props = {
    onChangeAttachmentOrder(attachmentOrder: ID[]): void,
    onAddAttachment(): void,
    onRemoveAttachment(): void,
    onUpdateAttachment(): void,
    addAccommodationAttachment: Function,
    setAccommodationAttachmentOrder: Function,
    updateAttachment: Function,
    removeAttachment: Function,
    accommodation: Accommodation
}

type State = {
    processing: boolean,
    description: ?string,
    name: ?string,
    selectedPhotos: CustomAttachment[],
    filesToUpload: CustomFile[],
    attachmentOrder: ID[],
    galleryOpen: boolean
}

class PhotoManager extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            processing: false,
            description: null,
            name: null,
            selectedPhotos: [],
            filesToUpload: [],
            attachmentOrder: props.accommodation.attachmentOrdering ? props.accommodation.attachmentOrdering : [],
            galleryOpen: false
        }
    }

    handleRemoveAttachments = async (attachmentIds: ID[]) => {
        this.setState({ processing: true })

        attachmentIds.forEach(async attachmentId => {
            await this.props.removeAttachment({
                variables: { attachmentId }
            }).catch(e => console.error(e))
        })

        this.setState({ selectedPhotos: [] }, () => {
            // the timeout is a weird apollo refetch hotfix
            setTimeout(() => {
                this.props.onRemoveAttachment()
                this.setState({ processing: false })
                if (attachmentIds.length > 1) {
                    showSuccessNotification(`Successfully removed ${attachmentIds.length} photos.`)
                } else {
                    showSuccessNotification('Successfully removed photo.')
                }
            }, 1000)
        })
    }

    handleUpdateAttachment = async attachmentId => {        
        const { name, description } = this.state
        // name cannot be empty
        if (name) {
            const response = await this.props.updateAttachment({
                variables: {
                    attachmentId,
                    attachmentDelta: {
                        name,
                        description
                    }
                }
            }).catch(e => e)

            if (response instanceof Error) {
                console.error(response)
                showErrorNotification('Unable to update photo.')
            } else {
                this.props.onUpdateAttachment()
            }
        }
    }

    handleChangeOrder = (attachmentOrder: ID[]) => {
        this.setState({ attachmentOrder }, async () => {
            await this.props.setAccommodationAttachmentOrder({
                variables: {
                    accommodationId: this.props.accommodation.id,
                    type: 'image',
                    order: attachmentOrder
                }
            })
        })
    }

    handleOpenGallery = () => this.setState({ galleryOpen: true })

    handleCloseGallery = () => this.setState({ galleryOpen: false })

    handleClickPhoto = (photo: CustomAttachment) => {
        this.setState(prevState => {
            const prevSelectedPhotos = prevState.selectedPhotos
            const alreadySelected = prevSelectedPhotos.find(sP => sP.id === photo.id)
            if (alreadySelected) {
                // deselect it by removing it from the selected photos
                // if previously there were two photos, there will now be a single photo
                // so set the description and name field up ready for editing
                const currentlySelectedPhotos = prevSelectedPhotos.filter(sP => sP.id !== photo.id)
                return {
                    selectedPhotos: currentlySelectedPhotos,
                    description: currentlySelectedPhotos.length === 1 ? currentlySelectedPhotos[0].description : '',
                    name: currentlySelectedPhotos.length === 1 ? currentlySelectedPhotos[0].name  : '',
                }
            } else {
                // select it by adding it to the selected photos
                // if previously there were no photos, there will now be a single photo
                // so set the description and name field up ready for editing
                return {
                    selectedPhotos: prevSelectedPhotos.concat(photo),
                    description: prevSelectedPhotos.length === 0 ? photo.description : '',
                    name: prevSelectedPhotos.length === 0 ? photo.name : '',
                }
            }
        })
    }

    handleDropRejected = files => {
        showErrorNotification('Only image files are allowed')
        console.error('Invalid file type submitted;', files)
    }

    handleDropAccepted = files => {
        const filesToUpload = files.map(file => {
            return {
                id: _.uniqueId('temp_'),
                file: file
            }
        })

        this.setState(() => {
            return { filesToUpload }
        }, this._uploadFiles)
    }

    handleChange = (e: SemanticEvent, props: SemanticEventProps) => {
        this.setState({ [props.name]: props.value })
    }

    _uploadFiles = async () => {
        const { filesToUpload } = this.state
        const cloudinaryPhotos = await Promise.all(filesToUpload.map(fileToUpload => {
            const { id, file } = fileToUpload
            return uploadImageToCloudinary(file, 'apartment_images_preset', id)
        }))
        
        cloudinaryPhotos.forEach(async cloudinaryPhoto => {
            if (cloudinaryPhoto instanceof Error) {
                console.error(cloudinaryPhoto)
            } else {
                const response = await this.props.addAccommodationAttachment({
                    variables: {
                        accommodationId: this.props.accommodation.id,
                        name: cloudinaryPhoto.name,
                        type: 'image',
                        url: cloudinaryPhoto.url
                    }
                }).catch(e => e)

                if (response instanceof Error) {
                    console.error(response)
                } else {
                    this.setState(prevState => {
                        // remove photo from files to upload
                        const filesToUpload = prevState.filesToUpload.filter(file => {
                            file.id !== cloudinaryPhoto.id
                        })

                        return { filesToUpload }
                    }, this.props.onAddAttachment)
                }
            }
        })
    }

    render() {
        const { accommodation } = this.props
        const { description, name, galleryOpen, processing, attachmentOrder } = this.state
        const selectedPhotos = this.state.selectedPhotos

        const accommodationPhotos = accommodation.attachments.filter(attachment => attachment.type === 'image')
        const templatePhotos = accommodation.template.attachments.filter(attachment => attachment.type === 'image')
        let photos = accommodationPhotos.concat(templatePhotos)
        photos = sortAttachments(photos, attachmentOrder)

        let photosToUpload = this.state.filesToUpload.map(fileToUpload => {
            // $FlowFixMe
            return {
                id: fileToUpload.id,
                name: fileToUpload.file.name,
                description: '',
                type: 'image',
                url: fileToUpload.file.preview,
                uploading: true,
                tags: []
            }
        })

        if (photosToUpload.length > 0)
            photos = photos.concat(photosToUpload)

        const selectedPhotosIds = selectedPhotos.map(selectedPhoto => selectedPhoto.id)

        photos = photos.map(photo => {
            return {
                ...photo,
                selected: selectedPhotosIds.includes(photo.id)
            }
        })

        return (
            <div className={style.photomanager}>
                <div className={style.photomanager__main}>
                    <Header as='h3'>
                        Upload Photos
                        <Header.Subheader>
                            An accommodation must have atleast 4 photos before it can be listed.
                        </Header.Subheader> 
                    </Header>
                    <div className={style.photomanager__uploader}>
                        <Dropzone
                            className={style.photomanager__dropzone}
                            accept='image/*'
                            onDropRejected={this.handleDropRejected}
                            onDropAccepted={this.handleDropAccepted}
                            multiple
                        >
                            <div className={style.photomanager__dropzone__inner}>
                                <div><Icon name='image' size='huge' /></div>
                                <p>Click to upload photos</p>
                            </div>
                        </Dropzone>
                    </div>
                    {
                        photos.length > 0 && (
                            <Fragment>
                                <Header as='h3'>
                                    Current Photos
                                    <Header.Subheader>
                                        Drag and drop the photos to change the order they will appear in the listing.
                                    </Header.Subheader>
                                </Header>
                                <div    
                                    className={
                                        processing || photosToUpload.length > 0
                                        ? cx(style.photomanager__gallery, style.photomanager__gallery__disabled)
                                        : style.photomanager__gallery
                                    }
                                >
                                    <SortablePhotos
                                        photos={photos}
                                        onChange={this.handleChangeOrder}
                                        onClick={this.handleClickPhoto}
                                    />
                                </div>
                            </Fragment>
                        )
                    }
                </div>
                <div className={style.photomanager__side}>
                    {
                        selectedPhotos.length === 1 && (
                            <Segment>
                                <div
                                    className={style.photomanager__side__image}
                                    onClick={this.handleOpenGallery}
                                >
                                    <img src={selectedPhotos[0].url} />
                                </div>
                                <Form>
                                    <Form.Input
                                        label='Photo name'
                                        placeholder='Photo name'
                                        name='name'
                                        value={name}
                                        onChange={this.handleChange}
                                    />
                                    <Form.TextArea
                                        label='Photo description'
                                        placeholder='Add a description for your photo'
                                        name='description'
                                        value={description}
                                        onChange={this.handleChange}
                                    />
                                    <Button
                                        disabled={
                                            !name || (
                                                name === selectedPhotos[0].name &&
                                                description === selectedPhotos[0].description
                                            )
                                        }
                                        fluid
                                        style={{
                                            marginBottom: '1rem'
                                        }}
                                        primary
                                        content='Save changes'
                                        onClick={() => this.handleUpdateAttachment(selectedPhotos[0].id)}
                                    />
                                    <Button
                                        color='red'
                                        fluid
                                        basic
                                        content='Remove photo'
                                        onClick={() => this.handleRemoveAttachments([selectedPhotos[0].id])}
                                    />
                                </Form>
                                <Gallery
                                    active={galleryOpen}
                                    onClickOutside={this.handleCloseGallery}
                                    onClose={this.handleCloseGallery}
                                    images={selectedPhotos.map(photo => {
                                        return { src: photo.url }
                                    })}
                                />
                            </Segment>
                        )
                    }
                    {
                        selectedPhotos.length > 1 && (
                            <Segment>
                                <div
                                    className={style.photomanager__side__imageStack}
                                    onClick={this.handleOpenGallery}
                                >
                                    {
                                        selectedPhotos.map(photo => (
                                            <div key={photo.id}>
                                                <img src={photo.url} />
                                            </div>
                                        ))
                                    }
                                </div>
                                <Button
                                    color='red'
                                    fluid
                                    basic
                                    content={`Remove ${selectedPhotos.length} photos`}
                                    onClick={() => this.handleRemoveAttachments(selectedPhotos.map(photo => photo.id))}
                                />
                                <Gallery
                                    active={galleryOpen}
                                    onClickOutside={this.handleCloseGallery}
                                    onClose={this.handleCloseGallery}
                                    images={selectedPhotos.map(photo => {
                                        return { src: photo.url }
                                    })}
                                />
                            </Segment>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default compose(
    graphql(
        addAccommodationAttachment,
        {
            name: 'addAccommodationAttachment'
        }
    ),
    graphql(
        setAccommodationAttachmentOrder,
        {
            name: 'setAccommodationAttachmentOrder'
        }
    ),
    graphql(
        updateAttachment,
        {
            name: 'updateAttachment'
        }
    ),
    graphql(
        removeAttachment,
        {
            name: 'removeAttachment'
        }
    )
)(withApollo(PhotoManager))