exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".widget__header__2G8Y4 {\n  position: relative;\n  min-height: 1rem;\n}\n.widget__header__2G8Y4:after {\n  content: \"\";\n  display: table;\n  clear: both;\n}\n.widget__headertext__2PPKV {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  display: inline-block;\n  margin: 0!important;\n}\n.widget__action__EdErs {\n  float: right;\n}\n.widget__action__EdErs button {\n  margin-right: 0 !important;\n}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/Widget/widget.less"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,iBAAiB;CAClB;AACD;EACE,YAAY;EACZ,eAAe;EACf,YAAY;CACb;AACD;EACE,mBAAmB;EACnB,SAAS;EACT,4BAA4B;EAC5B,sBAAsB;EACtB,oBAAoB;CACrB;AACD;EACE,aAAa;CACd;AACD;EACE,2BAA2B;CAC5B","file":"widget.less","sourcesContent":[".widget__header {\n  position: relative;\n  min-height: 1rem;\n}\n.widget__header:after {\n  content: \"\";\n  display: table;\n  clear: both;\n}\n.widget__headertext {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  display: inline-block;\n  margin: 0!important;\n}\n.widget__action {\n  float: right;\n}\n.widget__action button {\n  margin-right: 0 !important;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"widget__header": "widget__header__2G8Y4",
	"widget__headertext": "widget__headertext__2PPKV",
	"widget__action": "widget__action__EdErs"
};