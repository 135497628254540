/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Dec 2018
 * ========================================================================
 */

// @flow

import React, { PureComponent, Fragment } from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { Form, Popup } from 'semantic-ui-react'
import { transformDateToSimpleDate } from 'utils/dates'
import type { DateRanges } from 'utils/flowtypes/models'
import moment from 'moment'

type Props = {
    label?: string,
    popupHeader?: string,
    initialFromDate?: Date,
    initialToDate?: Date,
    fluid?: boolean,
    onChange?: Function,
    fromPlaceholder?: string,
    toPlaceholder?: string,
    inputClassname?: string,
    width?: number,
    disabledDayRanges?: DateRanges
}

type State = {
    from: ?Date,
    to: ?Date,
    derivedStateFromProps: boolean
}

type Modifiers = {
    start?: boolean,
    end?: boolean,
    selected?: boolean,
    today?: boolean,
    disabled?: boolean
}

class DayRangePickerInput extends PureComponent<Props, State> {
    state = {
        from: null,
        to: null,
        derivedStateFromProps: false
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (!state.derivedStateFromProps) {
            return {
                ...state,
                from: props.initialFromDate || null,
                to: props.initialToDate || null,
                derivedStateFromProps: true
            }
        }

        return null
    }

    handleChange = () => {
        if (this.props.onChange) {
            this.props.onChange({
                from: this.state.from,
                to: this.state.to
            })
        }
    }

    handleDayClick = (day: Date, modifiers: Modifiers = {}) => {
        // check to see if the chosen day is disabled
        if (modifiers.disabled)
            return null

        // check to see if any disabled dates are within the selected date range
        const { disabledDayRanges } = this.props
        const { from } = this.state

        if (disabledDayRanges) {
            if (from) {
                const invalid = disabledDayRanges.some(disabledDayRange => (
                    moment(disabledDayRange.from).isBetween(from, day, null, []) ||
                    moment(disabledDayRange.to).isBetween(from, day, null, []) ||
                    moment(disabledDayRange.from).isBetween(day, from, null, []) ||
                    moment(disabledDayRange.to).isBetween(day, from, null, [])
                ))

                if (invalid) return null
            }
        }

        const range = DateUtils.addDayToRange(day, this.state)
        this.setState(range, this.handleChange)
    }

    render() {
        const {
            label,
            fluid,
            width,
            fromPlaceholder = 'From',
            toPlaceholder = 'To',
            inputClassname,
            disabledDayRanges = []
        } = this.props
        const { from, to } = this.state
        const now = new Date()
        const past = { before: new Date() }
        const modifiers = { start: from, end: to }
        const fromValue = from ? transformDateToSimpleDate(from) : fromPlaceholder
        const toValue = to ? transformDateToSimpleDate(to) : toPlaceholder
        const initialMonth = from ? from : now
        const disabledDays = [past].concat(disabledDayRanges)

        return (
            <Fragment>
                <Popup
                    on='focus'
                    position='bottom center'
                    trigger={
                        <Form.Input
                            className={inputClassname}
                            fluid={fluid}
                            label={label}
                            value={`${fromValue} → ${toValue}`}
                            width={width}
                        />
                    }
                    content={
                        <DayPicker
                            className='Selectable'
                            numberOfMonths={1}
                            selectedDays={[from, { from, to }]}
                            fromMonth={now}
                            initialMonth={initialMonth}
                            disabledDays={disabledDays}
                            onDayClick={this.handleDayClick}
                            modifiers={modifiers}
                        />
                    }
                />
            </Fragment>
        )
    }
}

export default DayRangePickerInput

