/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import { SET_ACCESS_TOKEN, SET_REFRESH_TOKEN, SET_SESSION_EXPIRATION, SET_SESSION_GEODATA } from './actions'
import { HYDRATE } from 'store/store.js'
import type { Geodata } from 'utils/flowtypes/store'

export type SessionState = {
    accessToken: string,
    refreshToken: string,
    sessionExpiresAt: string,
    geodata: ?Geodata
}

export default function session(state: SessionState = {}, action) {
    switch (action.type) {
        case SET_ACCESS_TOKEN:
            return Object.assign({}, state, {
                accessToken: action.accessToken
            })
        case SET_REFRESH_TOKEN:
            return Object.assign({}, state, {
                refreshToken: action.refreshToken
            })
        case SET_SESSION_EXPIRATION:
            return Object.assign({}, state, {
                sessionExpiresAt: action.sessionExpiresAt
            })
        case SET_SESSION_GEODATA:
            return Object.assign({}, state, {
                geodata: action.sessionGeodata
            })
        case HYDRATE:
            return Object.assign({}, state, {
                accessToken: action.serializedState.session.accessToken,
                refreshToken: action.serializedState.session.refreshToken,
                sessionExpiresAt: action.serializedState.session.sessionExpiresAt,
                geodata: action.serializedState.session.geodata
            })
        default:
            return state
    }
}