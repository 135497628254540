/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Louis Capitanchik <louis.capitanchik@launchbase.solutions>, August 2018
 * ==================================================================================
 */

import EventEmitter from 'events'

/**
 * A class for running multiple async actions in a row, but only emitting the results of the most recent execution.
 *
 * An action can be run, and it will be given a ticket automatically, or a ticket can be acquired ahead of time and
 * provided alongside the execution, allowing for a single ticket to be reused for several operations
 */
class Ticketmaster extends EventEmitter {
	/**
	 * The latest ticket to have been issued. This will be used to determine whether or not to emit the results of
	 * a given execution
	 *
	 * @type {Symbol|null}
	 * @private
	 */
	_ticket = null

	/**
	 * Create a new ticket, and set that as the last ticket issued
	 *
	 * An alternative use for acquiring a ticket is to simply invalidate the latest execution, and discard the ticket
	 * after acquisition
	 *
	 * @returns {Symbol}
	 */
	getTicket() {
		this._ticket = Symbol()
		return this._ticket
	}

	/**
	 * Run a function and conditionally emit the result depending on whether or not this execution holds the last
	 * ticket.
	 *
	 * If a ticket has been acquired ahead of execution, it can be used multiple times to emit the results of a set
	 * of operations, as long as that ticket is still the most recent ticket issued
	 *
	 * @param {Function} fn
	 * @param {Symbol} ticket
	 * @returns {Promise<void>}
	 */
	async run(fn, ticket = this.getTicket()) {
		const result = await fn()
		if (ticket === this._ticket) {
			this.emit('data', result)
		}
	}
}

export default Ticketmaster
