/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import PubSub from 'pubsub-js'

export const showErrorNotification = (message = '', title = '') => {
    PubSub.publish('alert', {
        type: 'error',
        message,
        title
    })
}

export const showSuccessNotification = (message = '', title = '') => {
    PubSub.publish('alert', {
        type: 'success',
        message,
        title
    })
}

export const updateTicker = message => {
    PubSub.publish('ticker', {
        message
    })
}