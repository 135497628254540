exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/*\n * Copyright (C) LaunchBase LTD - All Rights Reserved \n * Unauthorized copying of this file, via any medium is strictly prohibited \n * Proprietary and confidential \n * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Jan 2019\n * ========================================================================\n */\n.suggestions__3Ex4G .menu__1cIqt {\n  margin-top: 0.5rem;\n}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/InputWithSuggestions/inputwithsuggestions.less"],"names":[],"mappings":"AAAA;;;;;;GAMG;AACH;EACE,mBAAmB;CACpB","file":"inputwithsuggestions.less","sourcesContent":["/*\n * Copyright (C) LaunchBase LTD - All Rights Reserved \n * Unauthorized copying of this file, via any medium is strictly prohibited \n * Proprietary and confidential \n * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Jan 2019\n * ========================================================================\n */\n.suggestions .menu {\n  margin-top: 0.5rem;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"suggestions": "suggestions__3Ex4G",
	"menu": "menu__1cIqt"
};