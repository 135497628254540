/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Feb 2019
 * ========================================================================
 */

// @flow

import React from 'react'
import { Modal, Button, Icon, Item, Header, Grid, Message } from 'semantic-ui-react'
import { displayTimestamp } from 'utils/dates'
import type { Certification } from 'utils/flowtypes/models'

type Props = {
    certification: Certification,
    onClose(): void,
    open: boolean
}

const CertificationModal = (props: Props) => {
    const { certification, open, onClose } = props

    const rejectionNotes = certification.notes.filter(note => note.tags.includes('rejection'))

    let active = true
    if (new Date(certification.issued_at) > Date.now()) {
        active = false
    }

    return (
        <Modal
            open={open}
            size='tiny'
            onClose={onClose}
        >
            <Header>
                <Icon name='certificate' />
                <Header.Content>
                    Certification
                    <Header.Subheader>{certification.name}</Header.Subheader>
                </Header.Content>
            </Header>
            <Modal.Content>
                <Item.Group>
                    <Item>
                        <Item.Content>
                            <Item.Meta>Name</Item.Meta>
                            <Item.Description>{certification.name}</Item.Description>
                        </Item.Content>
                    </Item>
                    <Item>
                        <Item.Content>
                            <Item.Meta>Active</Item.Meta>
                            <Item.Description>
                                { certification.approved === 'approved' && active && 'Yes' }
                                { certification.approved === 'approved' && !active && `From ${displayTimestamp(certification.issued_at, false)}` }
                                { certification.approved === 'pending' && 'Pending approval'}
                                { certification.approved === 'rejected' && 'Rejected'}
                            </Item.Description>
                        </Item.Content>
                    </Item>
                    {
                        certification.definition.possible_values.length >= 2 && (
                            <Item>
                                <Item.Content>
                                    <Item.Meta>Detail</Item.Meta>
                                    <Item.Description>{certification.value}</Item.Description>
                                </Item.Content>
                            </Item>
                        )
                    }
                    <Item>
                        <Grid style={{ width: '100%' }}>
                            <Grid.Row columns='equal'>
                                <Grid.Column>
                                    <Item>
                                        <Item.Content>
                                            <Item.Meta>Date Issued</Item.Meta>
                                            <Item.Description>{displayTimestamp(certification.issued_at, false)}</Item.Description>
                                        </Item.Content>
                                    </Item>
                                </Grid.Column>
                                <Grid.Column>
                                    <Item>
                                        <Item.Content>
                                            <Item.Meta>Date Expires</Item.Meta>
                                            <Item.Description>{displayTimestamp(certification.expires_at, false)}</Item.Description>
                                        </Item.Content>
                                    </Item>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Item>
                </Item.Group>
                {
                    certification.approved === 'rejected' && (
                        rejectionNotes.length > 0 ? (
                            <Message warning size='small'>
                                <p>
                                    The certification was rejected
                                </p>
                                <p>
                                    Please review the following reasons and resubmit your certification:
                                </p>
                                <ul>
                                    {
                                        rejectionNotes.map(note => {
                                            return (
                                                <li key={note.id}>
                                                    {note.contents}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </Message>
                        ) : (
                            <Message warning size='small'>
                                <p>
                                    The certification was rejected.
                                </p>
                            </Message>
                        )
                    )
                }
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={onClose}
                    content='Close'
                />
            </Modal.Actions>
        </Modal>
    )
}

export default CertificationModal