/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Jan 2019
 * ========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import ErrorBoundary from 'ErrorBoundary'
import style from './search.less'
import countries from 'resources/geography/country_codes'
import states from 'resources/geography/us_states_codes'
import SearchFilterBar from './SearchFilterBar'
import { Segment, Form } from 'semantic-ui-react'
import { Ticketmaster } from 'utils'
import { InputWithSuggestions, DayRangePickerInput } from 'components'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { setSessionGeodata } from 'store/session/actions'
import { ipLocationService } from '../../services/iplocation'
import { transformDateToSimpleDate, transformSimpleDateToDate } from 'utils/dates'
import { getListingSearchSuggestions } from 'queries/listings'
import type { ApolloClient } from 'utils/flowtypes/apollo'
import type { Session } from 'utils/flowtypes/store'
import type {
    SearchParams,
    DateRange,
    SearchFilters
} from 'utils/flowtypes/models'

type Props = {
    session: Session,
    setSessionGeodata: Function,
    onSearch: Function,
    client: ApolloClient,
    params: SearchParams,
    onChangeFilter: Function,
    onRemoveFilter: Function,
    filters: SearchFilters,
    onResetFilters: Function
}

type State = {
    search: string,
    checkIn: ?Date,
    checkOut: ?Date,
    occupants: number, 
    nearby: {
        coordinates: Array<number>
    } | null,
    pricingMode: 'perDay',
    priceRangeFrom: number | null,
    priceRangeTo: number | null,
    suggestions: Array<Object>
}

class SearchHeader extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            search: props.params.search || '',
            occupants: props.params.occupants || 1,
            checkIn: props.params.checkIn ? transformSimpleDateToDate(props.params.checkIn) : null,
            checkOut: props.params.checkOut ? transformSimpleDateToDate(props.params.checkOut) : null,
            nearby:null,
            pricingMode: 'perDay',
            priceRangeFrom: null,
            priceRangeTo: null,
            suggestions: []
        }
    }

    queryGuard = new Ticketmaster()

    async componentDidMount() {
        this.queryGuard && this.queryGuard.on('data', this._receiveSuggestions)

        if (this.props.session.geodata && this.props.session.geodata.location) {
            this.setState({
                nearby: {
                    coordinates: [
                        // $FlowFixMe
                        parseFloat(this.props.session.geodata.location.longitude),
                        // $FlowFixMe
                        parseFloat(this.props.session.geodata.location.latitude)
                    ]
                }
            })
        } else {
            const geodata = await (await ipLocationService).getLocationData()
            this.props.setSessionGeodata(geodata)
            
            if (geodata?.location) {
                this.props.setSessionGeodata(geodata)
                this.setState({
                    nearby: {
                        coordinates: [
                            parseFloat(geodata.location.longitude),
                            parseFloat(geodata.location.latitude)
                        ]
                    }
                })
            }
        }
    }

    componentWillUnmount() {
        this.queryGuard && this.queryGuard.removeListener('data', this._receiveSuggestions)
    }

    handleSubmit = () => {
        const { search, occupants, priceRangeFrom, priceRangeTo, pricingMode } = this.state
        const checkIn = this.state.checkIn ? transformDateToSimpleDate(this.state.checkIn) : undefined
        const checkOut = this.state.checkOut ? transformDateToSimpleDate(this.state.checkOut) : undefined
        this.props.onSearch({ search, checkIn, checkOut, occupants, priceRangeFrom, priceRangeTo, pricingMode })
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value }) 

    handleSearchChange = async (e, { name, value }) => {
        this.setState({ [name]: value })

        this.queryGuard.run(async () => {
            const suggestions = await this.props.client.query({
                query: getListingSearchSuggestions,
                variables: {
                    stem: value,
                    quantity: 5,
                    nearby: this.state.nearby
                }
            })

            return suggestions.data.getCityPredictions || []
        })
    }

    _receiveSuggestions = suggestions => { 
		if (suggestions && suggestions.length >= 1) {

			suggestions = suggestions.map(suggestion => {
				let text

				if (suggestion.country_code === 'US' && states[suggestion.admin_code_1]) {
					text = `${suggestion.name}, ${states[suggestion.admin_code_1]}, ${countries[suggestion.country_code]}`
				} else {
					text = `${suggestion.name}, ${countries[suggestion.country_code]}`
				}

				return {
					key: `${suggestion.name}${suggestion.country_code}${suggestion.admin_code_1}`,
					text,
					value: text
				}
			})
		}

		this.setState({ suggestions })
    }

    handleNumberRangeChange = (from, to) => {
        this.setState({ priceRangeFrom: from, priceRangeTo: to })
    }

    handlePricingModeChange = mode => this.setState({ pricingMode: mode })

    handleDatesChange = (daterange: DateRange) => {
        this.setState({
            checkIn: daterange.from,
            checkOut: daterange.to
        })
    }

    render() {
        const {
            search,
            occupants,
            suggestions
        } = this.state

        const { params = {}, filters } = this.props
        const initialCheckIn = params.checkIn ? transformSimpleDateToDate(params.checkIn) : undefined
        const initialCheckOut = params.checkOut ? transformSimpleDateToDate(params.checkOut) : undefined

        return (
            <ErrorBoundary>
                <Segment.Group raised className={style.search__header__wrapper}>
                    <Segment color='purple' attached='top'>
                        <Form onSubmit={this.handleSubmit}>
                            <div className={style.search__header}>
                                <div className={style.search__header__destination}>
                                    <InputWithSuggestions
                                        label='Destination'
                                        fluid
                                        icon='search'
                                        iconPosition='left'
                                        placeholder='Destination'
                                        name='search'
                                        value={search}
                                        onInputChange={this.handleSearchChange}
                                        onOptionChange={this.handleChange}
                                        suggestions={suggestions}
                                        className={style.search__header__destination__input}
                                    />
                                </div>
                                <div className={style.search__header__dates}>
                                    <DayRangePickerInput
                                        label='Dates'
                                        fromPlaceholder='Check-in'
                                        toPlaceholder='Check-out'
                                        onChange={this.handleDatesChange}
                                        initialFromDate={initialCheckIn}
                                        initialToDate={initialCheckOut}
                                    />
                                </div>
                                <div className={style.search__header__guests}>
                                    <Form.Input
                                        label='Guests'
                                        icon='group'
                                        type='number'
                                        iconPosition='left'
                                        min={1}
                                        max={99}
                                        fluid
                                        name='occupants'
                                        value={occupants}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className={style.search__header__submit}>
                                    <Form.Button
                                        content='Search'
                                        primary
                                        fluid
                                    />
                                </div>
                            </div>
                        </Form>
                    </Segment>
                    <SearchFilterBar
                        filters={filters}
                        onChangeFilter={this.props.onChangeFilter}
                        onResetFilters={this.props.onResetFilters}
                    />
                </Segment.Group>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        session: state.session
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSessionGeodata: sessionGeodata => dispatch(setSessionGeodata(sessionGeodata))
    }
}

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(SearchHeader))