/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Dec 2018
 * ========================================================================
 */

// @flow

import React, { PureComponent, Fragment } from 'react'
import ErrorBoundary from 'ErrorBoundary'
import { Widget } from 'components'
import { connect } from 'react-redux'
import { Progress, List, Message, Icon, Button } from 'semantic-ui-react'
import { withApollo, graphql, compose } from 'react-apollo'
import { listAccommodation, unlistAccommodation } from 'queries/listings'
import { stripeConnect } from 'queries/stripe'
import { getAccommodation } from 'queries/accommodation'
import { showErrorNotification, showSuccessNotification } from 'utils/notifications'
import type { Accommodation, User, StripeConnect } from 'utils/flowtypes/models'
import type { ApolloClient } from 'utils/flowtypes/apollo'

type Props = {
    accommodation: Accommodation,
    listAccommodation: Function,
    unlistAccommodation: Function,
    user: User,
    client: ApolloClient,
}

type State = {
    stripeConnect: ?StripeConnect
}

class ManageListingWidget extends PureComponent<Props, State> {
    state = {
        stripeConnect: null
    }


    handleListAccommodation = async () => {
        const response = await this.props.listAccommodation({
            variables: {
                accommodationId: this.props.accommodation.id
            },
            refetchQueries: [{
                query: getAccommodation,
                variables: { accommodationId: this.props.accommodation.id }
            }]
        }).catch(e => e)

        if (response instanceof Error) {
            console.error(response)
            showErrorNotification('Unable to list accommodation at this time.')
        } else {
            showSuccessNotification('Accommodation listed successfully.')
        }
    }

    handleUnlistAccommodation = async () => {
        const response = await this.props.unlistAccommodation({
            variables: {
                accommodationId: this.props.accommodation.id
            },
            refetchQueries: [{
                query: getAccommodation,
                variables: { accommodationId: this.props.accommodation.id }
            }]
        }).catch(e => e)

        if (response instanceof Error) {
            console.error(response)
            showErrorNotification('Unable to unlist accommodation at this time.')
        } else {
            showSuccessNotification('Accommodation unlisted.')
        }
    }

    getActions = () => {
        const {
            accommodation,
            // user
        } = this.props

        /**
         * Accommodation is APPROVED
         */
        if (accommodation.approved === 'approved') {
            if (accommodation.listed) {
                return (
                    <Button
                        content='Unlist accommodation'
                        fluid
                        color='red'
                        basic
                        onClick={this.handleUnlistAccommodation}
                    />
                )
            } else {
                return (
                    <Fragment>
                        <Message info size='small'>
                            Accommodation ready to be listed!
                        </Message>
                        <Button
                            content='List accommodation'
                            fluid
                            color='green'
                            onClick={this.handleListAccommodation}
                        />
                    </Fragment>
                )
            }
        }
        
        /**
         * Accommodation is PENDING
         */
        if (accommodation.approved === 'pending') {
            if (accommodation.listed) {
                return (
                    <Button
                        content='Listing pending approval'
                        fluid
                        disabled
                    />
                )
            } else {
                if (false //eslint-disable-line
                    // && user.company.connect_account.status !== 'active'
                ) {
                    if (false //eslint-disable-line
                        // user.company.connect_account.status === 'pending'
                    ){
                        return (
                            <Fragment>
                                <Message warning size='small'>
                                    Awaiting approval of company billing details.
                                </Message>
                                <Button
                                    content='List accommodation'
                                    fluid
                                    color='green'
                                    disabled
                                />
                            </Fragment>
                        )
                    } else {
                        return (
                            <Fragment>
                                <Message error size='small'>
                                    There seems to be something wrong with your company billing
                                    details supplied. Please contact support.
                                </Message>
                                <Button
                                    content='List accommodation'
                                    fluid
                                    color='green'
                                    disabled
                                />
                            </Fragment>
                        )
                    }
                } else {
                    return (
                        <Fragment>
                            <Message info size='small'>
                                Accommodation ready to be listed!
                            </Message>
                            <Button
                                content='List accommodation'
                                fluid
                                color='green'
                                onClick={this.handleListAccommodation}
                            />
                        </Fragment>
                    )
                }                
            }
        }
        
        /**
         * Accommodation is REJECTED
         */
        if (accommodation.approved === 'rejected') {
            if (accommodation.listed) {
                const rejectionNotes = accommodation.notes.filter(note => {
                    return note.tags.includes('approval') && note.tags.includes('rejection')
                })

                if (rejectionNotes.length >= 0) {
                    return (
                        <Fragment>
                            <Message warning size='small'>
                                <p>
                                    The listing request has not been approved.
                                </p>
                                <p>
                                    Please review the following reasons and make changes if necessary to resubmit.
                                </p>
                                <ul>
                                    {
                                        rejectionNotes.map(note => {
                                            return (
                                                <li key={note.id}>
                                                    {note.contents}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </Message>
                            <Button
                                fluid
                                primary
                                content='Make changes'
                                onClick={this.handleUnlistAccommodation}
                            />
                        </Fragment>
                    )
                } else {
                    return (
                        <Fragment>
                            <Message warning size='small'>
                                The listing request has not been approved.
                            </Message>
                            <Button
                                fluid
                                primary
                                content='Make changes'
                                onClick={this.handleUnlistAccommodation}
                            />
                        </Fragment>
                    )
                }
            } else {
                return (
                    <Fragment>
                        <Button
                            content='List accommodation'
                            fluid
                            color='green'
                            onClick={this.handleListAccommodation}
                        />
                    </Fragment>
                )
            }
        }
    }

    getChecklist = (tasks, completedTasks) => {
        return (
            <Fragment>
                {
                    <Message info size='small'>
                        The following information is required before the accommodation
                        can be listed.
                    </Message>
                }
                <Progress
                    value={completedTasks.length}
                    total={tasks.length}
                    progress='ratio'
                    size='medium'
                    indicating
                    style={{
                        marginBottom: '0px'
                    }}
                />
                <List relaxed>
                    {
                        tasks.map((task, index) => {
                            return (
                                <List.Item
                                    key={index}
                                >
                                    <Icon   
                                        name={task.complete ? 'check' : 'close'}
                                        color={task.complete ? 'green' : undefined}
                                        disabled={!task.complete}
                                    />
                                    <List.Content>
                                        <List.Header>
                                            {task.name}
                                        </List.Header>
                                        {
                                            !task.complete &&
                                            task.description &&
                                            <List.Description>
                                                {task.description}
                                            </List.Description>
                                        }
                                    </List.Content>
                                </List.Item>

                            )
                        })
                    }
                </List>
            </Fragment>
        )
    }

    _fetchStripeConnect = async () => {
        const response = await this.props.client.query({
            query: stripeConnect,
            variables: {
                companyId: this.props.user.company.id
            }
        }).catch(e => e)

        if (response instanceof Error) {
            console.error(response)
        } else {
            this.setState({ stripeConnect: response.data.stripeConnect })
        }
    }

    componentDidMount() {
        this._fetchStripeConnect()
    }

    render() {
        const {
            accommodation,
            // user
        } = this.props

        const { features, name, property } = accommodation
    
        const nameComplete = name.trim() !== ''
    
        const description = features.find(feature => {
            return feature.definition.shortcode === 'description'
        })?.value
    
        const descriptionComplete = description && description.trim() !== ''
    
        const requiredFeatures = []
    
        // size for accomm
        if (!features.find(feature => feature.definition.shortcode === 'size_m2')) {
            requiredFeatures.push('Size')   
        }
    
        // maximum guests for accomm
        if (!features.find(feature => feature.definition.shortcode === 'maximum_guests')) {
            requiredFeatures.push('Maximum guests')
        }
        
        const bedtypes = [
            'single_beds',
            'small_double_beds',
            'double_beds',
            'twin_beds',
            'king_beds',
            'super_king_beds',
        ]
    
        const beds = features.filter(feature => {
            return bedtypes.includes(feature.definition.shortcode) && parseInt(feature.value, 10) > 0
        })
    
        // accommodation beds
        if (beds.length === 0) {
            requiredFeatures.push('Beds')
        }
    
        const bathroomtypes = [
            'bathrooms',
            'en_suite_bathrooms',
            'wc',
        ]
    
        const bathrooms = features.filter(feature => {
            return bathroomtypes.includes(feature.definition.shortcode) && parseInt(feature.value, 10) > 0
        })
    
        // accommodation bathrooms
        if (bathrooms.length === 0) {
            requiredFeatures.push('Bathrooms')
        }
    
        const featuresComplete = requiredFeatures.length === 0
    
        const pricingComplete = accommodation.pricing.length === 4 && accommodation.pricing.every(price => price.price >= 0)
    
        const photos = accommodation.attachments.filter(attachment => attachment.type === 'image')
    
        const photosComplete = photos.length >= 4

        const electricityCertificate = property.attachments.find(attachment => {
            return attachment.tags.includes('electricity-certificate')
        })
    
        const gasCertificate = property.attachments.find(attachment => {
            return attachment.tags.includes('gas-certificate')
        })

        const documentsComplete = electricityCertificate && gasCertificate

        const cancellationPolicyComplete = accommodation.cancellation_policies.length >= 1

        const { stripeConnect } = this.state
        const connectAccountComplete = stripeConnect != null ? stripeConnect.stripe_payload.payouts_enabled : false

        const tasks = [
            {
                name: 'Add accommodation name',
                complete: nameComplete
            },
            {
                name: 'Include a description',
                complete: descriptionComplete
            },
            {
                name: 'Basic details',
                complete: featuresComplete,
                description: `Missing details: ${requiredFeatures.join(', ')}`
            },
            {
                name: 'Set pricing rates',
                complete: pricingComplete
            },
            {
                name: 'Upload photos',
                complete: photosComplete,
                description: 'An accommodation must have 4 photos as a minimum'
            },
            {
                name: 'Cancellation policy',
                complete: cancellationPolicyComplete,
                description: 'Set a cancellation policy'
            },
            {
                name: 'Attach property documents',
                complete: documentsComplete,
                description: 'The associated property is missing documents'
            },
            {
                name: 'Profile & billing details',
                complete: connectAccountComplete,
                description: 'Please ensure all of the information in your profile,\
                    company profile, and billing is complete within the\
                    Manage Account page.'
            }
        ]
    
        const completedTasks = tasks.filter(task => task.complete)

        return (
            <ErrorBoundary>
                <Widget
                    header='Manage listing'
                >
                    {
                        completedTasks.length === tasks.length
                        ? this.getActions()
                        : this.getChecklist(tasks, completedTasks)
                    }
                </Widget>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default compose(
    graphql(
        listAccommodation,
        {
            name: 'listAccommodation'
        }
    ),
    graphql(
        unlistAccommodation,
        {
            name: 'unlistAccommodation'
        }
    )
)(withApollo(connect(mapStateToProps)(ManageListingWidget)))