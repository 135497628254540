/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by
 *  Yunjia Li <yunjia.li@launchbase.solutions>
 *  Thomas Hewett <thomas.hewett@launchbase.solutions>
 * Dec 2018
 * ========================================================================
 */

// @flow
import { cloudinary as cloudinaryConfig } from 'config'

export async function uploadImageToCloudinary(image: Object, preset: ?string, imageId: ?string) {   
    const response = await new Promise((resolve, reject) => {
        const url = `https://api.cloudinary.com/v1_1/${cloudinaryConfig.cloud_name}/upload`
        const xhr = new XMLHttpRequest()
        const fd = new FormData()

        xhr.open('POST', url, true)
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    resolve(xhr.response)
                } else {
                    reject(new Error('Image upload failed'))
                }
            }
        }

        fd.append('upload_preset', preset ? preset : 'apartment_images_preset')
        fd.append('tags', 'browser_upload')
        fd.append('file', image)

        xhr.send(fd)
    }).catch(e => e)

    if (response instanceof Error) {
        console.error(response)
        return response
    }

    const cloudinaryImage = JSON.parse(response)

    return {
        name: cloudinaryImage.public_id,
        url: cloudinaryImage.secure_url,
        id: imageId
    }
}

export async function fetchBlobFromURL(url: string): Promise<Blob|Error> {
    const response = await fetch(url)
    return response.blob()
}