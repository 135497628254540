/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by
 *  Thomas Hewett <thomas.hewett@launchbase.solutions>
 *  Yunjia Li <yunjia.li@launchbase.solutions>
 * August 2018
 * ==========================================================================
 */

// @flow

import React, { PureComponent, Fragment } from 'react'
import ChatWidget from 'components/Chat/ChatWidget'
import queryString from 'query-string'
import config from 'config'
import 'react-day-picker/lib/style.css'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { GuestSearchDropdown, DayRangePickerInput } from 'components'
import { getGuests } from 'queries/guests'
import { stripeConnect } from 'queries/stripe'
import { getAccommodationPricingForDuration } from 'queries/accommodation'
import { getEstimatedFirstPaymentForBooking } from 'queries/invoices'
import { isValidDateRange, randomString, showErrorNotification } from 'utils'
import { addItinerary } from 'store/itinerary/actions'
import { getLowestPriceRange, displayPrice, displayPriceFromPriceObject, getPriceRangeForDuration } from 'utils/money'
import { transformSimpleDateToDate, transformAvailabilityToDateRanges, getDurationFromDateRange } from 'utils/dates'
import { Button, Divider, Header, Form, Icon, Table, Message } from 'semantic-ui-react'
import type { Accommodation, Guest, User, PriceRanges, DateRange, Price, StripeConnect, EstimatedInvoice } from 'utils/flowtypes/models'
import type { ApolloClient } from 'utils/flowtypes/apollo'
import type { Router } from 'utils/flowtypes/react-router'

type Props = {
    listing: Accommodation,
    maxOccupants: number,
    client: ApolloClient,
    user: User,
    addItinerary: Function,
    pricing: PriceRanges,
} & Router

type State = {
    occupants: number,
    loading: boolean,
    guests: Array<Guest>,
    selectedGuests: Array<Guest>,
    checkIn: ?Date,
    checkOut: ?Date,
    priceForDuration: ?Price,
    stripeConnect: ?StripeConnect,
    depositInvoice: ?EstimatedInvoice
}

class Booking extends PureComponent<Props, State> {
    state = {
        loading: false,
        occupants: 0,
        guests: [],
        selectedGuests: [],
        checkIn: new Date(),
        checkOut: new Date(),
        priceForDuration: null,
        stripeConnect: null,
        depositInvoice: null
    }

    refreshGuests = async () => {
        let response = await this.props.client.query({
            query: getGuests,
            variables: {
                companyId: this.props.user.company.id
            }
        }).catch(e => {
            showErrorNotification('Oops! Something went wrong.')
            console.error(e)
            this.setState({
                loading: false
            })
        })

        if (response) {
            this.setState({
                loading: false,
                guests: response.data.company.guests.map(g=>{
                    return {'key':g.id, 'text':g.name, 'value':g.id}
                })
            })
        }
    }

    handleAddItinerary = () => {
        if (config.featureFlags.disable_booking === 'TRUE') {
            return false
        }

        //redirect to itinerary page afterwards
        const { checkIn, checkOut, selectedGuests } = this.state

        if (selectedGuests.length === 0) {
            showErrorNotification('You must select at least 1 guest')
            return
        }

        if (selectedGuests.length > this.props.maxOccupants) {
            showErrorNotification(`You can select maximum ${this.props.maxOccupants} guest(s)`)
            return
        }

        const { listing } = this.props

        this.props.addItinerary({
            id: randomString(),
            checkIn,
            checkOut,
            guests: selectedGuests,
            listingId: listing.id,
            onDemandFeatures: []
        })

        this.props.history.push('/itinerary')
    }

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value })
    }

    handleDatesChange = (daterange: DateRange) => {
        this.setState({
            checkIn: daterange.from,
            checkOut: daterange.to
        }, this.calculateBookingCost)
    }

    calculateBookingCost = async () =>  {
        const { checkIn, checkOut } = this.state

        if (checkIn && checkOut) {
            const response = await this.props.client.query({
                query: getAccommodationPricingForDuration,
                variables: {
                    model_id: this.props.listing.id,
                    duration: getDurationFromDateRange(checkIn, checkOut)
                }
            })
    
            if (response instanceof Error) {
                console.error(response)
                showErrorNotification('Unable to calculate booking estimate.')
            } else {
                const price: Price = response.data.getPriceForDuration
                
                this.setState({ priceForDuration: price })

                const firstPaymentResponse = await this.props.client.query({
                    query: getEstimatedFirstPaymentForBooking,
                    variables: {
                        accommodationId: this.props.listing.id,
                        dateFrom: checkIn,
                        dateTo: checkOut
                    }
                }).catch(e => e)

                if (firstPaymentResponse instanceof Error) {
                    console.error(firstPaymentResponse)
                } else {
                    this.setState({
                        depositInvoice: firstPaymentResponse.data.getEstimatedFirstPaymentForBooking
                    })
                }
            }
        }
    }

    componentDidMount() {
        this._fetchStripeConnect()
        const { checkIn, checkOut } = queryString.parse(location.search)

        if (isValidDateRange(checkIn, checkOut)) {
            this.setState({
                checkIn: transformSimpleDateToDate(checkIn),
                checkOut: transformSimpleDateToDate(checkOut)
            }, this.calculateBookingCost)
        }

        this.refreshGuests()
    }

    _fetchStripeConnect = async () => {
        const response = await this.props.client.query({
            query: stripeConnect,
            variables: {
                companyId: this.props.user.company.id
            }
        }).catch(e => e)

        if (response instanceof Error) {
            console.error(response)
        } else {
            this.setState({ stripeConnect: response.data.stripeConnect })
        }
    }

    getActions() {
        const { listing, user } = this.props
        const { stripeConnect } = this.state

        if (listing.property.company.id === user.company.id) {
            return null
        }

        const payoutsEnabled = stripeConnect != null && stripeConnect.stripe_payload.payouts_enabled

        if (!payoutsEnabled) {
            return (
                <Fragment>
                    <Message
                        visible
                        warning
                        size='small'
                    >
                        Please ensure all of the information in your profile,
                        company profile, and billing is complete within the
                        Manage Account page to be able to make a booking.
                    </Message>
                    <Form.Button
                        primary
                        fluid
                        disabled
                    >
                        Book
                    </Form.Button>
                    <ChatWidget conversationId={`${ listing.id }@${ user.id }`} notifyOptions={{ type: 'accommodation', id: listing.id }}>
                        { toggle => <Button secondary inverted fluid onClick={toggle}>Contact Provider</Button> }
                    </ChatWidget>
                </Fragment>
            )
        }

        return (
            <Fragment>
                <Form.Button
                    primary
                    type='submit'
                    onClick={this.handleAddItinerary}
                    fluid
                    disabled={config.featureFlags.disable_booking === 'TRUE'}
                >
                    Book
                </Form.Button>
                <ChatWidget conversationId={`${ listing.id }@${ user.id }`} notifyOptions={{ type: 'accommodation', id: listing.id }}>
                    { toggle => <Button secondary inverted fluid onClick={toggle}>Contact Provider</Button> }
                </ChatWidget>
            </Fragment>
        )
    }

    render() {
        const { guests, loading, priceForDuration, checkIn, checkOut, depositInvoice } = this.state
        const { maxOccupants, pricing, listing } = this.props

        if (loading) {
            return (
                <div><Icon loading name='spinner' size='large' /></div>
            )
        }
        
        const lowestPriceRange = getLowestPriceRange(pricing)
        const query = queryString.parse(location.search)
        const initialCheckIn = query.checkIn ? transformSimpleDateToDate(query.checkIn) : undefined
        const initialCheckOut = query.checkOut ? transformSimpleDateToDate(query.checkOut) : undefined    
        let tax = priceForDuration ? priceForDuration.tax : 0
        let duration = checkIn && checkOut ? getDurationFromDateRange(checkIn, checkOut) : 0
        let priceRange = duration ? getPriceRangeForDuration(pricing, duration) : null
        let total = priceForDuration ? priceForDuration.price + priceForDuration.tax : 0

        return (
            <div>
                {
                    lowestPriceRange ? (
                        <Header as='h3'>
                            From {displayPrice(lowestPriceRange.currency.id, lowestPriceRange.price)}
                            <Header.Subheader>Per Night</Header.Subheader>
                        </Header>
                    ) : (
                        <Header as='h3'>
                            Book now
                        </Header>
                    )
                }
                <Divider />
                <Form>
                    <DayRangePickerInput
                        fluid
                        label='Dates'
                        fromPlaceholder='Check-in'
                        toPlaceholder='Check-out'
                        initialFromDate={initialCheckIn}
                        initialToDate={initialCheckOut}
                        onChange={this.handleDatesChange}
                        disabledDayRanges={transformAvailabilityToDateRanges(listing.availability)}
                    />
                    <GuestSearchDropdown guests={guests} maxOccupants={maxOccupants} onChange={this.handleChange}/>
                    {
                        duration > 0 &&
                        priceForDuration &&
                        priceRange &&
                        <Table basic='very'>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        {`${displayPrice(priceRange.currency.id, priceRange.price)} x ${duration} nights`} 
                                    </Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {displayPriceFromPriceObject(priceForDuration)}
                                    </Table.Cell>
                                </Table.Row>
                                {
                                    tax > 0 &&
                                    <Table.Row>
                                        <Table.Cell>
                                            Taxes
                                        </Table.Cell>
                                        <Table.Cell textAlign='right'>
                                            {displayPrice(priceRange.currency.id, tax)}
                                        </Table.Cell>
                                    </Table.Row>
                                }
                                <Table.Row>
                                    <Table.Cell>
                                        <strong>Total</strong>
                                    </Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        <strong>{displayPrice(priceRange.currency.id, total)}</strong>
                                    </Table.Cell>
                                </Table.Row>
                                {
                                    depositInvoice && depositInvoice.meta.deposit && (
                                        <Table.Row>
                                            {
                                                depositInvoice.meta.refundable ? (
                                                    <Table.Cell colSpan={2} textAlign='right'>
                                                        <small><em>Inc. deposit</em></small>
                                                    </Table.Cell>                                    
                                                ) : (
                                                    <Table.Cell colSpan={2} textAlign='right'>
                                                        <small><em>Inc. deposit of {displayPrice(depositInvoice.currency.id, depositInvoice.total)}</em></small>
                                                    </Table.Cell>
                                                )
                                            }
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    }
                    {
                        this.getActions()
                    }
                </Form>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addItinerary: itineraryParams => dispatch(addItinerary(itineraryParams))
    }
}

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(Booking)))