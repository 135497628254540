/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import { Button, Icon } from 'semantic-ui-react'

type Props = {
    onClick?: Function,
    activeFeatureList?: string
}

const AddFeature = (props: Props) => {
    return (
        <Button floated='right' size='mini' basic color='green'
            onClick={()=>{if(props.onClick) props.onClick(props.activeFeatureList)}}>
            <Icon name='plus circle'></Icon>Add details
        </Button>
    )
}

export default AddFeature