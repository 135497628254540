/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yunjia Li <yunjia.li@launchbase.solutions>, Oct 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import ErrorBoundary from 'ErrorBoundary'
import style from './search.less'
import { Layout, ListingCard } from 'components'
import { Message, Icon, Item, Dropdown, Button, Divider } from 'semantic-ui-react'
import type { Accommodations, SearchSortEnum, ID } from 'utils/flowtypes/models'

type Props = {
    results: Accommodations,
    searching: boolean,
    search: string,
    params: Object,
    sort: SearchSortEnum,
    onChangeSort: Function,
    cursor: ID,
    onShowMore: Function,
    loadingMoreResults: boolean
}

const SEARCH_SORT_OPTIONS = [
    {
        key: 'shortestdistance',
        value: 'shortestdistance',
        text: 'Distance: Closest First'
    },
    {
        key: 'lowestprice',
        value: 'lowestprice',
        text: 'Price: Lowest First'
    },
    {
        key: 'highestprice',
        value: 'highestprice',
        text: 'Price: Highest First'
    }
]

const SearchResults = (props: Props) => {
    const {
        results,
        searching,
        search,
        params,
        sort,
        cursor,
        onShowMore,
        loadingMoreResults
    } = props

    const handleChangeSort = (e, data) => {
        props.onChangeSort(data.value)
    }

    if (searching) {
        return (
            <div className={style.search__searching}>
                <Message className={style.veryBasicMessage} size='massive'>
                    <p><Icon name='spinner' loading /> Searching...</p>
                </Message>
            </div>
        )
    }

    if (results === null) {
        return null
    }

    if (results && results.length >= 1) {
        return (
            <ErrorBoundary>            
                    <Layout.Header
                        header={`${search}`}
                        subheader={`${results.length} properties found`}
                    >
                        <Dropdown
                            button
                            basic
                            className='icon'
                            options={SEARCH_SORT_OPTIONS}
                            icon='sort'
                            labeled
                            value={sort}
                            onChange={handleChangeSort}
                        />
                    </Layout.Header>
                    <div>
                        <Item.Group divided>
                            {
                                results.map(listing => <ListingCard listing={listing} fluid='true' key={listing.id} params={params}/>)
                            }
                        </Item.Group>
                    </div>
                    <Divider hidden />
                    <div className={style.search__results__pagination}>
                        {
                            cursor && (
                                <Button
                                    basic
                                    content='Show more'
                                    onClick={onShowMore}
                                    loading={loadingMoreResults}
                                />
                            )
                        }
                    </div>
            </ErrorBoundary>
        )
    }

    return (
        <ErrorBoundary>
            <div className={style.search__noresults}>
                <Message className={style.veryBasicMessage} size='massive'>
                    <p><Icon name='search' /> No results found for &quot;{search}&quot;</p>
                </Message>
            </div>
        </ErrorBoundary>
    )
}

export default SearchResults
