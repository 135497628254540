/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import style from './page.less'
import { Menu, Dropdown } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { logout } from 'store/user/actions'
import { setAccessToken, setRefreshToken, setSessionExpiration } from 'store/session/actions'
import { GuestGate, UserGate } from 'routes'
import { connect } from 'react-redux'
import { ProviderGate } from 'routes'
import { withApollo } from 'react-apollo'
import config from 'config'
import type { ApolloClient } from 'utils/flowtypes/apollo'

type Props = {
    user: {
        name: string
    },
    logout: Function,
    removeAccessToken: Function,
    removeRefreshToken: Function,
    expireSession: Function,
    history: {
        push: Function
    },
    transparent?: boolean,
    overlay?: boolean,
    inverted?: boolean,
    className?: string,
    fixed?: boolean,
    client: ApolloClient
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}


const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(logout()),
        removeAccessToken: () => dispatch(setAccessToken(null)),
        removeRefreshToken: () => dispatch(setRefreshToken(null)),
        expireSession: () => dispatch(setSessionExpiration(null))
    }
}

const Masthead = (props: Props) => {
    const {
        className,
        user,
        logout,
        removeAccessToken,
        removeRefreshToken,
        expireSession,
        history,
        inverted
    } = props

    async function handleLogout() {
        await props.client.cache.reset()        
        logout()
        removeAccessToken()
        removeRefreshToken()
        expireSession()
        history.push('/')
    }

    let branding = null
    if (inverted) {
        branding = require('../../resources/images/branding-inverted.png')
    } else {
        branding = require('../../resources/images/branding.png')
    }

    let styling = style.page__masthead
    if (inverted) styling += ` ${style.page__masthead__inverted}`
    if (className) styling += ` ${className}`

    return (
        <div className={styling}>
            <div>
                <Link to='/'>
                    <h1>
                        <img className={style.page__masthead__branding} src={branding} />
                    </h1>
                </Link>
            </div>
            <div>
                <Menu inverted={inverted} secondary>
                    <Menu.Menu position='right'>
                        <GuestGate>
                            <Menu.Item name='Log In' as={Link} to='/login' />
                            <Menu.Item name='Register' as={Link} to='/register' />
                        </GuestGate>
                        {
                            user &&
                            <UserGate>
                                <Menu.Item className={style.page__masthead__username} name='username' content={user.name} />
                                <Dropdown
                                    className={style.page__masthead__dropdown}
                                    pointing='top right'
                                    item
                                    icon={{name: 'user circle', size: 'big', style: { margin: '0' }}}
                                >
                                    <Dropdown.Menu>
                                        {
                                            config.featureFlags.disable_search !== 'TRUE' &&
                                            <Dropdown.Item as={Link} to='/search'>Search</Dropdown.Item>
                                        }
                                        <ProviderGate allowAdmin>
                                            <Dropdown.Item as={Link} to='/dashboard'>Dashboard</Dropdown.Item>
                                        </ProviderGate>
                                        <UserGate allowAdmin={false}>
                                            <Dropdown.Item as={Link} to='/account'>My Account</Dropdown.Item>
                                        </UserGate>
                                        <Dropdown.Item onClick={handleLogout}>Log Out</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </UserGate>   
                        }
                    </Menu.Menu>
                </Menu>
            </div>
        </div>
    )
}

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(Masthead)))