/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

import React, { PureComponent } from 'react'
import { AvailabilityCalendar } from 'components'
import moment from 'moment'
import style from './listing.less'
import { Header } from 'semantic-ui-react'

class Availability extends PureComponent {
    state = {
        month: moment().month()
    }

    handlePreviousButtonClick = () => {
        this.setState(prevState => ({ month: prevState.month - 1 }))
    }

    handleNextButtonClick = () => {
        this.setState(prevState => ({ month: prevState.month + 1 }))
    }

    render() {
        const { month } = this.state

        const currentMonth = moment().month(month)
        const nextMonth = moment().month(month + 1)

        let { availability } = this.props

        availability = availability.map(range => {
            return {
                start: moment.utc(range.start),
                end: moment.utc(range.end)
            }
        })

        return (
            <div>
                <Header id='availability'>Availability</Header>
                <div className={style.listing__availability}>
                    <AvailabilityCalendar
                        month={currentMonth}
                        onNextButtonClick={this.handleNextButtonClick}
                        onPreviousButtonClick={this.handlePreviousButtonClick}
                        dateRanges={availability}
                        hover={false}
                    />
                    <AvailabilityCalendar
                        month={nextMonth}
                        onNextButtonClick={this.handleNextButtonClick}
                        onPreviousButtonClick={this.handlePreviousButtonClick}
                        dateRanges={availability}
                        hover={false}
                    />
                </div>
            </div>
        )
    }
}

export default Availability