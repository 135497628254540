/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Feb 2019
 * ========================================================================
 */

// @flow

import React from 'react'
import style from './listingcard.less'
import qs from 'query-string'
import { Link } from 'react-router-dom'
import { List, Label, Header } from 'semantic-ui-react'
import { displayPrice, getLowestPriceRange } from 'utils/money'
import { getAccommodationPhotos, vaguePropertyAddress } from 'utils'
import type { Accommodation, Certification} from 'utils/flowtypes/models'

type Props = {
    listing: Accommodation,
    params: Object
}

const CERTIFICATION_CHIPS = ['asap_company', 'isaap_quality']

const ListingCard = (props: Props) => {
    
    const { listing, params } = props
    const { id, name, property, features, pricing } = listing
    const query = qs.stringify(params)
    const photo = getAccommodationPhotos(listing)[0]
    const lowestPrice = getLowestPriceRange(pricing)
    const size = features.find(feature => feature.definition.shortcode === 'size_m2')
    const nonsmoking = features.find(feature => feature.definition.shortcode === 'non_smoking')
    const bedrooms = features.find(feature => feature.definition.shortcode === 'bedrooms')

    const certifications = property.company.certifications.filter(certification => {
        return CERTIFICATION_CHIPS.includes(certification.definition.short_code)
    })

    return (
        <Link
            className={style.listingcard}
            to={`listings/${id}?${query}`}
        >
            <div className={style.listingcard__image}>
                <img src={photo.url} />
            </div>
            <div className={style.listingcard__details}>
                <div className={style.listingcard__details__description}>
                    <Header as='h3'>
                        { name }
                        <Header.Subheader>{`${property.name} · ${vaguePropertyAddress(property.address)}`}</Header.Subheader>
                    </Header>
                </div>
                <div className={style.listingcard__details__highlights}>
                    <List horizontal relaxed>
                        {
                            size &&
                            <List.Item>
                                <List.Icon name='expand' />
                                <List.Content>
                                    Size: {size.value}m<sup>2</sup>
                                </List.Content>
                            </List.Item>
                        }
                        {
                            nonsmoking &&
                            <List.Item
                                icon='ban'
                                content='Non-smoking'
                            />
                        }
                        {
                            bedrooms &&
                            <List.Item
                                icon='bed'
                                content={`${bedrooms.value} bedrooms`}
                            />
                        }
                    </List>
                </div>
                <div className={style.listingcard__details__footer}>
                    <div className={style.listingcard__details__footer__company}>
                        <span>Provided by <strong>{ property.company.name }</strong></span>
                        <CertificationChips certifications={certifications} />
                    </div>
                    <div className={style.listingcard__details__footer__pricing}>
                        {
                            lowestPrice && (
                                <Label size='large' basic>
                                    <small>From</small>
                                    <strong> {displayPrice(lowestPrice.currency.id, lowestPrice.price)}</strong>
                                    <small>/night</small>
                                </Label>
                            )
                        }
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default ListingCard

type CertificationChipsProps = {
    certifications: Certification[]
}

const CertificationChips = (props: CertificationChipsProps) => {    
    if (props.certifications.length > 0) {
        return (
            <div className={style.listingcard__details__certifications}>
                {
                    props.certifications.map((certification, index) => {
                        let color = undefined
                        switch (certification.definition.short_code) {
                            case 'asap_company':
                                color = '#ED3D31'
                                break;
                            
                            case 'isaap_quality':
                                color = 'teal'
                                break;

                            default:
                                color = undefined
                                break;
                        }

                        return (
                            <span key={certification.id}>
                                <strong style={color ? { color } : undefined}>
                                    { certification.value }  
                                </strong>
                                { index < props.certifications.length - 1 && ' · ' }
                            </span>
                        )
                    })
                }
            </div>
        )
    }

    return null
}