/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import { getTextHighlight } from 'utils'
import style from './listing.less'
import { Header, Divider } from 'semantic-ui-react'

type Props = {
    accommodationDescription: string,
    locationDescription: string 
}

type State = {
    showFullText: boolean
}

class Description extends PureComponent<Props, State> {
    state = {
        showFullText: false
    }

    handleReadMore = () => this.setState({ showFullText: true })

    render() {
        const { accommodationDescription, locationDescription } = this.props
        let { showFullText } = this.state

        if (!accommodationDescription) {
            return null
        }

        const description = locationDescription ? (
            `${locationDescription}\r\n\n${accommodationDescription}`
        ) : accommodationDescription

        const shortDescription = getTextHighlight(description)
    
        if (shortDescription.length === description.length)
            showFullText = true

        return (
            <div>
                <Header id='description' as='h3'>About the property</Header>
                {
                    showFullText ? (
                        <div className={style.longText}>
                            { description}
                        </div>
                    ) : (
                        <div>
                            <p>{shortDescription}</p>
                            <span className={style.clickableText} onClick={this.handleReadMore}>Read more</span>
                        </div>
                    )
                }
                <Divider section />
            </div>
        )
    }
}

export default Description