/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Louis Capitanchik <louis.capitanchik@launchbase.solutions>, December 2018
 */

import * as React from 'react'
import { Editor } from 'slate-react'
import { Value } from 'slate'
import Markdown from 'slate-md-serializer'
import cx from 'classnames'
import styles from './messagecomposer.less'

import { Popup, Icon } from 'semantic-ui-react'

const serializer = new Markdown()

const initial = Value.fromJSON({
    document: {
        nodes: [
            { object: 'block', type: 'paragraph', nodes: [
                { object: 'text', leaves: [{ text: '' }] },
            ] }
        ]
    },
})

type EditorButtonProps = {
    icon: string,
    tooltip?: string,
    onClick(): mixed,
}
function EditorButton(props: EditorButtonProps) {
    const {
        icon, tooltip, onClick
    } = props
    return (
        <Popup
            trigger={
                <button className={styles.message_composer__controls__button} onClick={onClick}>
                    <Icon name={icon} />
                </button>
            }
            content={tooltip}
        />
    )
}

type Props = {
    className?: ?(string | string[] | boolean),
    onSubmit(): mixed,
    saveText?: string,
}
type State = {
    value: Value,
}
export default class MessageComposer extends React.PureComponent<Props, State> {
    state = {
        value: initial,
    }

    render() {
        return (
            <div className={cx(styles.message_composer, this.props.className)}>
                <div className={styles.message_composer__controls}>
                    <EditorButton icon='bold' tooltip='Bold' onClick={this._insertBoldMark} />
                    <EditorButton icon='italic' tooltip='Italic' onClick={this._insertBoldMark} />
                </div>
                <div className={styles.message_composer__send}>
                    { this.props.onSubmit && <button className={styles.message_composer__send__button} onClick={this._submit}>{ this.props.saveText || 'Send' }</button> }
                </div>
                <Editor
                    value={this.state.value}
                    onChange={this._updateEditor}
                    className={styles.message_composer__editor}
                    spellCheck
                    autoCorrect
                    placeholder='Type a message...'
                />
            </div>
        )
    }

    _updateEditor = ({ value }) => this.setState(() => ({ value }))
    _submit = (e: Event) => {
        e.preventDefault()
        const value = serializer.serialize(this.state.value)
        if (this.props.onSubmit) {
            this.props.onSubmit(value)
        }
    }
}
