/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Oct 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import { Widget } from 'components'
import type { Attachment } from 'utils/flowtypes/models'
import { Image, Message } from 'semantic-ui-react'
import style from './photoswidget.less'


type Props = {
    onSwitchToManagePhotosTab: Function,
    photos: Attachment[]
}

const PhotosWidget = (props: Props) => {
    const { onSwitchToManagePhotosTab } = props
    
    let photos = props.photos
    const numberOfPhotosToShow = 3
    const numberOfHiddenPhotos = photos.length - numberOfPhotosToShow
    photos = numberOfHiddenPhotos > 0 ? photos.slice(0, numberOfPhotosToShow) : photos

    return (
        <Widget
            header='Photos'
            icon='image'
            action={{
                basic: true,
                icon: 'pencil',
                content: 'Change',
                onClick: props.onSwitchToManagePhotosTab
            }}
        >
            {
                photos.length > 0 ? (
                    <Image.Group>
                        {
                            photos.map((photo, index) => (
                                <Image
                                    className={style.photoswidget__thumbnail}
                                    key={index}
                                    src={photo.url}
                                    bordered
                                    rounded
                                />
                            ))
                        }
                    </Image.Group>
                ) : (
                    <Message info>
                        This accommodation has no photos yet. <span
                            className={style.textlink}
                            onClick={props.onSwitchToManagePhotosTab}>
                            Click here to add them.
                        </span>
                    </Message>
                )           
            }
            {
                numberOfHiddenPhotos > 0 && (
                    <div className={style.photoswidget__morephotos}>
                        <span onClick={onSwitchToManagePhotosTab}>+ {numberOfHiddenPhotos} more</span>
                    </div>
                )
            }
        </Widget>
    )
}

export default PhotosWidget