/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React, { type Node } from 'react'
import style from './page.less'

type Props = {
    children?: Node,
    className?: string,
    centered?: boolean
}

const Content = (props: Props) => {
    const { children, className, centered } = props

    let styling = style.page__content
    
    if (centered) styling = `${styling} ${style.page__content__centered}`

    if (className) styling = `${styling} ${className}`

    return (
        <div className={styling}>
            { children }
        </div>
    )
}

export default Content