/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

import React from 'react'
import style from './wizard.less'

const ExtraPanel = ({ desktopOnly = false, children }) => {
    
    
    return (
        <div className={desktopOnly ? style.extraPanel__desktopOnly : style.extraPanel}>
            { children }
        </div>
    )
}

export default ExtraPanel