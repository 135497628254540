/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React, { PureComponent, type Node } from 'react'
import ErrorBoundary from 'ErrorBoundary'
import style from './layout.less'
import Branding from './Branding'
import Masthead from './Masthead'
import Sidebar from './Sidebar'
import Breadcrumb from './Breadcrumb'
import Header from './Header'
import cx from 'classnames'

type Props = {
    children?: Node,
    layoutInnerClassName?: string,
}

class Layout extends PureComponent<Props> {
    static Breadcrumb = Breadcrumb
    static Header = Header

    render() {
        return (
            <ErrorBoundary>
                <div className={style.layout}>
                    <div className={style.layout__left}>
                        <Branding />
                        <Sidebar />
                    </div>
                    <div className={style.layout__right}>
                        <Masthead />
                        <div className={cx(style.layout__inner, this.props.layoutInnerClassName)}>
                            {
                                this.props.children
                            }
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        )
    }
}

export default Layout
