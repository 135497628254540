/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React, { Fragment } from 'react'
import style from './layout.less'
import { Link } from 'react-router-dom'
import { Breadcrumb as SUIBreadcrumb } from 'semantic-ui-react'

type Props = {
    breadcrumbs?: Array<Object>
}

const Breadcrumb = (props: Props) => {
    
    const { breadcrumbs } = props

    return (
        <SUIBreadcrumb size='tiny' className={style.layout__breadcrumb}>
            {
                breadcrumbs ? (
                    <Fragment>
                        <SUIBreadcrumb.Section as={Link} to='/'>Home</SUIBreadcrumb.Section>
                        <SUIBreadcrumb.Divider icon='right chevron' />
                        {
                            breadcrumbs.map((breadcrumb, index) => {
                                if (index !== (breadcrumbs.length - 1)) {
                                    return (
                                        <Fragment key={index}>
                                            {
                                                breadcrumb.link &&
                                                <SUIBreadcrumb.Section as={Link} to={breadcrumb.link}>{breadcrumb.name}</SUIBreadcrumb.Section>
                                            }
                                            {
                                                !breadcrumb.link &&
                                                <SUIBreadcrumb.Section>{breadcrumb.name}</SUIBreadcrumb.Section>
                                            }
                                            <SUIBreadcrumb.Divider icon='right chevron' />
                                        </Fragment>
                                    )
                                } else {
                                    return (
                                        <Fragment key={index}>
                                            <SUIBreadcrumb.Section active>{breadcrumb.name}</SUIBreadcrumb.Section>
                                        </Fragment>
                                    )
                                }   
                            })
                        }
                    </Fragment>
                ) : (
                    <SUIBreadcrumb.Section active>Home</SUIBreadcrumb.Section>
                )
            }
        </SUIBreadcrumb>
    )
}

export default Breadcrumb