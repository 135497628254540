/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Jan 2019
 * ========================================================================
 */

import gql from 'graphql-tag'

export const removeAttachment = gql`
    mutation(
        $attachmentId:ID!
    ){
        deleteAttachment(
            attachment_id: $attachmentId
        )
    }
`