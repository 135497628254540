/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

import React from 'react'
import { maps } from 'config'
import { CustomMap } from 'components'
import {
    Header
} from 'semantic-ui-react'

const Area = ({ location }) => {
    return (
        <div>
            <Header id='area'>Neighbourhood</Header>
            <CustomMap 
                zoom={13}
                markerEnabled={false}
                circleEnabled
                coords={{lat:location.latitude, lng:location.longitude}}
                markerDraggable={false}
                googleMapURL = {`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${maps.api_key}&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '400px' }} />}
                mapElement={<div style={{ height: '100%' }} />}
            />
        </div>
    )
}

export default Area