/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, July 2018
 * ========================================================================
 * Inspired by arfedulov's semantic-ui-calendar-react
 * https://github.com/arfedulov/semantic-ui-calendar-react/
 */

import React, { PureComponent } from 'react'

import {
    Form,
    Popup,
    Table,
    Button, ButtonGroup,
} from 'semantic-ui-react'

const cellStyles = bottomBorder => ({
    border: 'none',
    borderBottom: bottomBorder ? '1px solid rgba(34,36,38,.1)' : 'none',
})

const popupStyle = {
    padding: '0',
}

const RangeInputHeader = ({ from, to, ...rest }) => {
    return (
        <Table.Header {...rest}>
            <Table.Row>
                <Table.HeaderCell style={cellStyles(true)}>
                    {`${from || 0} - ${to || '. . .'}`}
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    )
}

const RangeInputComponent = ({ from, to, onChange, ...rest }) => {
    return (
        <Table.Body>
            <Table.Row>
                <Table.Cell>
                    <Form>
                        <Form.Input
                            label='From'
                            type='number'
                            name='from'
                            onChange={onChange}
                            value={from}
                            {...rest}
                        />
                        <Form.Input
                            label='To'
                            type='number'
                            name='to'
                            onChange={onChange}
                            value={to}
                            {...rest}
                        />
                    </Form>
                </Table.Cell>
            </Table.Row>
        </Table.Body>
    )
}

class NumberRangeInput extends PureComponent {
    state = {
        from: 0,
        to: 0,
        mode: 'perDay',
    }

    handleChange = (event, { name, value }) => {
        this.setState(prevState => {
            if (name === 'to' || name === 'from') {
                if (value === '') {
                    value = 0
                } else {
                    value = parseInt(value)
                }
            }

            if (name === 'to') {
                if (value <= prevState.from) {
                    return {
                        to: parseInt(prevState.from) + 1,
                    }
                }
            }

            return { [name]: value }
        }, () => {
            this.props.onChange(this.state.from, this.state.to)
        })
    }

    getRangeSlider = () => {
        const {
            from,
            to,
        } = this.state

        const {
            min,
            max,
            step,
        } = this.props

        return (
            <Table
                unstackable
                celled
                textAlign='center'
            >
                <RangeInputHeader
                    from={from}
                    to={to}
                    onChange={this.handleChange}
                />
                <RangeInputComponent
                    from={from}
                    to={to}
                    onChange={this.handleChange}
                    min={min}
                    max={max}
                    step={step}
                />
                <ButtonGroup attached='bottom'>
                    <Button primary={this.state.mode === 'perDay'} onClick={this._setMode('perDay')}>Per Day</Button>
                    <Button primary={this.state.mode === 'forStay'} onClick={this._setMode('forStay')}>Total</Button>
                </ButtonGroup>
            </Table>
        )
    }

    _setMode = mode => () => {
        this.setState(() => ({ mode }))
        this.props.onChangeMode && this.props.onChangeMode(mode)
    }

    render() {
        const {
            from,
            to,
        } = this.state

        const {
            onChange, // eslint-disable-line no-unused-vars 
            popupPosition,
            ...rest
        } = this.props

        let value = ''

        if (from || to)
            value = `${from || 0} - ${to || '. . .'}`

        const inputElement = (
            <Form.Input
                value={value}
                {...rest}
            />
        )

        return (
            <Popup
                trigger={inputElement}
                position={popupPosition}
                on='click'
                style={popupStyle}
            >
                {this.getRangeSlider()}
            </Popup>
        )
    }
}

export default NumberRangeInput