/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, July 2018
 * ========================================================================
 */

import React, { PureComponent, Fragment } from 'react'
import Dropzone from 'react-dropzone'
import _ from 'lodash'
import { showErrorNotification } from 'utils'
import { cloudinary as cloudinaryConfig } from 'config'
import { addApartmentTemplateAttachment, addApartmentAttachment } from 'queries/apartments'
import { getAccommodation } from 'queries/accommodation'
import { addGuestAttachment } from 'queries/guests'
import { compose, graphql, withApollo } from 'react-apollo'
import style from 'styles/app.less'
import uploaderStyle from './attachmentUploader.less'
import ErrorBoundary from 'ErrorBoundary'
import {
    Modal,
    Button,
    Header,
    Image,
    Icon
} from 'semantic-ui-react'

const inlineStyle = {
    modal: {
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: '0!important'
    }
}

class AttachmentUploader extends PureComponent {
    state = {
        modalOpen: false,
        files: [],
        uploadProgress: 0,
        images: []
    }

    acceptedModelTypes = [
        'apartment',
		'template',
		'guest'
    ]

    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => {
        this.setState({
            modalOpen: false,
            uploadProgress: 0,
            files: []
        })
    }

    handleDropAccepted = files => {
        let newFiles = files.map(file => {
            file.uploaded = false
            return file
        })

        this.setState(prevState => {
            const allFiles = prevState.files.concat(newFiles)
            const uniqueFiles = _.uniqWith(allFiles, (a, b) => {
                return (a.name === b.name && a.size === b.size)
            })

            return { files: uniqueFiles }
        }, () => {
            this.handleUpload()
        })
    }

    handleDropRejected = files => {
        showErrorNotification('Only image filetypes are allowed!')
        console.error('Invalid file type submitted:', files)
    }

    handleUpload = () => {
        this.state.files.map(async file => {
            if (file.uploaded !== true) {
                await this.uploadFile(file)
            }
        })
    }

    uploadFile = async file => {
        const response = await new Promise(resolve => {
            const url = `https://api.cloudinary.com/v1_1/${cloudinaryConfig.cloud_name}/upload`;
            const xhr = new XMLHttpRequest()
            const fd = new FormData()
            
            xhr.open('POST', url, true)
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
            
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(xhr.response)
                    } else {
                        console.error(xhr.response)
                        throw Error('Image upload failed')
                    }
                }
            }

            fd.append('upload_preset', 'apartment_images_preset')
            fd.append('tags', 'browser_upload')
            fd.append('file', file)
            
            xhr.send(fd)
        }).catch(e => e)

        if (response instanceof Error) {
            showErrorNotification('Oops! Something went wrong')
            console.error(response)
        } else {
            const image = JSON.parse(response)

            let variables = {
                modelType: this.props.modelType,
                id: this.props.modelId,
                name: image.public_id,
                type: 'image',
                url: image.secure_url
            }

            try {
                let response, attachment

                if (variables.modelType === 'template') {
                    response = await this.props.addApartmentTemplateAttachment({
                        variables: {
                            apartmentTemplateId: this.props.modelId,
                            name: variables.name,
                            type: variables.type,
                            url: variables.url
                        }
                    })

                    attachment = response.data.addApartmentTypeAttachment
                } else if (variables.modelType === 'apartment') {
                    response = await this.props.addApartmentAttachment({
                        variables: {
                            apartmentId: this.props.modelId,
                            name: variables.name,
                            type: variables.type,
                            url: variables.url
                        },
                        refetchQueries: [{
                            query: getAccommodation,
                            variables: { accommodationId: this.props.modelId}
                        }]
                    })

                    attachment = response.data.addApartmentAttachment
				} else if (variables.modelType === 'guest') {
					response = await this.props.addGuestAttachment({
                        variables: {
                            guestId: this.props.modelId,
                            name: variables.name,
                            type: variables.type,
                            url: variables.url
                        }
                    })

                    attachment = response.data.addGuestAttachment
				}

                this.setState(prevState => {
                    let prevFiles = prevState.files
                    const [fileToUpdate] = _.remove(prevFiles, f => f.name === file.name)
                    if (fileToUpdate) {
                        fileToUpdate.uploaded = true
                    }
                    if (this.props.onAttachmentUpload && fileToUpdate) {
                        this.props.onAttachmentUpload(attachment)
                    }
                    return { files: fileToUpdate ? prevFiles.concat([fileToUpdate]) : prevFiles }
                })
            } catch (e) {
                let pendingUploads = localStorage.getItem('pending-uploads')

                if (pendingUploads) {
                    pendingUploads = JSON.parse(pendingUploads)
                    pendingUploads.push(variables)
                } else {
                    pendingUploads = [variables]
                }

                localStorage.setItem('pending-uploads', JSON.stringify(pendingUploads))
                
                showErrorNotification('Oops! Something went wrong')
                console.error(e)
            }
        }
    }

    render() {
        const {
            modalOpen,
            files
        } = this.state

        const {
            accept,
            modelType
        } = this.props

        if (!this.acceptedModelTypes.includes(modelType)) {
            throw new Error('No accepted modelType provided as prop to AttachmentUploader')
        }

        return (
            <ErrorBoundary>
                <Modal
                    trigger={
                        <Button
                            fluid
                            primary
                            content='Add Image'
                            onClick={this.handleOpen}
                        />
                    }
                    open={modalOpen}
                    onClose={this.handleClose}
                    style={inlineStyle.modal}
                    size='small'
                >
                    <Modal.Header content='Add Images' />
                    <Modal.Content>
                        <div className={uploaderStyle.uploader__grid}>
                            <Dropzone
                                onDrop={this.handleDrop}
                                accept={accept}
                                onDropRejected={this.handleDropRejected}
                                onDropAccepted={this.handleDropAccepted}
                            >
                                <div className={style.dropzone__inner}>
                                    <p><Icon name='image' size='huge' /></p>
                                    <p>Click to upload images. You can drag and drop too!</p>
                                </div>
                            </Dropzone>
                            <Modal.Description>
                                {
                                    files.length
                                    ?
                                    <Fragment>
                                        <Header content='Images' />
                                        <Image.Group>
                                        {
                                            files.map((file, index) => (
                                                <Image
                                                    key={index}
                                                    src={file.preview}
                                                    className={uploaderStyle.uploader__attachment}
                                                    label={
                                                        file.uploaded
                                                        ?
                                                        {
                                                            corner:'left',
                                                            icon:'check',
                                                            color: 'green'
                                                        }
                                                        :
                                                        {
                                                            corner:'left',
                                                            icon: {
                                                                name: 'spinner',
                                                                loading: true
                                                            }
                                                        }
                                                    }
                                                    bordered
                                                    rounded
                                                />
                                            ))
                                        }
                                        </Image.Group>
                                    </Fragment>
                                    :
                                    null
                                }
                            </Modal.Description>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.handleClose} content='Cancel' />
                        <Button
                            primary
                            onClick={this.handleClose}
                            disabled={
                                (files.length >= 1) &&
                                (!files.every(file => file.uploaded))
                            }
                            content='Done'
                        />
                    </Modal.Actions>
                </Modal>
            </ErrorBoundary>
        )
    }
}

export default compose(
    graphql(
        addApartmentTemplateAttachment,
        {
            name: 'addApartmentTemplateAttachment'
        }
    ),
    graphql(
        addApartmentAttachment,
        {
            name: 'addApartmentAttachment'
        }
	),
	graphql(
        addGuestAttachment,
        {
            name: 'addGuestAttachment'
        }
    )
)(withApollo(AttachmentUploader))