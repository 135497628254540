/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import gql from 'graphql-tag'

export const createApartmentTemplate = gql`
    mutation(
        $propertyId:ID!
    ){
        createApartmentTypeStub(
            propertyId: $propertyId
        ){
            id
        }
    }
`

export const getApartmentTemplates = gql`
    query{
        apartmentTypes{
            list{
                id
                name
                draft
                apartmentCount
                approved
                property{
                    address
                }
                provider{
                    id
                    name
                }
            }
            pagination{
                count
            }
        }
    }
`

export const getApartmentTemplate = gql`
    query(
        $apartmentTemplateId:ID!
    ){
        apartmentType(
            id:$apartmentTemplateId
        ){
            id
            name
            approved
            draft
            property{
                id
                address
            }
            provider{
                id
                name
            }
            apartmentCount
            apartments{
                id
                name
            }
            features{
                id
                value
                name
                type
                country
                onDemand
            }
            notes{
                id
                note
            }
            attachments{
                id
                name
                description
                type
                url
            }
            pricing{
                start
                end
                cost
            }
        }
    }
`

export const getAllApartmentTemplateFeatures = gql`
    query{
        apartmentFeatures{
            id,
            name,
            type,
            required,
            meta,
            active,
            country{
                id
                name
            }
        }
    }
`

export const getAllApartmentFeatures = getAllApartmentTemplateFeatures

export const updateApartmentTemplate = gql`
    mutation(
        $apartmentTemplateId:ID!,
        $features:[ApartmentTypeFeatureInput!]!
    ){
        updateApartmentType(
            apartmentTypeId:$apartmentTemplateId,
            features:$features
        ){
            id
        }
    }
`

export const updateApartmentTemplateName = gql`
    mutation(
        $apartmentTemplateId:ID!,
        $name:String!
    ){
        updateApartmentType(
            apartmentTypeId:$apartmentTemplateId,
            name:$name
        ){
            id
            name
        }
    }
`

export const createApartment = gql`
    mutation(
        $templateId:ID!,
        $name:String!,
        $features:[ApartmentTypeFeatureInput!]
    ){
        createApartment(
            apartmentTypeId:$templateId,
            name:$name,
            features:$features
        ){
            id
            name
        }
    }
`

export const publishApartmentTemplate = gql`
    mutation(
        $apartmentTemplateId:ID!
    ){
        publishApartmentType(
            apartmentTypeId:$apartmentTemplateId
        ){
            id
        }
    }
`

export const getApartment = gql`
    query($apartmentId:ID!){
        apartment(
            id:$apartmentId
        ){
            id
            name
            property{
                id
                address
                location{
                    longitude
                    latitude
                }
            }
            template{
                id
                name
                attachments{
                    id
                    name
                    description
                    type
                    url
                }
            }
            features{
                id
                value
                name
                type
                country
                onDemand
            }
            provider{
                id
                name
            }
            createdAt
            updatedAt
            pricing{
                start
                end
                cost
            }
            attachments{
                id
                name
                description
                type
                url
            }
        }
    }
`

export const addApartmentTemplateAttachment = gql`
    mutation(
        $apartmentTemplateId:ID!,
        $name:String,
        $description:String,
        $type:AttachmentTypeEnum!,
        $url:String!
    ){
        addApartmentTypeAttachment(
            apartmentTypeId:$apartmentTemplateId,
            name:$name,
            description:$description,
            type:$type,
            url:$url
        ){
            id
            url
            name
        }
    }
`

export const addApartmentAttachment = gql`
    mutation(
        $apartmentId:ID!,
        $name:String,
        $description:String,
        $type:AttachmentTypeEnum!,
        $url:String!
    ){
        addApartmentAttachment(
            apartmentId:$apartmentId,
            name:$name,
            description:$description,
            type:$type,
            url:$url
        ){
            id
            url
            name
        }
    }  
`

export const removeAttachment = gql`
    mutation(
        $attachmentId:ID!
    ){
        removeAttachment(
            attachmentId:$attachmentId
        )
    }
`

export const getUnavailabilityForApartment = gql`
    query(
        $start:Timestamp!,
        $end:Timestamp!,
        $apartmentId:ID!
    ){
        getUnavailabilityForApartment(
            start:$start,
            end:$end,
            apartmentId:$apartmentId
        ){
            from
            to
        }
    }
`

export const updateApartmentPricing = gql`
    mutation(
        $apartmentId: ID!,
        $priceList: [PricingInput!]!
    ){
        setApartmentPricing(
            apartmentId:$apartmentId,
            priceList: $priceList
        )
    }
` 

export const updateApartmentName = gql`
    mutation(
        $apartmentId: ID!,
        $name: String
    ){
        updateApartmentInstance(
            apartmentId: $apartmentId,
            name: $name
        ){
            id
        }
    }
`

export const apartmentFeatures = gql`
    query{
        apartmentFeatures{
            id
            name
            type
            active
            required
            meta
            country{
                id
                name
            }
        }
    }
`