/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Oct 2018
 * ========================================================================
 */

// @flow

import { services } from 'config'

const ipLocationService = {
    get ipstack() { return import('./providers/ipstack') },
    get ipgeolocation() { return import('./providers/ipgeolocation') }
}

export default ipLocationService[services.iplocation].then(service => service.default)