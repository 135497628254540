/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import React from 'react'
import { Header, Segment } from 'semantic-ui-react'
import style from './output.less'

const Output = (props) => {
    const title = props.title ? <Header as='h2'>{props.title}</Header> : null

    return (
        <div className={style.output}>
            <Segment>
                { title }
                { props.children }
            </Segment>
        </div>
    )
}

export default Output