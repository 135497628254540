/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Yunjia Li <yunjia.li@launchbase.solutions>, Dec 2018
 * ========================================================================
 */

import React from 'react'

import {
    Label
} from 'semantic-ui-react'


const GuestLabel = ({ guest }) => {
    return (
        <Label color='blue'>
            {guest.name}
        </Label>
    )
}

export default GuestLabel