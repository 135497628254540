/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 * Based upon arfedulov's excellent semantic-ui-calendar-react
 * https://github.com/arfedulov/semantic-ui-calendar-react/
 */

import React, { PureComponent } from 'react'
import { Table } from 'semantic-ui-react'

const hoverCellStyle = {
    outline: '1px solid #85b7d9',
    cursor: 'pointer',
    textAlign: 'center'
}

const CellStyle = {
    textAlign: 'center'
}

class AvailabilityCalendarCell extends PureComponent {
    state = {
        hoverCell: false
    }

    toggleHoverCell = () => {
        this.setState(prevState => ({ hoverCell: !prevState.hoverCell }))
    }

    onCellClick = event => {
        const { onClick, data } = this.props
        event.stopPropagation()
        if (typeof onClick === 'function')
            onClick(event, { ...this.props, value: data })
    }

    render() {
        let { hoverCell } = this.state

        const { hover, ...rest } = this.props

        if (hover === false)
            hoverCell = false

        return (
            <Table.Cell
                {...rest}
                style={hoverCell ? hoverCellStyle : CellStyle}
                onMouseOver={this.toggleHoverCell}
                onMouseLeave={this.toggleHoverCell}
                onClick={this.onCellClick}
            >
                { this.props.data.format('D') }
            </Table.Cell>
        )
    }
}

export default AvailabilityCalendarCell