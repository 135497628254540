/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, March 2019
 * =========================================================================
 */

// @flow

import * as React from 'react'
import _ from 'lodash'
import cx from 'classnames'
import style from './fileuploader.less'
import Dropzone from 'react-dropzone'
import { Button, Modal, Icon, Header, Table } from 'semantic-ui-react'
import { formatBytes } from 'utils/misc'
import type { FileUpload, MimeType } from 'utils/flowtypes/models'

type Props = {
    fileUploads: FileUpload[],
    multiple?: boolean,
    minSize?: number,
    maxSize?: number,
    minDimensions?: number,
    maxDimensions?: number,
    acceptedFileTypes?: MimeType[],
    header?: string,
    description?: string | React.Node,
    dropzoneText?: string,
    dropzoneIcon?: string,
    children: React.Node,
    processing?: boolean,
    onChange: (files: FileUpload[]) => void,
    onError: (files: File[]) => void,
    onSubmit?: (handleClose: Function) => void,
    onDelete: (fileUpload: FileUpload) => void,
    // onCancel?: () => boolean // return whether or not the modal should close
}

type State = {
    open: boolean
}

class FileUploader extends React.PureComponent<Props, State> {
    state = {
        open: false
    }

    handleOpen = () => this.setState({ open: true })

    handleClose = () => this.setState({ open: false })
    
    // handleCancel = () => {
    //     if (this.props.onCancel) {
    //         const shouldClose = this.props.onCancel()
    //         if (shouldClose) {
    //             this.handleClose()
    //         }
    //     } else {
    //         this.handleClose()
    //     }
    // }

    isValidDimensions = (file: File): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            const image = new Image()
            image.onload = () => {
                const { minDimensions, maxDimensions } = this.props
                if (minDimensions) {
                    if (image.height < minDimensions || image.width < minDimensions) {
                        resolve(false)
                    }
                }
                if (maxDimensions) {
                    if (image.height > maxDimensions || image.width > maxDimensions) {
                        resolve(false)
                    }
                }
                resolve(true)
            }
            image.onerror = reject
            image.src = URL.createObjectURL(file)
        })
    }

    imageDimensions = (file: File): Promise<Object> => {
        return new Promise((resolve, reject) => {
            const image = new Image()
            image.onload = () => {
                resolve({
                    height: image.height,
                    width: image.width
                })
            }
            image.onerror = reject
            image.src = URL.createObjectURL(file)
        })
    }

    acceptedDimensions = (dimensions: { height: number, width: number }) => {
        const { minDimensions, maxDimensions } = this.props
        if (minDimensions) {
            if (dimensions.height < minDimensions || dimensions.width < minDimensions) {
                return false
            }
        }
        if (maxDimensions) {
            if (dimensions.height > maxDimensions || dimensions.width > maxDimensions) {
                return false
            }
        }
        return true
    }

    handleDropAccepted = async (files:File[]) => {
        const fileUploads = await Promise.all(files.map(async file => {
            const fileUpload = {}
            
            fileUpload.id = _.uniqueId('fileuploader_')
            fileUpload.file = file
            fileUpload.name = file.name
            fileUpload.size = file.size
            fileUpload.type = file.type
            fileUpload.deletable = this.props.onDelete != null
            fileUpload.status = 'pending'
            fileUpload.error = null
            fileUpload.valid = true

            if (this.props.minDimensions != null || this.props.maxDimensions != null) {
                fileUpload.dimensions = await this.imageDimensions(file)
                fileUpload.valid = this.acceptedDimensions(fileUpload.dimensions)
            }

            return fileUpload
        }))

        if (fileUploads.every(fileUpload => fileUpload.valid)) {
            return this.props.onChange(fileUploads)
        }

        return this.handleDropRejected(files)
    }

    handleDropRejected = (files:File[]) => {
        console.error('Dropzone rejected files:', files)
        this.props.onError(files)
    }

    handleDelete = (fileUpload: FileUpload) => {
        this.props.onDelete(fileUpload)
    }

    render() {
        const { open } = this.state
        
        const {
            dropzoneText = 'Click to upload your file',
            dropzoneIcon = 'upload',
            header,
            description,
            multiple,
            fileUploads = [],
            acceptedFileTypes,
            processing,
            minSize,
            maxSize
        } = this.props

        const maxFilesReached = multiple === false && fileUploads.length === 1
        const dropzoneDisabled = maxFilesReached

        return (
            <React.Fragment>
                {
                    // $FlowFixMe
                    this.props.children(this.handleOpen)
                }
                <Modal
                    open={open}
                    size='small'
                    onClose={this.handleClose}
                    closeOnDimmerClick={false}
                >
                    <Modal.Content>
                        <div className={style['fileuploader']}>
                            <div>
                                <Dropzone
                                    className={
                                        dropzoneDisabled
                                        ? cx(style['fileuploader__dropzone'], style['fileuploader__dropzone--disabled'])
                                        : style['fileuploader__dropzone']
                                    }
                                    accept={acceptedFileTypes}
                                    onDropRejected={this.handleDropRejected}
                                    onDropAccepted={this.handleDropAccepted}
                                    multiple={multiple}
                                    disabled={dropzoneDisabled}
                                    minSize={minSize}
                                    maxSize={maxSize}
                                >
                                    <div className={style['fileuploader__dropzone__inner']}>
                                        <div>
                                            <Icon
                                                name={
                                                    maxFilesReached
                                                    ? 'thumbs up'
                                                    : dropzoneDisabled
                                                    ? 'ban'
                                                    : dropzoneIcon
                                                }
                                                size='huge'
                                            />
                                        </div>
                                        <p>
                                            <strong>
                                                {
                                                    maxFilesReached
                                                    ? 'Click done to finish'
                                                    : dropzoneDisabled
                                                    ? 'File upload not available'
                                                    : dropzoneText
                                                }
                                            </strong>
                                        </p>
                                    </div>
                                </Dropzone>
                            </div>
                            <div className={style['fileuploader__details-panel']}>
                                {
                                    header &&
                                    <Header as='h3'>{ header }</Header> 
                                }
                                
                                {
                                    description &&
                                    <div className={style['fileuploader__description']}>
                                        {
                                            typeof(description) === 'string' ? (
                                                <p>{ description }</p>
                                            ) : description
                                        }
                                    </div>
                                }                                
                                {
                                    fileUploads.length === 0 ? (
                                        <div className={style['fileuploader__files-placeholder']}>
                                            <p>
                                                <em>No files uploaded</em>
                                            </p>
                                        </div>
                                    ) : (
                                        <div className={style['fileuploader__files']}>
                                            <Table
                                                basic='very'
                                                compact
                                                unstackable
                                            >
                                                <Table.Body>
                                                    {   
                                                        fileUploads.map((file, index) => {
                                                            return (
                                                                <Table.Row
                                                                    key={index}
                                                                    disabled={file.status === 'uploading'}
                                                                    error={file.error ? true : false}
                                                                >
                                                                    <Table.Cell
                                                                        collapsing
                                                                        className={style['fileuploader__file-icon']}
                                                                    >
                                                                        <Icon
                                                                            name={
                                                                                file.error
                                                                                ? 'warning sign'
                                                                                : 'file'
                                                                            }
                                                                        />
                                                                    </Table.Cell >
                                                                    <Table.Cell
                                                                        className={style['fileuploader__file-name']}
                                                                    >
                                                                        {file.name}
                                                                    </Table.Cell >
                                                                    <Table.Cell
                                                                        collapsing
                                                                        textAlign='right'
                                                                        className={style['fileuploader__file-size']}
                                                                    >
                                                                        {formatBytes(file.size)}
                                                                    </Table.Cell>
                                                                    <Table.Cell
                                                                        collapsing
                                                                        textAlign='right'
                                                                        className={style['fileuploader__file-action']}
                                                                    >
                                                                        <Button
                                                                            size='tiny'
                                                                            basic
                                                                            disabled={!file.deletable || file.status === 'uploading'}
                                                                            loading={file.status === 'uploading'}
                                                                            icon='trash'
                                                                            onClick={() => this.handleDelete(file)}
                                                                        />
                                                                    </Table.Cell >
                                                                </Table.Row>
                                                            )
                                                        })
                                                    }
                                                </Table.Body>
                                            </Table>
                                        </div>
                                    )
                                }
                                <div className={style['fileuploader__actions']}>
                                    <Button
                                        primary
                                        size='small'
                                        onClick={() => {
                                            if (this.props.onSubmit) {
                                                this.props.onSubmit(this.handleClose)
                                            } else {
                                                this.handleClose
                                            }
                                        }}
                                        loading={processing}
                                        disabled={processing}
                                        content='Done'
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        )
    }
}

export default FileUploader