/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import Header from './Header'
import Body from './Body'
import style from './listing.less'
import type { Accommodation } from 'utils/flowtypes/models'

type Props = {
    listing: Accommodation
}

const Listing = (props: Props) => {
    const { listing } = props

    return (
        <div className={style.listing}>
            <Header listing={listing}/>
            <Body listing={listing} />
        </div>
    )
}

export default Listing