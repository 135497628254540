/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import { searchProperties } from 'queries/properties'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { truncatePropertyAddress } from 'utils'
import ErrorBoundary from 'ErrorBoundary'
import localise from 'languages'
import { Form } from 'semantic-ui-react'
import type { User } from 'utils/flowtypes/models'
import type { ApolloClient } from 'utils/flowtypes/apollo'

type Props = {
    onChange: Function,
    client: ApolloClient,
    user: User,
    label?: string,
    fluid?: boolean,
    value?: string
}

type State = {
    properties: Array<{
        key: string,
        value: string,
        text: string
    }>
}

class PropertySearchDropdown extends PureComponent<Props, State> {
    state = {
        properties: []
    }

    handleChange = (event, { value }) => { this.props.onChange(value) }

    async componentDidMount() {
        let currentPage = 0
        let totalPages = 1
        let properties = []
        let errors = []
        
        while (currentPage < totalPages) {
            const response = await this.props.client.query({
                query: searchProperties,
                variables: {
                    page: currentPage
                },
                fetchPolicy: 'no-cache'
            }).catch(e => e)

            if (response instanceof Error) {
                errors.push(response)
                currentPage++
            } else {
                const { pagination, list } = response.data.properties
                properties = properties.concat(list)
                totalPages = pagination.total_pages
                currentPage++
            }
        }

        if (errors.length) {
            errors.map(error => { console.error(error) })
        }

        if (properties.length) {
            properties = properties.map(property => {
                return {
                    key: property.id,
                    value: property.id,
                    text: property.name || truncatePropertyAddress(property.address)
                }
            })

            this.setState({ properties })
        }
    }

    render() {
        const {
            properties
        } = this.state

        const {
            label = localise('PROPERTY.SELECT'),
            fluid = true,
            value = ''
        } = this.props

        return (
            <ErrorBoundary>
                <Form.Dropdown
                    placeholder={localise('PROPERTY.SELECT')}
                    fluid={fluid}
                    search
                    selection
                    onChange={this.handleChange}
                    options={properties}
                    label={label}
                    value={value}
                />
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default withApollo(connect(mapStateToProps)(PropertySearchDropdown))