/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

import { compose, withPropsOnChange } from 'recompose'
import { withRouter } from 'react-router'
import queryString from 'query-string'

const propsWithQuery = withPropsOnChange(
    ['location', 'match'],
    ({ location, match }) => {
        return {
            location: {
                ...location,
                query: queryString.parse(location.search)
            },
            match
        }
    }
)

export default compose(withRouter, propsWithQuery)