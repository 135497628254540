/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, April 2019
 * =========================================================================
 */

// @flow

import * as React from 'react'
import style from './notifications.less'
import cx from 'classnames'
import { Dropdown } from 'semantic-ui-react'

type NotificationItemType = 'positive' | 'negative' | 'warning' | 'standard' | 'primary'

type Props = {
    content: string,
    type?: NotificationItemType
}

const NotificationItem = (props: Props) => {

    let className = style['notifications__item']

    switch (props.type) {
        case 'primary':
            className = cx(className, style['notifications__item--primary'])
            break;

        case 'positive':
            className = cx(className, style['notifications__item--positive'])
            break;

        case 'negative':
            className = cx(className, style['notifications__item--negative'])
            break;
        
        case 'warning':
            className = cx(className, style['notifications__item--warning'])
            break;
    
        default:
            className = cx(className, style['notifications__item--standard'])
            break;
    }

    return (
        <Dropdown.Item className={className}>
            <div>
                <p className={style['notifications__description']}>
                    { props.content }
                </p>
            </div>
        </Dropdown.Item>
    )
}

export default NotificationItem
