exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/*\n * Copyright (C) LaunchBase LTD - All Rights Reserved \n * Unauthorized copying of this file, via any medium is strictly prohibited \n * Proprietary and confidential \n * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, March 2019\n * =========================================================================\n */\n.notificationdisplay__3WYWV {\n  width: 100%;\n  margin-bottom: 20px;\n  display: grid;\n  gap: 20px;\n}\n.notificationdisplay__3WYWV > div {\n  margin: 0!important;\n}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/NotificationsDisplay/notificationdisplay.less"],"names":[],"mappings":"AAAA;;;;;;GAMG;AACH;EACE,YAAY;EACZ,oBAAoB;EACpB,cAAc;EACd,UAAU;CACX;AACD;EACE,oBAAoB;CACrB","file":"notificationdisplay.less","sourcesContent":["/*\n * Copyright (C) LaunchBase LTD - All Rights Reserved \n * Unauthorized copying of this file, via any medium is strictly prohibited \n * Proprietary and confidential \n * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, March 2019\n * =========================================================================\n */\n.notificationdisplay {\n  width: 100%;\n  margin-bottom: 20px;\n  display: grid;\n  gap: 20px;\n}\n.notificationdisplay > div {\n  margin: 0!important;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"notificationdisplay": "notificationdisplay__3WYWV"
};