/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

import React from 'react'

import {
    Message,
    Divider,
    Icon
} from 'semantic-ui-react'

const Error = props => {
    return props.generic ? (
        <Message icon>
            <Icon name='exclamation triangle' />
            <Message.Content>
                <Message.Header>Uh oh. An Error Occurred.</Message.Header>
                Sorry about that! Please try again, or contact support.
            </Message.Content>
        </Message>
    ) : (
        <Message error>
            <Message.Header>{ props.header }</Message.Header>
            <Divider />
            <pre>{ props.stackTrace }</pre>
        </Message>
    )
}

export default Error