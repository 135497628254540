/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Yunjia Li <yunjia.li@launchbase.solutions>, Nov 2018
 * ========================================================================
 */

export const ADD_ITINERARY = 'ADD_ITINERARY'
export const REMOVE_ITINERARY = 'REMOVE_ITINERARY'

export function addItinerary(itineraryParams) {
    return {
        type: ADD_ITINERARY,
        itineraryParams
    }
}

export function removeItinerary(index) {
    return {
        type: REMOVE_ITINERARY,
        index
    }
}