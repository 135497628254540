/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, April 2019
 * =========================================================================
 */

// @flow

import * as React from 'react'
import cx from 'classnames'
import style from './dateinput.less'
import { Form } from 'semantic-ui-react'
import type { SemanticEvent, SemanticEventProps, SemanticSelectOptions } from 'utils/flowtypes/semantic'
import type { DateInputType } from 'utils/flowtypes/models'

type Props = {
    minYear?: number,
    maxYear?: number,
    yearsToShow?: number,
    disabled?: boolean,
    onChange: (e: SemanticEvent, props: SemanticEventProps) => void,
    defaultValue?: DateInputType,
    required?: boolean,
    name: string
}

type State = {
    day: ?number,
    month: ?number,
    year: ?number
}

class DateInput extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props)
        const { defaultValue } = props
        this.state = {
            day: defaultValue ? defaultValue.day : undefined,
            month: defaultValue ? defaultValue.month : undefined,
            year: defaultValue ? defaultValue.year : undefined
        }
    }

    generateDays = (): SemanticSelectOptions => {
        let days = 31

        const { month } = this.state

        if (month != null) {
            const monthsWith30Days = [4, 6, 9, 11]
            
            if (monthsWith30Days.includes(month)) {
                days = 30
            } else if (month === 2) {
                days = 29
            }
        }

        return [...Array(days)].map((u, index) => {
            return {
                value: index + 1,
                key: index + 1,
                text: index + 1
            }
        })
    }

    generateMonths = (): SemanticSelectOptions => {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ]

        return months.map((month, index) => {
            return {
                value: index + 1,
                key: month,
                text: month
            }
        })
    }

    generateYears = (): SemanticSelectOptions => {
        const upperYear = this.props.maxYear ? this.props.maxYear : new Date().getFullYear()
        const yearsToShow = this.props.minYear ? upperYear - this.props.minYear + 1 : 101

        return [...Array(yearsToShow)].map((u, index) => {
            return {
                value: upperYear - index,
                key: upperYear - index,
                text: upperYear - index,
            }
        })
    }

    handleChange = (e: SemanticEvent, props: SemanticEventProps) => {
        this.setState({ [props.name]: props.value }, () => {
            this.props.onChange(
                e,
                {
                    ...props,
                    value: {
                        day: this.state.day,
                        month: this.state.month,
                        year: this.state.year
                    },
                    name: this.props.name
                }
            )
        })
    }

    render() {
        const { day, month, year } = this.state
        const { disabled, required } = this.props
        
        let labelClassName = style['dateinput__label']

        if (disabled) {
            labelClassName = cx(labelClassName, style['dateinput__label--disabled'])
        }

        if (required) {
            labelClassName = cx(labelClassName, style['dateinput__label--required'])
        }

        return (
            <React.Fragment>
                <div style={{ width: '100%'}}>
                    <label className={labelClassName}>
                        Date of Birth
                    </label>
                    <Form.Group widths='equal'>
                        <Form.Select
                            placeholder='Day'
                            disabled={disabled}
                            value={day}
                            options={this.generateDays()}
                            onChange={this.handleChange}
                            name='day'
                        />
                        <Form.Select
                            placeholder='Month'
                            disabled={disabled}
                            value={month}
                            options={this.generateMonths()}
                            onChange={this.handleChange}
                            name='month'
                        />
                        <Form.Select
                            placeholder='Year'
                            disabled={disabled}
                            value={year}
                            options={this.generateYears()}
                            onChange={this.handleChange}
                            name='year'
                        />
                    </Form.Group>
                </div>
            </React.Fragment>
        )
    }
}

export default DateInput