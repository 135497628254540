/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential
 * Written by 
 *  Thomas Hewett <thomas.hewett@launchbase.solutions>, 
 *  Yunjia Li <yunjia.li@launchbase.solutions> 
 * Oct 2018
 * ==========================================================================
 */

import React from 'react'
import style from './imageinput.less'
import Dropzone from 'react-dropzone'
import { showErrorNotification } from 'utils'
import ErrorBoundary from 'ErrorBoundary'
import _ from 'lodash'

import {
    Card, Icon, Button, Image
} from 'semantic-ui-react'

const ImageInput = ({images = [], onChange, coverPhotoEnabled = false}) => {

    if (!onChange)
        console.error('ImageInput expects an ‘onChange’ prop')

    function handleDropAccepted(files) {
        const allImages = images.concat(files)
        const uniqueImages = _.uniqWith(allImages, (a, b) => {
            return (a.name === b.name && a.size === b.size)
        })
        handleChange(uniqueImages)
    }

    function handleDropRejected(files) {
        showErrorNotification('Only image files are allowed')
        console.error('Invalid file type submitted:', files)
    }


    function handleChange(images) {
        onChange(images)
    }

    function handleSetDefaultImage(index){
        let defaultImg = images[index]
        let newImages = _.clone(images)
        newImages.splice(index, 1)
        newImages.unshift(defaultImg);
        handleChange(newImages)
    }

    function handleRemoveImage(index){
        let newImages = _.clone(images)
        newImages.splice(index, 1)
        handleChange(newImages)
    }

    return (
        <ErrorBoundary>
            <div className={style.container}>
                <Card.Group itemsPerRow={2}>
                    {
                        images.map((image, index) => (
                            <Card key={index} raised className={coverPhotoEnabled ? style.gallery__image__extra : style.gallery__image__standard }>
                                <Image src={image.preview} 
                                    label={{ as: 'a', color:'red', size:'mini', corner: 'right', icon: 'close', onClick:handleRemoveImage.bind(this, index) }}/>
                                {
                                    coverPhotoEnabled && (
                                        <Card.Content extra>
                                            <Button
                                                fluid
                                                compact
                                                size='mini'
                                                basic={index === 0}
                                                primary
                                                onClick={handleSetDefaultImage.bind(this, index)} 
                                                disabled={index === 0}
                                                content={index === 0 ? 'Cover photo' : 'Set cover photo'}
                                            />
                                        </Card.Content>
                                    ) 
                                }
                                
                            </Card>
                        ))
                    }
                    <Card className={style.dropzone__outer}>
                        <Card.Content className={style.dropzone__content}>
                            <Card.Description>
                                <Dropzone
                                    className={style.dropzone}
                                    accept='image/*'
                                    onDropRejected={handleDropRejected}
                                    onDropAccepted={handleDropAccepted}
                                >
                                    <div className={style.dropzone__inner}>
                                        <p><Icon name='plus' size='big' /></p>
                                    </div>
                                </Dropzone>
                            </Card.Description>
                        </Card.Content>
                    </Card>
                </Card.Group>
                
            </div>
        </ErrorBoundary>
    )
}

export default ImageInput