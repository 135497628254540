/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, April 2019
 * =========================================================================
 */

// @flow

import * as React from 'react'
import style from './notifications.less'
import NotificationItem from './NotificationItem'
import { connect } from 'react-redux'
import { stripeConnect } from 'queries/stripe'
import { withApollo } from 'react-apollo'
import { Dropdown, Icon } from 'semantic-ui-react'
import type { User, StripeConnect } from 'utils/flowtypes/models'
import type { ApolloClient } from 'utils/flowtypes/apollo'

type Props = {
    user: User,
    client: ApolloClient
}

type State = {
    stripeConnect: ?StripeConnect
}

class Notifications extends React.PureComponent<Props, State> {
    state = {
        stripeConnect: null
    }

    componentDidMount() {
        if (this.props.user.company) {
            this._fetchStripeConnect()
        }
    }

    _fetchStripeConnect = async () => {
        const response = await this.props.client.query({
            query: stripeConnect,
            variables: {
                companyId: this.props.user.company.id
            }
        }).catch(e => e)

        if (response instanceof Error) {
            console.error(response)
        } else {
            this.setState({ stripeConnect: response.data.stripeConnect })
        }
    }

    render() {
        const notifications = []
        
        const { stripeConnect } = this.state 

        if (stripeConnect) {
            const { payouts_enabled } = stripeConnect.stripe_payload
            
            if (!payouts_enabled) {
                notifications.push({
                    id: 'stripe_information_required',
                    type: 'warning',
                    content: 'Your account profile is incomplete. You will not be able to list or book accommodation while it is incomplete. To finish it, go to Manage Account.'
                })
            }
        }

        return (
            <Dropdown
                pointing='top right'
                item
                icon={
                    <Icon.Group>
                        <Icon
                            name='bell'
                            size='large'
                            disabled={notifications.length === 0}
                        />
                        {
                            notifications.length > 0 && (
                                <Icon
                                    corner
                                    name='circle'
                                    color='pink'
                                    size='large'
                                />
                            )   
                        }                    
                    </Icon.Group>
                }
            >
                <Dropdown.Menu>
                    <Dropdown.Header content='Notifications' />
                    {   
                        notifications.length > 0 ? (
                            notifications.map(notification => {
                                return (
                                    <NotificationItem
                                        key={notification.id}
                                        type={notification.type ? notification.type : undefined}
                                        content={notification.content}
                                />
                                )
                            })
                        ) : (
                            <Dropdown.Item className={style['notifications__item']}>
                                <div>
                                    <p className={style['notifications__description']}>
                                        Hooray! You have no unread notifications.
                                    </p>
                                </div>
                            </Dropdown.Item>
                        )
                    }
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}

const mapStateToProps = state => ({ user: state.user })

export default withApollo(connect(mapStateToProps)(Notifications))