exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/*\n * Copyright (C) LaunchBase LTD - All Rights Reserved \n * Unauthorized copying of this file, via any medium is strictly prohibited \n * Proprietary and confidential \n * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, April 2019\n * =========================================================================\n */\n.dateinput__label__2UGOS {\n  color: rgba(0, 0, 0, 0.87);\n  font-size: 0.92857143em;\n  font-weight: 700;\n  text-transform: none;\n  margin: 0 0 0.28571429rem 0;\n  display: block;\n}\n.dateinput__label--required__3GNzI:after {\n  display: inline-block;\n  vertical-align: top;\n  margin: -0.2em 0 0 0.2em;\n  content: '*';\n  color: #db2828;\n}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/DateInput/dateinput.less"],"names":[],"mappings":"AAAA;;;;;;GAMG;AACH;EACE,2BAA2B;EAC3B,wBAAwB;EACxB,iBAAiB;EACjB,qBAAqB;EACrB,4BAA4B;EAC5B,eAAe;CAChB;AACD;EACE,sBAAsB;EACtB,oBAAoB;EACpB,yBAAyB;EACzB,aAAa;EACb,eAAe;CAChB","file":"dateinput.less","sourcesContent":["/*\n * Copyright (C) LaunchBase LTD - All Rights Reserved \n * Unauthorized copying of this file, via any medium is strictly prohibited \n * Proprietary and confidential \n * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, April 2019\n * =========================================================================\n */\n.dateinput__label {\n  color: rgba(0, 0, 0, 0.87);\n  font-size: 0.92857143em;\n  font-weight: 700;\n  text-transform: none;\n  margin: 0 0 0.28571429rem 0;\n  display: block;\n}\n.dateinput__label--required:after {\n  display: inline-block;\n  vertical-align: top;\n  margin: -0.2em 0 0 0.2em;\n  content: '*';\n  color: #db2828;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"dateinput__label": "dateinput__label__2UGOS",
	"dateinput__label--required": "dateinput__label--required__3GNzI"
};