/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, April 2019
 * =========================================================================
 */

import gql from 'graphql-tag'

export const stripeConnect = gql`
    query(
        $id: ID
        $ownerId: ID
        $companyId: ID
        $stripeId: String
    ){
        stripeConnect(
            id: $id
            owner_id: $ownerId
            company_id: $companyId
            stripe_id: $stripeId
        ){
            id
            stripe_id
            stripe_payload
            meta
        }
    }
`