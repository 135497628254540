/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import style from './layout.less'
import { connect } from 'react-redux'
import { Menu, Dropdown } from 'semantic-ui-react'
import { GuestGate, UserGate, ProviderGate } from 'routes'
import { withRouter, Link } from 'react-router-dom'
import { logout } from 'store/user/actions'
import { setAccessToken, setRefreshToken, setSessionExpiration } from 'store/session/actions'
import { NotificationTicker } from 'components'
import { Notifications } from 'components/Notifications'
import { withApollo } from 'react-apollo'
import type { ApolloClient } from 'utils/flowtypes/apollo'
import config from 'config'

type Props = {
    user: {
        name: string
    },
    logout: Function,
    removeAccessToken: Function,
    removeRefreshToken: Function,
    expireSession: Function,
    history: {
        push: Function
    },
    client: ApolloClient
}

const Masthead = (props: Props) => {
    const {
        user,
        logout,
        removeAccessToken,
        removeRefreshToken,
        expireSession,
        history
    } = props
    
    async function handleLogout() {
        await props.client.cache.reset()        
        logout()
        removeAccessToken()
        removeRefreshToken()
        expireSession()
        history.push('/')
    }

    return (
        <div className={style.layout__masthead}>
            <div className={style.layout__masthead__notifications}>
                <div>
                    <NotificationTicker />
                </div>
            </div>
            <div className={style.layout__masthead__profile}>
                <Menu secondary>
                    <Menu.Menu position='right'>
                        <GuestGate>
                            <Menu.Item name='Log In' as={Link} to='/login' />
                            <Menu.Item name='Register' as={Link} to='/register' />
                        </GuestGate>
                        {
                            user &&
                            <UserGate>
                                <Menu.Item className={style.page__masthead__username} name='username' content={user.name} />
                                <Notifications />
                                <Dropdown
                                    className={style.page__masthead__dropdown}
                                    pointing='top right'
                                    item
                                    icon={{name: 'user circle', size: 'big', style: { margin: '0' }}}
                                >
                                    <Dropdown.Menu>
                                        {
                                            config.featureFlags.disable_search !== 'TRUE' &&
                                            <Dropdown.Item as={Link} to='/search'>Search</Dropdown.Item>
                                        }
                                        <ProviderGate allowAdmin>
                                            <Dropdown.Item as={Link} to='/dashboard'>Dashboard</Dropdown.Item>
                                        </ProviderGate>
                                        <UserGate allowAdmin={false}>
                                            <Dropdown.Item as={Link} to='/account'>My Account</Dropdown.Item>
                                        </UserGate>
                                        <Dropdown.Item onClick={handleLogout}>Log Out</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </UserGate>   
                        }
                    </Menu.Menu>
                </Menu>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(logout()),
        removeAccessToken: () => dispatch(setAccessToken(null)),
        removeRefreshToken: () => dispatch(setRefreshToken(null)),
        expireSession: () => dispatch(setSessionExpiration(null))
    }
}

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(Masthead)))