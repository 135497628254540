/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Yunjia Li <yunjia.li@launchbase.solutions> Nov 2018
 * ========================================================================
 */

import { ADD_ITINERARY, REMOVE_ITINERARY } from './actions'
import { HYDRATE } from 'store/store.js'

export default function itinerary(state = [], action) {
    switch (action.type) {
        case ADD_ITINERARY:
            return [action.itineraryParams]
        case REMOVE_ITINERARY:
            return []
        case HYDRATE:
            return action.serializedState.itinerary || state
        default:
            return state
    }
}