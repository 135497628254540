/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Oct 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import { Widget } from 'components'
import { Link } from 'react-router-dom'
import { Message } from 'semantic-ui-react'

type Props = {
    propertyId: string,
    accommodationId: string
}

const AvailabilityWidget = (props: Props) => {
    return (
        <Widget
            header='Availability'
            icon='calendar'
            action={{
                basic: true,
                icon: 'pencil',
                content: 'Change',
                as: Link,
                to: `/calendar?accommodationId=${props.accommodationId}`
            }}
        >
            <Message info>
                <Link to={`/calendar?accommodationId=${props.accommodationId}`}>Click here to manage availability</Link>
            </Message>
        </Widget>
    )
}

export default AvailabilityWidget