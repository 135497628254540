/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

import React from 'react'
import {
    Form
} from 'semantic-ui-react'

const PricingInput = ({min = 0, start = 1, end, onChange, ...rest}) => {

    function handleChange(event, { name, value, ...otherProps }) {
        if (onChange) {
            onChange(event, {
                name,
                value: [{
                    start,
                    end,
                    price: value || '',
                }],
                ...otherProps
            })
        }
    }
    
    return (
        <Form.Input
            type='number'
            step='1'
            icon='pound'
            iconPosition='left'
            min={min}
            onChange={handleChange}
            {...rest}
        />
    )
}

export default PricingInput