/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React, { type Node, PureComponent } from 'react'
import style from './page.less'
import Masthead from './Masthead'
import Content from './Content'
import Footer from './Footer'
import Header from './Header'

type Props = {
    children?: Node,
    inverted?: boolean,
    className?: string,
    mastheadClassName?: string,
    footerClassName?: string
}

class Page extends PureComponent<Props> {
    
    static Content = Content
    static Header = Header
    
    render() {
        const {
            children,
            inverted,
            className,
            mastheadClassName,
            footerClassName
        } = this.props

        let styling = style.page
        if (className) styling += ` ${className}`

        return (
            <div className={styling}>
                <Masthead className={mastheadClassName} inverted={inverted} />
                    { children }
                <Footer className={footerClassName} inverted={inverted} />
            </div>
        )
    }
}

export default Page