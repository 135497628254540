/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by 
 *  Thomas Hewett <thomas.hewett@launchbase.solutions>
 *  Yunjia Li <yunjia.li@launchbase.solutions>
 * Oct 2018
 * ========================================================================
 */

import gql from 'graphql-tag'

export const createProperty = gql`
    mutation(
        $companyId: ID!,
        $name: String,
        $description: String,
        $address: String!,
        $location: CoordinatesInput
        $countryId: ID!
    ){
        createProperty(
            company_id: $companyId,
            name: $name,
            description: $description,
            address: $address,
            location: $location
            country_id: $countryId
        ){
            id
            name
            address
            location{
                latitude
                longitude
            }
        }
    }
`

export const getPropertyDetails = gql`
    query($property_id:ID!){
        property(
            id:$property_id
        ){
            id
            address
            name
            location{
                longitude
                latitude
            }
        }
    }
`

export const getProperty = gql`
    query(
        $propertyId:ID!
        $attachmentTag: String
        $someAttachmentTags: [String!]
        $allAttachmentTags: [String!]
    ){
        property(
            id:$propertyId
        ){
            id
            address
            name
            description
            location{
                latitude
                longitude
            }
            accommodations{
                id
                name
                approved
                listed
                public
                created_at
                updated_at
            }
            attachments(
                tag: $attachmentTag
                some_tags: $someAttachmentTags
                all_tags: $allAttachmentTags
            ){
                id
                name
                description
                type
                url
                tags
            }
            certifications{
                id
                name
                value
                approved
                issued_at
                expires_at
                definition{
                    id
                    name
                    possible_values
                    default_expiry
                    expires
                    certificate_required
                    meta
                }
                meta
                notes{
                    id
                    contents
                    tags
                }
            }
            created_at
            updated_at
        }
    }
`

export const searchProperties = gql`
    query(
        $propertyId: ID,
        $address: String,
        $page: Int 
    ){
        properties(
            id: $propertyId,
            address: $address,
            per_page: 10,
            page: $page
        ){
            list{
                id
                address
                name
            },
            pagination{
                count
                page
                total_pages
                next_page
            }
        }
    }
`

export const getProperties = gql`
    query(  
        $perPage: Int,
        $page: Int,
    ){
        properties(
            per_page: $perPage,
            page: $page
        ){
            list{
                id
                address
                name
                templates{
                    id
                    accommodations{
                        id
                    }
                }
                location{
                    longitude
                    latitude
                }
                created_at
                updated_at
            }
            pagination{
                count
                totalPages
                page
                perPage
            }
        }
    }
`

export const updateProperty = gql`
    mutation(
        $propertyId: ID!
        $name: String
        $description: String
        $location: CoordinatesInput
        $address: String,
        $countryId: ID
    ){
        updateProperty(
            property_id: $propertyId
            name: $name
            description: $description
            location: $location
            address: $address,
            country_id: $countryId
        ){
            id
            address
            description
            location{
                latitude
                longitude
            }
        }
    }
`

export const getPropertyCalendarData = gql`
    query($propertyId:ID!){
        property(
            id:$propertyId
        ){
            id
            templates{
                id
                apartments{
                    id
                    name
                    createdAt
                    updatedAt
                    pricing{
                        start
                        end
                        cost
                    }
                    unavailable{
                        id
                        start
                        end
                    }
                }
            }
        }
    }
`

export const getPropertyList = gql`
    query(
        $page: Int
    ){
        properties(
            perPage: 10,
            page: $page
        ){
            list{
                name
                id
                address
                accommodations{
                    id
                    name
                }
            },
            pagination{
                count
                page
                total_pages
                next_page
            }
        }
    }
`

export const quickSearchProperties = gql`
    query(
        $address: String
    ){
        properties(
            address: $address
            perPage: 30
        ){
            list{
                id
                name
                address
                accommodations{
                    id
                    name
                }
            }
        }
    }
`

export const getPropertyAccommodationList = gql`
    query(
        $propertyId: ID!
    ){
        property(
            id: $propertyId
        ){
            id
            address
            name
            accommodations{
                id
                name
            }
        }
    }
`

export const addPropertyAttachment = gql`
    mutation(
        $propertyId: ID!,
        $name: String,
        $description: String,
        $type: AttachmentTypeEnum!,
        $url: String!,
        $tags: [String!]
    ){
        addPropertyAttachment(
            property_id: $propertyId,
            name: $name,
            description: $description,
            type: $type,
            url: $url,
            tags: $tags
        ){
            id
            name
            description
            type
            url
        }
    }
`

export const deleteAttachment = gql`
    mutation(
        $attachmentId: ID!
    ){
        deleteAttachment(
            attachment_id: $attachmentId
        )
    }
`

export const properties = gql`
    query(
        $page: Int
        $perPage: Int
    ){
        properties(
            page: $page
            per_page: $perPage
        ){
            list{
                id
                name
                address
                accommodations{
                    id
                    name
                }
            }
            pagination{
                count
                total_pages
                page
                next_page
                previous_page
            }
        }
    }
`