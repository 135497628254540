/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import { createStore, applyMiddleware } from 'redux'
import sapApp from './reducers'
import { mirrorStateToLocalStorage } from './middleware' 

export const HYDRATE = 'HYDRATE'

export function hydrate(serializedState) {
    return {
        type: HYDRATE,
        serializedState
    }
}

export const store = createStore(
    sapApp,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(mirrorStateToLocalStorage)
)

window.s = () => store.getState()

export type Dispatch = typeof store.dispatch
