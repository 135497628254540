/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

import React, { PureComponent } from 'react'
import ErrorBoundary from 'ErrorBoundary'
import style from './inputwithsuggestions.less'
import { Form, Dropdown } from 'semantic-ui-react'

class InputWithSuggestions extends PureComponent {
    state = {}

    handleRef = node => this.setState({ node })

    render() {
        const {
            name,
            suggestions,
            onInputChange,
            onOptionChange,
            ...rest
        } = this.props

        return (
            <ErrorBoundary>
                <Dropdown
                    on='focus'
                    basic
                    fluid
                    selectOnNavigation={false}
                    icon={null}
                    onChange={(e, { value }) => {
                        if (value) onOptionChange(e, { name, value })
                    }}
                    className={style.suggestions}
                    trigger={
                        <Form.Input
                            name={name}
                            onChange={onInputChange}
                            {...rest}
                            autoComplete='off'
                        />
                    }
                    options={suggestions}
                />
            </ErrorBoundary>
        )      
    }
}

export default InputWithSuggestions
