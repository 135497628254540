/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

import React from 'react'
import style from './wizard.less'

import {
    Segment,
    Header,
    Button
} from 'semantic-ui-react'

const Panel = ({final, totalSteps, step, header, children, nextAction, previousAction}) => {
    return (
        <Segment.Group className={style.panel}>
            <Segment className={style.panel__body}>
                {
                    header &&
                    <Header as='h3'>
                        {
                            step &&
                            <Header.Subheader>Step {step} of {totalSteps}</Header.Subheader>
                        }
                        {
                            final &&
                            <Header.Subheader>Finished</Header.Subheader>
                        }
                        {header}
                    </Header>
                }
                <div className={style.panel__body__inner}>
                    {
                        children
                    }
                </div>                
            </Segment>
            {
                (nextAction || previousAction) &&
                <Segment clearing className={style.panel__footer}>
                    {
                        nextAction &&
                        <Button.Group floated='right'>
                            {nextAction}
                        </Button.Group>
                    }
                    {
                        previousAction &&
                        <Button.Group floated='left'>
                            {previousAction}
                        </Button.Group>
                    }
                </Segment>
            }
        </Segment.Group> 
    )
}

export default Panel