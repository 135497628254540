/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Yunjia Li <yunjia.li@launchbase.solutions>, Dec 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import type { LineItems } from 'utils/flowtypes/models'
import { Table } from 'semantic-ui-react'
import { displayPrice } from 'utils/money'

type Props = {
    items: LineItems,
    currencyId: string
}

const Listing = (props: Props) => {
	const { items, currencyId } = props

	const deposit = items.find(item => item.meta.deposit === true)

    return (
        <Table striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    items.map(item=>{
						if (!item.meta.payment && !item.meta.deposit) {
							return (
								<Table.Row key={item.id}>
									<Table.Cell>{item.name}</Table.Cell>
									<Table.Cell>{displayPrice(currencyId, item.amount_owed)}</Table.Cell>
								</Table.Row>
							)
						}

						return null
                    })
				}
				{
					deposit && (
						<Table.Row key={deposit.id}>
							<Table.Cell>{deposit.name}</Table.Cell>
							<Table.Cell>{displayPrice(currencyId, deposit.amount_owed)}</Table.Cell>
						</Table.Row>
					)
				}
            </Table.Body>
        </Table>
    )
}

export default Listing