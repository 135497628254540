/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Oct 2018
 * ========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import { Modal, Button } from 'semantic-ui-react'
import type { Feature, FeatureDefinition } from 'utils/flowtypes/models'
import { FeatureInput } from 'components'
import type { SemanticEvent, SemanticEventProps } from 'utils/flowtypes-sui'
import { getAccommodation, updateAccommodation } from 'queries/accommodation'
import { withApollo, graphql } from 'react-apollo'
import { showErrorNotification, showSuccessNotification } from 'utils/notifications'

type Props = {
    open: boolean,
    feature: {
        name: string,
        definition: FeatureDefinition,
        value: string,
    } | Feature,
    onClose: Function,
    accommodationId: string,
    updateAccommodation: Function
}

type State = {
    name: string,
    derivedStateFromProps: boolean
}

class ChangeFeatureModal extends PureComponent<Props, State> {
    state = {
        name: '',
        derivedStateFromProps: false
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (!state.derivedStateFromProps) {
            state.name = props.feature.definition.name
            state[props.feature.definition.name] = props.feature.value
            state.derivedStateFromProps = true
            return state
        }

        return null
    }

    handleChange = (e: SemanticEvent, props: SemanticEventProps) => {
        this.setState({ [props.name]: props.value })
    }

    handleSave = async () => {
        const feature = {
            feature_id: this.props.feature.definition.id,
            value: this.state[this.props.feature.definition.name].toString(),
            on_demand: false
        }

        const variables = {
            accommodationId: this.props.accommodationId,
            features: [feature]
        }

        const response = await this.props.updateAccommodation({
            variables,
            refetchQueries: [{
                query: getAccommodation,
                variables: { accommodationId: this.props.accommodationId }
            }]
        }).catch(e => e)

        if (response instanceof Error) {
            console.error(response)
            showErrorNotification('Unable to update Accommodation details')
        } else {
            showSuccessNotification('Updated successfully')
            this.props.onClose()
        }
    }

    render() {
        const { open, onClose, feature } = this.props
        const saveButtonDisabled = false  

        return (
            <Modal
                open={open}
                size='tiny'
            >
                <Modal.Header>Change {feature.definition.name}</Modal.Header>
                <Modal.Content>
                    <FeatureInput
                        feature={feature}
                        name={this.state.name}
                        value={this.state[feature.definition.name]}
                        onChange={this.handleChange}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={onClose}
                        basic
                        primary
                    >
                        Cancel
                    </Button>
                    <Button
                        primary
                        disabled={saveButtonDisabled}
                        onClick={this.handleSave}
                    >
                        Save
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default graphql(
    updateAccommodation,
    {
        name: 'updateAccommodation'
    }
)(withApollo(ChangeFeatureModal))