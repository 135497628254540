exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/**\n * The CSS shown here will not be introduced in the Quickstart guide, but shows\n * how you can use CSS to style your Element's container.\n */\n.StripeElement__2XLcL,\n.StripeElement__name__3Gulf {\n  background-color: white;\n  height: 40px;\n  padding: 10px 12px;\n  border-radius: 4px;\n  border: 1px solid transparent;\n  box-shadow: 0 1px 3px 0 #e6ebf1;\n  -webkit-transition: box-shadow 150ms ease;\n  transition: box-shadow 150ms ease;\n}\n.StripeElement__name__3Gulf {\n  margin-bottom: 10px;\n  width: 100%;\n}\n.StripeElement--focus__3uUtL {\n  box-shadow: 0 1px 3px 0 #cfd7df;\n}\n.StripeElement--invalid__1-Lcq {\n  border-color: #fa755a;\n}\n.StripeElement--webkit-autofill__NJZss {\n  background-color: #fefde5 !important;\n}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/PaymentCard/paymentcard.less"],"names":[],"mappings":"AAAA;;;GAGG;AACH;;EAEE,wBAAwB;EACxB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,gCAAgC;EAChC,0CAA0C;EAC1C,kCAAkC;CACnC;AACD;EACE,oBAAoB;EACpB,YAAY;CACb;AACD;EACE,gCAAgC;CACjC;AACD;EACE,sBAAsB;CACvB;AACD;EACE,qCAAqC;CACtC","file":"paymentcard.less","sourcesContent":["/**\n * The CSS shown here will not be introduced in the Quickstart guide, but shows\n * how you can use CSS to style your Element's container.\n */\n.StripeElement,\n.StripeElement__name {\n  background-color: white;\n  height: 40px;\n  padding: 10px 12px;\n  border-radius: 4px;\n  border: 1px solid transparent;\n  box-shadow: 0 1px 3px 0 #e6ebf1;\n  -webkit-transition: box-shadow 150ms ease;\n  transition: box-shadow 150ms ease;\n}\n.StripeElement__name {\n  margin-bottom: 10px;\n  width: 100%;\n}\n.StripeElement--focus {\n  box-shadow: 0 1px 3px 0 #cfd7df;\n}\n.StripeElement--invalid {\n  border-color: #fa755a;\n}\n.StripeElement--webkit-autofill {\n  background-color: #fefde5 !important;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"StripeElement": "StripeElement__2XLcL",
	"StripeElement__name": "StripeElement__name__3Gulf",
	"StripeElement--focus": "StripeElement--focus__3uUtL",
	"StripeElement--invalid": "StripeElement--invalid__1-Lcq",
	"StripeElement--webkit-autofill": "StripeElement--webkit-autofill__NJZss"
};