/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

export { default as customWithRouter } from './customWithRouter'
export { default as Ticketmaster } from './Ticketmaster'

export { errorMessages, getErrorDisplayMessage } from './errors'
export { getLanguageDropdownOptions } from './languages'
export { isValidDateRange, getCheckInCheckOutDuration } from './dates'
export { getLowestPriceFromPricing } from './pricing'
export { scrollToAnchor, sortAttachments, getAccommodationPhotos } from './misc'
export { randomString } from './strings'
export { getMaxOccupants, getBedrooms, getArea } from './features'
export { uploadImageToCloudinary } from './files'

export {
    truncatePropertyAddress,
    vaguePropertyAddress,
    splitPropertyAddress,
    truncateAndSplitPropertyAddress,
    beautifyPropertyAddress,
    getTextHighlight,
    addressBitsToHTML,
    addressBitsToText
} from './properties'

export {
    showErrorNotification,
    showSuccessNotification,
    updateTicker
} from './notifications'

export {
    getInvoiceNotificationText
} from './invoices'