/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Feb 2019
 * ========================================================================
 */

// @flow

import * as React from 'react'
import { withApollo } from 'react-apollo'
import { properties } from 'queries/properties'
import { showErrorNotification } from 'utils/notifications'
import type { Property } from 'utils/flowtypes/models'
import type { ApolloClient } from 'utils/flowtypes/apollo'

const DEFAULT_PER_PAGE = 30

type Props = {
    client: ApolloClient
}

type State = {
    properties: Property[],
    currentPage: number,
    totalPages: number,
    count: number,
    loading: boolean
}

export default function withProperties(
    WrappedComponent: React.AbstractComponent<{}>,
    perPage: number = DEFAULT_PER_PAGE): React.AbstractComponent<{}>
{
    class HigherOrderComponent extends React.Component<Props, State> {
        state = {
            properties: [],
            currentPage: 1,
            totalPages: 1,
            count: 0,
            loading: false
        }

        componentDidMount() {
            this._fetch()
        }

        handleGetNextPage = () => {
            this.setState(prevState => {
                if (prevState.currentPage < prevState.totalPages) {
                    return { currentPage: prevState.currentPage + 1 }
                }
                return { currentPage: prevState.currentPage }
            }, this._fetch)
        }

        handleGetPreviousPage = () => {
            this.setState(prevState => {
                if (prevState.currentPage > 1) {
                    return { currentPage: prevState.currentPage - 1 }
                }
                return { currentPage: prevState.currentPage }
            }, this._fetch)
        }

        handleGetPage = (page: number) => {
            this.setState({
                currentPage: page
            }, this._fetch)
        }

        _fetch = async () => {
            this.setState({ loading: true })
            
            const response = await this.props.client.query({
                query: properties,
                variables: {
                    page: this.state.currentPage - 1,
                    perPage
                }
            }).catch(e => e)

            this.setState({ loading: false })

            if (response instanceof Error) {
                showErrorNotification('Unable to load properties. Please try again later.')
                console.error(response)
            } else {
                this.setState({
                    properties: response.data.properties.list,
                    totalPages: response.data.properties.pagination.total_pages,
                    count: response.data.properties.pagination.count
                })
            }
        }

        render() {
            const properties = {
                getNextPage: this.handleGetNextPage,
                getPreviousPage: this.handleGetPreviousPage,
                getPage: this.handleGetPage,
                currentPage: this.state.currentPage,
                startOfList: this.state.currentPage === 1,
                endOfList: this.state.currentPage === this.state.totalPages,
                properties: this.state.properties,
                refetch: this._fetch,
                loading: this.state.loading,
                count: this.state.count,
                totalPages: this.state.totalPages
            }

            return (
                <WrappedComponent
                    properties={properties}
                    {...this.props}
                />
            )
        }
    }

    return withApollo(HigherOrderComponent)
}