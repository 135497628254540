/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

import React from 'react'
import Error from './Error'

const StandardError = props => {
    return (
        <div>
            <Error header={props.header} stackTrace={props.stackTrace} />
        </div>
    )
}

export default StandardError