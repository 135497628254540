/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Oct 2018
 * ========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import { Widget } from 'components'
import { List } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import DuplicateAccommodationModal from './DuplicateAccommodationModal'
import type { Accommodation } from 'utils/flowtypes/models'

type Props = {
    accommodation: Accommodation,
}

type State = {
    duplicateModalActive: boolean
}

class QuickLinksWidget extends PureComponent<Props, State> {
    state = {
        duplicateModalActive: false
    }

    handleOpenModel = () => this.setState({ duplicateModalActive: true })
    
    handleCloseModal = () => this.setState({ duplicateModalActive: false })

    render() {
        const { accommodation } = this.props
        const { duplicateModalActive } = this.state
        const { property } = accommodation

        return (
            <Widget>
                <List selection>
                    <List.Item as={Link} to={`/properties/${property.id}`}>View property</List.Item>
                    <List.Item as={Link} to={`/listings/${accommodation.id}`}>Preview listing</List.Item>
                    {
                        accommodation.approved === 'approved' &&
                        <List.Item onClick={this.handleOpenModel}>Duplicate</List.Item>
                    }
                </List>
                <DuplicateAccommodationModal
                    open={duplicateModalActive}
                    onClose={this.handleCloseModal}
                    accommodationId={accommodation.id}
                />
            </Widget>
        )
    }

}



export default QuickLinksWidget