/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Feb 2019
 * ========================================================================
 */

// @flow

import React from 'react'
import { Modal, Button, Icon, Item, Header, Grid } from 'semantic-ui-react'
import { FormattedAddress } from 'components'
import type { Company } from 'utils/flowtypes/models'

type Props = {
    company: Company,
    onClose(): void,
    open: boolean
}

const CompanyModal = (props: Props) => {
    const { company, open, onClose } = props

    return (
        <Modal
            open={open}
            size='tiny'
            onClose={onClose}
        >
            <Header>
                <Icon name='address card' />
                <Header.Content>
                    Company Information
                    <Header.Subheader>{company.name}</Header.Subheader>
                </Header.Content>
            </Header>
            <Modal.Content>
                <Item.Group>
                    <Item>
                        <Grid style={{ width: '100%' }}>
                            <Grid.Row columns='equal'>
                                <Grid.Column>
                                    <Item>
                                        <Item.Content>  
                                            <Item.Meta>Name</Item.Meta>
                                            <Item.Description>{company.name}</Item.Description>
                                        </Item.Content>
                                    </Item>
                                </Grid.Column>
                                <Grid.Column>
                                    <Item>
                                        <Item.Content>
                                            <Item.Meta>Homepage</Item.Meta>
                                            <Item.Description>
                                                {
                                                    company.homepage ? (
                                                        <a href={company.homepage} target='_blank'>
                                                            {company.homepage}
                                                        </a>
                                                    ) : 'Not Provided'
                                                }
                                            </Item.Description>
                                        </Item.Content>
                                    </Item>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Item>
                    <Item>
                        <Grid style={{ width: '100%' }}>
                            <Grid.Row columns='equal'>
                                <Grid.Column>
                                    <Item>
                                        <Item.Content>
                                            <Item.Meta>Address</Item.Meta>
                                            <Item.Description>
                                                <FormattedAddress address={company.address} />
                                            </Item.Description>
                                        </Item.Content>
                                    </Item>
                                </Grid.Column>
                                <Grid.Column>
                                    <Item>
                                        <Item.Content>
                                            <Item.Meta>Contact Number</Item.Meta>
                                            <Item.Description>{company.contact_number}</Item.Description>
                                        </Item.Content>
                                    </Item>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Item>
                </Item.Group>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={onClose}
                    content='Close'
                />
            </Modal.Actions>
        </Modal>
    )
}

export default CompanyModal