/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import React, { PureComponent } from 'react'
import { Error } from 'components'
import { debugMode } from 'config'

export default class ErrorBoundary extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
            info: null,
            errorInfo: null }
    }

    componentDidCatch(error, info) {
        console.error('Error caught by ErrorBoundary')

        this.setState({
            hasError: true,
            info: info,
            error: error
        })
    }

    render() {
        return this.state.hasError ? (
            <Error
                generic={!debugMode}
                header={this.state.error.toString()}
                stackTrace={this.state.info.componentStack}
            />
        ) : (
            this.props.children
        )
    }
}