import telcodes from 'resources/geography/phone_codes'
import _ from 'lodash'

/**
 * This function attempts to add or change an international telephone dialling
 * code at the beginning of a telephone number. If it is ambiguous as to where
 * the telcode ends, it will simply retun the telcode on it's own.
 * 
 * For example, given the country code GB, and the telephone number 07700900077,
 * it would return '+44 07700900077. However, given the same country code, but
 * +10770090077 as the telephoneNumber parameter, it would return '+44'
 * 
 * @param {string} countryCode - two digit country code to lookup, e.g. GB
 * @param {string} telephoneNumber - telephone number to alter
 * @return {string}
 */
export function setTelcode(countryCode: string, telephoneNumber: string) {
    telephoneNumber = telephoneNumber.trim()
    const telcode = telcodes.find(country => country.key === countryCode)?.telcode
    if (telcode) {
        if (telephoneNumber.startsWith('+')) {
            const tokenedTelNum = /(^\s*?\+\d*)(.*)/.exec(telephoneNumber)
            if (tokenedTelNum.length === 3) {
                return `${telcode}${tokenedTelNum[2]}`
            }
        }
        return `${telcode} ${telephoneNumber}` 
    }
    return telephoneNumber
}

/**
 * This function runs a telephone number through a fairly relaxed validation
 * process.
 * 
 * If strict mode is set to false, it will return true for any string between
 * 6 and 20 characters long (without whitespace).
 * 
 * If strict mode is set to true, it will return true for any string 
 * passed in that consists solely of +'s, -'s, digits, whitespace, parenthesis, 
 * and/or periods.
 * 
 * @param {string} telephoneNumber - telephone number to check
 * @param {boolean} strict - use a regex validator or not
 * @return {boolean}
 */
export function isValidTelephoneNumber(telephoneNumber: string, strict: boolean = false) {
    telephoneNumber = telephoneNumber.replace(/\s/g, '')
    return (
        strict
        ? /^[d+\- ().]*$/.test(telephoneNumber)
        : telephoneNumber.length > 6 && telephoneNumber.length < 18
    )
}

/**
 * Truncates text to the first paragraph, and then truncates the result
 * to the specified length.
 * 
 * @param {string} text 
 * @param {number} length 
 */
export function getTextHighlight(text = '', length = 500)
{
    text = text.split('\n')[0]
    text = _.truncate(text, { length })

    return text
}

/**
 * Get a random string, can be used to create IDs
 */
export function randomString(){
    return  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}


export function getCancellationDurationString(days: number) {
    return days <= 3 ? `${days * 24} hours` : `${days} days`
}