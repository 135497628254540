/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import { SET_LOCALE } from './actions'
import { HYDRATE } from 'store/store.js'

export default function locale(state = 'en-US', action) {
    switch (action.type) {
        case SET_LOCALE:
            return action.locale
        case HYDRATE:
            return action.serializedState.locale || state
        default:
            return state
    }
}