/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import gql from 'graphql-tag'

export const user = gql`
    query(
        $id: ID
    ){
        user(
            id: $id
        ){
            id
            name
            email
            approved
            address
            date_of_birth
            email_verified
            company{
                id
                name
            }
            notes{
                id
                contents
                tags
            }
            is_admin
            secure_documents{
                id
                approved
                type
            }
            created_at
            updated_at
        }
    }
`

export const users = gql`
    query(
        $perPage: Int
        $page: Int
        $name: String
    ){
        users(
            per_page: $perPage
            page: $page
            name: $name
        ){
            pagination{
                count
                total_pages
                page
                next_page
                previous_page
                per_page
            }
            list{
                id
                name
                email
                approved
                email_verified
                company{
                    id
                    name
                }
            }
        }
    }
`

export const searchUsers = gql`
    query{
        users{
            pagination{
                count
                total_pages
                page
                next_page
                previous_page
                per_page
            }
            list{
                id
                name
                email
                approved
                company{
                    id
                    name
                    address
                }
            }
        }
    }
`

export const getSelf = gql`
    query{
        self{
            id
            name
            email
            approved
            address
            date_of_birth
            email_verified
            company{
                id
                name
                homepage
                about
                trading_name
                country{
                    id
                    iso2
                    iso3
                    name
                }
                address
                contact_number
                vat_number
                tax_id
                approved
                is_provider
                meta
                certifications{
                    id
                    name
                    value
                    approved
                    issued_at
                    expires_at
                    definition{
                        id
                        name
                        possible_values
                        default_expiry
                        expires
                        certificate_required
                        meta
                    }
                    meta
                    notes{
                        id
                        contents
                        tags
                    }
                }
            }
            role{
                id
                name
                permissions{
                    id
                    name
                    namespace
                    action
                    description
                    value
                }
            }
            notes{
                id
                contents
                tags
            }
            is_admin
            secure_documents(
                types: ["identity_front", "identity_back"] 
            ){
                id
                approved
                type
            }
            created_at
            updated_at
        }
    }
`

export const updateUserStripeProfile = gql`
    mutation(
        $firstName: String
        $lastName: String
        $dateOfBirth: DateOfBirthInput
        $address: LimitedAddressInput
    ){
        updateUserStripeProfile(
            first_name: $firstName
            last_name: $lastName
            date_of_birth: $dateOfBirth
            address: $address
        )
    }
`

export const updateUserStripeVerification = gql`
    mutation(
        $documentFront: String!
        $documentBack: String!
    ){
        updateUserStripeVerification(
            document_front: $documentFront
            document_back: $documentBack
        )
    }
`

export const addCompanyExternalAccount = gql`
    mutation(
        $stripeToken: ID!
    ){
        addCompanyExternalAccount(
            stripe_token: $stripeToken
        )
    }
`