/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Yunjia Li <yunjia.li@launchbase.solutions>, Dec 2018
 * ==========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import ErrorBoundary from 'ErrorBoundary'
import { Form, Menu, Button, Icon } from 'semantic-ui-react'
import { DayRangePickerInput } from 'components'
import type { DateRange } from 'utils/flowtypes/models'

type Props = {
    onSearch: Function,
    initialFrom: ?Date,
    initialTo: ?Date
}

type State = {
    from: ?Date,
    to: ?Date,
    derivedStateFromProps: boolean
}

class ProviderBookingSearchHeader extends PureComponent<Props, State> {
    state = {
        from: null,
        to: null,
        derivedStateFromProps: false
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (!state.derivedStateFromProps) {
            return {
                ...state,
                derivedStateFromProps: true,
                from: props.initialFrom ? props.initialFrom : null,
                to: props.initialTo ? props.initialTo : null
            }
        }

        return null
    }

    handleDatesChange = (daterange: DateRange) => {
        this.setState({
            from: daterange.from,
            to: daterange.to
        })
    }

    onSubmit = () => {
        this.props.onSearch({
            from: this.state.from ? this.state.from : undefined,
            to: this.state.to ? this.state.to : undefined
        })
    }

    handleClearSearch = () => {
        this.setState({
            from: null,
            to: null
        }, this.onSubmit)
    }

    render() {
        const { initialFrom, initialTo } = this.props
        const { from, to } = this.state
        const searchActive = from || to

        return(
            <ErrorBoundary>
                <Menu size='small'>
                    <Menu.Item>
                        <Icon name='filter' />
                    </Menu.Item>
                    <Menu.Item>
                        <Form>
                            <DayRangePickerInput
                                fromPlaceholder='Filter from'
                                toPlaceholder='Filter to'
                                onChange={this.handleDatesChange}
                                initialFromDate={initialFrom}
                                initialToDate={initialTo}
                            />
                        </Form>
                    </Menu.Item>
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Button
                                content='Apply filters'
                                primary
                                onClick={this.onSubmit}
                            />
                        </Menu.Item>
                            {
                                searchActive &&
                                <Menu.Item>
                                    <Button
                                        onClick={this.handleClearSearch}
                                        content='Clear filters'
                                        basic
                                    />
                                </Menu.Item>
                            }
                    </Menu.Menu>
                </Menu>
            </ErrorBoundary>
        )
    }
}

export default ProviderBookingSearchHeader