/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import style from './listing.less'
import { Link } from 'react-router-dom'
import { vaguePropertyAddress, sortAttachments } from 'utils'
import ErrorBoundary from 'ErrorBoundary'
import { Gallery } from 'components'
import {
    Header as SUIHeader,
    Icon,
    Button
} from 'semantic-ui-react'
import type { Accommodation } from 'utils/flowtypes/models'
import { getMaxOccupants, getBedrooms, getArea } from 'utils'

type Props = {
    listing: Accommodation
}

type State = {
    galleryOpen: boolean
}

class Header extends PureComponent<Props, State> {
    state = {
        galleryOpen: false
    }

    handleOpenGallery = () => this.setState({ galleryOpen: true })
    handleCloseGallery = () => this.setState({ galleryOpen: false })

    render() {
        const { listing } = this.props
        const { galleryOpen } = this.state
        const { attachments, template, property, attachmentOrdering } = listing
        const available = false
        const apartmentAttachments = attachments || []
        const templateAttachments = template.attachments || []
        const combinedAttachments = apartmentAttachments.concat(templateAttachments)

        let photos = sortAttachments(combinedAttachments, attachmentOrdering)

        const mainPhoto = photos.length >= 1 ? photos[0].url : 'https://picsum.photos/800/600'

        photos = photos.map(photo => {
            return { src: photo.url }
        })

        const maxOccupants = getMaxOccupants(listing.features)
        const bedrooms = getBedrooms(listing.features)
        const area = getArea(listing.features)


        return (
            <ErrorBoundary>
                <div className={style.listing__header}>
                    <div className={style.listing__header__details}>
                        <p className={style.listing__header__listingType}>Apartment</p>
                        <SUIHeader as='h2'>{listing.name}</SUIHeader>
                        <p>
                            <Icon name='marker' />
                            <Link to='#area'>{vaguePropertyAddress(property.address)}</Link>
                        </p>
                        {
                            bedrooms && <p>
                                <Icon name='hotel' />
                                {` ${bedrooms} bedroom(s)`}
                            </p>
                        }
                        <p>
                            <Icon name='users' />
                            {` Sleeps ${maxOccupants} people`}
                        </p>
                        <p>
                            <Icon name='object group' />
                            {` ${area} m²`}
                        </p>
                        <div className={style.listing__header__detailsBottom}>
                            <Button as={Link} to='#availability' basic inverted>
                                {
                                    available ? 'Available on your chosen dates' : 'Check availability'
                                }
                            </Button>
                        </div>
                    </div>
                    <div className={style.listing__header__image}>
                        <img src={mainPhoto} />
                        {
                            photos.length >= 1  &&
                            <div>
                                <Button onClick={this.handleOpenGallery}>View photos</Button>
                                <Gallery 
                                    active={galleryOpen}
                                    onClickOutside={this.handleCloseGallery}
                                    onClose={this.handleCloseGallery}
                                    images={photos}
                                />
                            </div>
                        }
                    </div>
                </div>
            </ErrorBoundary>
        )
    }
}

export default Header