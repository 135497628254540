/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import Timeline from 'react-calendar-timeline'
import './timelinecalendar.css'
import moment from 'moment'
import style from './timelinecalendar.less'
import { Link } from 'react-router-dom'
import type { UnixTimestamp } from 'utils/flowtypes/models'

type Props = {
    groups: Array<Object>,
    items?: Array<Object>,
    onCanvasClick?: Function,
    onItemClick?: Function,
    itemRenderer?: Function
}

type State = {
    defaultTimeStart: Object,
    defaultTimeEnd: Object
}

class TimelineCalendar extends PureComponent<Props, State> {
    state = {
        defaultTimeStart: moment().startOf('week').toDate(),
        defaultTimeEnd: moment().startOf('week').add(14, 'days').toDate(),
    }

    // $FlowFixMe
    groupRenderer = ({ group }) => {
        return (
            <div className={style.timelinecalendar__group__label}>
                <Link to={`/accommodation/${group.id}`}>{group.title}</Link>
                <p className={style.timelinecalendar__group__label__subtitle}>{group.subtitle}</p>
            </div>
        )
    }

    handleCanvasClick = (groupId: string, time: number, event: Object) => {
        if (this.props.onCanvasClick) {
            this.props.onCanvasClick(groupId, time, event)
        }
    }

    verticalLineClassNamesForTime = (start: UnixTimestamp, end: UnixTimestamp) => {
        const now = Date.now()
        
        // Past Dates
        if (end < now) {
            return ['timeline-past-column']
        }

        // Today
        if ((start <= now + (86400 * 1000)) && (end <= now + (86400 * 1000))) {
            return ['timeline-today-column']
        }

        return []
    }

    render() {
        const keys = {
            groupIdKey: 'id',
            groupTitleKey: 'title',
            groupRightTitleKey: 'rightTitle',
            itemIdKey: 'id',
            itemTitleKey: 'title',
            itemDivTitleKey: 'title',
            itemGroupKey: 'group',
            itemTimeStartKey: 'start',
            itemTimeEndKey: 'end'
        }

        const { groups, items = [], onItemClick, itemRenderer } = this.props

        const {
            defaultTimeStart,
            defaultTimeEnd
        } = this.state

        return (
            <Timeline
                groups={groups}
                items={items}
                keys={keys}
                itemsSorted
                itemTouchSendsClick={false}
                itemHeightRatio={1}
                showCursorLine
                canMove={false}
                defaultTimeStart={defaultTimeStart}
                defaultTimeEnd={defaultTimeEnd}
                onCanvasClick={this.handleCanvasClick}
                minZoom={7 * 86400 * 1000}
                maxZoom={365.24 * 86400 * 1000}
                sidebarWidth={158.68}
                groupRenderer={this.groupRenderer}
                itemRenderer={itemRenderer}
                verticalLineClassNamesForTime={this.verticalLineClassNamesForTime}
                onItemClick={onItemClick}
            />
        )
    }
}

export default TimelineCalendar