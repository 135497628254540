/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

import React from 'react'
import { Wizard } from 'components'
import commonStyle from 'styles/app.less'
import { Link } from 'react-router-dom'

import {
    Button,
    Icon,
    Segment,
    Divider,
    Header
} from 'semantic-ui-react'

const FinishedStep = props => {
    const { step, activeStep,  propertyId } = props

    return (
        <Wizard.Step
            step={step}
            activeStep={activeStep}
            panel={
                <Wizard.Panel
                    nextAction={
                        <Button
                            as={Link}
                            to={`/properties/${propertyId}`}
                            primary 
                            animated
                        >
                            <Button.Content visible>Go To Property</Button.Content>
                            <Button.Content hidden>
                                <Icon name='arrow right' />
                            </Button.Content>
                        </Button>
                    }
                    previousAction={
                        <Button
                            basic
                            color='grey'
                            animated
                            as={Link}
                            to='/'
                        >
                            <Button.Content visible>Back to Home</Button.Content>
                            <Button.Content hidden>
                                <Icon name='arrow left' />
                            </Button.Content>
                        </Button>
                    }
                >
                    <div className={commonStyle.centerContent}>
                        <div>
                            <Header as='h3' icon>
                                <Icon color='green' name='check circle outline' />
                                All Done!
                                <Header.Subheader>
                                    The property was created successfully.
                                </Header.Subheader>
                            </Header>
                        </div>
                    </div>
                </Wizard.Panel>
            }
            extraPanel={
                <Wizard.ExtraPanel desktopOnly>
                    <Segment padded='very' basic textAlign='center'>
                        <Icon.Group size='massive'>
                            <Icon name='lightbulb' />
                        </Icon.Group>
                        <Divider hidden />
                        <Header as='h4'>Tip</Header>
                        <p>
                            You can add apartments to properties quickly by clicking <strong>‘Add Apartment’</strong> in the sidebar.
                        </p>
                    </Segment>
                </Wizard.ExtraPanel>
            }
        />
    )
}

export default FinishedStep