/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import ErrorBoundary from 'ErrorBoundary'
import telcodes from 'resources/geography/phone_codes'
import { isValidTelephoneNumber, setTelcode } from 'utils/strings'
import style from './registerform.less'
import { Form, Flag, Button, Input, Icon, Header, Dropdown } from 'semantic-ui-react'

type Props = {
    streetAddress: string,
    telephone: string,
    postCode: string,
    onChange: Function,
    onClickBack: Function,
    onSubmit: Function,
    registeredCountry: string,
    telephoneCountry: string,
    processing: boolean
}

const telcodeOptions = telcodes.map(({country, telcode, ccode, key }) => {
    return {
        key: key.toLowerCase(),
        value: key,
        flag: ccode.toLowerCase(),
        text: <Flag name={ccode.toLowerCase()} />,
        description: telcode,
        content: <strong>{country}</strong>
    }
})

class ContactInformation extends PureComponent<Props> {
    
    // $FlowFixMe
    handleTelephoneCountryChange = (event, { name, value }) => {
        this.props.onChange(event, { name, value })
        this.props.onChange(event, {
            name: 'telephone',
            value: setTelcode(value, this.props.telephone)
        })
    }

    render() {
        const {
            onClickBack,
            onSubmit,
            streetAddress,
            telephone,
            onChange,
            registeredCountry,
            telephoneCountry,
            postCode,
            processing
        } = this.props

        let derivedTelephone: string = ''

        if (!telephone) {
            const telcountry = telcodes.find(country => country.key === registeredCountry)
            derivedTelephone = telcountry ? telcountry.telcode : ''
        }

        const registerButtonDisabled = !streetAddress || !telephone || !postCode || !isValidTelephoneNumber(telephone) || processing

        return (
            <ErrorBoundary>
                <Header as='h3'>
                    Register a roosts.com Account
                    <Header.Subheader>Company Details</Header.Subheader>
                </Header>
                <Form.Input
                    fluid
                    label='Company street address'
                    placeholder='123 Example Street'
                    icon='marker'
                    iconPosition='left'
                    name='streetAddress'
                    required
                    value={streetAddress}
                    onChange={onChange}
                />
                <Form.Input
                    fluid
                    label='Postal/zip code'
                    placeholder='Postal/zip code'
                    icon='map'
                    iconPosition='left'
                    name='postCode'
                    required
                    value={postCode}
                    onChange={onChange}
                />
                <div className='required field'>
                    <label>Telephone Number</label>
                    <Input
                        fluid
                        type='tel'
                        label={
                            <Dropdown
                                name='telephoneCountry'
                                options={telcodeOptions}
                                basic
                                scrolling
                                onChange={this.handleTelephoneCountryChange}
                                value={telephoneCountry}
                            />
                        }
                        labelPosition='left'
                        placeholder='e.g. +44 7700 900077'
                        value={telephone ? telephone : derivedTelephone}
                        onChange={onChange}
                        name='telephone'
                    />
                </div>
                <div className={style.register__form__footer}>
                    <div className={style.register__form__footer__actions}>
                        <div>
                            <Button
                                onClick={onClickBack}
                                basic
                                color='grey'
                                animated
                                size='large'
                            >
                                <Button.Content visible>Back</Button.Content>
                                <Button.Content hidden>
                                    <Icon name='arrow left' />
                                </Button.Content>
                            </Button>
                        </div>
                        <div>
                            <Button
                                disabled={registerButtonDisabled}
                                loading={processing}
                                onClick={onSubmit}
                                primary
                                animated
                                size='large'
                                style={{
                                    margin: 0
                                }}
                            >
                                <Button.Content visible>Register</Button.Content>
                                <Button.Content hidden>
                                    <Icon name='checkmark' />
                                </Button.Content>
                            </Button>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        )
    }
}

export default ContactInformation