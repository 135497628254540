/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

// action types

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

// action creators

export function login(user) {
    return { type: LOGIN, user }
}

export function logout() {
    return { type: LOGOUT }
}