/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, May 2019
 * ========================================================================
 */

// @flow

import * as React from 'react'
import { withApollo } from 'react-apollo'
import { users } from 'queries/users'
import { showErrorNotification } from 'utils/notifications'
import type { User } from 'utils/flowtypes/models'
import type { ApolloClient } from 'utils/flowtypes/apollo'

const DEFAULT_PER_PAGE = 30

type SearchInput = {
    name: string
}

type Props = {
    client: ApolloClient
}

type State = {
    users: User[],
    currentPage: number,
    totalPages: number,
    count: number,
    loading: boolean,
    name: string,
    lastSearchInput: ?SearchInput
}

export default function withUsers(
    WrappedComponent: React.AbstractComponent<{}>,
    perPage: number = DEFAULT_PER_PAGE): React.AbstractComponent<{}>
{
    class HigherOrderComponent extends React.Component<Props, State> {
        state = {
            users: [],
            currentPage: 1,
            totalPages: 1,
            count: 0,
            loading: true,
            name: '',
            lastSearchInput: null
        }

        componentDidMount() {
            this._fetch()
        }

        handleGetNextPage = () => {
            this.setState(prevState => {
                if (prevState.currentPage < prevState.totalPages) {
                    return { currentPage: prevState.currentPage + 1 }
                }
                return { currentPage: prevState.currentPage }
            }, this._fetch)
        }

        handleGetPreviousPage = () => {
            this.setState(prevState => {
                if (prevState.currentPage > 1) {
                    return { currentPage: prevState.currentPage - 1 }
                }
                return { currentPage: prevState.currentPage }
            }, this._fetch)
        }

        handleGetPage = (page: number) => {
            this.setState({
                currentPage: page
            }, this._fetch)
        }

        _search = (searchInput: SearchInput) => {
            this.setState({
                lastSearchInput: searchInput,
                ...searchInput,
            }, this._fetch)
        }

        _fetch = async () => {
            if (!this.state.loading) {
                this.setState({ loading: true })
            }
            
            const variables = {}
            variables.page = this.state.currentPage - 1
            variables.perPage = perPage
            
            // handle searches
            if (this.state.name.trim() != '') {
                variables.name = this.state.name.trim()
            }

            const response = await this.props.client.query({
                query: users,
                variables
            }).catch(e => e)

            if (response instanceof Error) {
                this.setState({ loading: false })
                showErrorNotification('Unable to load users. Please try again later.')
                console.error(response)
            } else {
                this.setState({
                    loading: false,
                    users: response.data.users.list,
                    totalPages: response.data.users.pagination.total_pages,
                    count: response.data.users.pagination.count
                })
            }
        }

        render() {
            const users = {
                getNextPage: this.handleGetNextPage,
                getPreviousPage: this.handleGetPreviousPage,
                getPage: this.handleGetPage,
                currentPage: this.state.currentPage,
                startOfList: this.state.currentPage === 1,
                endOfList: this.state.currentPage === this.state.totalPages,
                users: this.state.users,
                refetch: this._fetch,
                loading: this.state.loading,
                count: this.state.count,
                totalPages: this.state.totalPages,
                search: this._search,
                lastSearchInput: this.state.lastSearchInput
            }

            return (
                <WrappedComponent
                    users={users}
                    {...this.props}
                />
            )
        }
    }

    return withApollo(HigherOrderComponent)
}