/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import React, { PureComponent } from 'react'
import PubSub from 'pubsub-js'
import style from 'styles/app.less'

class NotificationTicker extends PureComponent {
    state = {
        message: ''
    }

    componentDidMount() {
        this.token = PubSub.subscribe('ticker', (topic, alert) => {
            this.latestMessage = `${alert.message}`

            this.setState({
                message: alert.message
            })
        })
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.token)
    }

    render() {
        const { message } = this.state

        if (message) {
            return (
                <div className={style.notificationTicker}>
                    <span>{ message }</span>
                </div>
            )
        }

        return null
    }
}

export default NotificationTicker