/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import { Header, Form, Button, Icon } from 'semantic-ui-react'
import style from './registerform.less'
import localise from 'languages'
import ErrorBoundary from 'ErrorBoundary'
import type { Countries } from 'utils/flowtypes/models'

type Props = {
    onClickNext: Function,
    onClickBack: Function,
    onChange: Function,
    companyName: string,
    streetAddress: string,
    telephone: string,
    registeredCountry: string,
    telephoneCountry: string,
    postCode: string,
    companyType: 'Limited Company' | 'Sole Trader' | 'Other',
    countries: Countries
}

const companyOptions = [
    {
        key: 'lc',
        value: 'Limited Company',
        text: 'Limited Company'
    },
    {
        key: 'st',
        value: 'Sole Trader',
        text: 'Sole Trader'
    },
    {
        key: 'other',
        value: 'Other',
        text: 'Other'
    }
]

class CompanyInformation extends PureComponent<Props> {

    // $FlowFixMe
    handleRegisteredCountryChange = (event, { name, value }) => {
        this.props.onChange(event, { name, value })
        if (!this.props.telephone) {
            this.props.onChange(event, { name: 'telephoneCountry', value })
        }
    }

    render() {
        const {
            onClickBack,
            onClickNext,
            onChange,
            companyName,
            registeredCountry,
            companyType,
            countries
        } = this.props

        const disableNextButton = !companyName || !registeredCountry || !companyType

        const countryOptions = countries.map(country => {
            return {
                key: country.iso2,
                value: country.iso2,
                text: country.name
            }
        })

        return (
            <ErrorBoundary>
                <Header as='h3'>
                    Register a roosts.com Account
                    <Header.Subheader>Company Details</Header.Subheader>
                </Header>
                <Form.Input
                    fluid
                    label='Company name'
                    placeholder='Company name'
                    icon='building'
                    iconPosition='left'
                    name='companyName'
                    required
                    value={companyName}
                    onChange={onChange}
                />
                <Form.Dropdown
                    fluid
                    placeholder={localise('REGISTER_FORM.SELECT_COUNTRY')}
                    search
                    selection
                    options={countryOptions}
                    value={registeredCountry}
                    name='registeredCountry'
                    required
                    label='Registered country'
                    onChange={this.handleRegisteredCountryChange}
                />
                <Form.Dropdown
                    fluid
                    required
                    label='Company type'
                    placeholder='Select company type'
                    selection
                    options={companyOptions}
                    value={companyType}
                    name='companyType'
                    onChange={onChange}
                />
                <div className={style.register__form__footer}>
                    <div className={style.register__form__footer__actions}>
                        <div>
                            <Button
                                onClick={onClickBack}
                                basic
                                color='grey'
                                animated
                                size='large'
                            >
                                <Button.Content visible>Back</Button.Content>
                                <Button.Content hidden>
                                    <Icon name='arrow left' />
                                </Button.Content>
                            </Button>
                        </div>
                        <div>
                            <Button
                                disabled={disableNextButton}
                                onClick={onClickNext}
                                primary
                                animated
                                size='large'
                                style={{
                                    margin: 0
                                }}
                            >
                                <Button.Content visible>Next</Button.Content>
                                <Button.Content hidden>
                                    <Icon name='arrow right' />
                                </Button.Content>
                            </Button>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        )
    }
}

export default CompanyInformation