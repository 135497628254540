/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Louis Capitanchik <louis.capitanchik@launchbase.solutions>, Dec 2018
 * ========================================================================
 */

import gql from 'graphql-tag'

export const notifyChat = gql`
    mutation(
        $id:ID!,
        $type:String!,
        $model_id:ID!,
    ){
        notifyChat(id: $id, type: $type, model_id: $model_id) {
            id,
            updated_at
        }
    }
`

export const getChats = gql`   
query getChats($page: Int, $perPage: Int) {
  getLatestChats(page: $page, perPage: $perPage) {
    list {
      id
      accommodation {
        id
        name
      }
      booking {
        id
        reference
        accommodation {
          id
          name
        }
      }
      user {
        name
      }
      updated_at
    }
    pagination {
      count
      total_pages
      page
      per_page
      next_page,
      previous_page
    }
  }
}
`