/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by 
 *  Thomas Hewett <thomas.hewett@launchbase.solutions>
 *  Yunjia Li <yunjia.li@launchbase.solutions>
 * Oct 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import style from './layout.less'
import ErrorBoundary from 'ErrorBoundary'
import { Menu, Icon, Divider, Label } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import localise from 'languages'
import { withPendingCertifications, withPendingIdentityVerifications } from 'components/hoc'
import { AdminGate, UserGate, ProviderGate } from 'routes'
import { getPendingUserApprovals, getPendingAccommodationApprovals } from 'queries/approvals'
import { withApollo, compose, graphql } from 'react-apollo'
import type { User, PaginatedUsers, PaginatedAccommodations } from 'utils/flowtypes/models'
import type { ApolloQuery } from 'utils/flowtypes/apollo'
import type { PendingCertificationsHOCProps, PendingIdentityVerificationsHOCProps } from 'utils/flowtypes/hoc'
import { connect } from 'react-redux'
import { config } from 'config'

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

type Props = {
    user: User,
    pendingUserApprovals: {
        users: PaginatedUsers
    } & ApolloQuery,
    pendingAccommodationApprovals: {
        accommodations: PaginatedAccommodations
    } & ApolloQuery,
    certifications: PendingCertificationsHOCProps,
    identityVerifications: PendingIdentityVerificationsHOCProps
}

const Sidebar = (props: Props) => {

    let pendingUserApprovals = 0
    let pendingAccommodationApprovals = 0
    let pendingCertifications = 0
    let pendingIdentityVerifications = 0

    if (props.user != null && props.user.is_admin) {
        if (!props.pendingUserApprovals.loading && !props.pendingAccommodationApprovals.loading) {
            pendingUserApprovals = props.pendingUserApprovals.users.pagination.count
            pendingAccommodationApprovals = props.pendingAccommodationApprovals.accommodations.pagination.count
        }
        if (!props.certifications.loading) {
            pendingCertifications = props.certifications.count
        }
        if (!props.identityVerifications.loading) {
            pendingIdentityVerifications = props.identityVerifications.count
        }
    }

    return (
        <ErrorBoundary>
            <div className={style.layout__sidebar}>
                <Menu
                    className={style.layout__sidebar__menu}
                    compact
                    fluid
                    stackable
                    vertical
                    borderless
                >
                    <ProviderGate>
                        <Menu.Item name='dashboard' as={Link} to='/dashboard'>
                            <Icon name='dashboard' />
                            Dashboard
                        </Menu.Item>
                    </ProviderGate>
                    <AdminGate>
                        <Menu.Item name='dashboard' as={Link} to='/dashboard'>
                            <Icon name='dashboard' />
                            Dashboard
                        </Menu.Item>
                    </AdminGate>
                    <Menu.Item name='inbox' as={Link} to='/inbox'>
                        <Icon name='envelope' />
                        Messages
                    </Menu.Item>
                    <ProviderGate>
                        <Menu.Item name='calendar' as={Link} to='/calendar'>
                            <Icon name='calendar' />
                            {localise('MENU.CALENDAR')}
                        </Menu.Item>
                        <Menu.Item name='properties' as={Link} to='/properties'>
                            <Icon name='building' />
                            {localise('MENU.PROPERTIES')}
                        </Menu.Item>
                        {/* <Menu.Item name='certifications' as={Link} to='/properties/certifications'>
                            <Icon name='certificate' />
                            {localise('MENU.CERTIFICATIONS')}
                        </Menu.Item> */}
                        <Menu.Item name='provider-manage-bookings' as={Link} to='/provider/bookings'>
                            <Icon name='hotel' />
                            {localise('MENU.PROVIDER_MANAGE_BOOKINGS')}
                        </Menu.Item>
                    </ProviderGate>
                    <AdminGate>
                        <Divider />
                        <Menu.Item name='user-list' as={Link} to='/admin/users'>
                            <Icon name='users' />
                            {localise('MENU.USERS')}
                        </Menu.Item>
                        {/* <Menu.Item name='accommodation-list' as={Link} to='/admin/accommodation'>
                            <Icon name='hotel' />
                            {localise('MENU.ACCOMMODATION')}
                        </Menu.Item>
                        <Menu.Item name='company-list' as={Link} to='/admin/companies'>
                            <Icon name='address book' />
                            {localise('MENU.COMPANIES')}
                        </Menu.Item> */}
                        <Divider />
                        <Menu.Item name='approval-users' as={Link} to='/admin/approval-users'>
                            <Label color={ pendingUserApprovals > 0 ? 'pink' : undefined }>
                                { pendingUserApprovals }
                            </Label>
                            {localise('MENU.APPROVAL_USERS')}
                        </Menu.Item>
                        <Menu.Item name='approval-accommodation' as={Link} to='/admin/approval-accommodation'>
                            <Label color={ pendingAccommodationApprovals > 0 ? 'pink' : undefined }>
                                { pendingAccommodationApprovals }
                            </Label>
                            {localise('MENU.APPROVAL_ACCOMMODATION')}
                        </Menu.Item>
                        <Menu.Item name='certifications-approval' as={Link} to='/admin/certifications-approval-queue'>
                            <Label color={ pendingCertifications > 0 ? 'pink' : undefined }>
                                { pendingCertifications }
                            </Label>
                            {localise('MENU.APPROVAL_CERTIFICATIONS')}
                        </Menu.Item>
                        <Menu.Item name='identity-verifications' as={Link} to='/admin/identity-verifications'>
                            <Label color={  pendingIdentityVerifications > 0 ? 'pink' : undefined }>
                                { pendingIdentityVerifications }
                            </Label>
                            Identity Verifications
                        </Menu.Item>
                    </AdminGate>
                    <ProviderGate>
                        <Menu.Item name='add-property' as={Link} to='/properties/add'>
                            <Icon name='add' />
                            {localise('PROPERTY.ADD')}
                        </Menu.Item>
                        <Menu.Item name='add-accommodation' as={Link} to='/accommodation/add'>
                            <Icon name='add' />
                            {localise('ACCOMMODATION.ADD')}
                        </Menu.Item>
                    </ProviderGate>
                    <UserGate>
                        <Divider />
                        <Menu.Item name='manage-guests' as={Link} to='/guests'>
                            <Icon name='users' />
                            {localise('MENU.MANAGE_GUESTS')}
                        </Menu.Item>
                        {
                            config.featureFlags.disable_search !== 'TRUE' &&
                            <Menu.Item name='search' as={Link} to='/search'>
                                <Icon name='search' />
                                {localise('MENU.SEARCH')}
                            </Menu.Item>
                        }
                        {
                            config.featureFlags.disable_booking !== 'TRUE' &&
                            <Menu.Item name='itinerary' as={Link} to='/itinerary'>
                                <Icon name='road' />
                                {localise('MENU.ITINERARY')}
                            </Menu.Item>
                        }
                        <Menu.Item name='booker-manage-bookings' as={Link} to='/booker/bookings'>
                            <Icon name='hotel' />
                            {localise('MENU.BOOKER_MANAGE_BOOKINGS')}
                        </Menu.Item>
                        {/* <Menu.Item name='manage-guests' as={Link} to='/guests'>
                            <Icon name='users' />
                            {localise('MENU.MANAGE_GUESTS')}
                        </Menu.Item>
                        {/* <Menu.Item name='manage-bookings' as={Link} to='/bookings'>
                            <Icon name='hotel' />
                            {localise('MENU.MANAGE_BOOKINGS')}
                        </Menu.Item>
                        <Menu.Item name='manage-bookings' as={Link} to='/payments'>
                            <Icon name='credit card' />
                            {localise('MENU.MANAGE_PAYMENTS')}
                        </Menu.Item> */}
                    </UserGate>
                    <UserGate allowAdmin={false}>
                        <Divider />
                        <Menu.Item name='manage-account' as={Link} to='/account'>
                            <Icon name='cogs' />
                            {localise('MENU.MANAGE_ACCOUNT')}
                        </Menu.Item>
                    </UserGate>
                    <Menu.Item name='main-site' as={Link} to='/'>
                        <Icon name='arrow right' />
                        Back to Main Site
                    </Menu.Item>
                </Menu>
            </div>
            <div className={style.layout__sidebar__bottom}>
                {/* <LanguageSwitcher /> */}
                <p>© roosts.com 2019</p>
            </div>
        </ErrorBoundary>
    )
}

export default compose(
    graphql(
        getPendingUserApprovals,
        {   
            name: 'pendingUserApprovals',
            options: { fetchPolicy: 'network-only' }
        }
    ),
    graphql(
        getPendingAccommodationApprovals,
        {
            name: 'pendingAccommodationApprovals',
            options: { fetchPolicy: 'network-only' }
        }
    )
)(withApollo(withPendingCertifications(withPendingIdentityVerifications(connect(mapStateToProps)(Sidebar)))))