/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Yunjia Li <yunjia.li@launchbase.solutions>, Nov 2018
 * ========================================================================
 */

// @flow

import type { Features, Feature } from 'utils/flowtypes/models'
import _ from 'lodash'

export function getMaxOccupants(features: Features): ?number {
    const fs = features.filter(f=>f.name === 'Maximum guests')
    if(fs){
        const maxOccupants = fs.length > 0 ? parseInt(fs[0].value) : 1 //default 1 guest, just in case it's not defined
        return maxOccupants
    }
    else{
        return 1
    }
    
}

export function getBedrooms(features: Features): ?number {
    const fs = features.filter(f=>f.name === 'Bedrooms')
    if(fs){
        const beds = fs.length > 0 ? parseInt(fs[0].value) : 1 //default 1 guest, just in case it's not defined
        return beds
    }
    else{
        return null
    }
    
}

export function getArea(features: Features): ?number {
    const fs = features.filter(f=>f.name === 'Size (m2)')
    if(fs){
        const maxOccupants = fs.length > 0 ? parseInt(fs[0].value) : 1 //default 1 guest, just in case it's not defined
        return maxOccupants
    }
    else{
        return null
    }
}

export function displayEnumFeatureValue(feature: Feature): string {
    switch (feature.definition.shortcode) {
        case 'oven':
            return _.capitalize(`${feature.value} oven`)
    
        case 'refrigerator':
            return _.capitalize(`${feature.value}`)

        case 'hobs':
            return _.capitalize(`${feature.value} hobs`)

        case 'tv_service':
            return _.capitalize(`${feature.value} TV service`)

        case 'wi_fi':
            return _.capitalize(`${feature.value} Wi-fi`)

        case 'housekeeping':
            return _.capitalize(`${feature.value} housekeeping`)

        default:
            return `${feature.name}: ${feature.value}` 
    }
}

export function displayNumberFeatureValue(feature: Feature): string {
    switch (feature.definition.shortcode) {
        case 'number_of_hobs':
            return _.capitalize(`${feature.value} hobs`)
    
        default:
            return `${feature.name}: ${feature.value}`
    }
}