/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Dec 2018
 * ========================================================================
 */

// @flow

import React, { PureComponent, Fragment } from 'react'
import _ from 'lodash'
import ErrorBoundary from 'ErrorBoundary'
import Dropzone from 'react-dropzone'
import style from './attachmentuploadmodal.less'
import { cloudinary as cloudinaryConfig } from 'config'
import { showErrorNotification } from 'utils/notifications'
import { addPropertyAttachment } from 'queries/properties'
import { compose, graphql, withApollo } from 'react-apollo'
import { Modal, Icon, Header, Image, Button } from 'semantic-ui-react'
import type { ID, AttachmentTypeEnum, Attachment } from 'utils/flowtypes/models'
import fileIcon from 'resources/images/file_icon.png'

type OnUploadParams = {
    file: Object,
    attachment: Attachment
}

type MIMEType = string

type Props = {
    open: boolean,
    onClose: Function,
    onUpload(OnUploadParams): ?Function,
    modelId: ID,
    modelType: 'property',
    acceptedFileTypes: MIMEType[],
    attachmentType: AttachmentTypeEnum,
    attachmentTags?: string[],
    addPropertyAttachment(Object): Promise<any>,
    acceptMultipleFiles?: boolean,
    headerText?: string,
    dropzoneIcon?: string,
    dropzoneText?: string,
    galleryHeaderText?: string,
    refetchQueriesOnUpload?: Object[]
}

type State = {
    files: Object[]
}

class AttachmentUploadModal extends PureComponent<Props, State> {
    state = {
        files: []
    }

    handleClose = () => {
        this.setState({
            files: []
        }, this.props.onClose)
    }

    handleDropAccepted = files => {
        const filesToUpload = files.map(file => {
            file.uploaded = false
            return file
        })

        this.setState(prevState => {
            const allFiles = prevState.files.concat(filesToUpload)
            const uniqueFiles = _.uniqWith(allFiles, (a, b) => {
                return (a.name === b.name && a.size === b.size)
            })
            return { files: uniqueFiles }
        }, this.handleUpload)
    }

    handleDropRejected = async files => {
        showErrorNotification('This file type is not supported')
        console.error('Invalid file type submitted:', files)
    }

    handleUpload = () => {
        this.state.files.forEach(async file => {
            if (!file.uploaded) {
                await this.uploadFile(file)
            }
        })
    }

    uploadFile = async file => {
        const fileHostResponse = await new Promise(resolve => {
            const url = `https://api.cloudinary.com/v1_1/${cloudinaryConfig.cloud_name}/upload`
            const xhr = new XMLHttpRequest()
            const fd = new FormData()

            xhr.open('POST', url, true)
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(xhr.response)
                    } else {
                        console.error(xhr.response)
                        throw Error('File upload failed')
                    }
                }
            }

            fd.append('upload_preset', `${this.props.modelType}_attachments`)
            fd.append('tags', 'browser_upload')
            fd.append('file', file)

            xhr.send(fd)
        }).catch(e => e)

        if (fileHostResponse instanceof Error) {
            showErrorNotification('Unable to upload file to host.')
            console.error(fileHostResponse)
        } else {
            const hostedFile = JSON.parse(fileHostResponse)
            
            const {
                modelId,
                modelType,
                attachmentType,
                attachmentTags,
                addPropertyAttachment,
                onUpload,
                refetchQueriesOnUpload
            } = this.props

            try {
                let response, attachment

                if (modelType === 'property') {
                    response = await addPropertyAttachment({
                        variables: {
                            propertyId: modelId,
                            name: hostedFile.public_id,
                            type: attachmentType,
                            url: hostedFile.secure_url,
                            tags: attachmentTags ? attachmentTags : null
                        },
                        refetchQueries: refetchQueriesOnUpload ? refetchQueriesOnUpload : null
                    })
    
                    attachment = response.data.addPropertyAttachment
                }

                this.setState(prevState => {
                    let prevFiles = prevState.files
                    const [fileToUpdate] = _.remove(prevFiles, pf => pf.name === file.name)
                    fileToUpdate.uploaded = true
                    return { files: prevFiles.concat([fileToUpdate]) }
                }, () => {
                    if (onUpload) {
                        onUpload({
                            file: file,
                            attachment: attachment
                        })
                    }
                })
            } catch (e) {
                const pendingUpload = {
                    modelId: modelId,
                    modelType: modelType,
                    name: file.public_id,
                    type: attachmentType,
                    tags: attachmentTags,
                    url: file.secure_url
                }
                let pendingUploads = localStorage.getItem('pending-uploads')

                if (pendingUploads) {
                    pendingUploads = JSON.parse(pendingUploads)
                    pendingUploads.push(pendingUpload)
                } else {
                    pendingUploads = [pendingUpload]
                }

                localStorage.setItem('pending-uploads', JSON.stringify(pendingUploads))

                showErrorNotification('Unable to upload file, please try again later.')
                console.error(e)
            }
        }
    }

    render() {
        const {
            modelType,
            open,
            acceptMultipleFiles = true,
            acceptedFileTypes,
            headerText = 'Upload attachments',
            dropzoneIcon = 'file',
            dropzoneText = 'Click to upload attachments. You can drag and drop too!',
            galleryHeaderText = 'Attachments'
        } = this.props

        const {
            files
        } = this.state

        if (modelType !== 'property') {
            throw new Error('Invalid modelType prop supplied to component')
        }

        return (
            <ErrorBoundary>
                <Modal
                    open={open}
                    onClose={this.handleClose}
                    size='small'
                >
                    <Modal.Header content={headerText} />
                    <Modal.Content>
                        <div className={style.uploader__grid}>
                            <Dropzone
                                accept={acceptedFileTypes}
                                onDropRejected={this.handleDropRejected}
                                onDropAccepted={this.handleDropAccepted}
                                multiple={acceptMultipleFiles}
                                disabled={!acceptMultipleFiles && files.length > 0}
                            >
                                <div className={style.dropzone__inner}>
                                    <p><Icon name={dropzoneIcon} size='huge' /></p>
                                    <p>{dropzoneText}</p>
                                </div>
                            </Dropzone>
                            <Modal.Description>
                                {
                                    files.length > 0 && (
                                        <Fragment>
                                            <Header content={galleryHeaderText} />
                                            <Image.Group>
                                                {
                                                    files.map((file, index) => {
                                                        const src = file.type.includes('image') ? file.preview : fileIcon
                                                        return (
                                                            <Image
                                                                key={index}
                                                                src={src}
                                                                className={style.uploader__attachment}
                                                                label={
                                                                    file.uploaded ? ({
                                                                        corner: 'left',
                                                                        icon: 'check',
                                                                        color: 'green'
                                                                    }) : ({
                                                                        corner: 'left',
                                                                        icon: {
                                                                            name: 'spinner',
                                                                            loading: true
                                                                        }
                                                                    })
                                                                }
                                                                bordered
                                                                rounded
                                                            />
                                                        )
                                                    })
                                                }
                                            </Image.Group>
                                        </Fragment>
                                    )
                                }
                            </Modal.Description>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            onClick={this.handleClose}
                            content='Cancel'
                        />
                        <Button
                            primary
                            onClick={this.handleClose}
                            disabled={
                                (files.length > 0) &&
                                (!files.every(file => file.uploaded))
                            }
                            content='Done'
                        />
                    </Modal.Actions>
                </Modal>
            </ErrorBoundary>
        )
        
    }
}

export default compose(
    graphql(
        addPropertyAttachment,
        {
            name: 'addPropertyAttachment'
        }
    )
)(withApollo(AttachmentUploadModal))