/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

// action types

export const SET_LOCALE = 'SET_LOCALE'

// action creators

export function setLocale(locale) {
    return { type: SET_LOCALE, locale }
}