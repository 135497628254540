/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

import gql from 'graphql-tag'

export const countries = gql`
    query{
        countries{
            id
            iso2
            iso3
            name
        }
    }
`