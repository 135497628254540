/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

// @flow

import React, { PureComponent, Fragment } from 'react'
import style from './listing.less'
import Description from './Description'
import Area from './Area'
import Facilities from './Facilities'
import Availability from './Availability'
import Booking from './Booking'
import {getMaxOccupants} from 'utils'
import { Segment, Sticky, Message } from 'semantic-ui-react'
import { getCancellationDurationString } from 'utils/strings'
import type { Accommodation } from 'utils/flowtypes/models'

type Props = {
    listing: Accommodation
}

type State = {
    contextRef: ?HTMLElement
}

class Body extends PureComponent<Props, State> {
    state = {
        contextRef: null
    }

    handleContextRef = (contextRef: ?HTMLElement) => this.setState({ contextRef })

    render() {
        const { contextRef } = this.state
        const { listing } = this.props
        const description = listing.features.find(feature => feature.definition.shortcode === 'description')?.value || ''
        const maxOccupants = getMaxOccupants(listing.features)
        const cancellationPolicy = listing.cancellation_policies.length > 0 ? listing.cancellation_policies[0] : null

        return (
            <div className={style.listing__body}>
                <div className={style.listing__body__main}>
                    <Description
                        accommodationDescription={description}
                        locationDescription={listing.property.description}
                    />
                    <Facilities features={listing.features} />
                    <Area location={listing.property.location} />
                    <Availability availability={listing.availability} />
                    {
                        cancellationPolicy && (
                            <div>
                                <Message>
                                    {
                                        cancellationPolicy.duration > 0 ? (
                                            <Fragment>
                                                <Message.Header id='cancellation-policy'>Cancellation Policy</Message.Header>
                                                <Message.List>
                                                    <Message.Item>{getCancellationDurationString(cancellationPolicy.duration)}’ notice is required to cancel the booking prior to arrival.</Message.Item>
                                                    <Message.Item>This is for all lengths of stay in order to avoid charges.</Message.Item>
                                                    <Message.Item>For guest in house {getCancellationDurationString(cancellationPolicy.duration)}’ notice is required to shorten or extend their stay/booking.</Message.Item>
                                                    <Message.Item>Group Bookings cancellation policy may vary.</Message.Item>
                                                </Message.List>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <Message.Header>
                                                    Non-refundable
                                                </Message.Header>
                                                <Message.List>
                                                    <Message.Item>Once booking has been made and paid there is no refund.</Message.Item>
                                                    <Message.Item>Group Bookings cancellation policy may vary.</Message.Item>
                                                </Message.List>
                                            </Fragment>
                                        )
                                    }
                                </Message>
                            </div>
                        )
                    }
                </div>
                <div className={style.listing__body__siderail} ref={this.handleContextRef}>
                    <Sticky context={contextRef} offset={20}>    
                        <Segment>
                            <Booking listing={listing} maxOccupants={maxOccupants} pricing={listing.pricing} />
                        </Segment>
                    </Sticky>
                </div>
            </div>
        )
    }
}

export default Body