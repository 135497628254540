/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

import React, { PureComponent } from 'react'
import Step from './Step'
import Panel from './Panel'
import ExtraPanel from './ExtraPanel'
import NextButton from './NextButton'
import BackButton from './BackButton'

import {
    Segment
} from 'semantic-ui-react'

class Wizard extends PureComponent {
    state = {}
    
    static Step = Step
    static Panel = Panel
    static ExtraPanel = ExtraPanel
    static NextButton = NextButton
    static BackButton = BackButton

    render() {
        return (
            <Segment secondary>
                { this.props.children }
            </Segment>
        )
    }
}

export default Wizard