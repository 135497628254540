/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Feb 2019
 * ========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import Dropzone from 'react-dropzone'
import style from './managecertifications.less'
import { graphql, compose } from 'react-apollo'
import { Modal, Form, Button, Icon, Item, Message } from 'semantic-ui-react'
import { showErrorNotification, showSuccessNotification } from 'utils/notifications'
import { uploadImageToCloudinary } from 'utils/files'
import type { Property, CertificationDefinition } from 'utils/flowtypes/models'
import type { SemanticEvent, SemanticEventProps } from 'utils/flowtypes/semantic'
import type { ApolloQuery } from 'utils/flowtypes/apollo'
import {
    certificationDefinitions,
    addPropertyCertification,
    deleteCertification
} from 'queries/certifications'

type Props = {
    property: Property,
    onClose(): void,
    onSubmit?: Function,
    open: boolean,
    query: {
        certificationDefinitions: CertificationDefinition[]
    } & ApolloQuery,
    addPropertyCertification: Function,
    deleteCertification: Function
}

type State = {
    processing: boolean,
    certificate: ?Object,
    issuedAt: string,
    value: ?string,
    expiresAt: string,
    attachment: ?Object
}

class AddPropertyCertificationModal extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            processing: false,
            certificate: null,
            issuedAt: '',
            expiresAt: '',
            value: null,
            attachment: null
        }
    }

    handleSubmit = async () => {
        const { property, query } = this.props
        const { certificationDefinitions } = query
        const { certificate, issuedAt, expiresAt, attachment, value } = this.state
        const definition = certificationDefinitions.find(definition => definition.id === certificate) 

        if (certificate && definition) {
            this.setState({ processing: true })

            // remove any existing matching certifications

            const matchingExistingCertification = property.certifications.find(
                certification => certification.definition.id === certificate
            )
            
            if (matchingExistingCertification) {
                const response = await this.props.deleteCertification({
                    variables: { certificationId: matchingExistingCertification.id }
                }).catch(e => e)

                if (response instanceof Error) {
                    console.error('Unable to delete matching certificate')
                    console.error(response)
                }
            }

            // add the new one

            const variables = {}
            variables.propertyId = property.id
            variables.definitionId = certificate
            variables.issuedAt = issuedAt
            variables.value = value ? value : definition.possible_values[0]
            
            if (expiresAt) { variables.expiresAt = expiresAt }

            if (attachment) {
                const uploadedAttachment = await uploadImageToCloudinary(attachment)

                variables.attachment = {
                    name: uploadedAttachment.name,
                    url: uploadedAttachment.url,
                    tags: ['certification', 'property'],
                    type: 'image',
                    description: ''
                }
            }

            const response = this.props.addPropertyCertification({
                variables
            }).catch(e => e)

            this.setState({ processing: false })

            if (response instanceof Error) {
                console.error(response)
                showErrorNotification('Unable to add certification. Please try again later.')
            } else {
                showSuccessNotification('Certification added.')
                setTimeout(() => {
                    if (this.props.onSubmit) {
                        this.props.onSubmit()
                    }
                    this.props.onClose()
                }, 1000)
            }
        }
    }

    handleChange = (e: SemanticEvent, props: SemanticEventProps) => {
        this.setState({ [props.name]: props.value })
    }

    handleDropAccepted = (files: Object[]) => this.setState({ attachment: files[0] })

    handleDropRejected = (files: Object[]) => {
        showErrorNotification('Invalid file type submitted')
        console.error('Invalid file type submitted;', files)
    }

    render() {
        const {
            processing,
            certificate,
            issuedAt,
            expiresAt,
            attachment,
            value
        } = this.state

        const {
            onClose,
            open,
            query,
            property
        } = this.props

        /**
         * Available certification types
         */
        let options = []
        const { certificationDefinitions } = query
        if (certificationDefinitions) {
            options = query.certificationDefinitions.filter(
                definition => definition.model_types.includes('property')
            ).map(definition => {
                return {
                    key: definition.id,
                    text: definition.name,
                    value: definition.id
                }
            })
        }

        /**
         * Options step-through
         */
        let selectedCertificationDefinition = null
        let requiresValue = false
        let requiresExpiresAt = false
        let requiresAttachment = false
        let certificationExists = false

        if (certificate && certificationDefinitions) {
            selectedCertificationDefinition = certificationDefinitions.find(definition => definition.id === certificate)
            
            if (selectedCertificationDefinition) {
                const {
                    possible_values,
                    default_expiry,
                    expires,
                    certificate_required
                } = selectedCertificationDefinition

                if (possible_values.length > 1) {
                    requiresValue = true
                }

                if (expires && !default_expiry) {
                    requiresExpiresAt = true
                }

                if (certificate_required) {
                    requiresAttachment = true
                }

                const matchingExistingCertification = property.certifications.find(
                    certification => certification.definition.id === certificate
                )
                
                if (matchingExistingCertification) {
                    certificationExists = true
                }
            }
        }

        let readyToSubmit = true
        if (!certificate) readyToSubmit = false
        if (!issuedAt) readyToSubmit = false
        if (requiresValue && !value) readyToSubmit = false
        if (requiresExpiresAt && !expiresAt) readyToSubmit = false
        if (requiresAttachment && !attachment) readyToSubmit = false

        return (
            <Modal
                open={open}
                size='tiny'
            >
                <Modal.Header>Add Property Certificate</Modal.Header>
                <Modal.Content>
                    {
                        certificationExists &&
                        <Message warning>
                            A certification matching this name already exists,
                            adding this new certification will override it.
                        </Message>
                    }
                    <Form>
                        <Form.Dropdown
                            search
                            name='certificate'
                            value={certificate}
                            label='Choose certificate'
                            placeholder='Choose certificate'
                            selection
                            options={options}
                            onChange={this.handleChange}
                            loading={query.loading}
                        />
                        {
                            certificate && 
                            selectedCertificationDefinition &&
                            requiresValue && (
                                <Form.Dropdown
                                    required
                                    selection
                                    name='value'
                                    value={value}
                                    label='Certification options'
                                    onChange={this.handleChange}
                                    placeholder='Certification options'
                                    options={
                                        selectedCertificationDefinition.possible_values.map(
                                            possibleValue => {
                                                return {
                                                    key: possibleValue,
                                                    text: possibleValue,
                                                    value: possibleValue
                                                }
                                            }
                                        )
                                    }
                                />
                            )
                        }
                        {
                            certificate && (
                                <Form.Input
                                    required
                                    type='date'
                                    name='issuedAt'
                                    value={issuedAt}
                                    label='When was the certificate issued?'
                                    onChange={this.handleChange}
                                />
                            )
                        }
                        {
                            certificate &&
                            requiresExpiresAt && (
                                <Form.Input
                                    required
                                    type='date'
                                    name='expiresAt'
                                    value={expiresAt}
                                    label='When does the certificate expire?'
                                    onChange={this.handleChange}
                                />
                            )
                        }
                        {
                            certificate && 
                            requiresAttachment && (
                                !attachment ? (
                                    <div>
                                        <Dropzone
                                            className={style.dropzone}
                                            accept='image/*'
                                            onDropRejected={this.handleDropRejected}
                                            onDropAccepted={this.handleDropAccepted}
                                            multiple={false}
                                        >
                                            <div className={style.dropzone__inner}>
                                                <div><Icon name='image' size='huge' /></div>
                                                <p>Click to upload copy of certificate (required)</p>
                                            </div>
                                        </Dropzone>
                                    </div>
                                ) : (
                                    <Item.Group>
                                        <Item>
                                            <Item.Image size='tiny' src={attachment.preview} />
                                            <Item.Content verticalAlign='middle'>
                                                <Item.Header>{attachment.name}</Item.Header>
                                            </Item.Content>
                                        </Item>
                                    </Item.Group>
                                )
                            )
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={onClose}
                        content='Cancel'
                    />
                    <Button
                        primary
                        onClick={this.handleSubmit}
                        disabled={processing || !readyToSubmit}
                        loading={processing}
                        content='Save'
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

export default compose(
    graphql(
        certificationDefinitions,
        {
            name: 'query',
            options: { fetchPolicy: 'network-only' }
        }
    ),
    graphql(
        addPropertyCertification,
        {
            name: 'addPropertyCertification'
        }
    ),
    graphql(
        deleteCertification,
        {
            name: 'deleteCertification'
        }
    )
)(AddPropertyCertificationModal)