/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

export const errorMessages = {
    'CNF-403-TKN001': {
        displayMessage: 'Please verify your email address before logging in. A link has been sent to the email address you registered with.'
    },
    'A-IG-OAT-GRANT': {
        displayMessage: 'Unable to find a user with those details.'
    },
    'H-403-GQL-RST-002': {
        displayMessage: 'You do not have sufficient privileges to perform this action.'
    },
    'H-403-ATH-UT2': {
        displayMessage: 'You do not have sufficient privileges to perform this action.'
    }
}

export const getErrorDisplayMessage = error => {
    console.error(error)

    if (errorMessages[error.code])
        return errorMessages[error.code].displayMessage

    return null
}

export default errorMessages