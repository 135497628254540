/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Yunjia Li <yunjia.li@launchbase.solutions>, Dec 2018
 * ========================================================================
 */

import gql from 'graphql-tag'

export const getInvoice = gql`
    query(
        $invoiceId: ID,
        $reference: ID
    ){
        invoice(
            id: $invoiceId,
            reference: $reference
        ){
            id
            due_date
            status
            total
            payment_reference
            line_items{
                id
                name
                amount_owed
                meta
            }
            currency_id
            booking{
                id
                reference
                time{
                    id
                    start
                    end
                }
                guests{
                    id
                    name
                    email
                    contact_number
                }
            }
            created_at
            updated_at
        }
    }
`

export const getEstimatedInvoicesForBooking = gql`
    query(
        $accommodationId: ID!
        $dateFrom: Timestamp!
        $dateTo: Timestamp!
        $onDemandFeatures: [ID!]
    ){
        getEstimatedInvoicesForBooking(
            accommodation_id: $accommodationId
            date_from: $dateFrom
            date_to: $dateTo
            on_demand_features: $onDemandFeatures
        ){
            due_date
            total
            line_items{
                name
                amount_owed
                meta
            }
            currency_id
            currency{
                id
                name
            }
            meta
        }   
    }
`

export const getEstimatedFirstPaymentForBooking = gql`
    query(
        $accommodationId: ID!
        $dateFrom: Timestamp!
        $dateTo: Timestamp!
        $onDemandFeatures: [ID!]
    ){
        getEstimatedFirstPaymentForBooking(
            accommodation_id: $accommodationId
            date_from: $dateFrom
            date_to: $dateTo
            on_demand_features: $onDemandFeatures
        ){
            due_date
            total
            line_items{
                name
                amount_owed
                meta
            }
            currency_id
            currency{
                id
                name
            }
            meta
        }
    }
`