/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React, { Fragment } from 'react'
import type { Feature } from 'utils/flowtypes'
import { List, Popup, Button } from 'semantic-ui-react'

type Props = {
    type: 'number' | 'string' | 'boolean' | 'enum',
    name: string,
    value: any,
    icon?: string,
    feature: Feature,
    onClickChangeFeature: Function,
    onClickRemoveFeature: Function
}

const FeatureDisplay = (props: Props) => {
    const {
        type,
        name,
        value,
        icon,
        feature,
        onClickChangeFeature,
        onClickRemoveFeature
    } = props

    return (
        <Popup
            on='click'
            position='bottom left'
            trigger={
                <List.Item>
                    {
                        type === 'number' && (
                            <Fragment>
                                { icon && <List.Icon name={icon} /> }
                                <List.Content>{name}: <strong>{value}</strong></List.Content>
                            </Fragment>
                        )
                    }
                    {
                        type === 'string' && (
                            <Fragment>
                                { icon && <List.Icon name={icon} /> }
                                <List.Content>{name} <strong>{value}</strong></List.Content>
                            </Fragment>
                        )
                    }
                    {
                        type === 'enum' && (
                            <Fragment>
                                { icon && <List.Icon name={icon} /> }
                                <List.Content>{name}: <strong>{value}</strong></List.Content>
                            </Fragment>
                        )
                    }
                    {
                        type === 'boolean' && (
                            <Fragment>
                                { icon && <List.Icon name={icon} /> }
                                <List.Content><strong>{name}</strong></List.Content>
                            </Fragment>
                        )
                    }
                </List.Item>
            }
            content={
                <Button.Group
                    size='small'
                >
                    <Button
                        icon='trash'
                        color='red'
                        content='Remove'
                        onClick={() => { onClickRemoveFeature(feature) }}
                    />
                    {
                        feature.definition.type !== 'boolean' &&
                        <Fragment>
                            <Button.Or />
                            <Button
                                icon='pencil'
                                color='green'
                                content='Change'
                                onClick={() => { onClickChangeFeature(feature) }}
                            />
                        </Fragment>
                    }
                </Button.Group>
            }
        />
    )
}   


export default FeatureDisplay