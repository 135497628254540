/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

import React from 'react'
import { Wizard } from 'components'

import {
    Form,
    Button,
    Icon,
    Segment,
    Divider,
    Header
} from 'semantic-ui-react'

const PropertyAddressStep = props => {
    const {
        activeStep,
        step,
        onClickNext,
        onChange,
        name,
        street,
        town,
        county,
        postcode,
        stepCount,
        country,
        countries
    } = props

    const countryOptions = countries.map(country => {
        return {
            key: country.iso2,
            value: country.iso2,
            text: country.name
        }
    })

    return (
        <Wizard.Step
            step={step}
            activeStep={activeStep}
            panel={
                <Wizard.Panel
                    step={step}
                    totalSteps={stepCount}
                    header='What is the property address?'
                    nextAction={
                        <Button onClick={onClickNext}
                            primary 
                            animated
                            disabled={!street || !postcode || !country}
                        >
                            <Button.Content visible>Next</Button.Content>
                            <Button.Content hidden>
                                <Icon name='arrow right' />
                            </Button.Content>
                        </Button>
                    }
                >
                    <Form>
                        <Form.Input
                            label='Property Name (Optional)'
                            type='text'
                            onChange={onChange}
                            value={name}
                            name='name'
                            placeholder='e.g. Bassett House'
                        />
                        <Form.Input
                            required
                            label='Street Address (Required)'
                            type='text'
                            onChange={onChange}
                            value={street}
                            name='street'
                            placeholder='e.g. 18 Westfield Avenue'
                        />
                        <Form.Input
                            label='Town / City'
                            type='text'
                            onChange={onChange}
                            value={town}
                            name='town'
                            placeholder='e.g. Portsmouth'
                        />
                        <Form.Input
                            label='County / Region'
                            type='text'
                            onChange={onChange}
                            value={county}
                            name='county'
                            placeholder='e.g. Hampshire'
                        />
                        <Form.Input
                            required
                            label='Postal / Zip code (Required)'
                            type='text'
                            onChange={onChange}
                            value={postcode}
                            name='postcode'
                            placeholder='e.g. PO6 4RY'
                        />
                        <Form.Dropdown
                            required
                            search
                            selection
                            options={countryOptions}
                            value={country}
                            name='country'
                            label='Country'
                            onChange={onChange}
                        />
                    </Form>
                </Wizard.Panel>
            }
            extraPanel={
                <Wizard.ExtraPanel desktopOnly>
                    <Segment padded='very' basic textAlign='center'>
                        <Icon.Group size='massive'>
                            <Icon name='building' />
                        </Icon.Group>
                        <Divider hidden />
                        <Header as='h4'>Welcome</Header>
                        <p>
                            Use this wizard to easily add your property
                        </p>
                    </Segment>
                </Wizard.ExtraPanel>
            }
        />
    )
}

export default PropertyAddressStep