/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, April 2019
 * =========================================================================
 */

// @flow

import * as React from 'react'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import { usersWithPendingDocuments } from 'queries/documents'
import { showErrorNotification } from 'utils/notifications'
import type { User } from 'utils/flowtypes/models'
import type { ApolloClient } from 'utils/flowtypes/apollo'

const DEFAULT_PER_PAGE = 15

type Props = {
    client: ApolloClient,
    user: User
}

type State = {
    identityVerifications: User[],
    currentPage: number,
    totalPages: number,
    count: number,
    loading: boolean
}

const mapStateToProps = state => ({ user: state.user })

export default function withPendingIdentityVerifications(
    WrappedComponent: React.AbstractComponent<{}>,
    perPage: number = DEFAULT_PER_PAGE): React.AbstractComponent<{}>
{
    class HigherOrderComponent extends React.Component<Props, State> {
        state = {
            identityVerifications: [],
            currentPage: 1,
            totalPages: 1,
            count: 0,
            loading: false
        }

        componentDidMount() {
            this._fetch()
        }

        handleGetNextPage = () => {
            this.setState(prevState => {
                if (prevState.currentPage < prevState.totalPages) {
                    return { currentPage: prevState.currentPage + 1 }
                }
                return { currentPage: prevState.currentPage }
            }, this._fetch)
        }

        handleGetPreviousPage = () => {
            this.setState(prevState => {
                if (prevState.currentPage > 1) {
                    return { currentPage: prevState.currentPage - 1 }
                }
                return { currentPage: prevState.currentPage }
            }, this._fetch)
        }

        handleGetPage = (page: number) => {
            this.setState({
                currentPage: page
            }, this._fetch)
        }

        _fetch = async () => {
            // pending verifications only concerns the admins
            if (this.props.user?.is_admin) {
                this.setState({ loading: true })
                
                const response = await this.props.client.query({
                    query: usersWithPendingDocuments,
                    variables: {
                        page: this.state.currentPage - 1,
                        perPage: perPage
                    },
                    fetchPolicy: 'no-cache'
                }).catch(e => e)
                
                this.setState({ loading: false })

                if (response instanceof Error) {
                    showErrorNotification('Unable to load pending identity verifications.')
                    console.error(response)
                } else {
                    const requiredDocTypes = ['identity_front', 'identity_back']

                    const identityVerifications = response.data.users.list.filter(user => {
                        return requiredDocTypes.every(reqDocType => {
                            return user.secure_documents.some(doc => {
                                return doc.type === reqDocType
                            })
                        })
                    })

                    // the natural count returned by the server is of each
                    // pending secure document

                    // what we are really looking for, is the count of users
                    // who have pending *identity verification* secure documents
                    // which we will only consider when they have provided
                    // both the identity_front and identity_back documents

                    // to display a count to the user in the sidebar,
                    // we will show the number of valid pending identity
                    // verifications for the first page of results, and if
                    // there is more than one page, the interface will need to
                    // show a '+' to best cover the ambiguity

                    // this is obviously a poor solution and we will need
                    // to build a backend query specifically for identity
                    // verifications to be paginated properly

                    this.setState({
                        identityVerifications,
                        totalPages: response.data.users.pagination.total_pages,
                        count: identityVerifications.length
                    })
                }
            }
        }

        render() {
            const identityVerifications = {
                getNextPage: this.handleGetNextPage,
                getPreviousPage: this.handleGetPreviousPage,
                getPage: this.handleGetPage,
                currentPage: this.state.currentPage,
                startOfList: this.state.currentPage === 1,
                endOfList: this.state.currentPage === this.state.totalPages,
                identityVerifications: this.state.identityVerifications,
                refetch: this._fetch,
                loading: this.state.loading,
                count: this.state.count,
                totalPages: this.state.totalPages
            }

            return (
                <WrappedComponent
                    identityVerifications={identityVerifications}
                    {...this.props}
                />
            )
        }
    }

    return withApollo(connect(mapStateToProps)(HigherOrderComponent))
}