/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, March 2019
 * =========================================================================
 */

// @flow

import * as React from 'react'
import { countries } from 'queries/countries'
import { graphql } from 'react-apollo'
import { Form } from 'semantic-ui-react'
import type { Country } from 'utils/flowtypes/models'
import type { ApolloQuery } from 'utils/flowtypes/apollo'
import type { SemanticEventProps } from 'utils/flowtypes/semantic'

type Props = {
    countries: {
        countries: Country[]
    } & ApolloQuery,
    onChange?: (props: SemanticEventProps) => void,
    name?: string,
    value?: string,
    placeholder?: string,
    label?: string,
    fluid?: boolean,
    required?: boolean,
    className?: string
}

const CountryDropdownInput = (props: Props) => {
    const { loading, countries } = props.countries
    const {
        name,
        value,
        placeholder,
        label,
        onChange,
        fluid,
        required,
        className
    } = props

    let countryOptions = []

    if (!loading) {
        countryOptions = countries.map(country => {
            return {
                key: country.iso2,
                value: country.iso2,
                text: country.name
            }
        })
    }

    return (
        <Form.Dropdown
            search
            selection
            options={countryOptions}
            fluid={fluid}
            value={value}
            name={name}
            required={required}
            className={className}
            label={label}
            onChange={onChange}
            placeholder={placeholder}
            loading={loading}
        />
    )
}

export default graphql(
    countries,
    {
        name: 'countries',
        options: { fetchPolicy: 'network-only' }
    }
)(CountryDropdownInput)