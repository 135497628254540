/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 * Based upon arfedulov's excellent semantic-ui-calendar-react
 * https://github.com/arfedulov/semantic-ui-calendar-react/
 */

import React, { PureComponent } from 'react'
import moment from 'moment'
import AvailabilityCalendarCell from './AvailabilityCalendarCell'

import {
    Table,
    Icon
} from 'semantic-ui-react'

const cellStyle = {
    textAlign: 'center'
}

const headerCellStyle = Object.assign({}, cellStyle, {
    borderBottom: 'none'
})

const previousButtonStyle = Object.assign({}, headerCellStyle, {
    cursor: 'pointer'
})

const nextButtonStyle = Object.assign({}, previousButtonStyle)

const getWeekDays = (long = false) => {
    const weekDays = []
    let day = moment().startOf('week')
    for (let i = 0; i < 7; i++) {
        weekDays[i] = day.format(long ? 'dddd' : 'dd')
        day.add(1, 'd')
    }
    return weekDays
}

const getCalendarStart = referenceDate => {
    return referenceDate.clone().startOf('month').startOf('week')
}

const getArrayOfWeeks = (referenceDate, weeks = 6) => {
    const weeksList = new Array(weeks)
    let day = getCalendarStart(referenceDate).clone()
    
    for (let i = 0; i < weeksList.length; i++) {
        weeksList[i] = []
        for (let j = 0; j < 7; j++) {
            weeksList[i][j] = day.clone()
            day.add(1, 'd')
        }
    }
    
    return weeksList
}

/** Compare two `moment`'s by date. */
const compareDates = (oneDate, otherDate) => {
    if (!oneDate.year || !otherDate.year)
        return false

    return oneDate.year() === otherDate.year()
        && oneDate.month() === otherDate.month()
        && oneDate.date() === otherDate.date()
}

/**
 * Check if date should be showed as active in calendar.
 * Check if date is the same as `active` or is date included in given date's interval.
 * 
 * @param {moment} checkedDate Date which compared with `active`
 * @param {moment||{start: moment, end: moment}} active Eather date or date's interval as [start, end]
*/
const isActiveDate = (checkedDate, active) => {
    if (!checkedDate || !active)
        return false

    function _isActive(active) {
        if (!active.start)
            return
    
        if (!active.end)
            return compareDates(checkedDate, active.start)
        
        const normStart = moment({
            year: active.start.year(),
            month: active.start.month(),
            date: active.start.date()
        })

        const normEnd = moment({
            year: active.end.year(),
            month: active.end.month(),
            date: active.end.date()
        })

        const normCheckedDate = moment({
            year: checkedDate.year(),
            month: checkedDate.month(),
            date: checkedDate.date()
        })

        return normStart.isBefore(normCheckedDate)
            && normEnd.isAfter(normCheckedDate)
            || normStart.isSame(normCheckedDate)
            || normEnd.isSame(normCheckedDate)
    }

    // multiple date ranges
    if (Array.isArray(active)) {
        return active.some(dateRange => _isActive(dateRange))
    }

    // single date range
    if (active.hasOwnProperty('start') && active.hasOwnProperty('end')) {
        return _isActive(active)
    }

    // single date
    return compareDates(checkedDate, active)
}

/** Check if given day is in the `date`'s month. */
const isDayInMonth = (day, date) => day.month() === date.month()

class AvailabilityCalendar extends PureComponent {
    getRow = (week, key) => {
        const {
            onDateClick,
            activeDate,
            month,
            datesRange,
            dateRanges,
            hover
        } = this.props

        const days = week.map(day => {
            const active = isActiveDate(day, activeDate || datesRange || dateRanges)
            const disabled = !isDayInMonth(day, month)

            return (
                <AvailabilityCalendarCell
                    onClick={onDateClick}
                    active={active}
                    disabled={disabled}
                    data={day}
                    hover={hover}
                    key={day.format('DD-MM-YYYY')}
                />
            )
        })

        return (
            <Table.Row key={key}>
                { 
                    days
                }
            </Table.Row>
        )
    }
    
    getBody = weeks => weeks.map(week => {
        return this.getRow(week, week[0].format('YYYY-MM-DD'))
    })

    getWeekDayHeaders = () => {
        return getWeekDays().map(day => (
            <Table.HeaderCell
                style={cellStyle}
                key={day}
                colSpan='1'
            >
                {day}
            </Table.HeaderCell>
        ))
    }
    
    render() {
        const {
            month,
            nextButtonDisabled,
            onNextButtonClick,
            previousButtonDisabled,
            onPreviousButtonClick
        } = this.props

        const data = getArrayOfWeeks(month)

        return (
            <div>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='1' style={headerCellStyle}>
                                <Icon
                                    style={previousButtonStyle}
                                    fitted
                                    disabled={previousButtonDisabled}
                                    name='chevron left'
                                    onClick={previousButtonDisabled ? undefined : onPreviousButtonClick}
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='5' style={headerCellStyle}>
                                {month.format('MMMM')}
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='1' style={headerCellStyle}>
                                <Icon
                                    style={nextButtonStyle}
                                    fitted
                                    disabled={nextButtonDisabled}
                                    name='chevron right'
                                    onClick={nextButtonDisabled ? undefined : onNextButtonClick}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            {
                                this.getWeekDayHeaders()
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            this.getBody(data)
                        }
                    </Table.Body>   
                </Table>
            </div>
        )
    }
}

export default AvailabilityCalendar