/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React, { type Node } from 'react'
import style from './page.less'
import { Header as SUIHeader } from 'semantic-ui-react'

type Props = {
    header: string,
    subheader?: string,
    children?: Node
}

const Header = (props: Props) => {
    const { header, subheader, children } = props

    return (
        <div className={style.page__header}>
            <SUIHeader as='h2'>
                {header}
                {
                    subheader && (
                        <SUIHeader.Subheader>{subheader}</SUIHeader.Subheader>
                    )
                }
            </SUIHeader>
            {
                children && (
                    <div>
                        {children}
                    </div>
                )
            }
        </div>
    )
}

export default Header