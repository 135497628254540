/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Louis Capitanchik <louis.capitanchik@launchbase.solutions>, December 2018
 */

// @flow

import * as React from 'react'

type Props = {
    as: ?string,
    children: React.Node,
}

const OBSERVER_CONFIG = {
    childList: true,
}

export default class AutoscrollDiv extends React.PureComponent<Props> {
    _container: ?HTMLElement = null
    _observer: ?MutationObserver = null

    render() {
        const { as: C = 'div', children, ...rest } = this.props
        // $FlowFixMe Trouble determining correct element type from asable
        return <C ref={this._handleContainerRef} {...rest}>{ children }</C>
    }

    _handleContainerRef = (ref: HTMLElement) => {
        if (ref !== this._container && this._observer != null) {
            this._observer.disconnect()
            this._observer = null
        }

        this._container = ref

        if (ref != null) {
            this._observer = new MutationObserver(this._autoscroll)
            this._observer.observe(ref, OBSERVER_CONFIG)
        }
    }

    _autoscroll = () => {
        if (this._container != null) {
            this._container.scrollTop = this._container.scrollHeight
        }
    }
}