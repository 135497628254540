/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

import gql from 'graphql-tag'

export const getAccommodation = gql`
    query(
        $accommodationId: ID!
    ){
        accommodation(
            id: $accommodationId
        ){
            id
            name
            listed
            approved
            public
            created_at
            updated_at
            notes{
                id
                contents
                tags
            }
            features{
                id
                value
                name
                type
                country
                on_demand
                definition{
                    id
                    name
                    type
                    shortcode
                    group
                    active
                    meta
                }
                pricing{
                    start
                    end
                    price
                }
            }
            pricing{
                start
                end
                price
                currency{
                    id
                    name
                }
            }
            property{
                id
                name
                address
                description
                country{
                    iso2
                    name
                }
                location{
                    longitude
                    latitude
                }
                attachments{
                    id
                    name
                    type
                    url
                    tags
                }
            }
            template{
                id
                name
                attachments{
                    id
                    description
                    type
                    url
                }
                features{
                    id
                    value
                    name
                    type
                    country
                    on_demand
                    definition{
                        id
                        name
                        type
                        shortcode
                        group
                        active
                        meta
                    }
                    pricing{
                        start
                        end
                        price
                    }
                }
            }
            attachments{
                id
                name
                description
                type
                url
            }
            attachmentOrdering(type: image)
            cancellation_policies{
                id
                duration
                allow_instant
                refundable
                group_varying
                meta
            }
            certifications{
                id
                name
                value
                approved
                issued_at
                expires_at
                definition{
                    id
                    name
                    possible_values
                    default_expiry
                    expires
                    certificate_required
                    meta
                }
                meta
                notes{
                    id
                    contents
                    tags
                }
            }
        }
    }
`

export const createAccommodation = gql`
    mutation(
        $propertyId: ID!,
        $name: String,
        $features: [FeatureInput!]
    ){
        createAccommodation(
            property_id: $propertyId,
            name: $name,
            features: $features
        ){
            id
            name
        }
    }
`

export const removeAttachment = gql`
    mutation(
        $attachmentId:ID!
    ){
        deleteAttachment(
            attachment_id: $attachmentId
        )
    }
`

export const updateAccommodation = gql`
    mutation(
        $accommodationId: ID!,
        $name: String,
        $features: [FeatureInput!]
        $deleted: [ID!]
    ){
        updateAccommodation(
            accommodation_id: $accommodationId,
            name: $name,
            features: $features,
            deleted: $deleted
        ){
            id
        }
    }
`

export const featureDefinitions = gql`
    query{
        featureDefinitions{
            id
            name
            shortcode
            type
            model_types
            group
            active
            required
            meta
        }
    }
`

export const getAccommodationPricingForDuration = gql`
    query(
        $model_id: ID!,
        $duration: Int!
    ){
        getPriceForDuration(
            model_id:$model_id,
            duration: $duration
        ){
            price
            currency
			tax
			taxes{
				rate
				amount
				percentage
			}
        }
    }
`

export const setAccommodationPricing = gql`
    mutation(
        $accommodationId: ID!,
        $priceList: [PricingInput!],
        $currencyId: ID!
    ){
        setAccommodationPricing(
            accommodation_id: $accommodationId,
            price_list: $priceList,
            currency_id: $currencyId
        )
    }
`

export const addAccommodationAttachment = gql`
    mutation(
        $accommodationId: ID!,
        $name: String,
        $description: String,
        $type: AttachmentTypeEnum!,
        $url: String!
    ){
        addAccommodationAttachment(
            accommodation_id: $accommodationId
            name: $name
            description: $description
            type: $type
            url: $url
        ){
            id
            name
            description
            type
            url
            tags
        }
    }
`

export const duplicateAccommodation = gql`
    mutation(
        $accommodationId: ID!
    ){
        duplicateAccommodation(
            accommodation_id: $accommodationId
        ){
            id
            name
        }   
    }
`

export const quickSearchAccommodation = gql`
    query(
        $name: String
    ){
        accommodations(
            name: $name
        ){
            list{
                id
                name
            }
        }
    }
`

export const getUnavailabilityForAccommodation = gql`
    query(
        $accommodationId: ID,
        $start: Timestamp!,
        $end: Timestamp!
    ){
        getUnavailabilityForAccommodation(
            accommodation_id: $accommodationId,
            start: $start,
            end: $end
        ){
            from
            to
        }
    }
`

export const createAccommodationUnavailableSlot = gql`
    mutation(
        $accommodationId: ID!,
        $from: Timestamp!,
        $to: Timestamp!
    ){
        createAccommodationUnavailableSlot(
            accommodation_id: $accommodationId,
            from: $from,
            to: $to
        ){
            id
            start
            end
        }
    }
`

export const getAccommodationWithAvailability = gql`
    query(
        $accommodationId: ID!
        $from: Timestamp!
        $to: Timestamp!
    ){
        accommodation(
            id: $accommodationId
        ){
            id
            name
            property{
                id
                name
                address
            }
            availability(
                from: $from,
                to: $to
            ){
                id
                model_type
                model_id
                start
                end
            }
        }
    }
`

export const deleteUnavailableSlot = gql`
    mutation(
        $availabilityId: ID!
    ){
        deleteUnavailableSlot(
            availability_id: $availabilityId
        )
    }
`

export const bookAccommodation = gql`
    mutation(
        $date_to:Timestamp!,
        $date_from:Timestamp!,
        $accommodation_id:ID!,
        $guests:[ID!]!,
        $on_demand_features:[ID!]
    ){
        bookAccommodation(
            date_to:$date_to,
            date_from:$date_from,
            accommodation_id:$accommodation_id,
            on_demand_features:$on_demand_features,
            guests:$guests
        ){
            id
            reference
            invoices{
                id
                meta
                payment_reference
                line_items{
                    id
                    name
                    amount_owed
                }
            }
        }
    }
`

export const setAccommodationAttachmentOrder = gql`
    mutation(
        $accommodationId: ID!
        $type: AttachmentTypeEnum!
        $order: [ID!]!
    ){
        setAccommodationAttachmentOrder(
            accommodation_id: $accommodationId
            type: $type
            order: $order
        )
    }
`

export const updateAttachment = gql`
    mutation(
        $attachmentId: ID!
        $attachmentDelta: AttachmentDelta
    ){
        updateAttachment(
            attachment_id: $attachmentId
            attachment_delta: $attachmentDelta
        ){
            id
            name
            description
            type
            url
            tags
        }
    }
`

export const createCancellationPolicy = gql`
    mutation(
        $accommodationId: ID!
        $policy: CancellationPolicyInput!
    ){
        createCancellationPolicy(
            accommodation_id: $accommodationId
            policy: $policy
        ){
            id
        }
    }
`
