/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, July 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import type { Guest } from 'utils/flowtypes/models'
import { Tab, Table, Message, Button } from 'semantic-ui-react'

type Props = {
    guests: Guest[]
}

const GuestList = (props: Props) => {
    const { guests } = props

        if (guests.length === 0) {
            return (
                <Tab.Pane as='div'>
                    <Message info>
                        <Message.Header>Oh boo :(</Message.Header>
                        <p>
                            You have not added any guest yet.
                        </p>
                    </Message>
                </Tab.Pane>
            )
        }

        return (
            <div>
                <Table basic='very' compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                            <Table.HeaderCell>Contact number</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            guests.map(guest => {
                                return (
                                    <Table.Row key={guest.id}>
                                        <Table.Cell>
                                            <Link to={`/guests/${guest.id}`}>{guest.name}</Link>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {guest.email}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {guest.contact_number}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Button
                                                as={Link}
                                                to={`/guests/${guest.id}`}
                                                color='green'
                                                size='mini'
                                                content='Edit'
                                            />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Link to={`/booker/bookings/?guestIds=${guest.id}`}>View bookings</Link>
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })
                        }
                    </Table.Body>
                </Table>
            </div>
        )
    }


export default GuestList