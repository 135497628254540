/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Oct 2018
 * ========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import type { ID, Attachments, Attachment } from 'utils/flowtypes/models'
import style from './guestphotomanager.less'
import ErrorBoundary from 'ErrorBoundary'
import { graphql, withApollo } from 'react-apollo'
import { SelectableImageCard, AttachmentUploader } from 'components'
import { showErrorNotification } from 'utils/notifications'
import { Button, Segment, Popup, Message } from 'semantic-ui-react'
import _ from 'lodash'
import { removeAttachment } from 'queries/guests'

type Props = {
    guestId: string,
    photos: Attachments,
    removeAttachment: Function
}

type State = {
    selectedPhotos: Array<ID>,
    removedPhotos: Array<ID>,
    uploadedPhotos: Array<Object>,
    contextRef?: string
}

class GuestPhotoManager extends PureComponent<Props, State> {
    state = {
        selectedPhotos: [],
        removedPhotos: [],
        uploadedPhotos: []
    }

    handleToggleSelection = photoId => {
        this.setState(prevState => {
            let selectedPhotos = prevState.selectedPhotos.slice()

            if (selectedPhotos.includes(photoId)) {
                selectedPhotos = _.without(selectedPhotos, photoId)
            } else {
                selectedPhotos =  selectedPhotos.concat(photoId)
            }

            return { selectedPhotos }
        })
    }

    handleUploadPhoto = (uploadedPhoto: Attachment) => {
        this.setState(prevState => {
            let uploadedPhotos = prevState.uploadedPhotos.slice()
            uploadedPhotos = uploadedPhotos.concat(uploadedPhoto)
            return { uploadedPhotos }
        })
    }

    handleRemovePhotos = () => {
        const { selectedPhotos } = this.state

        selectedPhotos.forEach(async photoId => {
            const response = await this.props.removeAttachment({
                variables: { attachmentId: photoId }
            }).catch(e => e)

            if (response instanceof Error) {
                showErrorNotification('Unable to remove photos')
                console.error(response)
            } else {
                this.setState(prevState => {
                    let { selectedPhotos, removedPhotos } = prevState
                    selectedPhotos = _.without(selectedPhotos, photoId)
                    removedPhotos = removedPhotos.concat(photoId)
                    return { selectedPhotos, removedPhotos }
                })
            }
        })
    }

    render() {
        const { guestId } = this.props

        const {
            selectedPhotos,
            removedPhotos,
            uploadedPhotos
        } = this.state

        const photos = this.props.photos.concat(uploadedPhotos)
        
        if (photos.length) {
            return (
                <ErrorBoundary>
                    <div className={style.guestphotomanager}>
                        <div className={style.guestphotomanager__gallery}>
                            {
                                photos.map(photo => {
                                    if (!removedPhotos.includes(photo.id)) {
                                        return (
                                            <SelectableImageCard
                                                key={photo.id}
                                                src={photo.url}
                                                onClick={() => {this.handleToggleSelection(photo.id)}}
                                                selected={selectedPhotos.includes(photo.id)}
                                                cardClass={style.guestphotomanager__gallery__card}
                                                imageClass={style.guestphotomanager__gallery__image}
                                            />
                                        )
                                    }
                                })
                            }
                        </div>
                        <div className={style.guestphotomanager__actions}>
                            <Segment>
                                <AttachmentUploader
                                    accept='image/*'
                                    modelId={guestId}
                                    modelType='guest'
                                    onAttachmentUpload={this.handleUploadPhoto}
                                />
                                {
                                    selectedPhotos.length >= 1 ? (
                                        <Button
                                            fluid
                                            content={
                                                selectedPhotos.length >= 2
                                                ? `Remove ${selectedPhotos.length} Photos`
                                                : 'Remove Photo'
                                            }
                                            onClick={this.handleRemovePhotos}
                                        />
                                    ) : (
                                        <Popup
                                            trigger={
                                                <span>
                                                    <Button
                                                        fluid
                                                        disabled
                                                        content='Remove Photo'
                                                    />
                                                </span>
                                            }
                                            content='To remove a photo, first click on the photo in the gallery'
                                            inverted
                                        />
                                    )
                                }
                            </Segment>
                        </div>
                    </div>
                </ErrorBoundary>
            )
        }

        return (
            <ErrorBoundary>
                <Message info compact>
                    <p>
                        This guest has no document photo yet.
                    </p>
                    <p>
                        <AttachmentUploader
                            accept='image/*'
                            modelId={guestId}
                            modelType='guest'
                        />
                    </p>
                </Message>
            </ErrorBoundary>
        )
    }
}

export default graphql(
    removeAttachment,
    {
        name: 'removeAttachment'
    }
)(withApollo(GuestPhotoManager))