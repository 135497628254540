/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import style from './registerform.less'
import { Segment, Header, Form, Icon, Button } from 'semantic-ui-react'
import ErrorBoundary from 'ErrorBoundary'
import BasicInformation from './BasicInformation'
import CompanyInformation from './CompanyInformation'
import ContactInformation from './ContactInformation'
import { localiseHTML } from 'languages'
import { Link } from 'react-router-dom'
import type { Countries } from 'utils/flowtypes/models'

type Props = {
    onChange: Function,
    name: string,
    email: string,
    password: string,
    confirmPassword: string,
    onClickNext: Function,
    onClickBack: Function,
    isProvider: string | false,
    activeScreen: number,
    companyName: string,
    registeredCountry: string,
    streetAddress: string,
    telephone: string,
    telephoneCountry: string,
    postCode: string,
    onSubmit: Function,
    companyType: 'Limited Company' | 'Sole Trader' | 'Other',
    successfullyRegistered: boolean,
    countries: Countries,
    processing: boolean
}

const RegisterForm = (props: Props) => {

    const { activeScreen, successfullyRegistered, ...rest } = props

    return (
        <ErrorBoundary>
            <Segment raised className={style.register__form}>
                {
                    successfullyRegistered ? (
                        <div className={style.register__form__success}>
                            <Header as='h3' icon>
                                <Icon color='green' name='check circle outline' />
                                All Done!
                                <Header.Subheader>
                                    You were registered successfully.
                                </Header.Subheader>
                            </Header>
                            <p>{localiseHTML('REGISTER_FORM.SUCCESS_MESSAGE', { email: props.email })}</p>
                            <div className={style.register__form__footer}>
                                <div className={style.register__form__footer__actions}>
                                    <div>
                                        <Button
                                            as={Link}
                                            to='/'
                                            basic
                                            primary
                                            size='large'
                                        >
                                            Back to Home
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            as={Link}
                                            to='/login'
                                            primary
                                            animated
                                            size='large'
                                            style={{
                                                margin: 0
                                            }}
                                        >
                                            <Button.Content visible>Log In</Button.Content>
                                            <Button.Content hidden>
                                                <Icon name='arrow right' />
                                            </Button.Content>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Form>
                            {
                                activeScreen === 1 &&
                                <BasicInformation {...rest} />
                            }
                            {
                                activeScreen === 2 &&
                                <CompanyInformation {...rest} />
                            }
                            {
                                activeScreen === 3 &&
                                <ContactInformation {...rest} />
                            }
                        </Form>
                    )
                }
            </Segment>
      </ ErrorBoundary>
    )
}

export default RegisterForm