/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Yunjia Li <yunjia.li@launchbase.solutions>, Nov 2018
 * ========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import { CardElement, injectStripe } from 'react-stripe-elements'
import { Button } from 'semantic-ui-react'
import { showErrorNotification } from 'utils/notifications'
import paymentCardStyle from './paymentcard.less'
import type { Invoice } from 'utils/flowtypes/models'
import { displayPrice } from 'utils/money'

type Props = {
    invoice?: Invoice,
    amount: number,
    currency: string,
    isDeposit?: boolean,
    onSubmit(token: string): void,
    onChange?: Function,
    onReady?: Function,
    stripe: {
        createToken: Function
    },
    processing?: boolean,
    isFullPayment?: boolean
}

type State = {
    readyToSubmit: boolean,
    processing: boolean,
    name: string
}

class PaymentCard extends PureComponent<Props, State> {
    state = {
        readyToSubmit: false,
        processing: false,
        name: ''
    }

    handleSubmit = async () => {
        this.setState({ processing: true })

        const response = await this.props.stripe.createToken({
            name: this.state.name
        }).catch(e => {
            console.error(e)
            showErrorNotification('Unable to create payment token.')
        })

        this.setState({ processing: false })

        if (!(response instanceof Error)) {
            if (this.props.onSubmit) {
                this.props.onSubmit(response.token.id)
            }
        }        
    }

    handleCardChange = (card: Object) => {
        this.setState(() => {
            return {
                readyToSubmit: card.complete
            }
        })
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    render() {
        const {
            invoice,
            amount,
            currency,
            isDeposit,
            onReady,
            isFullPayment
        } = this.props

        const {
            readyToSubmit,
            name
        } = this.state

        const paymentText = isDeposit ? 'Confirm Booking' : `Pay ${displayPrice(currency, amount)}`

        const processing = this.props.processing || this.state.processing

        return (
            <div>
                <p>We will now charge you {displayPrice(currency, amount)} {isFullPayment ? 'for full settlement (inc. deposit).' : isDeposit ? 'for deposit.' : ''}</p>
                <input
                    name='name'
                    placeholder='Name (as it appears on card)'
                    onChange={this.handleChange}
                    className={paymentCardStyle.StripeElement__name}
                    value={this.state.name}
                />
                <CardElement
                    onChange={this.handleCardChange}
                    onReady={onReady}
                    className={paymentCardStyle.StripeElement}
                />
                <div style={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between', alignContent: 'right-aligned' }}>
                    <div>
                        {
                            invoice && (
                                <Button as='a' basic primary target='_blank' href={`/booker/bookings/${invoice.booking.id}`}>View booking</Button>
                            )
                        }
                    </div>
                    <div>
                        <Button
                            disabled={!name || !readyToSubmit || processing}
                            primary
                            content={paymentText}
                            loading={processing}
                            onClick={this.handleSubmit}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default injectStripe(PaymentCard)