/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Jan 2019
 * ========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import ErrorBoundary from 'ErrorBoundary'
import { uploadImageToCloudinary } from 'utils'
import { showErrorNotification, showSuccessNotification } from 'utils/notifications'
import { compose, graphql, withApollo } from 'react-apollo'
import { addGuestAttachment } from 'queries/guests'
import { removeAttachment } from 'queries/misc'
import { Modal, Button, Grid, Image, Loader, Segment, Dimmer } from 'semantic-ui-react'
import { ImageInput } from 'components'
import type { Guest } from 'utils/flowtypes/models'

type Props = {
    guest: Guest,
    open: boolean,
    onClose: Function,
    onSubmit: Function,
    removeAttachment: Function,
    addGuestAttachment: Function
}

type State = {
    loading: boolean
}

class GuestIdentificationModal extends PureComponent<Props, State> {
    state = {
        loading: false
    }

    handleRemoveDocument = async () => {
        const idDocument = this.props.guest.attachments.find(attachment => attachment.type === 'image')
        if (idDocument) {
            const response = this.props.removeAttachment({
                variables: {
                    attachmentId: idDocument.id
                }
            }).catch(e => e)

            if (response instanceof Error) {
                showErrorNotification('Unable to remove documentation.')
                console.error(response)
            } else {
                showSuccessNotification('Successfully removed documentation.')
                this.props.onSubmit()
            }
        }
    }   

    handleSubmit = async images => {
        this.setState({ loading: true })

        const cloudinaryImage = await uploadImageToCloudinary(images[0])

        if (cloudinaryImage instanceof Error) {
            showErrorNotification('Unable to upload documentation.')
            console.error(cloudinaryImage)
        } else {
            const response = await this.props.addGuestAttachment({
                variables: {
                    guestId: this.props.guest.id,
                    name: cloudinaryImage.name,
                    type: 'image',
                    url: cloudinaryImage.url
                }
            }).catch(e => e)

            if (response instanceof Error) {
                showErrorNotification('Unable to upload documentation.')
                console.error(response)
            } else {
                showSuccessNotification('Successfully uploaded documentation.')
                this.props.onSubmit()
            }
        }

        this.setState({ loading: false })
    }

    render() {
        const { open, guest } = this.props
        const { loading } = this.state 
        const idDocument = guest.attachments.find(attachment => attachment.type === 'image')

        if (loading) {
            return (
                <ErrorBoundary>
                    <Modal
                        open={open}
                        size='tiny'
                    >
                        <Modal.Header content='Upload Guest Identification' />
                        <Modal.Content>
                            <Segment padded='very' basic clearing>
                                <Dimmer active inverted>
                                    <Loader inverted content='Uploading...' />
                                </Dimmer>
                            </Segment>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                disabled
                                content='Close'
                                onClick={this.props.onClose}
                            />
                        </Modal.Actions>
                    </Modal>
                </ErrorBoundary>
            )
        }

        return (
            <ErrorBoundary>
                <Modal
                    open={open}
                    size='tiny'
                >
                    <Modal.Header content='Upload Guest Identification' />
                    <Modal.Content>
                        {
                            idDocument ? (
                                <Grid>
                                    <Grid.Row columns='equal'>
                                        <Grid.Column>
                                            <Image
                                                // className={style.profile__idDocumentThumbnail}
                                                bordered
                                                src={idDocument.url}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Button
                                                content='Remove document'
                                                onClick={this.handleRemoveDocument}
                                                primary
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            ) : (
                                <ImageInput
                                    onChange={this.handleSubmit}
                                    images={[]}
                                />
                            )
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            content='Close'
                            onClick={this.props.onClose}
                        />
                    </Modal.Actions>
                </Modal>
            </ErrorBoundary>
        )
    }
}

export default compose(
    graphql( 
        addGuestAttachment,
        {
            name: 'addGuestAttachment'
        }
    ),
    graphql(
        removeAttachment,
        {
            name: 'removeAttachment'
        }
    )
)(withApollo(GuestIdentificationModal))