/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Jan 2019
 * ========================================================================
 */

// @flow

import React from 'react'
import ErrorBoundary from 'ErrorBoundary'
import style from './search.less'
import _ from 'lodash'
import { NumberInput } from 'components'
import { Segment, Button, Popup, Checkbox, Modal, List, Header } from 'semantic-ui-react'
import type {
    SearchFilters,
    SearchFilter,
    SearchInputType
} from 'utils/flowtypes/models'

type Props = {
    filters: SearchFilters,
    onChangeFilter: Function,
    onResetFilters: Function
}   

type SearchFilterProps = {
    filter: SearchFilter & SearchInputType,
    onChangeFilter: Function
}

const DEFAULT_PRICE_RANGE = {
    from: 0,
    to: Infinity
}

const BAND_RANGES = [
    {
        from: 0,
        to: 49
    },
    {
        from: 50,
        to: 99
    },
    {
        from: 100,
        to: 149
    },
    {
        from: 150,
        to: 199,
    },
    {
        from: 200,
        to: Infinity
    }
]

export type Range = {
    from: number,
    to: number
}

function calculateNewRange(prevRange: Range, bandRange: Range, checked: boolean): {from: number, to: number} {
    let from = prevRange.from
    let to = prevRange.to

    if (checked) {
        if (_.isEqual(prevRange, DEFAULT_PRICE_RANGE)) {
            return bandRange
        }

        if (bandRange.from < prevRange.from) {
            // lower the lower bound
            from = bandRange.from
        }

        if (bandRange.from > prevRange.from) {
            // raise the upper bound
            to = bandRange.to
        }
    } else {
        if (_.isEqual(prevRange, bandRange)) {
            return DEFAULT_PRICE_RANGE
        }
        
        const currentBandRange = _.findIndex(BAND_RANGES, range => range.from === bandRange.from)
        
        if (currentBandRange !== -1) {
            if (bandRange.from === prevRange.from) {
                // raise the upper bound
                const nextBand = BAND_RANGES[currentBandRange + 1]
                from = nextBand.from
            }
            
            if (bandRange.to === prevRange.to) {
                // lower the lower bound
                const prevBand = BAND_RANGES[currentBandRange - 1]
                to = prevBand.to
            }
        }
    }

    return { from, to }
}

const SearchFilterButton = (props: SearchFilterProps) => {
    const { filter, onChangeFilter } = props
   
    const getButtonContent = () => {
        switch (filter.name) {
            case 'bedrooms': {
                if (filter.applied && filter.value.from) {
                    // $FlowFixMe
                    return `${filter.value.from}+ Bedrooms`
                }

                return 'Bedrooms'
            }
            case 'price': {
                if (filter.applied) {
                    // $FlowFixMe
                    return filter.value.to === Infinity ? `£${filter.value.from}+ per night` : `£${filter.value.from} - £${filter.value.to} per night` 
                }

                return 'Price per night'
            }
            case 'lift': return 'Lift'
            case 'pet_friendly': return 'Pets allowed'
            case 'asap_company': return 'ASAP Quality Accredited Operators'
            default: return null
        }
    }
    
    const getPopupContent = () => {
        switch (filter.type) {
            case 'Range': {
                if (filter.name === 'price') {
                    return (
                        <div>
                            <div style={{ marginBottom: '1rem' }}>
                                {
                                    BAND_RANGES.map((bandRange, index) => {
                                        return (
                                            <div
                                                key={index}
                                                style={{ marginBottom: '0.5rem' }}
                                            >
                                                <Checkbox
                                                    key={index}
                                                    label={
                                                        bandRange.to === Infinity
                                                        ? `£${bandRange.from}+`
                                                        : `£${bandRange.from} - £${bandRange.to}`
                                                    }    
                                                    onChange={(e, { checked }) => {
                                                        // $FlowFixMe
                                                        const newRange = calculateNewRange(filter.value, bandRange, checked)
                                                        onChangeFilter({
                                                            ...filter,
                                                            applied: !_.isEqual(newRange, DEFAULT_PRICE_RANGE),
                                                            value: newRange
                                                        })
                                                    }}
                                                    checked={filter.applied && (
                                                        // $FlowFixMe
                                                        filter.value.from <= bandRange.from &&
                                                        // $FlowFixMe
                                                        filter.value.to >= bandRange.to
                                                    )}
                                                    disabled={filter.applied && (
                                                        // $FlowFixMe
                                                        bandRange.from > filter.value.from &&
                                                        // $FlowFixMe
                                                        bandRange.to < filter.value.to
                                                    )}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                filter.applied &&
                                <div className={style.search__filter__popup__actions}>
                                    <span
                                        className={style.search__filter__popup__action}
                                        onClick={() =>
                                            onChangeFilter({
                                                ...filter,
                                                value: DEFAULT_PRICE_RANGE,
                                                applied: false
                                            })
                                        }
                                    >
                                        Clear
                                    </span>
                                </div>
                            }
                        </div>
                    )
                }

                // ranges with no upper bound
                if (filter.name === 'bedrooms') {
                    return (
                        <div>
                            <NumberInput
                                onChange={(e, { value }) =>
                                    onChangeFilter({
                                        ...filter,
                                        applied: true,
                                        value: {
                                            from: value,
                                            to: null
                                        }
                                    })
                                }
                                // $FlowFixMe
                                value={filter.value.from}
                                name={filter.name}
                                valueLabel='+'
                                min={1}
                                max={99}
                            />
                            {
                                filter.applied &&
                                <div className={style.search__filter__popup__actions}>
                                    <span
                                        className={style.search__filter__popup__action}
                                        onClick={() =>
                                            onChangeFilter({
                                                ...filter,
                                                applied: false
                                            })
                                        }
                                    >
                                        Clear
                                    </span>
                                </div>
                            }
                        </div>
                    )
                }

                return null
            }

            case 'Boolean': {
                return (
                    <div>
                        <Checkbox
                            toggle
                            style={{
                                marginBottom: '1rem'
                            }}
                            onChange={(e, data) => {
                                onChangeFilter({
                                    ...filter,
                                    applied: data.checked,
                                    value: data.checked
                                })
                            }}
                            checked={filter.value}
                        />
                        {
                            filter.applied &&
                            <div className={style.search__filter__popup__actions}>
                                <span
                                    className={style.search__filter__popup__action}
                                    onClick={() =>
                                        onChangeFilter({
                                            ...filter,
                                            applied: false,
                                            value: false
                                        })
                                    }
                                >
                                    Clear
                                </span>
                            </div>
                        }
                    </div>
                )
            }

            default: return null
        }
    }
    
    return (
        <Popup
            basic
            on='click'
            position='bottom left'
            trigger={
                <Button
                    basic={!filter.applied}
                    size='small'
                    color={filter.applied ? 'purple' : undefined}
                    content={getButtonContent()}
                />
            }
            content={getPopupContent()}
        />
    )
}

const HEADLINE_FILTERS = ['bedrooms', 'lift', 'price', 'pet_friendly']

const SearchFilterBar = (props: Props) => {
    const { filters, onChangeFilter, onResetFilters } = props
    // $FlowFixMe (Mixed Object: .applied)
    const filtered = Object.values(filters).some(filter => filter.applied)
    const headlineFilters = _.pick(filters, HEADLINE_FILTERS)
    const moreFilters = _.omit(filters, HEADLINE_FILTERS)
    // $FlowFixMe (Mixed Object: .applied)
    const moreFiltersApplied = Object.values(moreFilters).some(filter => filter.applied)

    return (
        <ErrorBoundary>
            <Segment secondary attached='bottom'>
                {
                    Object.values(headlineFilters).map((filter, index) => {
                        return (
                            <SearchFilterButton
                                key={index}
                                // $FlowFixMe
                                filter={filter}
                                onChangeFilter={onChangeFilter}
                            />
                        )
                    })
                }
                <Modal
                    size='mini'
                    closeIcon
                    trigger={
                        <Button
                            basic={!moreFiltersApplied}
                            size='small'
                            content='More filters'
                            color={moreFiltersApplied ? 'purple' : undefined}
                        />
                    }
                >
                    <Modal.Content scrolling>
                        <Header as='h3'>More filters</Header>
                        <Header>
                            <Header.Subheader>Certifications</Header.Subheader>
                        </Header>
                        <List
                            relaxed
                            selection
                        >
                            <List.Item
                                onClick={() => {
                                    onChangeFilter({
                                        ...moreFilters.asap_company,
                                        applied: !moreFilters.asap_company.value,
                                        value: !moreFilters.asap_company.value
                                    })
                                }}
                            >
                                <Checkbox
                                    style={{
                                        marginRight: '1rem',
                                        verticalAlign: 'bottom'
                                    }}
                                    onChange={(e, data) => {
                                        onChangeFilter({
                                            ...moreFilters.asap_company,
                                            applied: data.checked,
                                            value: data.checked
                                        })
                                    }}
                                    checked={moreFilters.asap_company.value}
                                />
                                <List.Content style={{ display: 'inline-block' }}>
                                    ASAP Quality Accredited Operators
                                </List.Content>
                            </List.Item>
                            <List.Item
                                onClick={() => {
                                    onChangeFilter({
                                        ...moreFilters.isaap_quality,
                                        applied: !moreFilters.isaap_quality.value,
                                        value: !moreFilters.isaap_quality.value
                                    })
                                }}
                            >
                                <Checkbox
                                    style={{
                                        marginRight: '1rem',
                                        verticalAlign: 'bottom'
                                    }}
                                    onChange={(e, data) => {
                                        onChangeFilter({
                                            ...moreFilters.isaap_quality,
                                            applied: data.checked,
                                            value: data.checked
                                        })
                                    }}
                                    checked={moreFilters.isaap_quality.value}
                                />
                                <List.Content style={{ display: 'inline-block' }}>
                                    ISAAP Quality Accreditation
                                </List.Content>
                            </List.Item>
                        </List>
                    </Modal.Content>
                </Modal>
                {
                    filtered &&
                    <Button
                        basic
                        size='small'
                        onClick={onResetFilters}
                        content='Clear Filters'
                        icon='filter'
                    />
                }
            </Segment>
        </ErrorBoundary>
    )
}   

export default SearchFilterBar