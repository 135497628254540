/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

import React, { Fragment } from 'react'
import PricingInput from './PricingInput'

import {
    Button
} from 'semantic-ui-react'

const MultiPricingInput = props => {
    
    function handleChange(event, { index, name, value }) {
        let pricing = props.value.slice()

        pricing[index] = {
            start: value[0].start,
            end: value[0].end,
            price: value[0].price
        }

        props.onChange(event, {
            name,
            value: pricing
        })
    }

    function handleRemoveRow() {
        let pricing = props.value.slice()

        // remove the last row
        pricing.pop()

        // update the end of the new last row
        pricing[pricing.length - 1].end = undefined

        props.onChange(event, {
            name: props.name,
            value: pricing
        })
    }

    function handleAddRow() {
        let pricing = props.value.slice()

        // update the last row
        let lastPrice = pricing.pop()
        lastPrice.end = lastPrice.end ? lastPrice.end + 7 : lastPrice.start + 6
        pricing.push(lastPrice)

        // add a new row
        pricing.push({
            start: lastPrice.end + 1,
            price: 0
        })
    
        props.onChange(event, {
            name: props.name,
            value: pricing
        })
    }

    let pricing = props.value

    if (pricing.length === 0) {
        pricing = [{
            start: 1,
            price: 0
        }]
    }

    return (
        <Fragment>
            {
                pricing.map((rate, index) => {
                    const { start, end, price } = rate
                    const label = end ? `Rate per night from ${start} - ${end} nights` : `Rate per night from ${start}+ nights`
    
                    return (
                        <PricingInput
                            index={index}
                            key={index}
                            label={label}
                            onChange={handleChange}
                            name={props.name}
                            start={start}
                            end={end}
                            value={price}
                        />
                    )
                })
            }
            {
                pricing.length > 1 &&
                <Button
                    size='small'
                    color='red'
                    onClick={handleRemoveRow}
                    content='Remove row'
                />
            }
            {
                parseInt(pricing[pricing.length - 1].price) > 0 &&
                <Button
                    size='small'
                    onClick={handleAddRow}
                    content='Add row'
                />
            }
        </Fragment>
    )
}

export default MultiPricingInput