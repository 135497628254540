/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

// @flow

import type { Attachment, Accommodation, ID } from 'utils/flowtypes/models'

import scrollToElement from 'scroll-to-element'

export function scrollToAnchor(anchor: string)
{
    if (anchor) scrollToElement(anchor, { duration: 250 })
}

export async function getIPAddress(): string {
    let ipAddress = ''
    const response = await fetch('https://api.ipify.org?format=json')
    if (response.status === 200) {
        const data = await response.json()
        ipAddress = data.ip
    }
    // $FlowFixMe
    return ipAddress
}

export function sortAttachments(attachments: Attachment[], attachmentOrder: ?ID[]): Attachment[] {
    let order = attachmentOrder ? attachmentOrder : []
    let sortedAttachments = Array(attachments.length).fill(null)
    let unsortedAttachments = []
    
    attachments.forEach(attachment => {
        const index = order.indexOf(attachment.id)

        if (index === -1) {
            unsortedAttachments.push(attachment)
        } else {
            sortedAttachments.splice(index, 1, attachment)
        }
    })

    // remove any nulls (attachments that probably no longer exist)
    sortedAttachments = sortedAttachments.filter(attachment => attachment)

    return sortedAttachments.concat(unsortedAttachments)
}   

/**
 * Returns an ordered list of accommodation photos
 */
export function getAccommodationPhotos(accommodation: Accommodation): Attachment[] {
    const accommodationPhotos = accommodation.attachments.filter(attachment => attachment.type === 'image')
    const templatePhotos = accommodation.template.attachments.filter(attachment => attachment.type === 'image')
    return sortAttachments(accommodationPhotos.concat(templatePhotos), accommodation.attachmentOrdering)
}

export function formatBytes(bytes: number, decimals:number = 2):string {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}