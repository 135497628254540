/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import { connect } from 'react-redux'

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const GuestGate = (props) => {
    if (props.user)
        return null

    return props.children
}

export default connect(mapStateToProps)(GuestGate)