/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Louis Capitanchik <louis.capitanchik@launchbase.solutions>, December 2018
 */

import React from 'react'
import styles from './chatpanel.less'
import { Segment, Header } from 'semantic-ui-react'

export function Text({ sender, message, ownMessage = true }) {
    const props = ownMessage ?
        {
            textAlign: 'right',
            className: styles.chatpanel__message__self,
            color: 'grey',
        } : {
            textAlign: 'left',
            className: styles.chatpanel__message__other,
            color: 'purple',
        }

    props.children = message.content

    return (
        <Segment color={props.color} className={props.className}>
            <Header color={props.color} inverted>
                { sender.name }
            </Header>
            { props.children }
        </Segment>
    )
}

export function Missing() {
    return <Segment color='grey' inverted />
}

export default {
    Text,
    Missing,
}
