/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, January 2019
 * ==========================================================================
 */

// @flow

import React from 'react'
import { Wizard } from 'components'
import { Button, Icon, Form, Segment, Header, Divider } from 'semantic-ui-react'
 
type Props = {
    step: number,
    activeStep: number,
    stepCount: number,
    onClickNext(): void,
    onClickBack(): void,
    onChange: Function,
    description: string,
    submitting: boolean
}

const getPanel = (props: Props) => (    
    <Wizard.Panel
        step={props.step}
        totalSteps={props.stepCount}
        header='Describe the location'
        nextAction={
            <Button
                onClick={props.onClickNext}
                primary 
                animated
            >
                <Button.Content visible>Done</Button.Content>
                <Button.Content hidden>
                    <Icon loading={props.submitting} name={props.submitting ? 'spinner' : 'check'} />
                </Button.Content>
            </Button>
        }
        previousAction={
            <Button
                onClick={props.onClickBack}
                basic
                color='grey'
                animated
            >
                <Button.Content visible>Back</Button.Content>
                <Button.Content hidden>
                    <Icon name='arrow left' />
                </Button.Content>
            </Button>
        }
    >
        <Form>
            <Form.TextArea
                autoHeight
                name='description'
                value={props.description}
                onChange={props.onChange}
                placeholder='Describe the propety and the surrounding area'
                label='Location description (optional)'
                rows={6}
                style={{
                    maxHeight: '250px'
                }}
            />
        </Form>
    </Wizard.Panel>
)

const AreaDescriptionStep = (props: Props) => {
    return (
        <Wizard.Step
            step={props.step}
            activeStep={props.activeStep}
            panel={getPanel(props)}
            extraPanel={
                <Wizard.ExtraPanel desktopOnly>
                    <Segment padded='very' basic textAlign='center'>
                        <Icon.Group size='massive'>
                            <Icon name='map signs' />
                        </Icon.Group>
                        <Divider hidden />
                        <Header as='h4'>Location</Header>
                        <p>
                            Add a description of the property and the surrounding
                            area for users to get a feel for their stay
                        </p>
                    </Segment>
                </Wizard.ExtraPanel>
            }
        />
    )
}

export default AreaDescriptionStep
