/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, July 2018
 * ========================================================================
 */

import React from 'react'
import { LoadableImage } from 'components'

import {
    Card,
    Image
} from 'semantic-ui-react'

const ImageError = () => {
    return (
        <p>Couldnt load image</p>
    )
}

const ImageLoading = () => {
    return (
        <p>Loading image</p>
    )
}

const SelectableImageCard = ({ src, alt, header, description, onClick, selected, cardClass, imageClass }) => {    
    return (
        <div>
            <Card
                onClick={onClick}
                fluid
                className={cardClass}
            >
                <LoadableImage
                    src={src}
                >
                    <Image
                        fluid
                        alt={alt}
                        label={
                            selected ? {
                                corner:'left',
                                icon:'check',
                                color: 'green'
                            } : null
                        }
                        className={imageClass}
                    />
                    <ImageError />
                    <ImageLoading />
                </LoadableImage>
                {
                    (header || description) &&
                    <Card.Content>
                        {
                            header &&
                            <Card.Header>{header}</Card.Header>
                        }
                        {
                            description &&
                            <Card.Description>{description}</Card.Description>
                        }
                    </Card.Content>
                }
            </Card>
        </div>
    )
}

export default SelectableImageCard