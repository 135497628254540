/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

import React from 'react'
import { Wizard } from './'
import style from './wizard.less'

const Step = ({ step, activeStep, panel, extraPanel }) => {
    if (step !== activeStep)
        return null

    if (panel && panel.type !== Wizard.Panel)
        throw new TypeError(`Expected instance of Panel found ${panel.type.name}`)

    if (extraPanel && extraPanel.type !== Wizard.ExtraPanel)
        throw new TypeError(`Expected instance of ExtraPanel found ${extraPanel.type.name}`)

    return (
        <div className={style.step}>
            { panel }
            { extraPanel }
        </div>
    )
}

export default Step