/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Yunjia Li <yunjia.li@launchbase.solutions>, Nov 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import InvoiceItemList from './InvoiceItemList'
import { FormattedAddress } from 'components'
import { displayPrice } from 'utils/money'
import { Segment, Header, Grid, List, Label, Message} from 'semantic-ui-react'
import type { Invoice, Company } from 'utils/flowtypes/models'

type Props = {
    invoice: Invoice,
    company: Company
}

const InvoiceCard = (props: Props) => {

    const { invoice, company } = props

    let ribbonColour = 'red'
    if(invoice.status === 'pending'){
        ribbonColour = 'yellow'
    }
    else if(invoice.status === 'settled'){
        ribbonColour = 'green'
	}
	
	const totalPayment = Math.abs(invoice.line_items.reduce((total, item) => total + (item.name === 'Payment' ? item.amount_owed : 0), 0))

    return (
        <Segment id={invoice.id}>
            <Label color={ribbonColour} ribbon>
                {invoice.status.toUpperCase()}
            </Label>
            <Header as='h2'>INVOICE</Header>
            <Grid stackable textAlign='left'>
                <Grid.Row verticalAlign='top'>
                    <Grid.Column width={8}>
                        <span>To:</span>
                        <FormattedAddress address={[company.name, company.address].join()} />
                        <br/>
                        {
                            company.vat_number && (<span>VAT {company.vat_number}</span>)
                        }
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <List>
                            <List.Item>
                                <List.Header>Invoice Date:</List.Header>
                                {new Date(invoice.created_at).toDateString()}
                            </List.Item>
                            <List.Item>
                                <List.Header>Due Date:</List.Header>
                                {new Date(invoice.due_date).toDateString()}
                            </List.Item>
                            <List.Item>
                                <List.Header>Reference Number:</List.Header>
                                {invoice.payment_reference}
                            </List.Item>
                            {/* <List.Item>
                                <List.Header>VAT:</List.Header>
                                GB123456789
                            </List.Item> */}
                        </List>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        From:
                        <FormattedAddress address='Roosts.com, 60 Oxford Street, Southampton, Hampshire, United Kingdom, SO14 3DL' />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <InvoiceItemList items={invoice.line_items} currencyId={invoice.currency_id} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row textAlign='right'>
                    <Grid.Column width={10}></Grid.Column>
                    <Grid.Column width={6}>
                        <span><strong>TOTAL: </strong>{displayPrice(invoice.currency_id, invoice.status === 'settled' ? totalPayment : invoice.total)}</span><br/>
                    </Grid.Column>
                </Grid.Row>
				{
					invoice.status === 'settled' && <Grid.Row>
						<Grid.Column width={16}>
						<Message color='green'>
							<Message.Header>This invoice has been fully paid on {new Date(invoice.updated_at).toDateString()}</Message.Header>
						</Message>
						</Grid.Column>
					</Grid.Row>
				}
            </Grid>
        </Segment>
    )
}

export default InvoiceCard