/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Nov 2018
 * ========================================================================
 */

// @flow

import type { PriceRange, PriceRanges, Price } from 'utils/flowtypes/models'

export function getCurrencySymbol(currencyId: string): string {
    switch (currencyId) {
        case 'GBP': return '£'
        case 'EUR': return '€'
        default: return '$'
    }
}

/**
 * Transform a pricing string into it's base unit value
 */
export function parseDisplayPrice(currencyId: string, amount: ?string|number): number {
    if (amount == null)
        return 0
    
    switch (currencyId) {
        case 'GBP': return Math.round(parseFloat(amount) * 100)
        case 'USD': return Math.round(parseFloat(amount) * 100)
        case 'EUR': return Math.round(parseFloat(amount) * 100)
        default: return Math.round(parseFloat(amount) * 100)
    }
}

/**
 * Transform a base unit price into it's currency representation
 */
export function displayPrice(currencyId: string, amount: number, symbol: boolean = true, useGrouping: boolean = true): string {
    switch (currencyId) {
        case 'GBP':
            return new Intl.NumberFormat('en-GB', {
                style: symbol ? 'currency' : 'decimal',
                currency: 'GBP',
                useGrouping
            }).format(amount / 100)
        case 'USD':
            return new Intl.NumberFormat('en-US', {
                style: symbol ? 'currency' : 'decimal',
                currency: 'USD',
                useGrouping
            }).format(amount / 100)
        case 'EUR':
            return new Intl.NumberFormat('en-GB', {
                style: symbol ? 'currency' : 'decimal',
                currency: 'EUR',
                useGrouping
            }).format(amount / 100)
        default:
            return new Intl.NumberFormat('en-US', {
                style: symbol ? 'currency' : 'decimal',
                currency: 'USD',
                useGrouping
            }).format(amount / 100)
    }
}

export function getLowestPriceRange(priceRanges: PriceRanges): ?PriceRange {
    let lowestPriceRange
    
    priceRanges.forEach(priceRange => {
        if (!lowestPriceRange || priceRange.price < lowestPriceRange.price) {
            lowestPriceRange = priceRange
        }
    })

    return lowestPriceRange
}

export function displayPriceFromPriceObject(price: Price, symbol: boolean = true): string {
    return displayPrice(price.currency, price.price, symbol)
}

export function getPriceRangeForDuration(priceRanges: PriceRanges, duration: number): ?PriceRange {
    return priceRanges.find(priceRange => {
        return (
            (duration >= priceRange.start && duration <= priceRange.end) ||
            (duration >= priceRange.start && priceRange.end === null)
        )
    })
}