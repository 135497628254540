/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Oct 2018
 * ========================================================================
 */

import React, { PureComponent, Fragment } from 'react'
import { Modal, Button, Form, Icon, Divider } from 'semantic-ui-react'
import { FeatureInput } from 'components'
import { getAccommodation, updateAccommodation } from 'queries/accommodation'
import { withApollo, graphql } from 'react-apollo'
import { showErrorNotification, showSuccessNotification } from 'utils/notifications'
import { FeatureSearchDropdown } from 'components'
import style from './featuremodal.less'
import { FullFeatureList } from 'components/ManageAccommodation'

type Props = {
    accommodation: Object,
    open: boolean,
    onClose: Function,
    updateAccommodation: Function,
    activeFeatureGroup: string
}

type State = {
    featureId: string,
    activeStep: number,
}

class AddFeatureModal extends PureComponent<Props, State> {
    state = {
        featureId: '',
        activeStep: 0,
        featureValue: '',
        feature: null
    }

    handleChange = (e, { value }) => {
        this.setState({ featureValue: value })
    }

    handleChangeFeature = (featureId, featureDefinition) => {
        this.setState({
            featureId,
            feature: {
                id: featureDefinition.id,
                definition: featureDefinition,
                type: featureDefinition.type
            },
            featureValue: featureDefinition.type === 'boolean' ? true : ''
        })
    }

    handleViewFullList = () => {
        this.setState({ activeStep: 2 })
    }

    handleBackButton = () => {
        this.setState({ activeStep: 0 })
    }

    handleNextButton = () => {
        this.setState({ activeStep: 1 })
    }

    handleSave = async () => {
        const feature = {
            featureId: this.state.featureId,
            value: this.state.featureValue.toString(),
            onDemand: false
        }

        const response = await this.props.updateAccommodation({
            variables: {
                accommodationId: this.props.accommodation.id,
                features: [feature]
            },
            refetchQueries: [{
                query: getAccommodation,
                variables: { accommodationId: this.props.accommodation.id }
            }]
        }).catch(e => e)

        if (response instanceof Error) {
            showErrorNotification('Unable to update accommodation.')
            console.error(response)
        } else {
            showSuccessNotification('Updated successfully.')
            this.setState({ 
                featureId: '',
                activeStep: 0,
                featureValue: '',
                feature: null
            })
            this.props.onClose()
        }
    }

    handleClose = () => {
        this.setState({
            featureId: '',
            activeStep: 0,
            featureValue: '',
            feature: null
        })

        this.props.onClose()
    }

    handleFeatureClick = async (feature, value) => {
        if (feature.type === 'number' || feature.type === 'string') {
            this.setState({
                featureId: feature.id,
                feature,
                activeStep: 1
            })
        } else {
            let variables = {
                accommodationId: this.props.accommodation.id
            }
    
            switch (feature.type) {       
                case 'boolean': {
                    variables.features = [{
                        featureId: feature.id,
                        value: (!value).toString(),
                        onDemand: false
                    }]
                    break;
                }
    
                default: {
                    variables.features = [{
                        featureId: feature.id,
                        value: value.toString(),
                        onDemand: false
                    }]
                    break;
                }
            }

            const response = await this.props.updateAccommodation({
                variables,
                refetchQueries: [{
                    query: getAccommodation,
                    variables: { accommodationId: this.props.accommodation.id }
                }]
            }).catch(e => e)
            
            if (response instanceof Error) {
                console.error(response)
            }
        }
    }

    render() {
        const { open, accommodation } = this.props
        let { activeFeatureGroup } = this.props
        const { featureId, feature, activeStep, featureValue } = this.state

        if (activeFeatureGroup === 'details') {
            activeFeatureGroup = 'description'
        }

        return (
            <Modal
                open={open}
                size='small'
            >
                {
                    activeStep === 0 && (
                        <Fragment>
                            <Modal.Header>Add Details</Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <FeatureSearchDropdown
                                        onChange={this.handleChangeFeature}
                                        value={featureId}
                                        label='Search details to add'
                                        activeFeatureGroup={activeFeatureGroup}
                                    />
                                    <Divider hidden />
                                    <p>
                                        <span onClick={this.handleViewFullList} className={style.linktext}><Icon name='unordered list' /> See full list</span>
                                    </p>
                                </Form>
                                
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    onClick={this.handleClose}
                                    basic
                                    primary
                                >
                                    Cancel
                                </Button>
                                <Button
                                    primary
                                    disabled={!featureId}
                                    onClick={this.handleNextButton}
                                >
                                    Next
                                </Button>
                            </Modal.Actions>
                        </Fragment>
                    )
                }
                {
                    activeStep === 1 && (
                        <Fragment>
                            <Modal.Header>Feature Details</Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <FeatureInput
                                        feature={feature}
                                        type={feature.type}
                                        value={featureValue}
                                        onChange={this.handleChange}
                                    />
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    onClick={this.handleBackButton}
                                    basic
                                >
                                    Back
                                </Button>
                                <Button
                                    primary
                                    onClick={this.handleSave}
                                    content={feature.definition.type === 'boolean' ? 'Add' : 'Save'}
                                />
                            </Modal.Actions>
                        </Fragment>
                    )
                }
                {
                    activeStep === 2 && (
                        <Fragment>
                            <Modal.Header>Feature List</Modal.Header>
                            <Modal.Content scrolling>
                                <FullFeatureList
                                    accommodationFeatures={accommodation.features}
                                    onFeatureClick={this.handleFeatureClick}
                                />
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    onClick={this.handleBackButton}
                                    basic
                                >
                                    Back
                                </Button>
                                <Button
                                    primary
                                    onClick={this.handleClose}
                                >
                                    Done
                                </Button>
                            </Modal.Actions>
                        </Fragment>
                    )
                }
            </Modal>
        )
    }
}

export default graphql(
    updateAccommodation,
    {
        name: 'updateAccommodation'
    }
)(withApollo(AddFeatureModal))