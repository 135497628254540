/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by <Yunjia Li <yunjia.li@launchbase.solutions> Dec 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import ErrorBoundary from 'ErrorBoundary'
import { Link } from 'react-router-dom'
import { GuestLabel } from 'components'
import { getInvoiceNotificationText } from 'utils'
import { randomString } from 'utils'
import { Tab, Table, Message } from 'semantic-ui-react'
import type { Bookings } from 'utils/flowtypes/models'

type Props = {
    bookings: Bookings,
    provider: ?boolean
}

const BookingList = (props: Props) => {
    const { bookings, provider } = props

    if (bookings.length === 0) {
        return (
            <Tab.Pane as='div'>
                <Message info>
                    <p>
                        No bookings found.
                    </p>
                </Message>
            </Tab.Pane>
        )
    }

    return (
        <ErrorBoundary>
            <div>
                <Table basic='very' compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Reference</Table.HeaderCell>
                            <Table.HeaderCell>Accommodation</Table.HeaderCell>
                            <Table.HeaderCell>Time</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Price</Table.HeaderCell>
                            <Table.HeaderCell>Guests</Table.HeaderCell>
                            <Table.HeaderCell>Payment</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            bookings.map(booking => {
                                return (
                                    <Table.Row key={booking.id} warning={booking.status === 'pending'}>
                                        <Table.Cell>
                                            <Link to={provider ? `/provider/bookings/${booking.id}` : `/booker/bookings/${booking.id}`}>
                                                { booking.reference }
                                            </Link>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {
                                                booking.accommodation &&
                                                <Link to={`/listings/${booking.accommodation.id}`}>
                                                    {booking.accommodation.name}, {booking.accommodation.property.name}
                                                </Link>
                                            }
                                        </Table.Cell>
                                        <Table.Cell>
                                            {
                                                booking.time.map(t => {
                                                    return (
                                                        <span key={t.id}>
                                                            {new Date(t.start).toDateString()} - {new Date(t.end).toDateString()}
                                                        </span>
                                                    )
                                                })
                                            }
                                        </Table.Cell>
                                        <Table.Cell>
                                            {booking.status}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {booking.booking_currency} {booking.price}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {
                                                booking.guests.map(g=>{
                                                    return(
                                                        <GuestLabel guest={g} key={`${g.id}_${randomString()}`}/>
                                                    )
                                                })
                                            }
                                        </Table.Cell>
                                        <Table.Cell>
                                            {getInvoiceNotificationText(booking.invoices)}
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })
                        }
                    </Table.Body>
                </Table>
            </div>
        </ErrorBoundary>
    )
}

export default BookingList