/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Feb 2019
 * ========================================================================
 */

import gql from 'graphql-tag'

export const certificationDefinitions = gql`
    query{
        certificationDefinitions{
            id
            name
            short_code
            model_types
            possible_values
            expires
            default_expiry
            certificate_required
            meta
        }
    }
`

export const addPropertyCertification = gql`
    mutation(
        $propertyId: ID!
        $definitionId: ID!
        $value: String!
        $issuedAt: Timestamp!
        $expiresAt: Timestamp
        $attachment: AttachmentInput
    ){
        addPropertyCertification(
            property_id: $propertyId
            definition_id: $definitionId
            value: $value
            issued_at: $issuedAt
            expires_at: $expiresAt
            attachment_args: $attachment
        ){
            id
            name
            value
            approved
            issued_at
            expires_at
            definition{
                id
                name
                short_code
                model_types
                possible_values
                expires
                default_expiry
                certificate_required
                meta
            }
            meta
        }
    }
`

export const addAccommodationCertification = gql`
    mutation(
        $accommodationId: ID!
        $definitionId: ID!
        $value: String!
        $issuedAt: Timestamp!
        $expiresAt: Timestamp
        $attachment: AttachmentInput
    ){
        addAccommodationCertification(
            accommodation_id: $accommodationId
            definition_id: $definitionId
            value: $value
            issued_at: $issuedAt
            expires_at: $expiresAt
            attachment_args: $attachment
        ){
            id
            name
            value
            approved
            issued_at
            expires_at
            definition{
                id
                name
                short_code
                model_types
                possible_values
                expires
                default_expiry
                certificate_required
                meta
            }
            meta
        }
    }
`

export const addCompanyCertification = gql`
    mutation(
        $companyId: ID!
        $definitionId: ID!
        $value: String!
        $issuedAt: Timestamp!
        $expiresAt: Timestamp
        $attachment: AttachmentInput
    ){
        addCompanyCertification(
            company_id: $companyId
            definition_id: $definitionId
            value: $value
            issued_at: $issuedAt
            expires_at: $expiresAt
            attachment_args: $attachment
        ){
            id
            name
            value
            approved
            issued_at
            expires_at
            definition{
                id
                name
                short_code
                model_types
                possible_values
                expires
                default_expiry
                certificate_required
                meta
            }
            meta
        }
    }
`

export const deleteCertification = gql`
    mutation(
        $certificationId: ID!
    ){
        deleteCertification(
            certification_id: $certificationId
        )
    }
`

export const approveCertification = gql`
    mutation(
        $certificationId: ID!
        $note: NoteInput
    ){
        approveCertification(
            certification_id: $certificationId
            note: $note
        ){
            id
        }
    }
`

export const rejectCertification = gql`
    mutation(
        $certificationId: ID!
        $note: NoteInput
    ){
        rejectCertification(
            certification_id: $certificationId
            note: $note
        ){
            id
        }
    }
`

export const certifications = gql`
    query(
        $id: ID
        $modelId: ID
        $type: String
        $approved: ApprovedEnum
        $page: Int
        $perPage: Int
    ){
        certifications(
            id: $id
            model_id: $modelId
            type: $type
            approved: $approved
            page: $page
            per_page: $perPage
        ){
            list{
                id
                name
                value
                model_type
                model_id
                approved
                issued_at
                expires_at
                company{
                    id
                    name
                    homepage
                    contact_number
                    address
                }
                definition{
                    id
                    name
                    possible_values
                    expires
                }
                certificates{
                    id
                    url
                }
                meta
                notes{
                    id
                    contents
                    tags
                }
            }
            pagination{
                count
                total_pages
                page
            }
        }
    }
`