/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Louis Capitanchik <louis.capitanchik@launchbase.solutions>, December 2018
 */

import React from 'react'
import { connect } from 'react-redux'

import ChatPanel from './ChatPanel'
import { withChat } from './ChatProvider'

import { graphql, withApollo } from 'react-apollo'
import { notifyChat } from 'queries/chat'

type Props = {
    conversationId: string,
    notifyOptions?: {
        type: string,
        id: string,
    },
    readOnly?: ?boolean,
    hideActions?: boolean
}

class ChatWidget extends React.PureComponent<Props> {

    constructor(props) {
        super(props)
        this.state = {
            chat: null,
            chatOpen: props.initialOpen || false,
            messages: [],
            loading: true
        }
    }

    componentDidMount() {
        this._mountWhippet()
    }

    render() {
        return (
            <React.Fragment>
                { this.props.children(this._toggleChat) }
                <ChatPanel
                    active={this.state.chatOpen}
                    messages={this.state.messages}
                    chat={this.state.chat}
                    onMessageSent={this._handleMessageSent}
                    renderContainer={this.props.renderContainer}
                    readOnly={this.props.readOnly}
                    loading={this.state.loading}
                    toggle={this._toggleChat}
                    hideActions={this.props.hideActions}
                />
            </React.Fragment>
        )
    }

    async _mountWhippet() {
        this.setState(() => ({ loading: true }))
        const chat = this.props.whippet.channel(this.props.conversationId)
        await this._loadChat(chat)
    }

    _loadChat = async (chat) => {
        const messages = await chat.search()
        chat.on('message', this._handleMessage)
        this.setState({ chat, messages: messages.reverse(), loading: false })
    }

    _handleMessage = (data, shift = false) => {
        this.setState(s => {
            if (s.messages.find(m => m.message.id === data.message.id)) {
                return null
            }
            return ({ messages: shift ? [data].concat(s.messages) : s.messages.concat([data]) })
        })
    }

    _toggleChat = () => this.setState(s => ({ chatOpen: !s.chatOpen }))

    _handleMessageSent = (message) => {
        this.setState(s => ({ messages: s.messages.concat([message]) }))
        this._notifyServer()
    }

    _notifyServer() {
        if (this.props.notifyOptions) {
            this.props.notifyChat({
                variables: {
                    id: this.props.conversationId,
                    type: this.props.notifyOptions.type,
                    model_id: this.props.notifyOptions.id,
                },
            })
        }
    }
}

const userFromStore = state => ({ user: state.user })

export default graphql(
    notifyChat,
    {
        name: 'notifyChat',
        options: { fetchPolicy: 'no-cache' },
    }
)(withApollo(withChat(connect(userFromStore)(ChatWidget))))
