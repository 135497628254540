/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Oct 2018
 * ========================================================================
 */

// @flow

import React, { Fragment, PureComponent } from 'react'
import { Widget, RatesInput } from 'components'
import { Table, Modal, Button, Form } from 'semantic-ui-react'
import { showErrorNotification } from 'utils/notifications'
import { transformPriceRangeToPricingInput } from 'utils/pricing'
import { setAccommodationPricing, getAccommodation } from 'queries/accommodation'
import { withApollo, graphql } from 'react-apollo'
import type { SemanticEvent, SemanticEventProps } from 'utils/flowtypes/semantic'
import type { PriceRanges, PricingInputs } from 'utils/flowtypes/models' 
import { displayPrice, parseDisplayPrice } from 'utils/money'

type Props = {
    accommodationId: string,
    pricing: PriceRanges,
    setAccommodationPricing: Function
}

type State = {
    modalActive: boolean,
    pricingInput: PricingInputs,
    derivedStateFromProps: boolean,
    currency: string
}

class RatesWidget extends PureComponent<Props, State> {
    state = {
        modalActive: false,
        pricingInput: [],
        name: 'pricing',
        derivedStateFromProps: false,
        currency: 'GBP'
    }
    
    static getDerivedStateFromProps(props: Props, state: State) {
        if (!state.derivedStateFromProps) {
            state.pricingInput = transformPriceRangeToPricingInput(state.currency, props.pricing)
            state.derivedStateFromProps = true
            return state
        }

        return null
    }

    handleOpenModal = () => this.setState({ modalActive: true })

    handleCloseModal = () => this.setState({ modalActive: false })

    handleChange = (e: SemanticEvent, props: SemanticEventProps) => {
        this.setState({ [props.name]: props.value })
    }

    handleSave = async () => {
        const priceList = this.state.pricingInput.map(price => {
            if (price.price || price.price === 0) {
                return {
                    start: price.start,
                    end: price.end,
                    price: parseDisplayPrice(this.state.currency, price.price)
                }
            }
        })

        const response = await this.props.setAccommodationPricing({
            variables: {
                accommodationId: this.props.accommodationId,
                priceList,
                currencyId: this.state.currency
            },
            refetchQueries: [{
                query: getAccommodation,
                variables: { accommodationId: this.props.accommodationId }
            }]
        }).catch(e => e)

        if (response instanceof Error) {
            showErrorNotification('Unable to update pricing')
            console.error(response)
        } else {
            this.handleCloseModal()
        }
    }

    render() {
        const { modalActive, pricingInput, currency } = this.state
        const { pricing } = this.props
        const saveButtonDisabled = pricingInput.length !== 4 ||
            !(pricingInput.every(priceInput => priceInput.price))

        return (
            <Fragment>
                <Widget
                    header='Rates'
                    icon='money'
                    action={{
                        basic: true,
                        icon: 'pencil',
                        content: 'Change',
                        onClick: this.handleOpenModal
                    }}
                >
                    <Table basic='very' compact selectable>
                        <Table.Body>
                            {
                                pricing.map((priceRange, index) => {
                                    return (
                                        <Table.Row key={index} onClick={this.handleOpenModal}>
                                            <Table.Cell>
                                                {
                                                    priceRange.end ?
                                                    <strong>{priceRange.start} - {priceRange.end} nights</strong> :
                                                    <strong>{priceRange.start}+ nights</strong>
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                {displayPrice(priceRange.currency.id, priceRange.price)}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </Table>
                </Widget>
                <Modal
                    open={modalActive}
                    size='tiny'
                >
                    <Modal.Header>Change Rates</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <RatesInput
                                currency={currency}
                                value={pricingInput}
                                name='pricingInput'
                                onChange={this.handleChange}
                                min={0}
                            />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            onClick={this.handleCloseModal}
                            basic
                            primary
                        >
                            Cancel
                        </Button>
                        <Button
                            primary
                            disabled={saveButtonDisabled}
                            onClick={this.handleSave}
                        >
                            Save
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Fragment>
        )
    }
}

export default graphql(
    setAccommodationPricing,
    {
        name: 'setAccommodationPricing'
    }
)(withApollo(RatesWidget))