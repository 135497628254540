exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/**\n * Copyright (C) LaunchBase LTD - All Rights Reserved \n * Unauthorized copying of this file, via any medium is strictly prohibited \n * Proprietary and confidential \n * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018\n * ========================================================================\n */\n.output__3F-H4 {\n  width: 100%;\n  max-width: 700px;\n  margin: 0 auto;\n}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/Output/output.less"],"names":[],"mappings":"AAAA;;;;;;GAMG;AACH;EACE,YAAY;EACZ,iBAAiB;EACjB,eAAe;CAChB","file":"output.less","sourcesContent":["/**\n * Copyright (C) LaunchBase LTD - All Rights Reserved \n * Unauthorized copying of this file, via any medium is strictly prohibited \n * Proprietary and confidential \n * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018\n * ========================================================================\n */\n.output {\n  width: 100%;\n  max-width: 700px;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"output": "output__3F-H4"
};