/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { featureDefinitions } from 'queries/accommodation'
import { Loader, Item, List } from 'semantic-ui-react'
import ListItem from './ListItem'
import style from '../featurelists.less'
import type { Features, FeatureDefinitions } from 'utils/flowtypes/models'
import type { ApolloQuery } from 'utils/flowtypes/apollo'

type ListSectionProps = {
    heading: string,
    featureDefinitions: FeatureDefinitions,
    accommodationFeatures?: Features,
    onFeatureClick: Function
}

const ListSection = (props: ListSectionProps) => {
    const {
        heading,
        featureDefinitions,
        accommodationFeatures = [],
        onFeatureClick
    } = props

    return (
        <Item.Group>
            <Item>
                <Item.Content>
                    <Item.Meta className={style.featurelists__section__heading}>{ heading }</Item.Meta>
                    <Item.Description>
                        <List
                            className={style.featurelists__list}
                            selection
                            verticalAlign='middle'
                        >
                            {
                                featureDefinitions.map(featureDefinition => {
                                    const accommodationFeature = accommodationFeatures.find(feature => {
                                        return feature.definition.shortcode === featureDefinition.shortcode
                                    })

                                    return (
                                        <ListItem
                                            key={featureDefinition.id}
                                            onClick={onFeatureClick}
                                            featureDefinition={featureDefinition}
                                            accommodationFeature={accommodationFeature}
                                        />
                                    )
                                })
                            }
                        </List>
                    </Item.Description>
                </Item.Content>
            </Item>
        </Item.Group>
    )
}

type Props = {
    featureDefinitions: {
        featureDefinitions: FeatureDefinitions
    } & ApolloQuery,
    accommodationFeatures: Features,
    onFeatureClick: Function,
    activeFeatureList?: string
}

const FullFeatureList = (props: Props) => {
    if (props.featureDefinitions.loading) {
        return (
            <Loader active />
        )
    }

    const featureDefinitions = props.featureDefinitions.featureDefinitions
    const featureGroups = ['rooms', 'description', 'appliances', 'housekeeping', 'amenities', 'additional services', 'complimentaries']
    const { accommodationFeatures = [], onFeatureClick } = props

    return (
        <div>
            {
                featureGroups.map(featureGroup => (
                    <ListSection
                        key={featureGroup}
                        featureDefinitions={featureDefinitions.filter(definition => definition.group === featureGroup)}
                        accommodationFeatures={accommodationFeatures}
                        heading={featureGroup}
                        onFeatureClick={onFeatureClick}
                    />
                ))
            }
        </div>
    )
}

export default compose(
    graphql(
        featureDefinitions,
        {
            name: 'featureDefinitions',
            options: { fetchPolicy: 'network-only' }
        }
    ),
)(withApollo(FullFeatureList))