/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Louis Capitanchik <louis.capitanchik@launchbase.solutions>, December 2018
 */

// @flow

import { WhippetClient, WhippetChannel } from './WhippetSDK'

class ChatManager {
    client: WhippetClient

    constructor(appId: string, wsHost: string, apiHost: string) {
        this.client = new WhippetClient(appId, {
            ws: {
                host: wsHost,
                tls: true,
            },
            api: {
                host: apiHost,
                tls: true,
            },
        })
    }

    channel(id: string): WhippetChannel {
        return this.client.channel(id)
    }
}

export default ChatManager
