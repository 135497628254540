/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN'
export const SET_SESSION_EXPIRATION = 'SET_SESSION_EXPIRATION'
export const SET_SESSION_GEODATA = 'SET_SESSION_GEODATA'

export function setAccessToken(accessToken) {
    return {
        type: SET_ACCESS_TOKEN,
        accessToken
    }
}

export function setRefreshToken(refreshToken) {
    return {
        type: SET_REFRESH_TOKEN,
        refreshToken
    }
}

export function setSessionExpiration(sessionExpiresAt) {
    return {
        type: SET_SESSION_EXPIRATION,
        sessionExpiresAt
    }
}

export function setSessionGeodata(sessionGeodata) {
    return {
        type: SET_SESSION_GEODATA,
        sessionGeodata
    }
}