/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, July 2018
 * ========================================================================
 * An rewrite of Deedmob's https://github.com/DeedMob/react-load-image
 */

import React, { PureComponent, Fragment } from 'react'
import ErrorBoundary from 'ErrorBoundary'

const Status = {
    PENDING: 'pending',
    LOADING: 'loading',
    LOADED: 'loaded',
    FAILED: 'failed',
  };

class LoadableImage extends PureComponent {    
    constructor(props) {
        super(props)
        this.state = {
            status: props.src ? Status.LOADING : Status.PENDING
        }

        if (props.children.length !== 3)
            throw Error(`Three child components required, found ${props.children.length}`)
    }

    componentDidMount() {
        if (this.state.status === Status.LOADING) {
            this.createLoadable()
        }
    }

    componentDidUpdate() {
        if (this.state.status === Status.LOADING && !this.img) {
            this.createLoadable()
        }
    }

    componentWillUnmount() {
        this.destroyLoadable()
    }

    createLoadable = () => {
        this.destroyLoadable()

        this.img = new Image()
        this.img.onload = this.handleLoad
        this.img.onerror = this.handleError
        this.img.src = this.props.src
        this.img.srcSet = this.props.srcSet || this.props.src
    }

    destroyLoadable = () => {
        if (this.img) {
            this.img.onload = null
            this.img.onerror = null
            this.img = null
        }
    }

    handleLoad = event => {
        this.destroyLoadable()
        this.setState({ status: Status.LOADED })

        if (this.props.onLoad) this.props.onLoad(event)
    }

    handleError = error => {
        this.destroyLoadable()
        this.setState({ status: Status.FAILED })

        if (this.props.onError) this.props.onError(error)
    }

    render() {
        const { src, srcSet } = this.props

        return (
            <ErrorBoundary>
                <Fragment>
                    {
                        this.state.status === Status.LOADED &&
                        React.cloneElement(this.props.children[0], { src, srcSet })
                    }
                    {
                        this.state.status === Status.FAILED &&
                        this.props.children[1]
                    }
                    {
                        (this.state.status === Status.LOADING || this.state.status === Status.PENDING) &&
                        this.props.children[2]
                    }
                </Fragment>
            </ErrorBoundary>
        )
    }
}

export default LoadableImage