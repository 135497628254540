/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import { Route, Redirect } from 'react-router-dom'
import React from 'react'
import ErrorBoundary from 'ErrorBoundary'
import { connect } from 'react-redux'

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const UnauthorisedRoute = ({ component: Component, ...rest }) => {
    return (
        <ErrorBoundary>
            <Route
                {...rest}
                render={
                    (props) => !rest.user
                    ? <Component {...props} />
                    : <Redirect to={{ pathname: '/', state: { from: props.location }}
                } />}
            />
        </ErrorBoundary>
    )
}

export default connect(mapStateToProps)(UnauthorisedRoute)