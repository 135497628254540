/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */


import gql from 'graphql-tag'

export const getListingSearchSuggestions = gql`
    query(
        $stem:String!,
        $quantity:Int,
        $nearby:GeoJSONPointInput
    ){
        getCityPredictions(
            stem: $stem,
            quantity: $quantity,
            nearby: $nearby
        ){
            name
            country_code
            admin_code_1
        }
    }
`

export const searchListings = gql`
    query(
        $place: String!
        $location: RadiusSearch
        $name: String
        $features: [FeatureSearchInput!]
        $certifications: [CertificationSearchInput!]
        $sort: QuerySortInput
        $available: DateRangeInput
        $price: PriceRangeInput
        $pagination: CursorPaginationInput
    ){
        searchAccommodationsv2(
            place: $place
            location: $location
            name: $name
            features: $features
            certifications: $certifications
            sort: $sort
            available: $available
            price: $price
            pagination: $pagination
        ){
            cursor
            data{
                id
                name
                property{
                    id
                    name
                    address
                    location{
                        latitude
                        longitude
                    }
                    company{
                        id
                        name
                        certifications(
                            active: true
                        ){
                            id
                            name
                            value
                            definition{
                                id
                                short_code
                                possible_values
                            }
                        }
                    }
                }
                features{
                    id
                    value
                    definition{
                        id
                        name
                        shortcode
                        meta
                    }
                    name
                    on_demand
                }
                template{
                    id
                    attachments{
                        id
                        name
                        description
                        type
                        url
                    }
                }
                attachments{
                    id
                    name
                    description
                    type
                    url
                }
                pricing{
                    start
                    end
                    price
                    currency{
                        id
                        name
                    }
                }
                attachmentOrdering(type: image)
                created_at
                updated_at
            }
        }
    }
`

export const searchListingsV1 = gql`
    query(
        $location: GeoJSONPointInput,
        $locationRadius: Float,
        $place: String,
        $maxPricePerDay: Int
    ){
        searchAccommodations(
            location: $location,
            location_radius: $locationRadius,
            place: $place,
            max_price_per_day: $maxPricePerDay
        ){
            id
            name
            property{
                id
                name
                address
                location{
                    latitude
                    longitude
                }
                company{
                    id
                    name
                }
            }
            features{
                id
                value
                definition{
                    id
                    name
                    shortcode
                    meta
                }
                name
                on_demand
            }
            template{
                id
                attachments{
                    id
                    name
                    description
                    type
                    url
                }
            }
            attachments{
                id
                name
                description
                type
                url
            }
            pricing{
                start
                end
                price
                currency{
                    id
                    name
                }
            }
            created_at
            updated_at
        }
    }
`

export const listAccommodation = gql`
    mutation(
        $accommodationId: ID!
    ){
        listAccommodation(
            apartment_id: $accommodationId
        ){
            id
        }
    }
`

export const unlistAccommodation = gql`
    mutation(
        $accommodationId: ID!
    ){
        unlistAccommodation(
            apartment_id: $accommodationId
        ){
            id
        }
    }
`


export const getListing = gql`
    query(
        $accommodationId: ID!
        $availabilityFrom: Timestamp!
        $availabilityTo: Timestamp!
    ){
        accommodation(
            id: $accommodationId
        ){
            id
            name
            listed
            approved
            public
            features{
                id
                value
                name
                type
                country
                on_demand
                definition{
                    id
                    name
                    type
                    shortcode
                    group
                    active
                    meta
                }
                pricing{
                    start
                    end
                    price
                }
            }
            pricing{
                start
                end
                price
                currency{
                    id
                    name
                }
            }
            property{
                id
                name
                address
                description
                company{
                    id
                    name
                }
                country{
                    iso2
                    name
                }
                location{
                    longitude
                    latitude
                }
            }
            template{
                id
                name
                attachments{
                    id
                    description
                    type
                    url
                }
                features{
                    id
                    value
                    name
                    type
                    country
                    on_demand
                    definition{
                        id
                        name
                        type
                        shortcode
                        group
                        active
                        meta
                    }
                    pricing{
                        start
                        end
                        price
                    }
                }
            }
            attachments{
                id
                name
                description
                type
                url
            }
            attachmentOrdering(type: image)
            cancellation_policies{
                id
                duration
                allow_instant
                refundable
                group_varying
                meta
            }
            certifications(
                active: true
            ){
                name
                value
                definition{
                    short_code
                }
            }
            availability(
                from: $availabilityFrom
                to: $availabilityTo
            ){
                id
                start
                end
                model_type
                model_id
                meta
            }
        }
    }
`