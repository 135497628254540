exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/**\n * Copyright (C) LaunchBase LTD - All Rights Reserved \n * Unauthorized copying of this file, via any medium is strictly prohibited \n * Proprietary and confidential \n * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018\n * ==========================================================================\n */\n/* responsive settings */\n/* common widths */\n/* colors */\n/* whitespace */\n/* font-sizes */\n/* border-radius */\n/* forms */\n.timelinecalendar__group__label__3NtzN {\n  line-height: 1.5rem;\n  height: 2rem;\n}\n.timelinecalendar__group__label__subtitle___TbtF {\n  color: rgba(0, 0, 0, 0.7);\n}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/TimelineCalendar/timelinecalendar.less"],"names":[],"mappings":"AAAA;;;;;;GAMG;AACH,yBAAyB;AACzB,mBAAmB;AACnB,YAAY;AACZ,gBAAgB;AAChB,gBAAgB;AAChB,mBAAmB;AACnB,WAAW;AACX;EACE,oBAAoB;EACpB,aAAa;CACd;AACD;EACE,0BAA0B;CAC3B","file":"timelinecalendar.less","sourcesContent":["/**\n * Copyright (C) LaunchBase LTD - All Rights Reserved \n * Unauthorized copying of this file, via any medium is strictly prohibited \n * Proprietary and confidential \n * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018\n * ==========================================================================\n */\n/* responsive settings */\n/* common widths */\n/* colors */\n/* whitespace */\n/* font-sizes */\n/* border-radius */\n/* forms */\n.timelinecalendar__group__label {\n  line-height: 1.5rem;\n  height: 2rem;\n}\n.timelinecalendar__group__label__subtitle {\n  color: rgba(0, 0, 0, 0.7);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"timelinecalendar__group__label": "timelinecalendar__group__label__3NtzN",
	"timelinecalendar__group__label__subtitle": "timelinecalendar__group__label__subtitle___TbtF"
};