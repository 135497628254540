/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import gql from 'graphql-tag'

export const startSecureUpload = gql`
    query(
        $contentType: String!
    ){
        startSecureUpload(
            content_type: $contentType
        ){
            url
            id
        }
    }
`

export const finishSecureUpload = gql`
    mutation(
        $fileId: ID!
        $type: String
    ){
        finishSecureUpload(
            file_id: $fileId
            type: $type
        ){
            id
            approved
        }
    }
`

export const secureDocuments = gql`
    query(
        $types: [String!]
        $statuses: [ApprovedEnum!]
        $id: ID
        $order: SecureDocumentSortOrderEnum
    ){
        self{
            id
            secure_documents(
                id: $id
                types: $types
                statuses: $statuses
                order: $order
            ){
                id
                approved
                type
                notes{
                    id
                    contents
                    tags
                }
            }
        }
    }
`


export const usersWithPendingDocuments = gql`
    query{
        users(
            has_pending_documents: true
        ){
            pagination{
                count
                total_pages
                page
                next_page
                previous_page
                per_page
            }
            list{
                id
                name
                date_of_birth
                email
                approved
                company{
                    id
                    name
                    address
                }
                secure_documents(
                    status: pending
                    types: ["identity_front", "identity_back"]
                    order: LastUpdated
                ){
                    id
                    approved
                    type
                }
            }
        }
    }
`

export const getSecureDownloadUrl = gql`
    query(
        $fileId: ID!
    ){
        getSecureDownloadUrl(
            file_id: $fileId
        )
    }
`

export const approveSecureDocument = gql`
    mutation(
        $secureDocumentId: ID!
        $note: NoteInput
    ){
        approveSecureDocument(
            secure_document_id: $secureDocumentId
            note: $note
        ){
            id
            approved
            type
        }
    }
`

export const rejectSecureDocument = gql`
    mutation(
        $secureDocumentId: ID!
        $note: NoteInput
    ){
        rejectSecureDocument(
            secure_document_id: $secureDocumentId
            note: $note
        ){
            id
            approved
            type
        }
    }
`