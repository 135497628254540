/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Louis Capitanchik <louis.capitanchik@launchbase.solutions>, Dec 2018
 * ========================================================================
 */

// @flow

import * as React from 'react'
import { Sidebar, Loader, Button, Icon, Popup } from 'semantic-ui-react'
import { AutoscrollDiv, MessageComposer } from 'components'
import { connect } from 'react-redux'
import styles from './chatpanel.less'
import Message from './ChatMessage'
import uuid from 'uuid/v4'
import type { User } from 'utils/flowtypes/models'
import typeof { WhippetChannel } from 'services/whippet/WhippetSDK'

export type ChatMessagePayload = {
    type: 'text',
    content: string,
    id: string,
}

export type RemoteChatMessage = {
    message: ChatMessagePayload,
    user: ChatUser,
}

export type ChatUser = {
    name: string,
    id: string,
}

type ChatMessageProps = {
    message: ChatMessagePayload,
    sender: ChatUser,
    user: User,
}
class ChatMessage extends React.PureComponent<ChatMessageProps> {
    render() {
        const { message, user, sender } = this.props

        switch(message.type) {
            case 'text': {
                return <Message.Text message={message} sender={sender} ownMessage={user.id === sender.id} />
            }
            default: return <Message.Missing />
        }
    }
}

export type Props = {
    user: User,
    active: boolean,
    loading?: ?boolean,
    readOnly?: ?boolean,
    chat: WhippetChannel,
    onMessageSent?: (Object) => void,
    messages: RemoteChatMessage[],
    toggle(): void,
    renderContainer?: (active: boolean, container: React.Node) => React.Node,
    renderMessage?: React.ComponentType<ChatMessageProps> | (payload: RemoteChatMessage) => React.Node,
    hideActions?: boolean
}

class ChatPanel extends React.PureComponent<Props> {
    render() {
        const { renderContainer = this._renderDefaultContainer, active, messages, renderMessage, readOnly } = this.props
        const { hideActions } = this.props 

        return renderContainer(active, <React.Fragment>
                <div className={styles.chatpanel__container}>
                    { 
                        !hideActions && (
                            <div className={styles.chatpanel__container__actions}>                   
                                <Button basic onClick={this.props.toggle}>
                                    Hide Chat
                                    <Icon name='right arrow' />
                                </Button>
                                <Popup trigger={<Button basic icon='help' />} content='You can view your conversations at anytime by going to Messages in the menu.' />
                            </div>
                        )
                    }
                    { this.props.loading ? (
                            <Loader active />
                        ) : (
                            <AutoscrollDiv className={styles.chatpanel__container__messages}>
                                { messages.map(message => {
                                    if (typeof renderMessage === 'function') {
                                        // $FlowFixMe Union Types
                                        return renderMessage(message)
                                    }
                                    const C = renderMessage || ChatMessage
                                    return <C key={message.message.id} user={this.props.user} sender={message.user} message={message.message} />
                                })}
                            </AutoscrollDiv>
                        )
                    }
                    { !readOnly &&
                        <MessageComposer className={styles.chatpanel__container__editor} onSubmit={this._handleSubmit}/>
                    }
                </div>
        </React.Fragment>)
    }

    _handleSubmit = (content: string) => {
        if (content.trim() === '') {
            return
        }
        const message = {
            user: {
                name: this.props.user.name,
                id: this.props.user.id,
            },
            message: {
                type: 'text',
                content,
                id: uuid(),
            }
        }
        if (this.props.chat !== null) {
            this.props.chat.send('message', message)
            if (this.props.onMessageSent) {
                this.props.onMessageSent(message)
            }
        }
    }

    _renderDefaultContainer = (visible: boolean, content: React.Node) => <Sidebar direction='right' animation='overlay' width='wide' visible={visible}>
        { content }
    </Sidebar>
}

export default connect(state => ({
    user: state.user,
}))(ChatPanel)