/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Oct 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import { Feed } from 'semantic-ui-react'

const events = [
    {
        date: '1 Hour Ago',
        icon: 'building outline',
        meta: 'Agent: Elliot Fu',
        summary: 'Elliot Fu updated information for Penthouse Suite',
    },
    {
        date: '3 days ago',
        icon: 'star',
        meta: 'Agent: Elliot Fu',
        summary: 'Kristine left a review for Penthouse Suite',
        extraText: 'Terrific stay, couldn’t have asked for much more.',
    },
    {
        date: '4 days ago',
        icon: 'calendar',
        meta: 'Agent: Elliot Fu',
        summary: 'Helen Troy booked Penthouse Suite from 18th December to 21st December'
    },
    {
        date: 'August 20th 2018',
        icon: 'calendar',
        meta: 'Agent: Elliot Fu',
        summary: 'Kristine booked Penthouse Suite from 25th September to 2nd October'
    },
]

const AccommodationActivityFeed = () => <Feed events={events} />

export default AccommodationActivityFeed