/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, May 2018
 * ========================================================================
 */

export const graphql = {
    uri: process.env.GRAPHQL_URL
}

export const cloudinary = {
    cloud_name: process.env.CLOUDINARY_CLOUD_NAME,
    api_key: process.env.CLOUDINARY_API_KEY,
    api_secret: process.env.CLOUDINARY_API_SECRET,
    enhance_image_tag: true,
    static_image_support: false,
    secure: true
}

export const maps = {
    api_key: process.env.GOOGLE_MAPS_KEY,
    mapbox_key: process.env.MAPBOX_KEY
}

export const services = {
    iplocation: process.env.IPLOCATION_SERVICE
}

// IP LOCATION SERVICES

export const ipstack = {
    api_key: process.env.IPSTACK_KEY
}

export const ipgeolocation = {
    api_key: process.env.IPGEOLOCATION_KEY
}

// PAYMENT HANDLING

export const stripe = {
    api_key: process.env.STRIPE_KEY
}

// FEATURE FLAGS

export const featureFlags = {
    disable_search: process.env.DISABLE_SEARCH,
    disable_booking: process.env.DISABLE_BOOKING
}

export const pusher = {
    appKey: process.env.PUSHER_APP_KEY,
}

export const whippet = {
    appId: process.env.WHIPPET_APP_ID,
    wsHost: process.env.WHIPPET_WS_HOST,
    apiHost: process.env.WHIPPET_API_HOST,
}

// LOCALES

export const defaultLocale = {
    'name': 'United Kingdom of Great Britain and Northern Ireland',
    'display-name': 'United Kingdom',
    'alpha-2': 'GB',
    'alpha-3': 'GBR',
    'country-code': 826,
    'iso_3166-2': 'ISO 3166-2:GB',
    'region': 'Europe',
    'sub-region': 'Northern Europe',
    'region-code': 150,
    'sub-region-code': 154,
    'key': 'GB'
}

/* debug mode shows errors in the view as well as the console */
export const debugMode = process.env.DEBUG_MODE === 'TRUE' ? true : false

export const config = {
    graphql,
    cloudinary,
    services,
    featureFlags,
    stripe,
    whippet,
    maps
}

export default config
