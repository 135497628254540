/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import intl from 'react-intl-universal'
import SUPPORTED_LANGUAGES from 'resources/language/supported'

/**
 * Straight up wrapping function for the i18n get() function.
 * Programmers _are_ lazy.
 */
export function localise(key, variables) {
    return intl.get(key, variables)
}

export function localiseHTML(key, variables) {
    return intl.getHTML(key, variables)
}

export function getLanguageDropdownOptions() {
    return SUPPORTED_LANGUAGES.map((language) => {
        return {
            key: language.value,
            text: language.name,
            value: language.value,
            content: language.name
        }
    })
}

export default localise