/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import type { Feature } from 'utils/flowtypes/models'
import { Form } from 'semantic-ui-react'
import { NumberInput } from 'components'
import style from './featureinput.less'
 
type Props = {
    feature: Feature,
    onChange: Function,
    value: number,
    name: string
}

const renderAsTextAreaField = ['description']

const EnumFeatureInput = (props: Props) => {
    let options = []

    if (props.feature.definition.meta.options) {
        options = props.feature.definition.meta.options.map(option => ({
            key: option,
            value: option,
            text: option
        }))
    }

    return (
        <Form.Dropdown
            fluid
            placeholder={props.feature.definition.name}
            search
            selection
            options={options}
            value={props.value}
            name={props.feature.definition.name}
            required
            label={props.feature.definition.name}
            onChange={props.onChange}
        />
    )
}

const BooleanFeatureInput = (props: Props) => {
    return (
        <p><strong>{props.feature.definition.name}</strong></p>
    )
}

const StringFeatureInput = (props: Props) => {
    return (
        <Form.Input
            type='text'
            placeholder={props.feature.definition.name}
            value={props.value}
            label={props.feature.definition.name}
            onChange={props.onChange}
            fluid
            required
            name={props.feature.definition.name}
        />
    )
}

const TextAreaFeatureInput = (props: Props) => {
    return (
        <Form.TextArea
            autoHeight
            value={props.value}
            placeholder={props.feature.definition.name}
            label={props.feature.definition.name}
            required
            className={style.featureinput__textarea}
            onChange={props.onChange}
            name={props.feature.definition.name}
        />
    )
}

const FeatureInput = (props: Props) => {
    
    const { feature, value, onChange } = props

    const type = renderAsTextAreaField.includes(feature.definition.shortcode) ? 'textarea' : feature.type
    
    return (
        <Form>
            {
                type === 'number' && (
                    <NumberInput
                        label={feature.definition.name}
                        value={parseInt(value) || 0}
                        min={0}
                        name={feature.definition.name}
                        onChange={onChange}
                    />
                )
            }
            {
                type === 'enum' && <EnumFeatureInput {...props} />
            }
            {
                type === 'boolean' && <BooleanFeatureInput {...props} />
            }
            {
                type === 'string' && <StringFeatureInput {...props} />
            }
            {
                type === 'textarea' && <TextAreaFeatureInput {...props} />
            }
        </Form>
    )
}

export default FeatureInput