/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

import React, { PureComponent } from 'react'
import { Wizard } from 'components'
import { countries } from 'queries/countries'
import { createProperty } from 'queries/properties'
import { graphql, withApollo, compose } from 'react-apollo'
import { connect } from 'react-redux'
import { showErrorNotification } from 'utils'
import PropertyAddressStep from './PropertyAddressStep'
import ConfirmPropertyAddressStep from './ConfirmPropertyAddressStep'
import FinishedStep from './FinishedStep'
import AreaDescriptionStep from './AreaDescriptionStep'
import type { User } from 'utils/flowtypes/models'

type Props = {
    user: User
}

class PropertyWizard extends PureComponent<Props, {}> {
    constructor(props) {
        super(props)

        this.state = {
            activeStep: 1,
            name:'',
            street: '',
            town: '',
            county: '',
            postcode: '',
            propertyId: '',
            addressText:'',
            coords:{},
            submitting: false,
            description: '',
            country: props.user.company.country.iso2 || null
        }
    }

    stepCount = 4

    handleClickNext = () => this.setState(prevState => {
        const nextStep = prevState.activeStep >= this.stepCount ? this.stepCount : prevState.activeStep + 1
        return { activeStep: nextStep}
    })

    handleClickBack = () => this.setState(prevState => {
        const prevStep = prevState.activeStep <= 1 ? 0 : prevState.activeStep - 1
        return { activeStep: prevStep }
    })

    handleSubmit = async () => {
        this.setState({ submitting: true })

        const { name, street, town, county, postcode, coords, description, country } = this.state
        const address = [street, town, county, postcode].filter(bit => bit).join()
        const response = await this.props.createProperty({
            variables: {
                companyId: this.props.user.company.id,
                name,
                description,
                address,
                location: {
                    latitude: coords.lat ? coords.lat : null,
                    longitude: coords.lng ? coords.lng : null,
                },
                countryId: country   
            }
        }).catch(e => e)

        if (response instanceof Error) {
            showErrorNotification('Oops! Something went wrong.')
            console.error(response)
            this.setState({ submitting: false })
        } else {
            this.setState({
                submitting: false,
                propertyId: response.data.createProperty.id,
                activeStep: 4
            })
        }
    }

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value })
    }

    render() {
        const {
            activeStep,
            name,
            street,
            town,
            county,
            postcode,
            coords,
            propertyId,
            submitting,
            description,
            country
        } = this.state

        if (this.props.countries.loading)
            return null

        let countries = this.props.countries.countries.slice()
        
        countries.sort(function(a, b) {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        })

        return (
            <Wizard>
                <PropertyAddressStep
                    step={1}
                    activeStep={activeStep}
                    stepCount={this.stepCount}
                    onClickNext={this.handleClickNext}
                    onChange={this.handleChange}
                    name={name}
                    street={street}
                    town={town}
                    county={county}
                    postcode={postcode}
                    countries={countries}
                    country={country}
                />
                <ConfirmPropertyAddressStep
                    step={2}
                    activeStep={activeStep}
                    stepCount={this.stepCount}
                    onSubmit={this.handleSubmit}
                    onClickBack={this.handleClickBack}
                    onClickNext={this.handleClickNext}
                    onChange={this.handleChange}
                    street={street}
                    town={town}
                    county={county}
                    postcode={postcode}
                    coords={coords}
                    submitting={submitting}
                />
                <AreaDescriptionStep
                    step={3}
                    activeStep={activeStep}
                    stepCount={this.stepCount}
                    onClickBack={this.handleClickBack}
                    onClickNext={this.handleSubmit}
                    onChange={this.handleChange}
                    description={description}
                />
                <FinishedStep
                    step={4}
                    activeStep={activeStep}
                    stepCount={this.stepCount}
                    propertyId={propertyId}
                />
            </Wizard> 
        )
    }
}

const mapStateToProps = state => ({ user: state.user })

export default compose(
    graphql(
        createProperty,
        {
            name: 'createProperty'
        }
    ),
    graphql(
        countries,
        {
            name: 'countries',
            options: {
                fetchPolicy : 'network-only'
            }
        }
    )
)(withApollo(connect(mapStateToProps)(PropertyWizard)))