/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React, { type Node, Fragment } from 'react'
import { Segment, Header, Button, Divider, Icon } from 'semantic-ui-react'
import type { SemanticUIButton } from 'utils/flowtypes-sui'
import style from './widget.less'

type Props = {
    header?: string,
    action?: SemanticUIButton,
    children?: Node,
    icon?: string
}

const Widget = (props: Props) => {
    
    const { header, action, children, icon } = props
    
    return (
        <Segment>
            {
                (header || action) && (
                    <Fragment>
                        <div className={style.widget__header}>
                            {
                                header && (
                                    <Header
                                        
                                        className={style.widget__headertext}
                                        size='small'
                                    >
                                        {icon && <Icon name={icon} />}
                                        {header}
                                    </Header>
                                )
                            }
                            {
                                action && (
                                    <div className={style.widget__action}>
                                        <Button
                                            disabled={action.disabled}
                                            as={action.as}
                                            to={action.to}
                                            onClick={action.onClick}
                                            content={action.content}
                                            primary={action.primary}
                                            color={action.color}
                                            icon={action.icon}
                                            basic={action.basic}
                                            size='tiny'
                                            compact
                                        />
                                    </div>
                                )
                            }
                        </div>
                        <Divider />
                    </Fragment>
                )
            }
            {
                children
            }
        </Segment>
    )
}

export default Widget