/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

// @flow

import moment from 'moment'
import type { BookingTime, UnixTimestamp, SimpleDate, Timestamp, AvailabilitySlots, DateRanges, Availability } from 'utils/flowtypes/models'

export function isValidSimpleDate(simpleDate: SimpleDate): boolean {
    return moment(simpleDate, 'DD-MM-YYYY').isValid()
}

export function isValidDateRange(startDate?: ?string  = '', endDate?: ?string = ''): %checks {
    return (
        moment(startDate, 'DD-MM-YYYY').isValid() &&
        moment(endDate, 'DD-MM-YYYY').isValid() &&
        moment(startDate, 'DD-MM-YYYY').isSameOrBefore(moment(endDate, 'DD-MM-YYYY'))
    )
}

export function transformUnixTimestampToSimpleDate(unixTimestamp: UnixTimestamp): SimpleDate {
    return moment(unixTimestamp).format('DD-MM-YYYY')
}

export function transformSimpleDateToTimestamp(simpleDate: SimpleDate, endOfDay?: boolean): Timestamp {
    if (endOfDay) {
        return moment(simpleDate, 'DD-MM-YYYY').endOf('day').format()
    }
    return moment(simpleDate, 'DD-MM-YYYY').startOf('day').format()
}

export function getDurationFromDateRange(from: Date, to: Date): number {
    let duration = moment.duration(moment(to).diff(moment(from)))
    return Math.floor(duration.asDays())
}

export function transformDateToSimpleDate(date: Date): SimpleDate {
    return moment(date).format('DD-MM-YYYY')
}

export function transformSimpleDateToDate(simpleDate: SimpleDate): Date {
    return new Date(moment(simpleDate, 'DD-MM-YYYY').valueOf())
}

export function transformAvailabilitySlotsToDateRanges(availabilitySlots: AvailabilitySlots): DateRanges {
    return availabilitySlots.map(slot => {
        return {
            from: new Date(slot.from),
            to: new Date(slot.to)
        }
    })
}

export function transformAvailabilityToDateRanges(availability: Availability[]): DateRanges {
    return availability.map(range => {
        return {
            from: utcStringToUTCDate(range.start),
            to: utcStringToUTCDate(range.end)
        }
    })
}

export function utcStringToUTCDate(utcString: string): Date {
    let date = new Date(utcString)
    return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
    )
}

export function displayTimestampZeroIndexed(timestamp: Timestamp, showTime: boolean = true): string {
    if (showTime)
        return moment.utc(timestamp).subtract(1, 'months').format('MMM Do YYYY, h:mm a')

    return moment.utc(timestamp).subtract(1, 'months').format('MMM Do YYYY')
}

export function displayTimestamp(timestamp: Timestamp, showTime: boolean = true): string {
    if (showTime)
        return moment.utc(timestamp).format('MMM Do YYYY, h:mm a')

    return moment.utc(timestamp).format('MMM Do YYYY')
}

export function displayShortTimestamp(timestamp: Timestamp, showTime: boolean = true): string {
    if (showTime)
        return moment.utc(timestamp).format('DD/MM/YY, HH:mm')

    return moment.utc(timestamp).format('DD/MM/YY')
}

export function isPastBooking(time: BookingTime): boolean {
    if (time.length < 1)
        throw new Error('function isPastBooking expects arg ‘time’ to have one or more time slots. None found.')

    return Date.now() > new Date(time[0].end)
}