/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import { Modal, Button, Form } from 'semantic-ui-react'
import type { Accommodation } from 'utils/flowtypes'
import type { SemanticEvent, SemanticEventProps } from 'utils/flowtypes-sui'
import { getAccommodation, updateAccommodation } from 'queries/accommodation'
import { withApollo, graphql } from 'react-apollo'
import { showErrorNotification, showSuccessNotification } from 'utils/notifications'

type Props = {
    open: boolean,
    onClose: Function,
    accommodation: Accommodation,
    updateAccommodation: Function
}

type State = {
    name: string,
    derivedStateFromProps: boolean
}

class ChangeAccommodationNameModal extends PureComponent<Props, State> {
    state = {
        name: '',
        derivedStateFromProps: false
    }
    
    static getDerivedStateFromProps(props: Props, state: State) {
        if (!state.derivedStateFromProps) {
            state.name = props.accommodation.name
            state.derivedStateFromProps = true
        }

        return null
    }

    handleChange = (e: SemanticEvent, props: SemanticEventProps) => {
        this.setState({ [props.name]: props.value })
    }

    handleSave = async () => {
        const response = await this.props.updateAccommodation({
            variables: {
                accommodationId: this.props.accommodation.id,
                name: this.state.name
            },
            refetchQueries: [{
                query: getAccommodation,
                variables: { accommodationId: this.props.accommodation.id }
            }]
        }).catch(e => e)

        if (response instanceof Error) {
            console.error(response)
            showErrorNotification('Unable to update name of accommodation')
        } else {
            showSuccessNotification('Updated name successfully')
            this.props.onClose()
        }
    }

    render() {
        const { open, onClose } = this.props
        const { name } = this.state

        return (
            <Modal
                open={open}
                size='tiny'
            >
                <Modal.Header>Change accommodation name</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Input
                            name='name'
                            value={name}
                            required
                            label='Accommodation name'
                            placeholder='Accommodation name'
                            onChange={this.handleChange}
                            type='text'
                            fluid
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={onClose}
                        basic
                        primary
                        content='Cancel'
                    />
                    <Button
                        onClick={this.handleSave}
                        primary
                        disabled={!name}
                        content='Save'
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

export default graphql(
    updateAccommodation,
    {
        name: 'updateAccommodation'
    }
)(withApollo(ChangeAccommodationNameModal))