/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Yunjia Li <yunjia.li@launchbase.solutions>, Oct 2018
 * ========================================================================
 */

// @flow

import React, { PureComponent} from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Circle } from 'react-google-maps'

type Props = {
    onMarkerDragEnd?: Function,
    markerEnabled?: boolean,
    circleEnabled?: boolean,
    circleRadius?: number,
    zoom: number,
    markerDraggable: boolean,
    coords?: {
        lat: string,
        lng: string
    }
}

class CustomMap extends PureComponent<Props> {

    onMarkerDragEnd = (evt) =>{
        const lat = evt.latLng.lat()
        const lng = evt.latLng.lng()
        if (this.props.onMarkerDragEnd) {
            this.props.onMarkerDragEnd(this, {name:'coords', value:{lat:lat, lng:lng}})
        }
    }

    render(){
        const {zoom, coords, markerDraggable, markerEnabled, circleEnabled, circleRadius} = this.props
        
        if(!coords?.lat){
            return (<div>Loading...</div>)
        }

        return(
            <GoogleMap
                defaultZoom={zoom}
                center={coords}
            >
                {
                    markerEnabled && <Marker 
                        position={coords}
                        draggable={markerDraggable}
                        onDragEnd={(evt)=>this.onMarkerDragEnd(evt)}
                    />
                }
                {
                    circleEnabled && <Circle
                        center={coords}
                        radius={circleRadius ? circleRadius : 1000}
                        options={{
                            fillColor: '#763db2',
                            strokeColor: '#763db2',
                            strokeWeight: 0.5
                        }}
                    />}
            </GoogleMap>
        )
    }
}

export default withScriptjs(withGoogleMap(CustomMap))