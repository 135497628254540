exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".featureinput__textarea__3i0pg {\n  max-height: 400px;\n  overflow-y: scroll;\n}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/FeatureInput/featureinput.less"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;CACpB","file":"featureinput.less","sourcesContent":[".featureinput__textarea {\n  max-height: 400px;\n  overflow-y: scroll;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"featureinput__textarea": "featureinput__textarea__3i0pg"
};