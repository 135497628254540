/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import style from './page.less'

type Props = {
    className?: string,
    inverted?: boolean
}

const Footer = (props: Props) => {
    const { className, inverted } = props

    let styling = style.page__footer
    if (inverted) styling += ` ${style.page__footer__inverted}`
    if (className) styling += ` ${className}`

    return (
        <div className={styling}>
            <p>© roosts.com 2019</p>
        </div>
    )
}

export default Footer