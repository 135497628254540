/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Feb 2019
 * ========================================================================
 */

// @flow

import React, { type Node } from 'react'
import style from './tabactionscontainer.less'

type Props = {
    children?: Node
}

const TabActionsContainer = (props: Props) => {

    const { children } = props

    return (
        <div className={style.tabactionscontainer}>
            {
                children && (
                    <div>
                        {children}
                    </div>
                )
            }
        </div>
    )
}

export default TabActionsContainer
