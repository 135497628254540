/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Oct 2018
 * ========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import ErrorBoundary from 'ErrorBoundary'
import { Form } from 'semantic-ui-react'
import { withApollo } from 'react-apollo'
import localise from 'languages'
import type { SemanticEvent, SemanticEventProps } from 'utils/flowtypes/semantic'
import type { ApolloClient } from 'utils/flowtypes/apollo'
import type { FeatureDefinition } from 'utils/flowtypes/models'
import { featureDefinitions } from 'queries/accommodation'

type Props = {
    onChange: Function,
    fluid?: boolean,
    label?: string,
    value?: string,
    optional?: boolean,
    templateId?: string,
    propertyId?: string,
    client: ApolloClient,
    activeFeatureGroup?: string
}

type State = {
    features: Array<{
        id: string,
        key: string,
        value: string,
        definition: FeatureDefinition,
        text: string
    }>
}

class FeatureSearchDropdown extends PureComponent<Props, State> {
    state = {
        features: []
    }

    handleChange = (e: SemanticEvent, props: SemanticEventProps) => {
        const feature = this.state.features.find(feature => feature.id === props.value)
        if (feature) this.props.onChange(props.value, feature.definition)
    }

    filterDisplayedFeatures = () => {
        const { features } = this.state
        const { activeFeatureGroup } = this.props

        if (activeFeatureGroup) {
            return features.filter(feature => feature.definition.group === activeFeatureGroup)
        }

        return features
    }

    async componentDidMount() {
        const response = await this.props.client.query({
            query: featureDefinitions
        })

        if (response instanceof Error) {
            console.error(response)
        } else {
            const features = response.data.featureDefinitions.map(feature => ({
                id: feature.id,
                key: feature.id,
                value: feature.id,
                definition: feature,
                text: feature.name
            }))

            this.setState({ features })
        }   
    }

    render() {
        const displayedFeatures = this.filterDisplayedFeatures()

        const {
            label = 'Select Feature',
            fluid = true,
            value = '',
            optional
        } = this.props

        return (
            <ErrorBoundary>
                <Form.Dropdown
                    placeholder='Select Feature'
                    label={`${label} ${optional ? localise('FORMFIELD.OPTIONAL') : ''}`}
                    fluid={fluid}
                    search
                    selection
                    onChange={this.handleChange}
                    options={displayedFeatures}
                    value={value}
                />
            </ErrorBoundary>
        )
    }
}

export default withApollo(FeatureSearchDropdown)