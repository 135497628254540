export default [
  {
    'country': 'Afghanistan',
    'telcode': '+93',
    'ccode': 'AF',
    'key': 'AF'
  },
  {
    'country': 'Åland Islands',
    'telcode': '+358',
    'ccode': 'AX',
    'key': 'AX'
  },
  {
    'country': 'Albania',
    'telcode': '+355',
    'ccode': 'AL',
    'key': 'AL'
  },
  {
    'country': 'Algeria',
    'telcode': '+213',
    'ccode': 'DZ',
    'key': 'DZ'
  },
  {
    'country': 'American Samoa',
    'telcode': '+1',
    'ccode': 'AS',
    'key': 'AS'
  },
  {
    'country': 'Andorra',
    'telcode': '+376',
    'ccode': 'AD',
    'key': 'AD'
  },
  {
    'country': 'Angola',
    'telcode': '+244',
    'ccode': 'AO',
    'key': 'AO'
  },
  {
    'country': 'Anguilla',
    'telcode': '+1',
    'ccode': 'AI',
    'key': 'AI'
  },
  {
    'country': 'Antigua and Barbuda',
    'telcode': '+1',
    'ccode': 'AG',
    'key': 'AG'
  },
  {
    'country': 'Argentina',
    'telcode': '+54',
    'ccode': 'AR',
    'key': 'AR'
  },
  {
    'country': 'Armenia',
    'telcode': '+374',
    'ccode': 'AM',
    'key': 'AM'
  },
  {
    'country': 'Aruba',
    'telcode': '+297',
    'ccode': 'AW',
    'key': 'AW'
  },
  {
    'country': 'Australia',
    'telcode': '+61',
    'ccode': 'AU',
    'key': 'AU'
  },
  {
    'country': 'Austria',
    'telcode': '+43',
    'ccode': 'AT',
    'key': 'AT'
  },
  {
    'country': 'Azerbaijan',
    'telcode': '+994',
    'ccode': 'AZ',
    'key': 'AZ'
  },
  {
    'country': 'Bahamas',
    'telcode': '+1',
    'ccode': 'BS',
    'key': 'BS'
  },
  {
    'country': 'Bahrain',
    'telcode': '+973',
    'ccode': 'BH',
    'key': 'BH'
  },
  {
    'country': 'Bangladesh',
    'telcode': '+880',
    'ccode': 'BD',
    'key': 'BD'
  },
  {
    'country': 'Barbados',
    'telcode': '+1',
    'ccode': 'BB',
    'key': 'BB'
  },
  {
    'country': 'Belarus',
    'telcode': '+375',
    'ccode': 'BY',
    'key': 'BY'
  },
  {
    'country': 'Belgium',
    'telcode': '+32',
    'ccode': 'BE',
    'key': 'BE'
  },
  {
    'country': 'Belize',
    'telcode': '+501',
    'ccode': 'BZ',
    'key': 'BZ'
  },
  {
    'country': 'Benin',
    'telcode': '+229',
    'ccode': 'BJ',
    'key': 'BJ'
  },
  {
    'country': 'Bermuda',
    'telcode': '+1',
    'ccode': 'BM',
    'key': 'BM'
  },
  {
    'country': 'Bhutan',
    'telcode': '+975',
    'ccode': 'BT',
    'key': 'BT'
  },
  {
    'country': 'Bolivia, Plurinational State of',
    'telcode': '+591',
    'ccode': 'BO',
    'key': 'BO'
  },
  {
    'country': 'Bosnia and Herzegovina',
    'telcode': '+387',
    'ccode': 'BA',
    'key': 'BA'
  },
  {
    'country': 'Botswana',
    'telcode': '+267',
    'ccode': 'BW',
    'key': 'BW'
  },
  {
    'country': 'Bouvet Island',
    'telcode': '+',
    'ccode': 'BV',
    'key': 'BV'
  },
  {
    'country': 'Brazil',
    'telcode': '+55',
    'ccode': 'BR',
    'key': 'BR'
  },
  {
    'country': 'British Indian Ocean Territory',
    'telcode': '+246',
    'ccode': 'IO',
    'key': 'IO'
  },
  {
    'country': 'Brunei Darussalam',
    'telcode': '+673',
    'ccode': 'BN',
    'key': 'BN'
  },
  {
    'country': 'Bulgaria',
    'telcode': '+359',
    'ccode': 'BG',
    'key': 'BG'
  },
  {
    'country': 'Burkina Faso',
    'telcode': '+226',
    'ccode': 'BF',
    'key': 'BF'
  },
  {
    'country': 'Burundi',
    'telcode': '+257',
    'ccode': 'BI',
    'key': 'BI'
  },
  {
    'country': 'Cambodia',
    'telcode': '+855',
    'ccode': 'KH',
    'key': 'KH'
  },
  {
    'country': 'Cameroon',
    'telcode': '+237',
    'ccode': 'CM',
    'key': 'CM'
  },
  {
    'country': 'Canada',
    'telcode': '+1',
    'ccode': 'CA',
    'key': 'CA'
  },
  {
    'country': 'Cape Verde',
    'telcode': '+238',
    'ccode': 'CV',
    'key': 'CV'
  },
  {
    'country': 'Cayman Islands',
    'telcode': '+1',
    'ccode': 'KY',
    'key': 'KY'
  },
  {
    'country': 'Central African Republic',
    'telcode': '+236',
    'ccode': 'CF',
    'key': 'CF'
  },
  {
    'country': 'Chad',
    'telcode': '+235',
    'ccode': 'TD',
    'key': 'TD'
  },
  {
    'country': 'Chile',
    'telcode': '+56',
    'ccode': 'CL',
    'key': 'CL'
  },
  {
    'country': 'China',
    'telcode': '+86',
    'ccode': 'CN',
    'key': 'CN'
  },
  {
    'country': 'Christmas Island',
    'telcode': '+61',
    'ccode': 'CX',
    'key': 'CX'
  },
  {
    'country': 'Cocos (Keeling) Islands',
    'telcode': '+61',
    'ccode': 'CC',
    'key': 'CC'
  },
  {
    'country': 'Colombia',
    'telcode': '+57',
    'ccode': 'CO',
    'key': 'CO'
  },
  {
    'country': 'Comoros',
    'telcode': '+269',
    'ccode': 'KM',
    'key': 'KM'
  },
  {
    'country': 'Congo',
    'telcode': '+242',
    'ccode': 'CG',
    'key': 'CG'
  },
  {
    'country': 'Congo, the Democratic Republic of the',
    'telcode': '+243',
    'ccode': 'CD',
    'key': 'CD'
  },
  {
    'country': 'Cook Islands',
    'telcode': '+682',
    'ccode': 'CK',
    'key': 'CK'
  },
  {
    'country': 'Costa Rica',
    'telcode': '+506',
    'ccode': 'CR',
    'key': 'CR'
  },
  {
    'country': 'Côte d’Ivoire',
    'telcode': '+225',
    'ccode': 'CI',
    'key': 'CI'
  },
  {
    'country': 'Croatia',
    'telcode': '+385',
    'ccode': 'HR',
    'key': 'HR'
  },
  {
    'country': 'Cuba',
    'telcode': '+53',
    'ccode': 'CU',
    'key': 'CU'
  },
  {
    'country': 'Cyprus',
    'telcode': '+357',
    'ccode': 'CY',
    'key': 'CY'
  },
  {
    'country': 'Czech Republic',
    'telcode': '+420',
    'ccode': 'CZ',
    'key': 'CZ'
  },
  {
    'country': 'Denmark',
    'telcode': '+45',
    'ccode': 'DK',
    'key': 'DK'
  },
  {
    'country': 'Djibouti',
    'telcode': '+253',
    'ccode': 'DJ',
    'key': 'DJ'
  },
  {
    'country': 'Dominica',
    'telcode': '+1',
    'ccode': 'DM',
    'key': 'DM'
  },
  {
    'country': 'Dominican Republic',
    'telcode': '+1',
    'ccode': 'DO',
    'key': 'DO'
  },
  {
    'country': 'Ecuador',
    'telcode': '+593',
    'ccode': 'EC',
    'key': 'EC'
  },
  {
    'country': 'Egypt',
    'telcode': '+20',
    'ccode': 'EG',
    'key': 'EG'
  },
  {
    'country': 'El Salvador',
    'telcode': '+503',
    'ccode': 'SV',
    'key': 'SV'
  },
  {
    'country': 'Equatorial Guinea',
    'telcode': '+240',
    'ccode': 'GQ',
    'key': 'GQ'
  },
  {
    'country': 'Eritrea',
    'telcode': '+291',
    'ccode': 'ER',
    'key': 'ER'
  },
  {
    'country': 'Estonia',
    'telcode': '+372',
    'ccode': 'EE',
    'key': 'EE'
  },
  {
    'country': 'Ethiopia',
    'telcode': '+251',
    'ccode': 'ET',
    'key': 'ET'
  },
  {
    'country': 'Falkland Islands (Malvinas)',
    'telcode': '+500',
    'ccode': 'FK',
    'key': 'FK'
  },
  {
    'country': 'Faroe Islands',
    'telcode': '+298',
    'ccode': 'FO',
    'key': 'FO'
  },
  {
    'country': 'Fiji',
    'telcode': '+679',
    'ccode': 'FJ',
    'key': 'FJ'
  },
  {
    'country': 'Finland',
    'telcode': '+358',
    'ccode': 'FI',
    'key': 'FI'
  },
  {
    'country': 'France',
    'telcode': '+33',
    'ccode': 'FR',
    'key': 'FR'
  },
  {
    'country': 'French Guiana',
    'telcode': '+594',
    'ccode': 'GF',
    'key': 'GF'
  },
  {
    'country': 'French Polynesia',
    'telcode': '+689',
    'ccode': 'PF',
    'key': 'PF'
  },
  {
    'country': 'French Southern Territories',
    'telcode': '+',
    'ccode': 'TF',
    'key': 'TF'
  },
  {
    'country': 'Gabon',
    'telcode': '+241',
    'ccode': 'GA',
    'key': 'GA'
  },
  {
    'country': 'Gambia',
    'telcode': '+220',
    'ccode': 'GM',
    'key': 'GM'
  },
  {
    'country': 'Georgia',
    'telcode': '+995',
    'ccode': 'GE',
    'key': 'GE'
  },
  {
    'country': 'Germany',
    'telcode': '+49',
    'ccode': 'DE',
    'key': 'DE'
  },
  {
    'country': 'Ghana',
    'telcode': '+233',
    'ccode': 'GH',
    'key': 'GH'
  },
  {
    'country': 'Gibraltar',
    'telcode': '+350',
    'ccode': 'GI',
    'key': 'GI'
  },
  {
    'country': 'Greece',
    'telcode': '+30',
    'ccode': 'GR',
    'key': 'GR'
  },
  {
    'country': 'Greenland',
    'telcode': '+299',
    'ccode': 'GL',
    'key': 'GL'
  },
  {
    'country': 'Grenada',
    'telcode': '+1',
    'ccode': 'GD',
    'key': 'GD'
  },
  {
    'country': 'Guadeloupe',
    'telcode': '+590',
    'ccode': 'GP',
    'key': 'GP'
  },
  {
    'country': 'Guam',
    'telcode': '+1',
    'ccode': 'GU',
    'key': 'GU'
  },
  {
    'country': 'Guatemala',
    'telcode': '+502',
    'ccode': 'GT',
    'key': 'GT'
  },
  {
    'country': 'Guernsey',
    'telcode': '+44',
    'ccode': 'GB',
    'key': 'GG'
  },
  {
    'country': 'Guinea',
    'telcode': '+224',
    'ccode': 'GN',
    'key': 'GN'
  },
  {
    'country': 'Guinea-Bissau',
    'telcode': '+245',
    'ccode': 'GW',
    'key': 'GW'
  },
  {
    'country': 'Guyana',
    'telcode': '+592',
    'ccode': 'GY',
    'key': 'GY'
  },
  {
    'country': 'Haiti',
    'telcode': '+509',
    'ccode': 'HT',
    'key': 'HT'
  },
  {
    'country': 'Heard Island and McDonald Islands',
    'telcode': '+ ',
    'ccode': 'HM',
    'key': 'HM'
  },
  {
    'country': 'Holy See (Vatican City State)',
    'telcode': '+379',
    'ccode': 'VA',
    'key': 'VA'
  },
  {
    'country': 'Honduras',
    'telcode': '+504',
    'ccode': 'HN',
    'key': 'HN'
  },
  {
    'country': 'Hong Kong',
    'telcode': '+852',
    'ccode': 'HK',
    'key': 'HK'
  },
  {
    'country': 'Hungary',
    'telcode': '+36',
    'ccode': 'HU',
    'key': 'HU'
  },
  {
    'country': 'Iceland',
    'telcode': '+354',
    'ccode': 'IS',
    'key': 'IS'
  },
  {
    'country': 'India',
    'telcode': '+91',
    'ccode': 'IN',
    'key': 'IN'
  },
  {
    'country': 'Indonesia',
    'telcode': '+62',
    'ccode': 'ID',
    'key': 'ID'
  },
  {
    'country': 'Iran, Islamic Republic of',
    'telcode': '+98',
    'ccode': 'IR',
    'key': 'IR'
  },
  {
    'country': 'Iraq',
    'telcode': '+964',
    'ccode': 'IQ',
    'key': 'IQ'
  },
  {
    'country': 'Ireland',
    'telcode': '+353',
    'ccode': 'IE',
    'key': 'IE'
  },
  {
    'country': 'Isle of Man',
    'telcode': '+44',
    'ccode': 'GB',
    'key': 'IM'
  },
  {
    'country': 'Israel',
    'telcode': '+972',
    'ccode': 'IL',
    'key': 'IL'
  },
  {
    'country': 'Italy',
    'telcode': '+39',
    'ccode': 'IT',
    'key': 'IT'
  },
  {
    'country': 'Jamaica',
    'telcode': '+1',
    'ccode': 'JM',
    'key': 'JM'
  },
  {
    'country': 'Japan',
    'telcode': '+81',
    'ccode': 'JP',
    'key': 'JP'
  },
  {
    'country': 'Jersey',
    'telcode': '+44',
    'ccode': 'GB',
    'key': 'JE'
  },
  {
    'country': 'Jordan',
    'telcode': '+962',
    'ccode': 'JO',
    'key': 'JO'
  },
  {
    'country': 'Kazakhstan',
    'telcode': '+7',
    'ccode': 'KZ',
    'key': 'KZ'
  },
  {
    'country': 'Kenya',
    'telcode': '+254',
    'ccode': 'KE',
    'key': 'KE'
  },
  {
    'country': 'Kiribati',
    'telcode': '+686',
    'ccode': 'KI',
    'key': 'KI'
  },
  {
    'country': 'Korea, Democratic People’s Republic of',
    'telcode': '+850',
    'ccode': 'KP',
    'key': 'KP'
  },
  {
    'country': 'Korea, Republic of',
    'telcode': '+82',
    'ccode': 'KR',
    'key': 'KR'
  },
  {
    'country': 'Kuwait',
    'telcode': '+965',
    'ccode': 'KW',
    'key': 'KW'
  },
  {
    'country': 'Kyrgyzstan',
    'telcode': '+996',
    'ccode': 'KG',
    'key': 'KG'
  },
  {
    'country': 'Lao People’s Democratic Republic',
    'telcode': '+856',
    'ccode': 'LA',
    'key': 'LA'
  },
  {
    'country': 'Latvia',
    'telcode': '+371',
    'ccode': 'LV',
    'key': 'LV'
  },
  {
    'country': 'Lebanon',
    'telcode': '+961',
    'ccode': 'LB',
    'key': 'LB'
  },
  {
    'country': 'Lesotho',
    'telcode': '+266',
    'ccode': 'LS',
    'key': 'LS'
  },
  {
    'country': 'Liberia',
    'telcode': '+231',
    'ccode': 'LR',
    'key': 'LR'
  },
  {
    'country': 'Libya',
    'telcode': '+218',
    'ccode': 'LY',
    'key': 'LY'
  },
  {
    'country': 'Liechtenstein',
    'telcode': '+423',
    'ccode': 'LI',
    'key': 'LI'
  },
  {
    'country': 'Lithuania',
    'telcode': '+370',
    'ccode': 'LT',
    'key': 'LT'
  },
  {
    'country': 'Luxembourg',
    'telcode': '+352',
    'ccode': 'LU',
    'key': 'LU'
  },
  {
    'country': 'Macao',
    'telcode': '+853',
    'ccode': 'MO',
    'key': 'MO'
  },
  {
    'country': 'Macedonia, the Former Yugoslav Republic of',
    'telcode': '+389',
    'ccode': 'MK',
    'key': 'MK'
  },
  {
    'country': 'Madagascar',
    'telcode': '+261',
    'ccode': 'MG',
    'key': 'MG'
  },
  {
    'country': 'Malawi',
    'telcode': '+265',
    'ccode': 'MW',
    'key': 'MW'
  },
  {
    'country': 'Malaysia',
    'telcode': '+60',
    'ccode': 'MY',
    'key': 'MY'
  },
  {
    'country': 'Maldives',
    'telcode': '+960',
    'ccode': 'MV',
    'key': 'MV'
  },
  {
    'country': 'Mali',
    'telcode': '+223',
    'ccode': 'ML',
    'key': 'ML'
  },
  {
    'country': 'Malta',
    'telcode': '+356',
    'ccode': 'MT',
    'key': 'MT'
  },
  {
    'country': 'Marshall Islands',
    'telcode': '+692',
    'ccode': 'MH',
    'key': 'MH'
  },
  {
    'country': 'Martinique',
    'telcode': '+596',
    'ccode': 'MQ',
    'key': 'MQ'
  },
  {
    'country': 'Mauritania',
    'telcode': '+222',
    'ccode': 'MR',
    'key': 'MR'
  },
  {
    'country': 'Mauritius',
    'telcode': '+230',
    'ccode': 'MU',
    'key': 'MU'
  },
  {
    'country': 'Mayotte',
    'telcode': '+262',
    'ccode': 'YT',
    'key': 'YT'
  },
  {
    'country': 'Mexico',
    'telcode': '+52',
    'ccode': 'MX',
    'key': 'MX'
  },
  {
    'country': 'Micronesia, Federated States of',
    'telcode': '+691',
    'ccode': 'FM',
    'key': 'FM'
  },
  {
    'country': 'Moldova, Republic of',
    'telcode': '+373',
    'ccode': 'MD',
    'key': 'MD'
  },
  {
    'country': 'Monaco',
    'telcode': '+377',
    'ccode': 'MC',
    'key': 'MC'
  },
  {
    'country': 'Mongolia',
    'telcode': '+976',
    'ccode': 'MN',
    'key': 'MN'
  },
  {
    'country': 'Montenegro',
    'telcode': '+382',
    'ccode': 'ME',
    'key': 'ME'
  },
  {
    'country': 'Montserrat',
    'telcode': '+1',
    'ccode': 'MS',
    'key': 'MS'
  },
  {
    'country': 'Morocco',
    'telcode': '+212',
    'ccode': 'MA',
    'key': 'MA'
  },
  {
    'country': 'Mozambique',
    'telcode': '+258',
    'ccode': 'MZ',
    'key': 'MZ'
  },
  {
    'country': 'Myanmar',
    'telcode': '+95',
    'ccode': 'MM',
    'key': 'MM'
  },
  {
    'country': 'Namibia',
    'telcode': '+264',
    'ccode': 'NA',
    'key': 'NA'
  },
  {
    'country': 'Nauru',
    'telcode': '+674',
    'ccode': 'NR',
    'key': 'NR'
  },
  {
    'country': 'Nepal',
    'telcode': '+977',
    'ccode': 'NP',
    'key': 'NP'
  },
  {
    'country': 'Netherlands',
    'telcode': '+31',
    'ccode': 'NL',
    'key': 'NL'
  },
  {
    'country': 'New Caledonia',
    'telcode': '+687',
    'ccode': 'NC',
    'key': 'NC'
  },
  {
    'country': 'New Zealand',
    'telcode': '+64',
    'ccode': 'NZ',
    'key': 'NZ'
  },
  {
    'country': 'Nicaragua',
    'telcode': '+505',
    'ccode': 'NI',
    'key': 'NI'
  },
  {
    'country': 'Niger',
    'telcode': '+227',
    'ccode': 'NE',
    'key': 'NE'
  },
  {
    'country': 'Nigeria',
    'telcode': '+234',
    'ccode': 'NG',
    'key': 'NG'
  },
  {
    'country': 'Niue',
    'telcode': '+683',
    'ccode': 'NU',
    'key': 'NU'
  },
  {
    'country': 'Norfolk Island',
    'telcode': '+672',
    'ccode': 'NF',
    'key': 'NF'
  },
  {
    'country': 'Northern Mariana Islands',
    'telcode': '+1',
    'ccode': 'MP',
    'key': 'MP'
  },
  {
    'country': 'Norway',
    'telcode': '+47',
    'ccode': 'NO',
    'key': 'NO'
  },
  {
    'country': 'Oman',
    'telcode': '+968',
    'ccode': 'OM',
    'key': 'OM'
  },
  {
    'country': 'Pakistan',
    'telcode': '+92',
    'ccode': 'PK',
    'key': 'PK'
  },
  {
    'country': 'Palau',
    'telcode': '+680',
    'ccode': 'PW',
    'key': 'PW'
  },
  {
    'country': 'Palestine, State of',
    'telcode': '+970',
    'ccode': 'PS',
    'key': 'PS'
  },
  {
    'country': 'Panama',
    'telcode': '+507',
    'ccode': 'PA',
    'key': 'PA'
  },
  {
    'country': 'Papua New Guinea',
    'telcode': '+675',
    'ccode': 'PG',
    'key': 'PG'
  },
  {
    'country': 'Paraguay',
    'telcode': '+595',
    'ccode': 'PY',
    'key': 'PY'
  },
  {
    'country': 'Peru',
    'telcode': '+51',
    'ccode': 'PE',
    'key': 'PE'
  },
  {
    'country': 'Philippines',
    'telcode': '+63',
    'ccode': 'PH',
    'key': 'PH'
  },
  {
    'country': 'Pitcairn',
    'telcode': '+870',
    'ccode': 'PN',
    'key': 'PN'
  },
  {
    'country': 'Poland',
    'telcode': '+48',
    'ccode': 'PL',
    'key': 'PL'
  },
  {
    'country': 'Portugal',
    'telcode': '+351',
    'ccode': 'PT',
    'key': 'PT'
  },
  {
    'country': 'Puerto Rico',
    'telcode': '+1',
    'ccode': 'PR',
    'key': 'PR'
  },
  {
    'country': 'Qatar',
    'telcode': '+974',
    'ccode': 'QA',
    'key': 'QA'
  },
  {
    'country': 'Réunion',
    'telcode': '+262',
    'ccode': 'RE',
    'key': 'RE'
  },
  {
    'country': 'Romania',
    'telcode': '+40',
    'ccode': 'RO',
    'key': 'RO'
  },
  {
    'country': 'Russian Federation',
    'telcode': '+7',
    'ccode': 'RU',
    'key': 'RU'
  },
  {
    'country': 'Rwanda',
    'telcode': '+250',
    'ccode': 'RW',
    'key': 'RW'
  },
  {
    'country': 'Saint Barthélemy',
    'telcode': '+590',
    'ccode': 'FR',
    'key': 'BL'
  },
  {
    'country': 'Saint Helena, Ascension and Tristan da Cunha',
    'telcode': '+290',
    'ccode': 'SH',
    'key': 'SH'
  },
  {
    'country': 'Saint Kitts and Nevis',
    'telcode': '+1',
    'ccode': 'KN',
    'key': 'KN'
  },
  {
    'country': 'Saint Lucia',
    'telcode': '+1',
    'ccode': 'LC',
    'key': 'LC'
  },
  {
    'country': 'Saint Martin (French part)',
    'telcode': '+590',
    'ccode': 'FR',
    'key': 'MF'
  },
  {
    'country': 'Saint Pierre and Miquelon',
    'telcode': '+508',
    'ccode': 'PM',
    'key': 'PM'
  },
  {
    'country': 'Saint Vincent and the Grenadines',
    'telcode': '+1',
    'ccode': 'VC',
    'key': 'VC'
  },
  {
    'country': 'Samoa',
    'telcode': '+685',
    'ccode': 'WS',
    'key': 'WS'
  },
  {
    'country': 'San Marino',
    'telcode': '+378',
    'ccode': 'SM',
    'key': 'SM'
  },
  {
    'country': 'Sao Tome and Principe',
    'telcode': '+239',
    'ccode': 'ST',
    'key': 'ST'
  },
  {
    'country': 'Saudi Arabia',
    'telcode': '+966',
    'ccode': 'SA',
    'key': 'SA'
  },
  {
    'country': 'Senegal',
    'telcode': '+221',
    'ccode': 'SN',
    'key': 'SN'
  },
  {
    'country': 'Serbia',
    'telcode': '+381',
    'ccode': 'RS',
    'key': 'RS'
  },
  {
    'country': 'Seychelles',
    'telcode': '+248',
    'ccode': 'SC',
    'key': 'SC'
  },
  {
    'country': 'Sierra Leone',
    'telcode': '+232',
    'ccode': 'SL',
    'key': 'SL'
  },
  {
    'country': 'Singapore',
    'telcode': '+65',
    'ccode': 'SG',
    'key': 'SG'
  },
  {
    'country': 'Slovakia',
    'telcode': '+421',
    'ccode': 'SK',
    'key': 'SK'
  },
  {
    'country': 'Slovenia',
    'telcode': '+386',
    'ccode': 'SI',
    'key': 'SI'
  },
  {
    'country': 'Solomon Islands',
    'telcode': '+677',
    'ccode': 'SB',
    'key': 'SB'
  },
  {
    'country': 'Somalia',
    'telcode': '+252',
    'ccode': 'SO',
    'key': 'SO'
  },
  {
    'country': 'South Africa',
    'telcode': '+27',
    'ccode': 'ZA',
    'key': 'ZA'
  },
  {
    'country': 'South Georgia and the South Sandwich Islands',
    'telcode': '+',
    'ccode': 'GS',
    'key': 'GS'
  },
  {
    'country': 'Spain',
    'telcode': '+34',
    'ccode': 'ES',
    'key': 'ES'
  },
  {
    'country': 'Sri Lanka',
    'telcode': '+94',
    'ccode': 'LK',
    'key': 'LK'
  },
  {
    'country': 'Sudan',
    'telcode': '+249',
    'ccode': 'SD',
    'key': 'SD'
  },
  {
    'country': 'Suriname',
    'telcode': '+597',
    'ccode': 'SR',
    'key': 'SR'
  },
  {
    'country': 'Svalbard and Jan Mayen',
    'telcode': '+47',
    'ccode': 'SJ',
    'key': 'SJ'
  },
  {
    'country': 'Swaziland',
    'telcode': '+268',
    'ccode': 'SZ',
    'key': 'SZ'
  },
  {
    'country': 'Sweden',
    'telcode': '+46',
    'ccode': 'SE',
    'key': 'SE'
  },
  {
    'country': 'Switzerland',
    'telcode': '+41',
    'ccode': 'CH',
    'key': 'CH'
  },
  {
    'country': 'Syrian Arab Republic',
    'telcode': '+963',
    'ccode': 'SY',
    'key': 'SY'
  },
  {
    'country': 'Taiwan, Province of China',
    'telcode': '+886',
    'ccode': 'TW',
    'key': 'TW'
  },
  {
    'country': 'Tajikistan',
    'telcode': '+992',
    'ccode': 'TJ',
    'key': 'TJ'
  },
  {
    'country': 'Tanzania, United Republic of',
    'telcode': '+255',
    'ccode': 'TZ',
    'key': 'TZ'
  },
  {
    'country': 'Thailand',
    'telcode': '+66',
    'ccode': 'TH',
    'key': 'TH'
  },
  {
    'country': 'Timor-Leste',
    'telcode': '+670',
    'ccode': 'TL',
    'key': 'TL'
  },
  {
    'country': 'Togo',
    'telcode': '+228',
    'ccode': 'TG',
    'key': 'TG'
  },
  {
    'country': 'Tokelau',
    'telcode': '+690',
    'ccode': 'TK',
    'key': 'TK'
  },
  {
    'country': 'Tonga',
    'telcode': '+676',
    'ccode': 'TO',
    'key': 'TO'
  },
  {
    'country': 'Trinidad and Tobago',
    'telcode': '+1',
    'ccode': 'TT',
    'key': 'TT'
  },
  {
    'country': 'Tunisia',
    'telcode': '+216',
    'ccode': 'TN',
    'key': 'TN'
  },
  {
    'country': 'Turkey',
    'telcode': '+90',
    'ccode': 'TR',
    'key': 'TR'
  },
  {
    'country': 'Turkmenistan',
    'telcode': '+993',
    'ccode': 'TM',
    'key': 'TM'
  },
  {
    'country': 'Turks and Caicos Islands',
    'telcode': '+1',
    'ccode': 'TC',
    'key': 'TC'
  },
  {
    'country': 'Tuvalu',
    'telcode': '+688',
    'ccode': 'TV',
    'key': 'TV'
  },
  {
    'country': 'Uganda',
    'telcode': '+256',
    'ccode': 'UG',
    'key': 'UG'
  },
  {
    'country': 'Ukraine',
    'telcode': '+380',
    'ccode': 'UA',
    'key': 'UA'
  },
  {
    'country': 'United Arab Emirates',
    'telcode': '+971',
    'ccode': 'AE',
    'key': 'AE'
  },
  {
    'country': 'United Kingdom',
    'telcode': '+44',
    'ccode': 'GB',
    'key': 'GB'
  },
  {
    'country': 'United States',
    'telcode': '+1',
    'ccode': 'US',
    'key': 'US'
  },
  {
    'country': 'United States Minor Outlying Islands',
    'telcode': '+1',
    'ccode': 'UM',
    'key': 'UM'
  },
  {
    'country': 'Uruguay',
    'telcode': '+598',
    'ccode': 'UY',
    'key': 'UY'
  },
  {
    'country': 'Uzbekistan',
    'telcode': '+998',
    'ccode': 'UZ',
    'key': 'UZ'
  },
  {
    'country': 'Vanuatu',
    'telcode': '+678',
    'ccode': 'VU',
    'key': 'VU'
  },
  {
    'country': 'Venezuela, Bolivarian Republic of',
    'telcode': '+58',
    'ccode': 'VE',
    'key': 'VE'
  },
  {
    'country': 'Viet Nam',
    'telcode': '+84',
    'ccode': 'VN',
    'key': 'VN'
  },
  {
    'country': 'Virgin Islands, British',
    'telcode': '+1',
    'ccode': 'VG',
    'key': 'VG'
  },
  {
    'country': 'Virgin Islands, U.S.',
    'telcode': '+1',
    'ccode': 'VI',
    'key': 'VI'
  },
  {
    'country': 'Wallis and Futuna',
    'telcode': '+681',
    'ccode': 'WF',
    'key': 'WF'
  },
  {
    'country': 'Western Sahara',
    'telcode': '+212',
    'ccode': 'EH',
    'key': 'EH'
  },
  {
    'country': 'Yemen',
    'telcode': '+967',
    'ccode': 'YE',
    'key': 'YE'
  },
  {
    'country': 'Zambia',
    'telcode': '+260',
    'ccode': 'ZM',
    'key': 'ZM'
  },
  {
    'country': 'Zimbabwe',
    'telcode': '+263',
    'ccode': 'ZW',
    'key': 'ZW'
  }
]