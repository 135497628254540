/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import { Form, Input } from 'semantic-ui-react'
import style from './ratesinput.less'
import localise from 'languages'
import currencyOptions from 'resources/geography/currencies'

type RateInputProps = {
    label?: string,
    currency: string,
    onChange: Function,
    value?: string | number,
    start?: number,
    end?: number,
    index?: number,
    warning?: boolean,
    min?: number,
    max?: number,
    name: string
}

const RateInput = (props: RateInputProps) => {
    const {
        label,
        currency,
        onChange,
        start,
        end,
        index,
        warning,
        min,
        max,
        name,
        value = ''
    } = props

    const symbol = currencyOptions.find(currencyOption => currencyOption.value === currency)?.symbol
    const styling = warning ? style.ratesinput__row__warning : style.ratesinput__row
    
    return (
        <Form.Field 
            required
        >
            <Input
                label={{ className: style.ratesinput__row__label, content: label, detail: symbol }}
                className={styling}
                type='number'
                step='1'
                onChange={onChange}
                name={name}
                value={value}
                placeholder={localise('RATES_INPUT.INPUT_PLACEHOLDER')}
                start={start}
                end={end}
                index={index}
                min={min}
                max={max}
            />
        </Form.Field>
    )
}

export default RateInput