/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import style from './layout.less'
import { Link } from 'react-router-dom'
import branding from '../../resources/images/branding-inverted.png'

const Branding = () => {
    return (
        <div className={style.layout__branding}>
            <Link to='/'>
                <h1>
                    <img className={style.layout__branding__logo} src={branding} />
                </h1>
            </Link>
        </div>
    )
}

export default Branding