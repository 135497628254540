/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

import React from 'react'

import {
    Icon,
    Button
} from 'semantic-ui-react'

const BackButton = ({onClick, text = 'Back', icon = 'arrow left', ...rest}) => {
    return (
        <Button
            onClick={onClick}
            basic
            color='grey'
            animated
            {...rest}
        >
            <Button.Content visible>{text}</Button.Content>
            <Button.Content hidden>
                <Icon name={icon} />
            </Button.Content>
        </Button>
    )
}

export default BackButton