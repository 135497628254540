/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Feb 2019
 * ========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import { graphql } from 'react-apollo'
import { rejectCertification } from 'queries/certifications'
import { Modal, Form, Button } from 'semantic-ui-react'
import { showErrorNotification, showSuccessNotification } from 'utils/notifications'
import type { Certification, ID } from 'utils/flowtypes/models'
import type { SemanticEvent, SemanticEventProps } from 'utils/flowtypes/semantic'

type Props = {
    certification: Certification,
    rejectCertification: Function,
    open: boolean,
    onSubmit(certificationId: ID): void,
    onClose(): void
}

type State = {
    reason: string,
    processing: boolean
}

class RejectCertificationModal extends PureComponent<Props, State> {
    state = {
        reason: '',
        processing: false
    }

    handleSubmit = async () => {
        this.setState({ processing: true })
        
        const { certification, onSubmit, onClose } = this.props
        const { reason } = this.state
        
        const variables = {}
        variables.certificationId = certification.id
        if (reason) {
            variables.note = {
                contents: reason,
                tags: ['approval', 'certification', 'property', 'rejection']
            }
        }

        const response = await this.props.rejectCertification({
            variables
        }).catch(e => e)

        this.setState({ processing: false })

        if (response instanceof Error) {
            console.error(response)
            showErrorNotification('Unable to reject certification. Please try again later.')
        } else {
            showSuccessNotification('Certification rejected.')
            onSubmit(certification.id)
            onClose()
        }
    }

    handleChange = (e: SemanticEvent, props: SemanticEventProps) => {
        this.setState({ [props.name]: props.value })
    }

    render() {
        const { open, onClose } = this.props
        const { reason, processing } = this.state

        return (
            <Modal
                open={open}
                onClose={onClose}
                size='tiny'
            >
                <Modal.Header>Reject Certificate</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.TextArea
                            value={reason}
                            onChange={this.handleChange}
                            name='reason'
                            autoHeight
                            placeholder='This will be shown to the user'
                            label='Reason for rejection (optional)'
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={onClose}
                        content='Cancel'
                    />
                    <Button
                        primary
                        onClick={this.handleSubmit}
                        disabled={processing}
                        loading={processing}
                        content='Submit'
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

export default graphql(
    rejectCertification,
    {
        name: 'rejectCertification'
    }
)(RejectCertificationModal)