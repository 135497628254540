/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

// @flow

import React from 'react'
import ErrorBoundary from 'ErrorBoundary'
import localise from 'languages'
import { Form } from 'semantic-ui-react'
import type { Guest } from 'utils/flowtypes/models'

type Props = {
    onChange: Function,
    maxOccupants: string,
    guests: Array<Guest>
}

const GuestSearchDropdown = (props: Props) => {
    const { maxOccupants, onChange, guests } = props
    const label = `${localise('GUEST.SELECT') } (Max ${maxOccupants} occupants)`

    return (
        <ErrorBoundary>
            <Form.Dropdown
                placeholder={localise('GUEST.SELECT')}
                name='selectedGuests'
                onChange={onChange}
                options={guests}
                label={label}
                multiple
                search
                fluid
                selection
            />
        </ErrorBoundary>
    )
}

export default GuestSearchDropdown