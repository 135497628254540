/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import { combineReducers } from 'redux'
import user from './user/reducers'
import session from './session/reducers'
import locale from './locale/reducers'
import itinerary from './itinerary/reducers'


import type { UserState } from './user/reducers'
import type { SessionState } from './session/reducers'

export type StateShape = {
    user: ?UserState,
    session: ?SessionState,
    locale: ?Object, // ToDo: Types
}

const sapApp = combineReducers({
    user,
    session,
    locale,
    itinerary
})

export default sapApp