/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

const SUPPORTED_LANGUAGES = [
    {
        name: 'English',
        value: 'en-US',
        loader: async () => await import('./en-US.json')
    },
    {
        name: 'Français',
        value: 'fr-FR',
        loader: async () => await import('./fr-FR.json')
    }
]

export default SUPPORTED_LANGUAGES