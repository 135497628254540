/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

// @flow

import { LOGIN, LOGOUT } from './actions'
import { HYDRATE } from 'store/store.js'

import type { User } from 'utils/flowtypes/models'

export type UserState = User

export default function user(state: ?UserState = null, action: Object) {
    switch (action.type) {
        case LOGIN:
            return action.user
        case LOGOUT:
            return null
        case HYDRATE:
            return action.serializedState.user
        default:
            return state
    }
}