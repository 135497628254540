/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import { connect } from 'react-redux'

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const UserGate = (props) => {
    if (props.user) {
        if (props.allowAdmin === false && props.user.is_admin) {
            return null
        }
        return props.children
    }
    
    return null
}

export default connect(mapStateToProps)(UserGate)
