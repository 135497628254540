/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Yunjia Li <yunjia.li@launchbase.solutions> Nov 2018
 * ========================================================================
 */

import gql from 'graphql-tag'

export const getGuest = gql`
    query(
        $guestId: ID!
    ){
        guest(
            id: $guestId
        ){
            id
            name
            email
            contact_number
            company{
                name
                trading_name
                homepage
                about
                address
                contact_number
                vat_number
                country_id
            }
            attachments{
                id
                name
                description
                type
                url
                tags
            }
        }
    }
`

export const addGuest = gql`
    mutation(
        $companyId: ID!,
        $guestDetails: GuestInput!
    ){
        addGuest(
            company_id:$companyId,
            guest_details:$guestDetails
        ){
            id
            name
            email
            contact_number
        }
    }
`

export const getGuests = gql`
    query($companyId:ID!){
        company(id:$companyId){
            guests{
                id
                name
                email
                contact_number
				attachments{
					id
					name
					description
					type
					url
				}
            }
        }
    }
`

export const updateGuest = gql`
    mutation(
        $guestId:ID!,
        $guestDetails: GuestInput
        $guestCompanyDetails: CompanyInput
    ){
        updateGuest(
            guest_id: $guestId
              guest_details: $guestDetails
              guest_company_details: $guestCompanyDetails
        ){
            id
            name
            email
            contact_number
        }
    }
`

export const addGuestAttachment = gql`
	 mutation(
        $guestId: ID!,
        $name: String,
        $description: String,
        $type: AttachmentTypeEnum!,
        $url: String!
    ){
        addGuestAttachment(
            guest_id: $guestId
            name: $name
            description: $description
            type: $type
            url: $url
        ){
            id
            name
            description
            type
            url
        }
    }
`

export const removeAttachment = gql`
    mutation(
        $attachmentId:ID!
    ){
        deleteAttachment(
            attachment_id: $attachmentId
        )
    }
`