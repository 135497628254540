/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import gql from 'graphql-tag'

export const getPendingUserApprovals = gql`
    query{
        users(approved: pending){
            pagination{
                count
                totalPages
                nextPage
            }
            list{
                id
                name
                email
                company{
                    id
                    name
                    address
                }
            }
        }
    }
`

export const getPendingAccommodationApprovals = gql`
    query{
        accommodations(
            approved: pending,
            listed: true
        ){
            pagination{
                count
                totalPages
                nextPage
            }
            list{
                id
                name
                property{
                    id
                    name
                    address
                    company{
                        id
                        name
                        address
                    }
                }
                listed
            }
        }
    }
`

/**
 * Handling User Approvals
 */

export const approveUser = gql `
    mutation(
        $userId: ID!
    ){
        approveUser(
            user_id: $userId
        ){
            id
            approved
        }
    }
`

export const rejectUser = gql `
    mutation(
        $userId: ID!,
        $note: NoteInput
    ){
        rejectUser(
            user_id: $userId,
            note: $note
        ){
            id
            approved
        }
    }
`

export const resetUserApproval = gql `
    mutation(
        $id:ID!
    ){
        resetUserApproval(
            id:$id
        ){
            id
            approved
        }
    }
`

export const resubmitUser = gql`
    mutation(
        $userId:ID!,
        $userDelta: UserInput!
    ){
        resubmitUser(
            id: $userId,
            userDelta: $userDelta
        ){
            id
            approved
        }
    }
`

/**
 * Handling Accommodation Approvals
 */

export const approveAccommodation = gql `
    mutation(
        $accommodationId: ID!
    ){
        approveAccommodation(
            accommodation_id: $accommodationId
        ){
            id
            approved
        }
    }
`

export const rejectAccommodation = gql`
    mutation(
        $accommodationId: ID!,
        $note: NoteInput
    ){
        rejectAccommodation(
            accommodation_id: $accommodationId,
            note: $note
        ){
            id
            approved
        }
    }
`

// export const rejectAccommodation = gql `
//     mutation(
//         $apartmentTypeId:ID!,
//         $reason: String
//     ){
//         rejectApartmentType(
//             apartmentTypeId:$apartmentTypeId,
//             reason:$reason
//         ){
//             id
//             approved
//         }
//     }
// `

export const resubmitAccommodation = gql `
    mutation(
        $id:ID!,
        $userDelta: UserInput!
    ){
        resubmitApartmentType(
            id:$id,
        ){
            id
            approved
        }
    }
`

/**
 * Handling Company Approvals
 */

export const rejectCompany = gql `
    mutation(
        $companyId: ID!,
        $note: NoteInput
    ){
        rejectCompany(
            company_id: $companyId,
            note: $note
        ){
            id
        }
    }
`

export const approveCompany = gql `
    mutation(
        $companyId: ID!
    ){
        approveCompany(
            company_id: $companyId
        ){
            id
            approved
        }
    }
`

export const resubmitCompany = gql`
    mutation(
        $companyId: ID!,
        $companyDelta: CompanyInput
    ){
        resubmitCompany(
            company_id: $companyId,
            company_delta: $companyDelta
        ){
            id
        }
    }
`