/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import { connect } from 'react-redux'

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const ProviderGate = props => {
    if (props.user?.company?.is_provider || (props.allowAdmin && props.user?.is_admin))
        return props.children

    return null
}

export default connect(mapStateToProps)(ProviderGate)
