/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import React from 'react'
import _ from 'lodash'

/**
 * Truncates and beautifies an address given in the following format:
 * "Building,Street,City,Region,Country,Code" to "Building, Code"
 * 
 * @param {string} address
 * @return {string}
 */
export function truncatePropertyAddress(address = '') {
    if (address) {
        const addressBits = address.split(',')
        if (addressBits.length >= 2)
            address = `${addressBits[0]}, ${addressBits[addressBits.length - 1]}`
    }

    return address
}

/**
 * Return the vague address of the property:
 * "Building,Street,City,Region,Country,Code" to "City"
 * 
 * @param {string} address
 * @return {string}
 */
export function vaguePropertyAddress(address = ''){
    if (address) {
        const addressBits = address.split(',')
        if (addressBits.length >= 1)
            address = `${addressBits[1]}`
        else(
            address = addressBits[0]
        )
    }

    return address
}

/**
 * Splits a comma-separated address string in the following format:
 * "Building,Street,City,Region,Country,Code" to ['Building','...rest']
 * 
 * @param {string} address
 * @return {Array}
 */
export function splitPropertyAddress(address = '') {
    return address ? address.split(/,(.+)/) : []
}


/**
 * Shortcut function to truncate an address into its first and last parts,
 * and then split it so it's available as the first and last part
 * 
 * @param {string} address
 * @return {Array}
 */
export function truncateAndSplitPropertyAddress(address = '') {
    return splitPropertyAddress(truncatePropertyAddress(address))
}

export function getPostCodeFromPropertyAddress(address = '') {
    return address.split(',').pop().trim()
}

/**
 * Inserts spaces after each of the commas in an address string
 * 
 * @param {string} address
 * @return {string}
 */
export function beautifyPropertyAddress(address = '') {
    return address.split(',').join(', ')
}

/**
 * Truncates text to the first paragraph, and then truncates the result
 * to the specified length.
 * 
 * @param {string} text 
 * @param {number} length 
 */
export function getTextHighlight(text = '', length = 500)
{
    text = text.split('\n')[0]
    text = _.truncate(text, { length })

    return text
}

/**
 * 
 * @param {String[]} addressBits 
 */
export function addressBitsToHTML(addressBits = []) {
    return (
        <address>
            {
                addressBits.map((bit, index) => {
                    if (bit) return <span key={index}>{bit}<br/></span>
                })
            }
        </address>
    )
}

/**
 * 
 * @param {String[]} addressBits 
 */
export function addressBitsToText(addressBits = []) {
    let addressText = ''
    addressBits.map((bit, index) => {
        if (bit) {
            if(index === 0){
                addressText += bit
            }
            else{
                addressText += `,${  bit}`
            }
        }
    })

    return addressText
}