/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Louis Capitanchik <louis.capitanchik@launchbase.solutions>, December 2018
 */

// @flow

import * as React from 'react'
import { displayName } from 'utils/react'

import typeof WhippetManager from 'services/whippet/ChatManager'

export const WhippetWrapper = React.createContext({ whippet: null })

export function withChat(WrappedComponent: React.ComponentType<*>) {
    type InheritedProps = $Diff<React.ElementProps<typeof WrappedComponent>, { whippet: WhippetManager | void }>
    class WithChat extends React.Component<InheritedProps> {
        static displayName = `withChat(${ displayName(WrappedComponent) })`
        render() {
            const { forwardRef, ...props } = this.props
            return (
                <WhippetWrapper.Consumer>
                    { value => <WrappedComponent {...props} whippet={value.whippet} ref={forwardRef} /> }
                </WhippetWrapper.Consumer>
            )
        }
    }

    // $FlowFixMe See https://github.com/facebook/flow/issues/6103
    return React.forwardRef((props, ref) => <WithChat {...props} forwardRef={ref} />)
}