/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

import React, { Fragment } from 'react'

import {
    Icon,
    Button
} from 'semantic-ui-react'

const NextButton = props => {
    const {
        onClick,
        text = 'Next',
        icon = 'arrow right',
        loading = false,
        disabled = false,
        ...rest
    } = props

    return (
        <Button
            onClick={onClick}
            primary 
            animated
            disabled={disabled || loading}
            {...rest}
        >
            {
                loading ? (
                    <Fragment>
                        <Button.Content visible>
                            <Icon loading name='spinner' />
                        </Button.Content>
                        <Button.Content hidden>{text}</Button.Content>
                    </Fragment>
                ) : (
         
                    <Fragment>
                        <Button.Content visible>{text}</Button.Content>
                        <Button.Content hidden>
                            <Icon name={icon} />
                        </Button.Content>
                    </Fragment>
                )
            }
        </Button>
    )
}

export default NextButton