/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

export default [
    {
        key: 'GBP',
        text: 'GBP - British Pound',
        value: 'GBP',
        symbol: '£'
    },
    // {
    //     key: 'USD',
    //     text: 'USD - US Dollar',
    //     value: 'USD',
    //     symbol: '$'
    // },
    // {
    //     key: 'CAD',
    //     text: 'CAD - Canadian Dollar',
    //     value: 'CAD',
    //     symbol: '$'
    // },
    // {
    //     key: 'EUR',
    //     text: 'EUR - Euro',
    //     value: 'EUR',
    //     symbol: '€'
    // }
]