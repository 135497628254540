/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import React from 'react'
import style from 'styles/app.less'

const FormattedAddress = ({ address }) => {
    const addressBits = address.split(',')

    return (
        <address className={style.address}>
            {
                addressBits.map((bit, index) => {
                    return (
                        <span key={index}>{bit}</span>
                    )
                })
            }
        </address>
    )
}

export default FormattedAddress