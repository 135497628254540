/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import type { PriceRanges, PricingInputs } from 'utils/flowtypes/models'
import { displayPrice } from 'utils/money'

export function getLowestPriceFromPricing(pricing: PriceRanges) {
    return pricing.reduce((acc, obj) => {
        return obj.price < acc ? obj.price : acc
    }, Infinity)
}

export function transformPriceRangeToPricingInput(currency: string, priceRange: PriceRanges): PricingInputs {
    return priceRange.map(price => ({
        start: price.start,
        end: price.end,
        price: displayPrice(currency, price.price, false)
    }))
}
