/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Nov 2018
 * ========================================================================
 */

// @flow

import React, { PureComponent, Fragment } from 'react'
import { NumberInput } from 'components'
import { Form, List, Modal, Button, Header, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import type { SemanticEvent, SemanticEventProps } from 'utils/flowtypes/semantic'
import { showErrorNotification } from 'utils/notifications'
import { duplicateAccommodation } from 'queries/accommodation'
import { graphql, withApollo } from 'react-apollo'
import style from './manageaccommodation.less'

type Props = {
    open: boolean,
    onClose: Function,
    accommodationId: string,
    duplicateAccommodation: Function
}

type State = {
    numberOfDuplicates: number,
    newAccommodationList: Array<{
        id: string,
        name: string
    }>,
    processing: boolean,
    finished: boolean
}

class DuplicateAccommodationModal extends PureComponent<Props, State> {
    state = {
        numberOfDuplicates: 1,
        processing: false,
        newAccommodationList: [],
        finished: false
    }   

    handleChange = (e: SemanticEvent, props: SemanticEventProps) => {
        this.setState({ [props.name]: props.value })
    }

    handleDuplicateAccommodation = async () => {
        const { accommodationId } = this.props
        const { numberOfDuplicates } = this.state

        this.setState({ processing: true })

        const duplicateFn = () => this.props.duplicateAccommodation({
            variables: { accommodationId }
        })

        const newAccommodation = Array(parseInt(numberOfDuplicates)).fill()

        const results = await Promise.all(
            newAccommodation.map(() => {
                return duplicateFn().catch(e => {
                    console.error(e)
                    return e
                })
            })
        )

        if (results.every(response => response instanceof Error)) {
            showErrorNotification('Unable to duplicate accommodation')
            console.error('No accommodations were added')
        } else {
            if (results.some(response => response instanceof Error)) {
                console.error('Not all accommodation was added')
            }

            let newAccommodationList = []

            results.forEach(response => {
                if (!(response instanceof Error)) {
                    newAccommodationList.push({
                        id: response.data.duplicateAccommodation.id,
                        name: response.data.duplicateAccommodation.name
                    })
                }
            })

            this.setState({
                newAccommodationList,
                finished: true
            })
        }

        this.setState({ processing: false })
    }

    render() {
        const { open, onClose } = this.props

        const { finished, numberOfDuplicates, newAccommodationList, processing } = this.state

        return (
            <Modal
                open={open}
                size='tiny'
            >
                <Modal.Header>Duplicate Accommodation</Modal.Header>
                {
                    finished ? (
                        <Fragment>
                            <Modal.Content>
                                <div className={style.duplicate__modal__finished}>
                                    <Header as='h3' icon>
                                        <Icon color='green' name='check circle outline' />
                                        All Done!
                                        <Header.Subheader>
                                            Successfully added the following accommodation
                                        </Header.Subheader>
                                    </Header>
                                    <div className={style.duplicate__modal__accommlist}>
                                        <List selection verticalAlign='middle'>
                                            {
                                                newAccommodationList.map(accomm => {
                                                    return (
                                                        <List.Item key={accomm.id}>
                                                            <Icon name='pencil' />
                                                            <List.Content>
                                                                <List.Header as={Link} to={`/accommodation/${accomm.id}`}>{accomm.name}</List.Header>
                                                            </List.Content>
                                                        </List.Item>
                                                    )
                                                })
                                            }
                                        </List>
                                    </div>
                                </div>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    primary
                                    onClick={onClose}
                                    content='Close'
                                />
                            </Modal.Actions>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Modal.Content>
                                <Form>
                                    <NumberInput
                                        min={1}
                                        max={99}
                                        label='Number of duplicates to make'
                                        value={numberOfDuplicates}
                                        name='numberOfDuplicates'
                                        onChange={this.handleChange}
                                    />
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    onClick={onClose}
                                    basic
                                    primary
                                    content='Cancel'
                                />
                                <Button
                                    primary
                                    loading={processing}
                                    onClick={this.handleDuplicateAccommodation}
                                    content={numberOfDuplicates > 1 ? `Duplicate x${numberOfDuplicates}` : 'Duplicate'}
                                />
                            </Modal.Actions>
                        </Fragment>
                    )
                } 
            </Modal>
        )
    }
}

export default graphql(
    duplicateAccommodation,
    {
        name: 'duplicateAccommodation'
    }
)(withApollo(DuplicateAccommodationModal))