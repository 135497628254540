/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Yunjia Li <yunjia.li@launchbase.solutions>, Dec 2018
 * ========================================================================
 */


/**
 * Generate the text to be displayed in the booking list table
 */
export function getInvoiceNotificationText(invoices) {
    const pendingInvoices = invoices.filter(i=>i.status === 'pending')

    if(pendingInvoices.length === 0){
        return 'No pending invoices.'
    }
    else{
        return `${pendingInvoices.length} invoices pending.`
    } 
}