/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React from 'react'
import { Form, Divider, Message, Icon } from 'semantic-ui-react'
import ErrorBoundary from 'ErrorBoundary'
import style from './ratesinput.less'
import type { PricingInput } from 'utils/flowtypes'
import RateInput from './RateInput'
import currencyOptions from 'resources/geography/currencies'

type Props = {
    currency: string,
    value: PricingInput,
    name: string,
    onChange: Function,
    max?: number,
    min?: number
}

const RatesInput = (props: Props) => {
    
    const { currency, value, name, onChange, min, max } = props
    
    function handlePricingChange(event, { index, name, value, start, end }) {
        let newPricing = props.value.slice()
        
        newPricing[index] = {
            start: start,
            end: end,
            price: value
        }

        onChange(event, {
            name,
            value: newPricing
        })
    }

    return (
        <ErrorBoundary>
            <div className={style.ratesinput}>
                <RateInput
                    currency={currency}
                    onChange={handlePricingChange}
                    label='1 - 7 nights'
                    start={1}
                    end={7}
                    index={0}
                    value={value[0]?.price}
                    min={min}
                    max={max}
                    name={name}
                />
                <RateInput
                    currency={currency}
                    onChange={handlePricingChange}
                    label='8 - 28 nights'
                    start={8}
                    end={28}
                    index={1}
                    value={value[1]?.price}
                    min={min}
                    max={max}
                    name={name}
                    // warning={value[1]?.price && value[0]?.price && value[1].price > value[0].price}
                />
                <RateInput
                    currency={currency}
                    onChange={handlePricingChange}
                    label='29 - 89 nights'
                    start={29}
                    end={89}
                    index={2}
                    value={value[2]?.price}
                    min={min}
                    max={max}
                    name={name}
                    // warning={value[2]?.price && value[1]?.price && value[2].price > value[1].price}
                />
                <RateInput
                    currency={currency}
                    onChange={handlePricingChange}
                    label='90+ nights'
                    start={90}
                    index={3}
                    value={value[3]?.price}
                    min={min}
                    max={max}
                    name={name}
                    // warning={value[3]?.price && value[2]?.price && value[3].price > value[2].price}
                />
                <Message info>
                    <Icon name='lightbulb' />
                    Remember to factor in our platform commissions and fees when setting your rates.
                    As a rule-of-thumb, 85% of the night rate (plus tax) is earned on a booking.
                </Message>
                <Divider />
                <Form.Dropdown
                    button
                    basic
                    compact
                    inline
                    options={currencyOptions}
                    text={currency}
                    value={currency}
                    onChange={onChange}
                    name='currency'
                    label='Change currency'
                />
            </div>
        </ErrorBoundary>
    )
}



export default RatesInput