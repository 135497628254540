/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Sept 2018
 * ========================================================================
 */

// @flow

import React, { Fragment } from 'react'
import { Header, Form, Message, Icon, Checkbox, Button } from 'semantic-ui-react'
import localise from 'languages'
import { Link } from 'react-router-dom'
import style from './registerform.less'

type Props = {
    onChange: Function,
    name: string,
    email: string,
    password: string,
    confirmPassword: string,
    onClickNext: Function,
    isProvider: string | false
}

const BasicInformation = (props: Props) => {
    const {
        onChange,
        name,
        email,
        password,
        confirmPassword,
        onClickNext,
        isProvider
    } = props

    function handleProviderToggle(event, { name, value }) {
        if (value === isProvider) value = false
        onChange(event, { name, value })
    }

    const passwordMismatch = password !== confirmPassword
    const disableNextButton = !name || !password || !confirmPassword || !email || passwordMismatch

    return (
        <Fragment>
            <Header as='h3'>
                Register a roosts.com Account
                <Header.Subheader>Your Details</Header.Subheader>
            </Header>
            <Form.Input
                fluid
                label='Full name'
                placeholder='Full Name'
                icon='user'
                iconPosition='left'
                name='name'
                required
                onChange={onChange}
                value={name}
            />
            <Form.Input
                fluid
                label={localise('EMAIL_ADDRESS')}
                placeholder={localise('EMAIL_ADDRESS')}
                icon='mail'
                iconPosition='left'
                name='email'
                required
                onChange={onChange}
                value={email}
            />
            <Form.Group widths='equal'>
                <Form.Input
                    type='password'
                    label='Password'
                    placeholder='Password'
                    icon='lock'
                    iconPosition='left'
                    name='password'
                    required
                    onChange={onChange}
                    value={password}
                />
                <Form.Input
                    type='password'
                    label='Confirm password'
                    placeholder='Confirm password'
                    icon='lock'
                    iconPosition='left'
                    name='confirmPassword'
                    required
                    onChange={onChange}
                    value={confirmPassword}
                    error={confirmPassword !== '' && passwordMismatch}
                />
            </Form.Group>
            <div className={style.register__form__footer}>
                <Message info>
                    <Message.Content>
                        <Message.Header>Are you a serviced apartment provider?</Message.Header>
                        <br />
                        <Checkbox
                            label='Yes, sign me up to the beta program'
                            value='isProvider'
                            name='isProvider'
                            checked={isProvider === 'isProvider'}
                            onChange={handleProviderToggle}
                        />
                    </Message.Content>
                </Message>
                <div className={style.register__form__footer__actions}>
                    <div>
                        <Button
                            as={Link}
                            to='/login'
                            basic
                            primary
                            size='large'
                        >
                            Log in instead
                        </Button>
                    </div>
                    <div>
                        <Button
                            onClick={onClickNext}
                            primary
                            animated
                            size='large'
                            style={{
                                margin: 0
                            }}
                            disabled={disableNextButton}
                        >
                            <Button.Content visible>Next</Button.Content>
                            <Button.Content hidden>
                                <Icon name='arrow right' />
                            </Button.Content>
                        </Button>
                    </div>
                </div>
            </div>
        </Fragment>  
    )
}

export default BasicInformation