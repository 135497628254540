/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, May 2018
 * ========================================================================
 */

import React, { PureComponent } from 'react'
import dom from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import Loadable from 'react-loadable'
import { Dimmer, Loader } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css' 
import 'resources/semantic-roosts.css'
import 'resources/daypicker-roosts.css'
import { HttpLink, ApolloLink, InMemoryCache } from 'apollo-boost'
import { config } from 'config'
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { Provider } from 'react-redux'
import { store, hydrate } from 'store/store.js'
import { logout } from 'store/user/actions'
import { setAccessToken, setRefreshToken, setSessionExpiration } from 'store/session/actions'
import ErrorBoundary from 'ErrorBoundary'
import { StandardError } from 'components'
import { onError } from 'apollo-link-error'
import { showErrorNotification } from 'utils'
import Whippet from 'services/whippet/ChatManager'
import { WhippetWrapper } from 'components/Chat/ChatProvider'
import { StripeProvider } from 'react-stripe-elements'

const httpLink = new HttpLink({ uri: config.graphql.uri })

const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        const sessionExpired = graphQLErrors.some(graphQLError => {
            return (graphQLError.error && graphQLError.error.code === 'A-IT-OAT-TOKEN')
        })
        if (sessionExpired) {
            showErrorNotification('Your session expired. Please log back in again to continue.')
            store.dispatch(logout())
            store.dispatch(setAccessToken(null))
            store.dispatch(setRefreshToken(null))
            store.dispatch(setSessionExpiration(null))
        }
    }
})

const middlewareLink = new ApolloLink((operation, forward) => {
    const session = store.getState().session

    if (session.accessToken) {
        operation.setContext({
            headers: {
                authorization: `Bearer ${session.accessToken}`
            }
        })
    }

    return forward(operation)
}).concat(errorLink)

const link = ApolloLink.from([
    middlewareLink,
    httpLink
])

const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache({
        fragmentMatcher: {
            match: ({ id }, typeCond, context) => !!context.store.get(id)
        }
    }).restore(window.__APOLLO_STATE__)
})

const LoadableApp = Loadable({
    loader: () => import('./App'),
    loading: function loadingApp(props) {
        if (props.error) {
            return (
                <StandardError header={props.error.toString()} stackTrace={props.error.stack} />
            )
        }

        return (
            <ErrorBoundary>
                <div style={
                    {
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#fff'
                    }
                }>
                    <Dimmer active inverted>
                        <Loader />
                    </Dimmer>
                </div>
            </ErrorBoundary>
        )
    }
})

class App extends PureComponent {
    state = {
        whippet: null
    }

    notificationSystem = null

    componentDidMount() {
        const serializedState = localStorage.getItem('state')
        if (serializedState) {
            store.dispatch(hydrate(JSON.parse(serializedState)))
        }
        this.setState({ whippet: new Whippet(config.whippet.appId, config.whippet.wsHost, config.whippet.apiHost) })
    }

    render() {
        return (
            <ApolloProvider client={client}>
                <StripeProvider apiKey={config.stripe.api_key}>
                    <Provider store={store}>
                        <WhippetWrapper.Provider value={{ whippet: this.state.whippet }}>
                            <Router>
                                <LoadableApp />
                            </Router>
                        </WhippetWrapper.Provider>
                    </Provider>
                </StripeProvider>
            </ApolloProvider>
        )
    }
}

dom.render(<App />, document.getElementById('root'))