/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, June 2018
 * ========================================================================
 */

import React from 'react'
import style from './notificationdisplay.less'
import { Message } from 'semantic-ui-react'

const NotificationsDisplay = props => {
    if (props.notifications.length) {
        const notificationMessages = props.notifications.map((notification, index) => {
            const messageProps = { [notification.type]: true }

            return (
                <Message key={index} {...messageProps} compact>
                    <Message.Header>{ notification.header }</Message.Header>
                    <p>{ notification.body }</p>
                </Message>
            )
        })

        return (
            <div className={style.notificationdisplay}>
               { notificationMessages }
            </div>
        )
    }

    return null 
}

export default NotificationsDisplay