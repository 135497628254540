exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".container__MqY9P {\n  margin-bottom: 1em;\n}\n.number__1y3Zy {\n  padding-left: 1rem;\n  padding-right: 1rem;\n  font-weight: bold;\n}\n.label__vMPOl {\n  color: rgba(0, 0, 0, 0.87);\n  font-size: 0.92857143em;\n  font-weight: 700;\n  text-transform: none;\n  margin: 0 0 0.28571429rem 0;\n  display: block;\n}\n", "", {"version":3,"sources":["/opt/build/repo/src/components/NumberInput/numberinput.less"],"names":[],"mappings":"AAAA;EACE,mBAAmB;CACpB;AACD;EACE,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;CACnB;AACD;EACE,2BAA2B;EAC3B,wBAAwB;EACxB,iBAAiB;EACjB,qBAAqB;EACrB,4BAA4B;EAC5B,eAAe;CAChB","file":"numberinput.less","sourcesContent":[".container {\n  margin-bottom: 1em;\n}\n.number {\n  padding-left: 1rem;\n  padding-right: 1rem;\n  font-weight: bold;\n}\n.label {\n  color: rgba(0, 0, 0, 0.87);\n  font-size: 0.92857143em;\n  font-weight: 700;\n  text-transform: none;\n  margin: 0 0 0.28571429rem 0;\n  display: block;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "container__MqY9P",
	"number": "number__1y3Zy",
	"label": "label__vMPOl"
};