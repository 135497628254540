/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yunjia Li <yunjia.li@launchbase.solutions> Nov 2018
 * ==========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import { withApollo, graphql, compose } from 'react-apollo'
import { GuestForm } from 'components'
import { addGuest, addGuestAttachment } from 'queries/guests'
import { Modal } from 'semantic-ui-react'
import { showErrorNotification, showSuccessNotification } from 'utils/notifications'
import type { SemanticEvent, SemanticEventProps } from 'utils/flowtypes/semantic'
import { uploadImageToCloudinary } from 'utils'

type Props = {
    open: boolean,
    onClose: Function,
    onSubmit: Function,
    companyId: string,
    addGuestAttachment: Function,
    addGuest: Function
}

type State = {
    guestName: string,
    guestEmail: string,
    contactNumber: string,
    images: Array<{
        id: string,
        name: string,
        description: string,
        url: string,
        type: string
    }>,
	derivedStateFromProps: boolean,
	loading:boolean
}

class GuestModal extends PureComponent<Props, State> {
    state = {
        guestName: '',
        guestEmail: '',
        contactNumber: '',
        images:[],
		derivedStateFromProps: false,
		loading: false
    }

    handleAddImage = images => this.setState({ images })

    handleChange = (event: SemanticEvent, props: SemanticEventProps) => {
        this.setState({ [props.name] : props.value })
    }

    handleAddGuest = async() => {
		const { guestName, guestEmail, contactNumber, images } = this.state

        const variables = {
            companyId: this.props.companyId,
            guestDetails:{
                name: guestName,
                email: guestEmail,
                contact_number: contactNumber
            }
		}

		let guestId = ''

        let response = await this.props.addGuest({
            variables
		}).catch(e => e)

        if (response instanceof Error) {
            showErrorNotification('Oops! Something went wrong.')
            console.error(response)
            return false
        } else {
            guestId = response.data.addGuest.id
		}

		const cloudinaryImages = await Promise.all(images.map(image => {
            return uploadImageToCloudinary(image)
		}))

		cloudinaryImages.map(async cloudinaryImage => {
            if (!(cloudinaryImage instanceof Error)) {
                await this.props.addGuestAttachment({
                    variables: {
                        guestId,
                        name: cloudinaryImage.name,
                        type: 'image',
                        url: cloudinaryImage.url
                    }
                }).catch(e => {
                    console.error(e)
                })
            }
		})

		showSuccessNotification(`Guest ${guestName} has been successfully added.`)
		this.props.onClose()
		return response.data.addGuest
	}

    handleSubmit = async() =>{
		this.setState({loading:true})
        const newGuest = await this.handleAddGuest()   
		await this.props.onSubmit(newGuest)
		this.setState({loading:false})
    }

    render(){
        const { guestName, guestEmail, contactNumber, images, loading } = this.state
        const { open, onClose } = this.props

        return (
            <Modal
                open={open}
                size='small'
            >
                <Modal.Header content='Add a new guest' />
                <Modal.Content>
                    <GuestForm
						isUpdate={false}
						guestId={null}
                        guestName={guestName}
                        guestEmail={guestEmail}
                        contactNumber={contactNumber}
                        images={images}
                        onCancel={onClose}
                        onAddImage={this.handleAddImage}
                        onChange={this.handleChange}
						onSubmit={this.handleSubmit}
						loading={loading}
                    />
                </Modal.Content>
            </Modal>
        )
    }
}

export default compose(
    graphql(
        addGuest,
        {
            name: 'addGuest'
        }
    ),
	graphql(
        addGuestAttachment,
        {
            name: 'addGuestAttachment'
        }
    )
)(withApollo(GuestModal))
