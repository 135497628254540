/**
 * @module WhippetClient
 *
 * The WhippetClient and Whippet Channel classes are an example implementation of the Whippet Javascript Client provided
 * alongside the Whippet server. The code inside this file would not need to be written by an application that uses
 * Whippet
 */

import * as msgpack from 'msgpack-lite'
import { EventEmitter2 } from 'eventemitter2'
import ReconnectingWebSocket from 'reconnecting-websocket'

const WHIPPET_EMITTER_CONFIG = {
    wildcard: true,
    delimiter: '::',
    maxListeners: 50,
}

export class WhippetChannel extends EventEmitter2 {
    constructor(client: WhippetClient, name: string) {
        super(WHIPPET_EMITTER_CONFIG)
        this._name = name
        this._client = client
    }

    send(type, message) {
        this._client._sendMessage({
            type,
            payload: message,
            channel: this._name,
        })
    }

    search(opts) {
        return this._client.search(this._name, opts)
    }
}

export class WhippetClient extends EventEmitter2 {
    get socketAddress() {
        const opts = this._opts.ws
        return [opts.tls ? 'wss' : 'ws', '://', opts.host, ':', opts.port, '/', this._appid].join('')
    }

    get apiAddress() {
        const opts = this._opts.api
        return [opts.tls ? 'https' : 'http', '://', opts.host, ':', opts.port, '/api/'].join('')
    }

    constructor(appid, opts) {
        super(WHIPPET_EMITTER_CONFIG)

        const client = this

        this._opts = Object.assign({ ws: { host: 'localhost', port: 80, tls: false }, api: { host: 'localhost', port: 80, tls: false } }, opts || {})

        this._appid = appid
        this._socket = null
        this._buffer = []
        this._channels = {}

        this._supports = []
        // Check required dependencies for msgpack support
        if (window.Uint8Array != null && window.ArrayBuffer != null) {
            this._supports.push('binary')
            this._encodeBinary = function encodeWhippetBinaryMessage(message) {
                return msgpack.encode(message)
            }
            this._decodeBinary = function decodeWhippetBinaryMessage(blob) {
                const reader = new FileReader()
                reader.addEventListener('loadend', function decodeWhippetArrayBuffer() {
                    const data = msgpack.decode(new Uint8Array(reader.result))
                    client._emitData(data)
                })
                reader.readAsArrayBuffer(blob)
            }
        }

        this._supports.push('text')

        const ws = new ReconnectingWebSocket(this.socketAddress, '', {minReconnectionDelay: 1})
        ws.onopen = function onWhippetSocketOpen() {
            client._socket = ws
            if (client._supports.includes('binary')) {
                client._sendSettings({ supports_binary: true })
            }
        }
        ws.onmessage = function onWhippetSocketMessage(message) {
            if (client._supports.includes('binary')) {
                client._decodeBinary(message.data)
            } else {
                client._decodeText(message.data)
            }
        }
        ws.onclose = function onWhippetSocketClose(e) {
            console.warn(e)
            if (client.throwOnClose) {
                throw new Error(`[${ e.code }] ${ e.reason }`)
            } else {
                client.emit('close', e)
            }

        }

        if (client.debug) {
            // eslint-disable-next-line no-console
            client.onAny((...args) => console.log('[DEBUG]', ...args))
        }
    }

    _encodeText(message) {
        return JSON.stringify(message)
    }
    _decodeText(message) {
        const data = JSON.parse(message)
        this._emitData(data)
    }

    _emitData(data) {
        if (data.channel != null) {
            const channel = this._channels[data.channel]
            if (channel != null) {
                channel.emit(data.type, data.payload)
            }
            this.emit(`channel::${  data.channel  }::${  data.type}`, data.payload)
        } else {
            this.emit(`broadcast::${  data.type}`, data.payload)
        }
    }

    _sendMessage(message) {
        if (this._encodeBinary) {
            this._socket.send(this._encodeBinary({
                frame_type: 'Data',
                message,
            }))
        } else {
            this._socket.send(this._encodeText({
                frame_type: 'Data',
                message,
            }))
        }
    }
    _sendSettings(settings) {
        if (this._encodeBinary) {
            this._socket.send(this._encodeBinary({
                frame_type: 'Settings',
                settings,
            }))
        } else {
            this._socket.send(this._encodeText({
                frame_type: 'Settings',
                settings,
            }))
        }
    }
    _sendChannel(name) {
        if (this._encodeBinary) {
            this._socket.send(this._encodeBinary({
                frame_type: 'Channel',
                name,
            }))
        } else {
            this._socket.send(this._encodeText({
                frame_type: 'Channel',
                name,
            }))
        }
    }

    channel(name) {
        if (name in this._channels) {
            return this._channels[name]
        }
        const channel = new WhippetChannel(this, name)
        this._channels[name] = channel
        this._sendChannel(name)
        return channel
    }

    broadcast(event, payload) {
        const message = {
            type: event,
            channel: null,
            payload,
        }

        if (this._socket == null) {
            this._buffer.push(message)
        } else {
            this._sendMessage(message)
        }
    }

    search(channelId) {
        if (channelId == null) {
            return []
        }
        const query = encodeURIComponent(`eq:channel_id:${ JSON.stringify(channelId) }`)
        return fetch(`${ this.apiAddress }apps/${ this._appid }/events?event_type=message&q=${ query }`)
            .then(r => r.json())
            .then(list => list.map(message => message.payload))
    }

}
