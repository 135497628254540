/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Yunjia Li <thomas.hewett@launchbase.solutions>, Dec 2018
 * ==========================================================================
 */

// @flow

import React, { PureComponent } from 'react'
import ErrorBoundary from 'ErrorBoundary'
import localise from 'languages'
import bookingSearchStyle from './bookingsearch.less'
import { DayRangePickerInput } from 'components'
import { Form, Menu, Button } from 'semantic-ui-react'
import type { ID, Guests, DateRange } from 'utils/flowtypes/models'  
import type { SemanticEvent, SemanticEventProps } from 'utils/flowtypes/semantic'

type Props = {
    onSearch: Function,
    guests: Guests,
    filteredGuests: Array<ID>,
    initialFrom: ?Date,
    initialTo: ?Date
}

type State = {
    from: ?Date,
    to: ?Date,
    filteredGuests: Array<ID>,
    derivedStateFromProps: boolean
}

class BookerBookingSearchHeader extends PureComponent<Props, State> {
    state = {
        from: null,
        to: null,
        filteredGuests: [],
        derivedStateFromProps: false
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (!state.derivedStateFromProps) {
            return {
                ...state,
                derivedStateFromProps: true,
                from: props.initialFrom || null,
                to: props.initialTo || null,
                filteredGuests: props.filteredGuests || []
            }
        }

        return null
    }

    handleChange = (e: SemanticEvent, props: SemanticEventProps) => {
        this.setState({ [props.name]: props.value })
    }

    handleDatesChange = (daterange: DateRange) => {
        this.setState({
            from: daterange.from,
            to: daterange.to
        })
    }

    handleClearSearch = () => {
        this.setState({
            from: null,
            to: null,
            filteredGuests: []
        }, this.handleSubmit)
    }

    handleSubmit = () => {
        this.props.onSearch({
            from: this.state.from ? this.state.from : null,
            to: this.state.to ? this.state.to : null,
            filteredGuests: this.state.filteredGuests
        })
    }

    render() {
        const { initialFrom, initialTo } = this.props
        const { from, to } = this.state
        const { filteredGuests } = this.state
        const guests = this.props.guests.map(guest => ({
            key: guest.id,
            text: guest.name,
            value: guest.id
        }))
        const searchActive = from || to || filteredGuests.length > 0


        return(
            <ErrorBoundary>
                <Menu size='small' borderless>
                    <Menu.Item>
                        <Form>
                            <DayRangePickerInput
                                label='Filter dates'
                                fromPlaceholder='Filter from'
                                toPlaceholder='Filter to'
                                onChange={this.handleDatesChange}
                                initialFromDate={initialFrom}
                                initialToDate={initialTo}
                            />
                        </Form>
                    </Menu.Item>
                    <Menu.Item>
                        <Form>
                            <Form.Dropdown
                                className={bookingSearchStyle.guestdropdown}
                                placeholder={localise('GUEST.SELECT')}
                                name='filteredGuests'
                                value={filteredGuests}
                                onChange={this.handleChange}
                                options={guests}
                                label='Guests'
                                multiple
                                search
                                selection
                            />
                        </Form>
                    </Menu.Item>
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Button
                                content='Apply Filters'
                                primary
                                fluid
                                onClick={this.handleSubmit}
                            />
                        </Menu.Item>
                        {
                            searchActive &&
                            <Menu.Item>
                                <Button
                                    onClick={this.handleClearSearch}
                                    content='Clear filters'
                                    basic
                                />
                            </Menu.Item>
                        }
                    </Menu.Menu>
                </Menu>
            </ErrorBoundary>
        )
    }
}

export default BookerBookingSearchHeader