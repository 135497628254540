/**
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, August 2018
 * ==========================================================================
 */

import React, { PureComponent } from 'react'
import style from './numberinput.less'

import {
    Button
} from 'semantic-ui-react'

class NumberInput extends PureComponent {
    handleMinusClick = () => {
        const { value, min, step = 1 } = this.props
        
        let newValue = value - step

        if (min && newValue < min)
            newValue = min
        
        this.handleChange(value, newValue)
    }

    handlePlusClick = () => {
        const { value, max, step = 1 } = this.props
        
        let newValue = value + step
        
        if (max && newValue > max)
            newValue = max
        
        this.handleChange(value, newValue)
    }

    handleChange = (oldValue, newValue) => {
        if (oldValue !== newValue) {
            this.props.onChange(null, {
                value: newValue,
                name: this.props.name
            })
        }
    }
    
    render() {
        const { min, max, label, value, inlineStyle, valueLabel } = this.props

        return (
            <div className={style.container} style={inlineStyle}>
                {
                    label &&
                    <div>
                        <label className={style.label}>
                            {label}
                        </label>
                    </div>
                }
                <div>
                    <Button
                        circular
                        basic
                        icon='minus'
                        onClick={this.handleMinusClick}
                        disabled={value === min}
                    />
                        <span className={style.number}>
                            {value}{valueLabel}
                        </span>
                    <Button
                        circular
                        basic
                        icon='plus'
                        onClick={this.handlePlusClick}
                        disabled={value === max}
                    />
                </div>
            </div>
        )
    }
}

export default NumberInput