/*
 * Copyright (C) LaunchBase LTD - All Rights Reserved 
 * Unauthorized copying of this file, via any medium is strictly prohibited 
 * Proprietary and confidential 
 * Written by Thomas Hewett <thomas.hewett@launchbase.solutions>, Feb 2019
 * ========================================================================
 */

// @flow

import * as React from 'react'
import { withApollo } from 'react-apollo'
import { certifications } from 'queries/certifications'
import { showErrorNotification } from 'utils/notifications'
import { connect } from 'react-redux'
import type { Certification, ModelTypes, User } from 'utils/flowtypes/models'
import type { ApolloClient } from 'utils/flowtypes/apollo'

const DEFAULT_PER_PAGE = 15

type Props = {
    client: ApolloClient,
    user: User
}

type State = {
    certifications: Certification[],
    currentPage: number,
    totalPages: number,
    count: number,
    loading: boolean
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default function withPendingCertifications(
    WrappedComponent: React.AbstractComponent<{}>,
    modelType?: ModelTypes,
    perPage: number = DEFAULT_PER_PAGE): React.AbstractComponent<{}>
{
    class HigherOrderComponent extends React.Component<Props, State> {
        state = {
            certifications: [],
            currentPage: 1,
            totalPages: 1,
            count: 0,
            loading: false
        }

        componentDidMount() {
            this._fetch()
        }

        handleGetNextPage = () => {
            this.setState(prevState => {
                if (prevState.currentPage < prevState.totalPages) {
                    return { currentPage: prevState.currentPage + 1 }
                }
                return { currentPage: prevState.currentPage }
            }, this._fetch)
        }

        handleGetPreviousPage = () => {
            this.setState(prevState => {
                if (prevState.currentPage > 1) {
                    return { currentPage: prevState.currentPage - 1 }
                }
                return { currentPage: prevState.currentPage }
            }, this._fetch)
        }

        handleGetPage = (page: number) => {
            this.setState({
                currentPage: page
            }, this._fetch)
        }

        _fetch = async () => {
            this.setState({ loading: true })

            // certification data is only available to admins
            if (this.props.user != null && this.props.user.is_admin) {
                const variables = {}
                variables.approved = 'pending'
                variables.page = this.state.currentPage - 1,
                variables.perPage = perPage

                if (modelType) {
                    variables.type = modelType
                }

                const response = await this.props.client.query({
                    query: certifications,
                    variables,
                    fetchPolicy: 'no-cache'
                }).catch(e => e)
    
                this.setState({ loading: false })
    
                if (response instanceof Error) {
                    showErrorNotification('Unable to load certifications. Please try again later.')
                    console.error(response)
                } else {
                    this.setState({
                        certifications: response.data.certifications.list,
                        totalPages: response.data.certifications.pagination.total_pages,
                        count: response.data.certifications.pagination.count
                    })
                }
            }
        }

        render() {
            const certifications = {
                getNextPage: this.handleGetNextPage,
                getPreviousPage: this.handleGetPreviousPage,
                getPage: this.handleGetPage,
                currentPage: this.state.currentPage,
                startOfList: this.state.currentPage === 1,
                endOfList: this.state.currentPage === this.state.totalPages,
                certifications: this.state.certifications,
                refetch: this._fetch,
                loading: this.state.loading,
                count: this.state.count,
                totalPages: this.state.totalPages
            }

            return (
                <WrappedComponent
                    certifications={certifications}
                    {...this.props}
                />
            )
        }
    }

    return withApollo(connect(mapStateToProps)(HigherOrderComponent))
}